import React, { Component, Fragment } from 'react';
import Tabs from 'devextreme-react/tabs';
import date from './Date';
import AdeverinteSelectie from './AdeverinteSelectie/AdeverinteSelectie';
import AdeverinteTip from './AdeverinteTip/AdeverinteTip';
import ListaDeclaratii from './ListaDeclaratii/ListaDeclaratii';
import Notificari from './Notificari/Notificari';

class PopAdeverinteTip extends Component {
    state = {
        tabSelectat: null
    }

    handleOnItemClick = (date) => {
        this.setState({ tabSelectat: date.id })
    }

    render() {
        let deAfisat = null;
        switch (this.state.tabSelectat) {
            case 1:
                /*ATENTIE! Orice modificare faci aici, fa si la default:*/
                deAfisat = <AdeverinteSelectie selectat={this.props.gospSelectata} ascunde={this.props.ascunde} />;
                break;
            case 2:
                deAfisat = <AdeverinteTip selectat={this.props.gospSelectata} ascunde={this.props.ascunde} />;
                break;
            case 3:
                deAfisat = <ListaDeclaratii selectat={this.props.gospSelectata} ascunde={this.props.ascunde} />;
                break;
            case 4:
                deAfisat = <Notificari selectat={this.props.gospSelectata} ascunde={this.props.ascunde} />;
                break;
            default:
                deAfisat = <AdeverinteSelectie selectat={this.props.gospSelectata} ascunde={this.props.ascunde} />;
                break;
        }

        return (
            <Fragment>
                <div id="longtabs">
                    <Tabs
                        dataSource={date}
                        onItemClick={(e) => this.handleOnItemClick(e.itemData)}
                        defaultSelectedIndex={0}
                    />

                    {deAfisat}
                </div>
            </Fragment>
        )
    }
}

export default PopAdeverinteTip;