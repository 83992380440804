import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Utilitare from './../../../../../../content/Utilitare';
import { alert } from "devextreme/ui/dialog";
import DataGrid, { Column, Pager, Paging, Export, FilterRow, FilterPanel } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react';
import clase from './ListaDeclaratii.module.css';
import date from './date';

class ListaDeclaratii extends Component {
    state = {
        selectat: null
    }

    handleOnClick = () => {
        alert("Exporta tabelul");
    }

    componentDidMount() {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Rapoarte/GetListaAdeverinte/' + localStorage.getItem("siruta"),
        })
            .then((response) => {
                if (response.data) {
                    if (response.data == null) {
                        alert("Nu au putut fi citite Adeverinţele. (ListaDeclaratii)");
                    }
                    else {
                        this.setState({
                            listaAdeverinte: response.data
                        });
                    }
                }
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "ListaDeclaratii");
            });
    }

    render() {
        //console.log("ListaDeclaratii state", this.state);

        return (
            <Fragment>
                <DataGrid
                    style={{ marginTop: "5px" }}
                    dataSource={this.state.listaAdeverinte}
                    showBorders={true}
                    rowAlternationEnabled={false}
                >
                    <Export enabled={true} fileName="ListaAdeverinte" allowExportSelectedData={true} />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                    <Column dataField="idGosp" caption="ID Gospodarie" visible={false} alignment="center" width="100px"/>
                    <Column dataField="vol" caption="Volum" alignment="center" width="100px"/>
                    <Column dataField="poz" caption="Poziţie" alignment="center" width="100px"/>
                    <Column dataField="reprezentant" caption="Reprezentant legal" width="200px"/>
                    <Column dataField="model" caption="descriere" />
                    <Column dataField="data" caption="Data" alignment="center" width="200px" />

                    <FilterRow
                        visible={true}
                        applyFilter={true} />
                    <FilterPanel visible={true} />
                </DataGrid>

                <div className={clase.Butoane}>
                    <Button
                        className={clase.Buton}
                        width={120}
                        text="Anulează"
                        type="danger"
                        stylingMode="outlined"
                        onClick={this.props.ascunde}
                    />
                </div>
            </Fragment>
        );
    }
}

export default ListaDeclaratii;