import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import DataGrid, { FilterRow, FilterPanel, Paging, Editing, Column, Form } from 'devextreme-react/data-grid';
import { TextBox, Button, FileUploader, SelectBox, ScrollView } from 'devextreme-react';
import { confirm } from 'devextreme/ui/dialog';
import service from './date';
import { SimpleItem } from 'devextreme-react/form';
import Utilitare from './../../../../content/Utilitare';

export default class DateLocalitate extends Component {
    state = {
        linieSelectata: null,
        stradaSelectata: '',
        stema: null,
        antet: null,
        dateGrid: [],
        refLocalitate: null,
        refLocalitateNS: null,
        deTrimis: null,
        activ: false
    }

    stil = {
        width: '98%',
        height: 'calc(100vh - 57px)',
        margin: 'auto',
        border: '1px solid #ddd',
        textAlign: 'center',
        overflow: "hidden"
    };

    componentDidMount() {
        this.incarcaDate();
    }

    incarcaDate = () => {
        service.getDateLocalitate().then(r => {
            if (r && r.datePrimarie) {
                var date = r.datePrimarie;
                var obj = {
                    id: date.idDatePrimarie,
                    primaria: date.numePrimarie,
                    primar: date.numePrimar,
                    secretar: date.numeSecretar,
                    codPostal: date.adrese.codPostal,
                    codFiscal: date.codFiscal,
                    codSiruta: date.adrese.nomenclatorUAT.codSirutaUAT,
                    telefon: date.adrese.telefon,
                    fax: null,
                    email: date.adrese.email,
                    url: date.url,
                    judet: date.adrese.nomenclatorUAT.denumireJudet,
                    localitate: date.adrese.nomenclatorUAT.denumireLocalitate,
                    strada: date.adrese.artera,
                    numar: date.adrese.numar
                };

                this.setState({
                    datePrimarie: r.datePrimarie,
                    dateGrid: [obj],
                    listaUAT: r.listaUAT,
                    refLocalitate: r.datePrimarie.adrese.nomenclatorUAT.idNomenclatorUAT,
                    refLocalitateNS: r.localitatiComponente[0].idNomenclatorUAT,
                    codSirutaUAT: r.datePrimarie.adrese.nomenclatorUAT.codSirutaUAT,
                    localitatiComponente: r.localitatiComponente,
                    nomenclatorStradal: r.nomenclatorStradal,
                    stema: r.datePrimarie.stema,
                    antet: r.datePrimarie.antet
                });
            }

        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "DateLocalitate");
        })
    }

    handleSalveazaImagini = () => {
        if (this.state.stema === null && this.state.antet === null) {
            alert("Nu aţi selectat nicio imagine.");
            return;
        }
        this.setState({ imaginiSalvate: false }, () => {
            axios({
                data: {
                    siruta: parseInt(localStorage.getItem("siruta")),
                    numeUtilizator: localStorage.getItem("userName"),
                    stema: this.state.stema,
                    antet: this.state.antet
                },
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: "/api/DatePrimarie/InsertImagini"
            }).then((response) => {
                if (response && response.data && response.data === true) {
                    //daca avem raspuns nenull true
                    alert("Salvarea imaginilor s-a efectuat cu succes.");
                    this.setState({ imaginiSalvate: true });
                } else {
                    alert("Imaginile nu au fost salvate! (DateLocalitate.jsx)");
                }

            }).catch((error) => {
                Utilitare.handleEroare(error, "DateLocalitate");
            });
        });
    }

    handleFisier = (fisier, care) => {
        var reader = new FileReader();

        reader.readAsDataURL(fisier);
        var continut = null;

        reader.onload = () => {
            var res = reader.result;

            if (res.split(",").length > 0)
                continut = res.split(",")[1];

            if (care === "stema")
                this.setState({ stema: continut });
            if (care === "antet")
                this.setState({ antet: continut });
        };
        reader.onerror = (error) => {
            alert("S-a produs o eroare la citirea fisierului! (AdeverinteSablon - stema)");
        };
    }

    stergeImagini = (care) => {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "delete",
            url: "/api/DatePrimarie/StergeImagine/" + localStorage.getItem("siruta") + "/" + care
        }).then((response) => {
            if (response && response.data && response.data === true) {
                //daca avem raspuns nenull true
                alert("A fost şters fişierul " + care + ".");
            } else {
                alert("Fişierul nu a fost şters! (DateLocalitate.jsx)");
            }
            this.incarcaDate();
        }).catch((error) => {
            Utilitare.handleEroare(error, "DateLocalitate");
        });
    }

    handleInsereazaStrada = () => {
        if (!this.state.stradaSelectata || this.state.stradaSelectata.trim() === '') {
            alert("Nu aţi completat strada.");
            return;
        }

        var listaStrazi = this.state.nomenclatorStradal ? this.state.nomenclatorStradal.filter(x => x.refNomenclatorUAT === this.state.refLocalitateNS) : null;

        if (listaStrazi.filter(x => x.denumire === this.state.stradaSelectata).length > 0) {
            alert("Această stradă există deja în nomenclator.");
        } else {
            axios({
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: "/api/DatePrimarie/InsertStrada/" + localStorage.getItem("siruta") + "/" + this.state.stradaSelectata + "/" + parseInt(this.state.refLocalitateNS)
            }).then((response) => {
                if (!response || !response.data)
                    alert("Strada nu a fost salvată! (DateLocalitate.jsx)");
                else
                    this.setState({ nomenclatorStradal: response.data, stradaSelectata: null });
            }).catch((error) => {
                Utilitare.handleEroare(error, "DateLocalitate");
            });
        }
    }

    handleModificaStrada = (date) => {
        if (date.changes[0].type === "remove") {
            axios({
                headers: { "Content-Type": "application/json" },
                method: "delete",
                url: "/api/DatePrimarie/StergeStrada/" + parseInt(localStorage.getItem("siruta")) + "/" + date.changes[0].key
            }).then((response) => {
                if (!response || !response.data)
                    alert("Strada nu a fost ştearsă! (DateLocalitate.jsx)");
                else
                    this.setState({ nomenclatorStradal: response.data });
            }).catch((error) => {
                Utilitare.handleEroare(error, "DateLocalitate");
            });
            return;
        }

        var strada = date.changes[0].data;

        var listaStrazi = this.state.nomenclatorStradal
            ? this.state.nomenclatorStradal.filter(x => x.refNomenclatorUAT === this.state.refLocalitateNS)
            : null;

        if (listaStrazi.filter(x => x.denumire === strada.denumire).length > 1) {
            let result = confirm("<i>Doriti sa o stergeti pentru a o pastra pe cea deja existenta?</i>", "Această stradă există.");
            result.then((dialogResult) => {
                if (dialogResult)
                    alert("sterge");
                else
                    return;
            });
        }
        else {
            axios({
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: "/api/DatePrimarie/ModificaStrada/" + parseInt(localStorage.getItem("siruta")) + "/" + strada.idNomenclatorStradal + "/" + strada.denumire
            }).then((response) => {
                if (!response || !response.data)
                    alert("Strada nu a fost modificată! (DateLocalitate.jsx)");
                else
                    this.setState({ nomenclatorStradal: response.data });
            }).catch((error) => {
                Utilitare.handleEroare(error, "DateLocalitate");
            });
        }
    }

    handleSalveazaDatePrimarie = () => {
        if (!this.state.datePrimarie.numePrimarie ||
            this.state.datePrimarie.numePrimarie === "" ||
            !this.state.datePrimarie.adrese) {
            alert("Datele sunt neconforme.");
            return;
        }

        axios({
            data: this.state.datePrimarie,
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: "/api/DatePrimarie/SalveazaPrimarie"
        }).then((response) => {
            if (!response || response.data === null || response.data === false) {
                alert("Datele primăriei nu au fost salvate. (DateLocalitate.jsx)");
            } else {
                alert("Datele primăriei au fost salvate.");
            }
        }).catch((error) => {
            Utilitare.handleEroare(error, "DateLocalitate");
        });
    }

    render() {
        //console.log("DateLocalitate props", this.props);
        //console.log("DateLocalitate state", this.state);

        return (
            <Fragment>
                <div style={this.stil} id='chenar'>
                    <ScrollView height={"100%"}>
                        <h3 style={{ display: "inline-block" }}>Date localitate</h3>

                        <DataGrid
                            style={{ margin: "0 5px 5px 5px" }}
                            dataSource={this.state.dateGrid ? this.state.dateGrid : null}
                            columnAutoWidth={true}
                            allowColumnReordering={true}
                            showBorders={true}
                            wordWrapEnabled={true}
                        >
                            <FilterRow visible={false}
                                applyFilter={false} />
                            <FilterPanel visible={false} />
                        </DataGrid>

                        {/********* Date primarie ********/}
                        <div style={{ width: "30%", display: "inline-block", padding: "0 5px 0 5px" }}>
                            <table width="100%" style={{ textAlign: 'left' }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>Denumire:</p>
                                        </td>
                                        <td>
                                            <TextBox
                                                value={this.state.datePrimarie ? this.state.datePrimarie.numePrimarie : null}
                                                onValueChanged={(e) => {
                                                    var dp = { ...this.state.datePrimarie };
                                                    var activ = false;
                                                    if (dp.numePrimarie !== e.value)
                                                        activ = true;
                                                    dp.numePrimarie = e.value;
                                                    this.setState({ datePrimarie: dp, activ: activ });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Primar:</p>
                                        </td>
                                        <td>
                                            <TextBox
                                                value={this.state.datePrimarie ? this.state.datePrimarie.numePrimar : null}
                                                onValueChanged={(e) => {
                                                    var dp = { ...this.state.datePrimarie };
                                                    var activ = false;
                                                    if (dp.numePrimar !== e.value)
                                                        activ = true;
                                                    dp.numePrimar = e.value;
                                                    this.setState({ datePrimarie: dp, activ: activ });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Secretar:</p>
                                        </td>
                                        <td>
                                            <TextBox
                                                value={this.state.datePrimarie ? this.state.datePrimarie.numeSecretar : null}
                                                onValueChanged={(e) => {
                                                    var dp = { ...this.state.datePrimarie };
                                                    var activ = false;
                                                    if (dp.numeSecretar !== e.value)
                                                        activ = true;
                                                    dp.numeSecretar = e.value;
                                                    this.setState({ datePrimarie: dp, activ: activ });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Inspector Taxe:</p>
                                        </td>
                                        <td>
                                            <TextBox
                                                value={this.state.datePrimarie ? this.state.datePrimarie.numeInspT : null}
                                                onValueChanged={(e) => {
                                                    var dp = { ...this.state.datePrimarie };
                                                    var activ = false;
                                                    if (dp.numeInspT !== e.value)
                                                        activ = true;
                                                    dp.numeInspT = e.value;
                                                    this.setState({ datePrimarie: dp, activ: activ });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Inspector Registru:</p>
                                        </td>
                                        <td>
                                            <TextBox
                                                value={this.state.datePrimarie ? this.state.datePrimarie.numeInspR : null}
                                                onValueChanged={(e) => {
                                                    var dp = { ...this.state.datePrimarie };
                                                    var activ = false;
                                                    if (dp.numeInspR !== e.value)
                                                        activ = true;
                                                    dp.numeInspR = e.value;
                                                    this.setState({ datePrimarie: dp, activ: activ });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Cod fiscal:</p>
                                        </td>
                                        <td>
                                            <TextBox
                                                value={this.state.datePrimarie ? this.state.datePrimarie.codFiscal : null}
                                                onValueChanged={(e) => {
                                                    var dp = { ...this.state.datePrimarie };
                                                    var activ = false;
                                                    if (dp.codFiscal !== e.value)
                                                        activ = true;
                                                    dp.codFiscal = e.value;
                                                    this.setState({ datePrimarie: dp, activ: activ });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Cod postal:</p>
                                        </td>
                                        <td>
                                            <TextBox
                                                value={this.state.datePrimarie && this.state.datePrimarie.adrese && this.state.datePrimarie.adrese.codPostal
                                                    ? this.state.datePrimarie.adrese.codPostal.toString()
                                                    : null}
                                                onValueChanged={(e) => {
                                                    var dp = { ...this.state.datePrimarie };
                                                    if (dp.adrese.codPostal !== e.value)
                                                        this.setState({ activ: true });
                                                    dp.adrese.codPostal = parseInt(e.value);
                                                    this.setState({ datePrimarie: dp });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Telefon:</p>
                                        </td>
                                        <td>
                                            <TextBox
                                                value={this.state.datePrimarie ? this.state.datePrimarie.adrese.telefon : null}
                                                onValueChanged={(e) => {
                                                    var dp = { ...this.state.datePrimarie };
                                                    var activ = false;
                                                    if (dp.adrese.telefon !== e.value)
                                                        activ = true;
                                                    dp.adrese.telefon = e.value;
                                                    this.setState({ datePrimarie: dp, activ: activ });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Fax:</p>
                                        </td>
                                        <td>
                                            <TextBox
                                                value={this.state.datePrimarie ? this.state.datePrimarie.adrese.fax : null}
                                                onValueChanged={(e) => {
                                                    var dp = { ...this.state.datePrimarie };
                                                    var activ = false;
                                                    if (dp.adrese.fax !== e.value)
                                                        activ = true;
                                                    dp.adrese.fax = e.value;
                                                    this.setState({ datePrimarie: dp, activ: activ });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>E-mail:</p>
                                        </td>
                                        <td>
                                            <TextBox
                                                value={this.state.datePrimarie ? this.state.datePrimarie.adrese.email : null}
                                                onValueChanged={(e) => {
                                                    var dp = { ...this.state.datePrimarie };
                                                    var activ = false;
                                                    if (dp.adrese.email !== e.value)
                                                        activ = true;
                                                    dp.adrese.email = e.value;
                                                    this.setState({ datePrimarie: dp, activ: activ });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>URL:</p>
                                        </td>
                                        <td>
                                            <TextBox
                                                value={this.state.datePrimarie ? this.state.datePrimarie.url : null}
                                                onValueChanged={(e) => {
                                                    var dp = { ...this.state.datePrimarie };
                                                    var activ = false;
                                                    if (dp.url !== e.value)
                                                        activ = true;
                                                    dp.url = e.value;
                                                    this.setState({ datePrimarie: dp, activ: activ });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Județ:</p>
                                        </td>
                                        <td>
                                            <TextBox
                                                value={this.state.datePrimarie ? this.state.datePrimarie.adrese.nomenclatorUAT.denumireJudet : null}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Localitate:</p>
                                        </td>
                                        <td>
                                            <TextBox
                                                value={this.state.datePrimarie ? this.state.datePrimarie.adrese.nomenclatorUAT.denumireLocalitate : null}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Cod SIRUTA:</p>
                                        </td>
                                        <td>
                                            <TextBox
                                                value={this.state.datePrimarie ? this.state.codSirutaUAT.toString() : null}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Strada:</p>
                                        </td>
                                        <td>
                                            <TextBox
                                                value={this.state.datePrimarie ? this.state.datePrimarie.adrese.artera : null}
                                                onValueChanged={(e) => {
                                                    var dp = { ...this.state.datePrimarie };
                                                    var activ = false;
                                                    if (dp.adresa && dp.adresa.artera !== e.value)
                                                        activ = true;
                                                    dp.adrese.artera = e.value;
                                                    this.setState({ datePrimarie: dp, activ: activ });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Numar:</p>
                                        </td>
                                        <td>
                                            <TextBox
                                                value={this.state.datePrimarie ? this.state.datePrimarie.adrese.numar : null}
                                                onValueChanged={(e) => {
                                                    var dp = { ...this.state.datePrimarie };
                                                    var activ = false;
                                                    if (dp.adrese.numar !== e.value)
                                                        activ = true;
                                                    dp.adrese.numar = e.value;
                                                    this.setState({ datePrimarie: dp, activ: activ });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Poliţia:</p>
                                        </td>
                                        <td>
                                            <TextBox
                                                value={this.state.datePrimarie ? this.state.datePrimarie.politia : null}
                                                onValueChanged={(e) => {
                                                    var dp = { ...this.state.datePrimarie };
                                                    var activ = false;
                                                    if (dp.politia !== e.value)
                                                        activ = true;
                                                    dp.politia = e.value;
                                                    this.setState({ datePrimarie: dp, activ: activ });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <Button
                                text="Salveaza modificari"
                                onClick={this.handleSalveazaDatePrimarie}
                                disabled={!this.state.activ}
                            />
                        </div>

                        {/********* Stema si Antetul ********/}
                        <div style={{ width: "30%", display: "inline-block", verticalAlign: "top", textAlign: "center", padding: "0 5px 0 5px", backgroundColor: "#f5f5f5", height: "80%" }}>
                            <FileUploader
                                width={250}
                                selectButtonText="Încarcă stema de pe disc"
                                labelText=""
                                accept="image/*"
                                uploadMode="useForm"
                                onValueChanged={(e) => { this.handleFisier(e.value[0], "stema") }}
                            />
                            <FileUploader
                                width={250}
                                selectButtonText="Încarcă antet de pe disc"
                                labelText=""
                                accept="image/*"
                                uploadMode="useForm"
                                onValueChanged={(e) => { this.handleFisier(e.value[0], "antet") }}
                            />
                            <Button style={{ display: "inline-block" }} className="button" text="Salvează imaginile" onClick={this.handleSalveazaImagini} />
                            <Button style={{ display: "inline-block" }} className="button" text="Şterge stema" onClick={() => this.stergeImagini("stema")} />
                            <Button style={{ display: "inline-block" }} className="button" text="Şterge antet" onClick={() => this.stergeImagini("antet")} />
                            <p>Stema:</p>
                            <div><img width="20%" src={this.state.stema ? "data:image/*;base64," + this.state.stema : null} alt="Stema" /></div>
                            <p>Antet:</p>
                            <div><img width="100%" src={this.state.antet ? "data:image/*;base64," + this.state.antet : null} alt="Antet" /></div>
                        </div>

                        {/********* Strazi ********/}
                        <div style={{ width: "30%", display: "inline-block", verticalAlign: "top", padding: "0 5px 0 5px" }}>
                            <p>Componente:</p>
                            <SelectBox
                                style={{ margin: "0 5px 5px 5px" }}
                                dataSource={this.state.localitatiComponente ? this.state.localitatiComponente : null}
                                valueExpr={"idNomenclatorUAT"}
                                displayExpr={"denumireLocalitate"}
                                value={this.state.refLocalitateNS ? this.state.refLocalitateNS : null}
                                onValueChange={(e) => { this.setState({ refLocalitateNS: e }) }}
                            />
                            <TextBox
                                style={{ margin: "0 5px 5px 5px" }}
                                id="strada"
                                placeholder="Strada..."
                                value={this.state.stradaSelectata && this.state.stradaSelectata ? this.state.stradaSelectata : null}
                                onValueChanged={(e) => { this.setState({ stradaSelectata: e.value }) }}
                            />
                            <DataGrid
                                style={{ margin: "0 5px 5px 5px" }}
                                dataSource={this.state.nomenclatorStradal ? this.state.nomenclatorStradal.filter(x => x.refNomenclatorUAT === this.state.refLocalitateNS) : null}
                                keyExpr={"idNomenclatorStradal"}
                                columnAutoWidth={true}
                                allowColumnReordering={true}
                                showBorders={true}
                                wordWrapEnabled={true}
                                focusedRowEnabled={false}
                                onRowClick={(e) => { this.setState({ linieSelectata: e.data, stradaSelectata: e.data.denumire }) }}
                                selectedRowKeys={this.state.linieSelectata && this.state.linieSelectata.idNomenclatorStradal ? [this.state.linieSelectata.idNomenclatorStradal] : null}
                                onSaved={(e) => { this.handleModificaStrada(e) }}
                                onContextMenuPreparing={(e) => {
                                    if (e && e.row && e.row.data)
                                        this.setState({ linieSelectata: e.row.data, stradaSelectata: e.row.data.denumire }, () => {
                                            e.items = [{
                                                text: "Modifică",
                                                onItemClick: function () {
                                                    e.component.editRow(e.row.rowIndex);
                                                }
                                            },
                                            {
                                                text: "Şterge",
                                                onItemClick: function () {
                                                    e.component.deleteRow(e.row.rowIndex);
                                                }
                                            }];
                                        })
                                    else {
                                        this.setState({ linieSelectata: e.row.data, stradaSelectata: e.row.data.denumire });
                                        e.items = [];
                                    }
                                }}
                            >
                                {(!localStorage.getItem("checkClickDreapta").replaceAll(" ", "").split(",").includes(localStorage.getItem("siruta").toString()))
                                    ? <Editing
                                        mode="form"
                                    >
                                        <Form>
                                            <SimpleItem dataField="idNomenclatorStradal" disabled />
                                            <SimpleItem dataField="denumire" />
                                            <SimpleItem dataField="refNomenclatorUAT" visible={false} />
                                        </Form>
                                    </Editing>
                                    : <Editing
                                        mode="form"
                                        allowDeleting={true}
                                        allowUpdating={true}
                                        useIcons={true}
                                    >
                                        <Form>
                                            <SimpleItem dataField="idNomenclatorStradal" disabled />
                                            <SimpleItem dataField="denumire" />
                                            <SimpleItem dataField="refNomenclatorUAT" visible={false} />
                                        </Form>
                                    </Editing>
                                }
                                <Paging defaultPageSize={10} />
                                <FilterRow visible={true}
                                    applyFilter={false} />
                                <FilterPanel visible={false} />
                                <Column dataField="idNomenclatorStradal" caption={"Id"} width={"20%"} visible={false} />
                                <Column dataField="denumire" caption={"Denumire"} />
                                <Column dataField="refNomenclatorStradal" visible={false} />
                            </DataGrid>
                            <Button
                                text="Salvează strada"
                                onClick={this.handleInsereazaStrada}
                            />
                        </div>
                    </ScrollView>
                </div>
            </Fragment>
        );
    }
}