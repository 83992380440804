import React, { Component, Fragment } from 'react';
import axios from 'axios';
//import { alert } from "devextreme/ui/dialog";
import CapVar from './CapVar/CapVar';
import MeniuCap1 from './CapVar/MeniuCap1';
import MeniuCap2b from './CapVar/MeniuCap2b';
import MeniuCap11 from './CapVar/MeniuCap11';
import CapFix from './CapFix/CapFix';
import MeniuCapFix from './CapFix/MeniuCapFix';
import { TreeView, Button, ScrollView } from 'devextreme-react';
import service from './DateCapitole';
import clase from './Capitole.module.css';
//import { navigate } from "@reach/router";
import { confirm } from 'devextreme/ui/dialog';
import Utilitare from './../../../../../content/Utilitare';
import { Tooltip } from 'devextreme-react/tooltip';

//!!!nu uita sa importi si meniul capitolului odata cu acesta

export default class Capitole extends Component {
    constructor(props) {
        super(props);
        this.refCapVar = React.createRef(); //declara variabila ca referinta, valoarea dfiind setata mai jos, cand se utilizeaza componenta CapVar
        //aceasta referinta ne ajuta sa setam din parinte (acest Capitole) state-ul copilului (CapVar) pentru a declansa rerender (redeseneaza gridul)
        this.refCapFix = React.createRef();

        this.refMeniu1 = React.createRef();
        //this.refMeniuCapFix = React.createRef();
    }

    state = {
        listaCapitole: [],
        opened: true,
        latime: "18%",
        inaltime: Math.floor(window.innerHeight) - 75,
        capitolSelectat: 'CAP. I',
        idParcelaSelectata: null,
        linieSelectata: null,
        capitol: null,
        meniu: null,
        linieDeSters: null
    }

    setLinieDeSters = (idUnic) => {
        this.refCapVar.current.setState({ linieDeSters: idUnic });  //seteaza state-ul in componenta a carei referinta se utilizeaza aici (capVar)
        //this.setState({ linieDeSters: idUnic })
    }

    trimiteCapFix = () => {//ruleaza handleSalveazaInBaza din CapFix.jsx la click pe butonul Salveaza capitol din MeniuCapFix.jsx
        this.refCapFix.current.handleSalveazaInBaza();
    }

    stergeCapitol = () => {
        let result = confirm("<i>Sigur doriţi Ştergere Capitol?</i>", "Confirmare ştergere date");
        result.then((dialogResult) => {
            if (dialogResult) {
                axios({
                    headers: { "Content-Type": "application/json" },
                    method: "delete",
                    url: './api/Capitole/StergeCapitol/' + localStorage.getItem("siruta") + "/" + this.state.capitolSelectat + "/" + this.props.gospodariaSelectata.idRegistrulAgricol,
                })
                    .then((response) => {
                        if (response.data === true) {
                            switch (this.state.capitolSelectat) {              //switch pentru selectare meniuri
                                case 'CAP. I':
                                case 'CAP. II b':
                                case 'CAP. XI':
                                case 'CAP. XII':
                                case 'CAP. XIII':
                                case 'CAP. XIV':
                                case 'CAP. XVa':
                                case 'CAP. XVb':
                                case 'CAP. XVI':
                                    this.refCapVar.current.incarcaCapitol();
                                    break;
                                case 'CAP. II a':
                                case 'CAP. III':
                                case 'CAP. IV a':
                                case 'CAP. IV a1':
                                case 'CAP. IV b1':
                                case 'CAP. IV b2':
                                case 'CAP. IV c':
                                case 'CAP. V a':
                                case 'CAP. V b':
                                case 'CAP. V c':
                                case 'CAP. V d':
                                case 'CAP. VI':
                                case 'CAP. VII':
                                case 'CAP. VIII':
                                case 'CAP. IX':
                                case 'CAP. X a':
                                case 'CAP. X b':
                                    this.refCapFix.current.citesteCapitol();
                                    break;
                                default:
                                    this.refCapVar.current.incarcaCapitol();
                            };
                        }
                    })
                    .catch((error) => {
                        Utilitare.handleEroare(error, "Capitole");
                    });
            }
            else {
                return;
            }
        });
    }

    componentDidUpdate() {
        if (this.props.selectat === 2) {
            if (this.state.inaltime !== window.innerHeight - 118) {
                this.setState({ inaltime: window.innerHeight - 118 })
            }
        } else {
            if (this.state.inaltime !== Math.floor(window.innerHeight) - 75) {
                this.setState({ inaltime: Math.floor(window.innerHeight) - 75 })
            }
        }
    }

    //seteaza in state linia adusa din CapVar.jsx
    setLinieSelectata = (linie) => {
        if (this.state.capitolSelectat === 'CAP. I')
            this.refMeniu1.current.trimiteLinieSelectataMeniu(linie, this.props.gospodariaSelectata);

        this.setState({ linieSelectata: linie });
    }

    //returneaza din state linia adusa din CapVar.jsx pentru a fi trimisa la meniuri, apoi la transfer, adeverinte sablon etc.
    getLinieSelectata = () => {
        return this.state.linieSelectata;
    }

    handleLatime = () => {
        if (this.state.latime === "18%") {
            this.setState({ latime: "100%" })
        } else {
            this.setState({ latime: "18%" })
        }
    }

    handleStergeLinieSelectata = () => {
        this.setState({ linieSelectata: null });
        this.refCapVar.current.incarcaCapitol();
    }

    handleSelectieCapitol = (cap) => {
        var idCapitol = 1;
        if (cap !== "CAP. I") {
            const find = (array, prescurtat) => {//fctie recursiva care cauta id-ul dupa prescurtarea capitolului
                var result;
                array.some(o => result = o.prescurtat === cap ? o.id : find(o.items || [], prescurtat));
                return result;
            };

            idCapitol = find(this.state.listaCapitole, cap);
        }

        let capitol = null;
        let meniuCapitol = null;
        switch (cap) {              //switch pentru selectare meniuri
            case 'CAP. I':
                meniuCapitol = <MeniuCap1
                    //gospodariaSelectata={this.props.gospodariaSelectata}
                    //linieSelectata={this.state.linieSelectata}
                    inapoi={this.props.inapoi}
                    ref={this.refMeniu1}
                    stergeCapitol={this.stergeCapitol}
                    listaDeclaratii={this.state.listaDeclaratii ? this.state.listaDeclaratii : null}
                />;
                break;
            case 'CAP. II b':
            case 'CAP. XI':
                meniuCapitol = <MeniuCap2b
                    gospodariaSelectata={this.props.gospodariaSelectata}
                    getLinieSelectata={this.getLinieSelectata}
                    anSelectat={this.props.anSelectat}
                    inapoi={this.props.inapoi}
                    setLinieDeSters={this.setLinieDeSters}
                    capitol={cap}
                    stergeLinieSelectata={this.handleStergeLinieSelectata}
                    stergeCapitol={this.stergeCapitol}
                    listaDeclaratii={this.state.listaDeclaratii ? this.state.listaDeclaratii : null}
                />;
                break;
            case 'CAP. II a':
            case 'CAP. III':
            case 'CAP. IV a':
            case 'CAP. IV a1':
            case 'CAP. IV b1':
            case 'CAP. IV b2':
            case 'CAP. IV c':
            case 'CAP. V a':
            case 'CAP. V b':
            case 'CAP. V c':
            case 'CAP. V d':
            case 'CAP. VI':
            case 'CAP. VII':
            case 'CAP. VIII':
            case 'CAP. IX':
            case 'CAP. X a':
            case 'CAP. X b':
            case 'CAP. XII':
            case 'CAP. XIII':
            case 'CAP. XIV':
            case 'CAP. XVI':
                meniuCapitol =
                    <MeniuCapFix
                        cap={cap}
                        idGospSelectata={this.props.gospodariaSelectata.idRegistrulAgricol}
                        inapoi={this.props.inapoi}
                        trimiteCapFix={this.trimiteCapFix}
                        stergeCapitol={this.stergeCapitol}
                        listaDeclaratii={this.state.listaDeclaratii ? this.state.listaDeclaratii : null}
                    />;
                break;
            case 'CAP. XVa':
            case 'CAP. XVb':
                meniuCapitol =
                    <MeniuCap11
                        gospodariaSelectata={this.props.gospodariaSelectata}
                        getLinieSelectata={this.getLinieSelectata}
                        anSelectat={this.props.anSelectat}
                        inapoi={this.props.inapoi}
                        setLinieDeSters={this.setLinieDeSters}
                        capitol={cap}
                        stergeCapitol={this.stergeCapitol}
                        listaDeclaratii={this.state.listaDeclaratii ? this.state.listaDeclaratii : null}
                    />;
                break;
            default:
                capitol = <h3 style={{ margin: "auto" }}>Nu ați selectat un capitol valid sau nu ați selectat o gospodărie</h3>;
        };

        switch (cap) {              //switch pentru selectare capitol
            case 'CAP. I':
            case 'CAP. II b':
            case 'CAP. XI':
            case 'CAP. XII':
            case 'CAP. XIII':
            case 'CAP. XIV':
            case 'CAP. XVa':
            case 'CAP. XVb':
            case 'CAP. XVI':
                capitol = this.props.gospodariaSelectata
                    ? <CapVar/*Capitolele cu nr. de randuri variabil*/
                        idGospSelectata={this.props.gospodariaSelectata.idRegistrulAgricol}
                        anSelectat={this.props.anSelectat}
                        setLinieSelectata={this.setLinieSelectata}
                        capitol={idCapitol}
                        cap={cap}
                        ref={this.refCapVar}  //referinta catre CapVar
                    />
                    : <p>Selectati o gospodarie pentru afisarea datelor!</p>;
                break;
            case 'CAP. II a':
            case 'CAP. III':
            case 'CAP. IV a':
            case 'CAP. IV a1':
            case 'CAP. IV b1':
            case 'CAP. IV b2':
            case 'CAP. IV c':
            case 'CAP. V a':
            case 'CAP. V b':
            case 'CAP. V c':
            case 'CAP. V d':
            case 'CAP. VI':
            case 'CAP. VII':
            case 'CAP. VIII':
            case 'CAP. IX':
            case 'CAP. X a':
            case 'CAP. X b':
                capitol = this.props.gospodariaSelectata
                    ? <CapFix
                        capitol={idCapitol}
                        cap={cap}
                        idGospSelectata={this.props.gospodariaSelectata.idRegistrulAgricol}
                        anSelectat={this.props.anSelectat}
                        ref={this.refCapFix}  //referinta catre CapVar
                        meniu={this.meniuCapitol}
                        butonDisabled={this.handleButonDisabled}
                    />
                    : <p>Selectati o gospodarie pentru afisarea datelor!</p>;
                break;
            default:
                capitol = <h3 style={{ margin: "auto" }}>Nu ați selectat un capitol valid sau nu ați selectat o gospodărie</h3>;
        };

        this.setState({ capitolSelectat: cap, capitol: capitol, meniu: meniuCapitol }, () => { this.props.capitolSelectat(cap) })
    }

    handleParcelaSelectata = (date) => {
        this.setState({ idParcelaSelectata: date });
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({ inaltime: Math.floor(window.innerHeight) - 75 })
        });
        let an = this.props.anSelectat;
        service.getCapitole(an, this.props.gospodariaSelectata.idRegistrulAgricol).then(r => {
            this.setState({ listaCapitole: r.capitole, listaDeclaratii: r.listaDeclaratii });
            this.handleSelectieCapitol("CAP. I");
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "Capitole");
        })
    }

    render() {
        //console.log("capitole props", this.props);
        //console.log("capitole state", this.state);

        const itemTemplate = (item) => {
            return <div title={item.text}>{item.text}</div>;
        };

        return (
            <Fragment>
                <div style={{ margin: '0 5px 0 5px' }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button
                            icon="chevrondoubleright"
                            type="normal"
                            stylingMode="contained"
                            hint={"Extinde capitole"}
                            onClick={this.handleLatime}
                        />
                        {this.state.latime === '100%' ? null :
                            <div id="meniuCapitol">
                                {this.state.meniu}
                            </div>
                        }
                    </div>

                    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                        <TreeView
                            height={document.getElementById('chenar').offsetHeight - 75}
                            className={clase.Tree}
                            width={this.state.latime}
                            id="tw1"
                            showScrollbar='always'
                            items={this.state.listaCapitole}
                            onItemClick={(e) => this.handleSelectieCapitol(e.itemData.prescurtat)}
                            //itemTemplate={itemTemplate}
                            itemRender={itemTemplate}
                        >
                        </TreeView>
                        {this.state.latime === '100%' ? null :
                            <ScrollView
                                height={document.getElementById('chenar').offsetHeight - 70}
                                className={clase.CapVar}
                                width={"83%"}
                                scrollByContent={true}
                                showScrollbarMode={"onScroll"}
                            >
                                <div>{this.state.capitol}</div>
                            </ScrollView>}
                    </div>
                </div>
            </Fragment >
        )
    }
}