import React, { Component, Fragment } from 'react';
import { alert } from "devextreme/ui/dialog";
import { Button, CheckBox, TextBox, RadioGroup } from 'devextreme-react';
import clase from './AdeverinteSelectie.module.css';
import axios from 'axios';
import Utilitare from './../../../../../../content/Utilitare';
import service from '../../../Jos/Capitole/CapVar/AdeverinteSablon/DateSablon';

class AdeverinteSelectie extends Component {
    state = {
        tipExport: [{ id: 0, text: "word" }, { id: 2, text: "excel" }, { id: 3, text: "pdf" }],
        tipExportSelectat: { id: 0, text: "word" },
        toate: [],
        scop: '_',
        nrRegistratura: "........",
        caleFisier: "_",
        numeFisier: "_",
        numarDisabled: false
    }

    componentDidMount() {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Rapoarte/GetAdeverinteTipSelectie/' + localStorage.getItem("siruta"),
        })
            .then((response) => {
                if (response.data) {
                    if (response.data == null) {
                        alert("Nu au putut fi citite Adeverinţele. (AdeverinteSelectie.jsx)");
                    }
                    else
                        this.setState({
                            idPrim: response.data.idPrim,
                            iduser: response.data.iduser,
                            tipuriAdeverinte: response.data.listaRapoarte,
                            reportServer: response.data.reportServer,
                            listaAni: JSON.parse(localStorage.getItem("listaAni")),
                            anSelectat: JSON.parse(localStorage.getItem("anSelectat"))
                        });
                }
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "AdeverinteSelectie");
            });
    }

    handleGetNrRegistratura = () => {
        /*pentru prezentare video*/
        //this.setState({
        //    nrRegistratura: (Math.floor(Math.random() * 1000) + 1).toString(),
        //    caleFisier: "_",
        //    numeFisier: "_",
        //    numarDisabled: true
        //});
        //return;
        /*end of pentru prezentare video*/

        service.getNumarRegistratura(this.props.selectat.refDatePrimarie, this.props.selectat.idRegistrulAgricol).then(r => {
            if (!r.nrAdeverinta.nrCur
                || r.nrAdeverinta.nrCur === '........'
                || !r.nrAdeverinta.numeFisier
                || r.nrAdeverinta.numeFisier === ""
                || !r.nrAdeverinta.cale
                || r.nrAdeverinta.cale === "")
                alert("Nu există legătură cu aplicaţia Document Management şi Arhivare.<br />Pentru a instala/restaura interconectivitatea, contactaţi administratorul aplicaţiei.");

            this.setState({
                nrRegistratura: r.nrAdeverinta.nrCur,
                caleFisier: r.nrAdeverinta.cale && r.nrAdeverinta.cale !== "" ? r.nrAdeverinta.cale : "_",
                numeFisier: r.nrAdeverinta.numeFisier && r.nrAdeverinta.cale !== "" ? r.nrAdeverinta.numeFisier : "_",
                numarDisabled: true
            });
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "AdeverinteSablon");
            this.setState({
                nrRegistratura: "........",
                numarDisabled: false
            });
        })
    }

    handleNrRegistratura = (valoare) => {
        this.setState({ nrRegistratura: valoare });
    }

    showFile = (blob) => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([blob], { type: "application/" + this.state.tipExportSelectat.text })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        var extensie = "docx";
        switch (this.state.tipExportSelectat.text) {
            case "word":
                extensie = "doc";
                break;
            case "excel":
                extensie = "xls";
                break;
            case "pdf":
                extensie = "pdf";
                break;
            default:
                extensie = "word";
                break;
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download = "file." + extensie;
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    }

    handleAfiseazaAdeverinta = () => {
        if (this.state.toate.length === 0) {
            alert("Nu aţi selectat niciun capitol.");
            return;
        }

        let URL = "./api/Rapoarte/PreviewReportSelectie?idprim="+this.state.idPrim+'&iduser='+this.state.iduser+'&Parametru_Destinatie='+this.state.scop+'&numeAdeverinta='+"Adeverinte"+'&idreg='+this.props.selectat.idRegistrulAgricol+'&an='+localStorage.getItem("anSelectat")+'&Parametru_NrAdeverinta='+this.state.nrRegistratura+'&Parametru_Model_Adeverinta=3'+'&Parametru_SelectAdeverinte='+this.state.toate.toString()+'&tip=' + this.props.selectat.tip+'&tipExportSelectat='+this.state.tipExportSelectat.text+'&caleFisier='+this.state.caleFisier+'&numeFisier='+this.state.numeFisier;
        console.log(URL);

        axios.get(URL, { responseType: 'blob' })
            .then((response) => {
                this.showFile(response.data);
                this.handleScrieJurnalOperare();

            })
            .catch((e) => {
                console.log("Eroare", e);
                this.handleScrieJurnalOperare();
            });
    }

    handleScrieJurnalOperare = () => {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/Rapoarte/PostAdeverinteJurnalOperare/' + localStorage.getItem("siruta") + '/' + this.props.selectat.idRegistrulAgricol + '/' + " cu selecţie din capitole " + "/" + 0,
        })
            .then((response) => {
                if (!response || !response.data || response.data == false)
                    console.log("Eroare Scriere Jurnal Operare.");
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "AdeverinteSelectie");
            });
    }

    handleSelecteazaTot = () => {
        const toateSelectate = ["1", "3", "4", "6", "8", "13", "17", "19", "23"];//codCapitol din tabela ListaCapitole
        this.setState({ toate: toateSelectate });
    }

    handleDeselecteazaTot = () => {
        const toateDeselectate = [];
        this.setState({ toate: toateDeselectate });
    }

    handleOnValueChanged = (id) => {
        let toateVechi = [...this.state.toate];

        if (toateVechi.indexOf(id) < 0) {
            toateVechi.push(id);
        } else {
            let toateNoi = [...toateVechi].filter(x => x !== id);
            toateVechi = [...toateNoi];
        }
        this.setState({ toate: toateVechi });
    }

    handleScop = (valoare) => {
        this.setState({ scop: valoare });
    }

    render() {
        //console.log("AdeverinteTipSelectie props", this.props);
        //console.log("AdeverinteTipSelectie state", this.state);

        //generam lista de checkbox-uri folosind functia map():
        var checkuri = this.state.tipuriAdeverinte
            ?
            this.state.tipuriAdeverinte.map(x =>
                <div key={x.valPr} onClick={() => this.handleOnValueChanged(x.valPr)}>
                    <CheckBox
                        id={x.valPr}
                        className={clase.Check}
                        defaultValue={false}
                        text={x.denumire}
                        value={this.state.toate.indexOf(x.valPr) < 0 ? false : true}
                    />
                </div>
            )
            : null

        return (
            <Fragment>
                <Button
                    style={{ display: "inline-block", position: "relative", top: "-2px" }}
                    text="Generează număr registratură"
                    type="success"
                    stylingMode="outlined"
                    onClick={this.handleGetNrRegistratura}
                />
                <TextBox
                    width={120}
                    style={{ margin: "10px 0 0 5px", display: "inline-block" }}
                    placeholder="Nr. Registratura"
                    value={this.state.nrRegistratura}
                    onValueChanged={(e) => this.handleNrRegistratura(e.value)}
                    disabled={this.state.numarDisabled === true && this.state.nrRegistratura != "........"}
                />
                <span style={{ margin: "0 10px 0 30px" }}>Exportă </span>
                <RadioGroup
                    style={{ display: "inline-block", position: "relative", top: "5px" }}
                    items={this.state.tipExport}
                    defaultValue={this.state.tipExport[0]}
                    layout="horizontal"
                    onValueChanged={(e) => { this.setState({ tipExportSelectat: e.value }) }}
                />
                <p />
                <p>Reprezentant Legal: <span style={{ fontWeight: 'bold' }}>{this.props.dateGospodarie ? this.props.dateGospodarie.nume + ' ' + this.props.dateGospodarie.initiala + ' ' + this.props.dateGospodarie.prenume : null}</span></p>
                <Button
                    className={clase.Buton}
                    text="Selectează tot"
                    type="default"
                    stylingMode="outlined"
                    onClick={this.handleSelecteazaTot}
                />
                &nbsp;
                <Button
                    className={clase.Buton}
                    text="Deselectează tot"
                    type="default"
                    stylingMode="outlined"
                    onClick={this.handleDeselecteazaTot}
                />

                {checkuri}

                <p style={{ fontWeight: 'bold', marginBottom: "6px" }}>Pentru a-i servi la:</p>
                <TextBox
                    id={"tb2"}
                    className={clase.InputText}
                    placeholder="Introduceţi scopul eliberării adeverinţei..."
                    onValueChanged={(e) => this.handleScop(e.value)}
                />

                <div className={clase.Butoane}>
                    <Button
                        className={clase.Buton}
                        width={180}
                        text="Generează Adeverinţa"
                        type="success"
                        stylingMode="outlined"
                        onClick={this.handleAfiseazaAdeverinta}
                    />

                    <Button
                        className={clase.Buton}
                        width={120}
                        text="Anulează"
                        type="danger"
                        stylingMode="outlined"
                        onClick={this.props.ascunde}
                    />
                </div>

            </Fragment>
        );
    }
}

export default AdeverinteSelectie;