import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import { Button, LoadIndicator } from 'devextreme-react';
import DataGrid, { FilterRow, FilterPanel, Column, Editing, Scrolling, Format, Export, RangeRule, Toolbar, Item } from 'devextreme-react/data-grid';
import service from './DateCapFix';
//import { navigate } from "@reach/router";
import 'devextreme-intl';
import Utilitare from '../../../../../../content/Utilitare';

export default class CapFix extends Component {
    state = {
        dateCapitol: null,
        dateFormular: null,
        salvat: false,
        loadIndicatorVisible: false
    }

    citesteCapitol = () => {
        this.setState({ loadIndicatorVisible: true });

        service.getDateCapitol(this.props.idGospSelectata, this.props.capitol).then(r => {
            this.setState({
                dateCapitol: r.capitole,
                dateFormular: r.formular,
                salvat: false,
                loadIndicatorVisible: false
            })
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "CapFix");
        })
    }

    componentDidMount() {
        this.citesteCapitol();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {//verifica daca se schimba gospodaria ca sa defocuseze linia
        if (prevProps.idGospSelectata !== this.props.idGospSelectata)
            this.handleOnRowClick(null);

        if (prevProps.capitol !== this.props.capitol)
            service.getDateCapitol(this.props.idGospSelectata, this.props.capitol).then(r => {
                this.setState({
                    dateCapitol: r.capitole,
                    dateFormular: r.formular,
                    salvat: false
                })
            }).catch(error => {//in caz de eroare
                Utilitare.handleEroare(error, "CapFix");
            })
    }

    ceLulaMeaTemplate = (text, cod) => {
        return "<div>" + text + (cod ? "<br/> (" + cod.trim() + ")" : "") + "</div>";
    }

    handleBenzi = (benzi) => {
        //sortam benzile
        if (!benzi)
            return;
        var benziOrdonate = benzi.sort((a, b) => (a.colOrder > b.colOrder) ? 1 : ((b.colOrder > a.colOrder) ? -1 : 0));

        return benziOrdonate.map((r) => {
            //var idColoaneDeAdunat = r.total ? r.total.split(',') : []; //array cu id-urile coloanelor de adunat din campul total

            //var numeColoane = [];
            //if (idColoaneDeAdunat.length > 0) //daca avem de facut total luam denumirea coloanelor
            //    numeColoane = this.state.dateFormular.filter(x => idColoaneDeAdunat.includes(x.idListaCampuriTabele.toString())).map(x => { return x.denumire });
            //var coloaneAri = this.state.dateFormular.filter(x => x.destinatie !== null).map(y => { return y.denumire });
            //var coloaneHa = this.state.dateFormular.filter(x => (x.campReadOnly === false || x.total !== null) && x.destinatie === null).map(x => { return x.denumire });

            return <Column
                key={r.id}
                dataField={r.fieldName}
                allowSorting={false}
                caption={r.caption + (r.codColoana ? (" (" + r.codColoana.trim() + ")") : "")}
                headerCellTemplate={this.ceLulaMeaTemplate(r.caption, r.codColoana)}
                visible={true}
                allowEditing={localStorage.getItem("role") === "UtilizatorExtern" || this.verificaAccesIstoric() === false
                    ? localStorage.getItem("role") === "Administrator" ? true : false //inhibare editare pentru UtilizatorExtern
                    : this.state.dateFormular && this.state.dateFormular.find(x => x.denumire === r.fieldName) ?
                        !this.state.dateFormular.find(x => x.denumire === r.fieldName).campReadOnly : false} //atentie la negatie!
                dataType={r.tipCamp === "varchar" ? "string" : "number"}
                calculateCellValue={(rowdata) => {
                    var coloaneValori = this.state.dateFormular.filter(x => x.campReadOnly === false || x.total !== null).map(x => { return x.denumire });

                    if (coloaneValori.indexOf(r.fieldName) < 0)//denumire, codrand... randuri fara valoare
                        return rowdata[r.fieldName];

                    var suma = 0;
                    coloaneValori.forEach(x => { suma += parseFloat(rowdata[x] ?? 0) });

                    if (suma != 0 && (rowdata[r.fieldName] == 0 || !rowdata[r.fieldName]))//randuri cu valoare:
                        return 0
                    else {
                        return r.precizie === 0 && rowdata[r.fieldName]
                            ? Math.round(rowdata[r.fieldName])
                            : rowdata[r.fieldName];
                    }
                }}
            >
                {/*coloaneHa.indexOf(r.fieldName) >= 0
                    ? null :
                    <Format
                    type="fixedPoint"
                    precision={2}
                />*/}
                {r.tipCamp === 'money' ? <Format type="fixedPoint" precision={r.precizie} /> : null}
                {r.tipCamp === 'money' ? <RangeRule message="Valoare invalidă." min={0} /> : null}
                {r.benzi !== undefined ? this.handleBenzi(r.benzi) : null}
            </Column>
        });
    }

    editingStart = (e) => {
        e.cancel = e.data.randReadOnly; //inhiba editarea randurilor readOnly
    }

    trimiteCapFix = () => {
        axios({
            data: this.state.dateCapitol.randuri,
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/Capitole/PostCapRanduriFixe/' + localStorage.getItem("siruta") + "/" + this.props.capitol + "/da" + "/" + (localStorage.getItem("declaratieSelectata") && localStorage.getItem("declaratieSelectata") !== 'null' ? localStorage.getItem("declaratieSelectata") : 0),
        })
            .then((response) => {
                if (response.data === true) {
                    this.citesteCapitol();
                    alert("Datele au fost salvate.");
                }
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "MeniuAplicatie");
            });
    }

    totalizarePeLinii = (refRand) => {
        var randuriNeordonate = [...this.state.dateCapitol.randuri];

        var randuriOrdonate = [];

        randuriNeordonate.forEach(rand => {
            if (rand.total === null) {
                randuriOrdonate.push(rand);
            }
        });

        while (randuriNeordonate.length !== randuriOrdonate.length) {
            let idOrdonate = randuriOrdonate.map(x => { return x.refRand });

            randuriNeordonate.forEach(rand => {
                if (!idOrdonate.includes(rand.refRand)) {
                    let total = rand.total.split(',').map(x => { return parseInt(x) });

                    const filteredArray = total.filter(value => idOrdonate.includes(value));

                    if (filteredArray.length > 0)
                        randuriOrdonate.push(rand);
                }
            })
        }

        var coloaneValori = this.state.dateFormular.filter(x => x.campReadOnly === false).map(y => { return y.denumire });

        //aici se face totalizarea pe coloane. se iau randurile din "total" si se insumeaza, apoi se pun in randul corespunzator.
        randuriOrdonate.forEach(rand => {
            if (rand.total !== null) {//daca e rand de total
                let IDranduriDeTotalizat = rand.total.split(",").map(x => { return parseInt(x) });
                coloaneValori.forEach(c => {
                    let total = 0;
                    IDranduriDeTotalizat.forEach(id => {
                        total += randuriOrdonate.find(x => x.refRand === id)[c] ?? 0;
                    });

                    rand[c] = total;
                })
            }
        })

        //pe mminus
        randuriOrdonate.forEach(rand => {
            if (rand.scade !== null) {//daca e rand de total
                let IDranduriDeTotalizat = rand.scade.split(",").map(x => { return parseInt(x) });
                coloaneValori.forEach(c => {
                    let total = 0;
                    IDranduriDeTotalizat.forEach(id => {
                        total += randuriOrdonate.find(x => x.refRand === id)[c] ?? 0;
                    });

                    rand[c] -= total;
                })
            }
        })

        var coloaneDeTotalizareOrizontal = this.state.dateFormular.filter(x => x.total !== null);
        var coloaneDeTotalOrizontal = this.state.dateFormular.filter(x => x.destinatie !== null);

        randuriOrdonate.forEach(rand => {
            coloaneDeTotalizareOrizontal.forEach(col => {
                if (col.total !== null) {
                    let idUriTotal = col.total.split(",").map(x => { return parseInt(x) });

                    let total = 0;
                    idUriTotal.forEach(id => {
                        let denCol = this.state.dateFormular.find(x => x.idListaCampuriTabele === id).denumire;
                        if (rand[denCol]) {
                            total = total + rand[denCol];
                        }
                    })

                    //if (total !== 0)
                    rand[col.denumire] = total;
                }
            })
        })

        randuriOrdonate.forEach(rand => {
            coloaneDeTotalOrizontal.forEach(col => {
                if (rand[col.denumire] > 99.99) {
                    let ha = Math.floor(rand[col.denumire] / 100);
                    let ari = rand[col.denumire] - (ha * 100);
                    let destinatie = this.state.dateFormular.find(x => x.idListaCampuriTabele === col.destinatie).denumire;
                    //alert("ha " + ha + " ari " + ari + "destinatie" + destinatie);
                    rand[col.denumire] = Utilitare.rotunjire(ari, 2);
                    let suma = (rand[destinatie] ?? 0) + (ha ?? 0);
                    rand[destinatie] = suma;
                }
                if (rand[col.denumire] < 0) {
                    let destinatie = this.state.dateFormular.find(x => x.idListaCampuriTabele === col.destinatie).denumire;
                    let ha = rand[destinatie];
                    let ari = rand[col.denumire];
                    let suma = ha * 100 + ari;
                    rand[destinatie] = Math.floor(suma / 100)
                    rand[col.denumire] = Utilitare.rotunjire(suma - (rand[destinatie] * 100), 2);
                }
            })
        })

        var ordonate = randuriOrdonate.sort((a, b) => (parseInt(a.codRand) > parseInt(b.codRand)) ? 1 : ((parseInt(b.codRand) > parseInt(a.codRand)) ? -1 : 0));
        var dePusInState = this.state.dateCapitol;
        dePusInState.randuri = ordonate;
        this.setState({ dateCapitol: dePusInState });
    }

    handleSalveazaInBaza = () => {
        this.setState({ loadIndicatorVisible: true }, () => { this.trimiteCapFix(); });
    }


    handleToolbarPreparing = (date) => {
        date.toolbarOptions.items[0].location = "before";

        //ascunde butonul de export excel (care este implicit)
        if (date.toolbarOptions.items[0])
            date.toolbarOptions.items[0].visible = false;
    }

    verificaAccesIstoric = () => {
        var ani = JSON.parse(localStorage.getItem("listaAni"));

        var anSelectat = parseInt(localStorage.getItem("anSelectat"));

        var accesIstoric = localStorage.getItem("accesIstoric");
        var siruta = localStorage.getItem("siruta");

        if (anSelectat < Math.max(...ani) && !accesIstoric.includes(siruta))
            return false;

        return true;
    }

    exportWord = () => {
        if (!window.Blob) {
            alert('Your legacy browser does not support this action.');
            return;
        }

        var html, link, blob, url, css;

        // EU A4 use: size: 841.95pt 595.35pt;
        // US Letter use: size:11.0in 8.5in;

        css = (
            '<style>' +
            '@page WordSection1{size: 595.35pt 841.95pt;mso-page-orientation: portrait;}' +
            'div.WordSection1 {page: WordSection1;}' +
            'table{border-collapse:collapse;width:100%;}td{border:1px gray solid;padding:2px;font-size:10px}' +
            '</style>'
        );

        var html1 = "<p>" + localStorage.getItem("dateCapitol") + "</p>" + window.docx.innerHTML;

        console.log(html1);

        var pozPornire = html1.indexOf("</tbody>");
        var pozOprire = html1.indexOf("<tbody role=\"presentation\">");
        var textDeInlocuit = html1.substring(pozPornire, pozOprire + 27);
        html = html1.replace(textDeInlocuit, "").replace("No data", "").replace("Loading...", "").replace("Add a row", "");

        blob = new Blob(['\ufeff', css + html], {
            type: 'application/msword'
        });
        url = URL.createObjectURL(blob);
        link = document.createElement('A');
        link.href = url;

        link.download = 'Document';
        document.body.appendChild(link);
        if (navigator.msSaveOrOpenBlob) navigator.msSaveOrOpenBlob(blob, 'Document.doc'); // IE10-11
        else link.click();  // other browsers
        document.body.removeChild(link);
    }

    render() {
        console.log("CapFix state", this.state);
        //console.log("CapFix props", this.props);

        return (
            <Fragment>
                {this.state.loadIndicatorVisible ?
                    <div style={{ position: "absolute", top: "0px", bottom: "0px", left: "0px", right: "0px", zIndex: "1502", backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", border: "1px solid black", backgroundColor: "white" }}>
                            <p>Se încarcă...</p>
                            <LoadIndicator
                                className="button-indicator"
                                visible={this.state.loadIndicatorVisible}
                            />
                        </div>
                    </div>
                    : null}
                <div id="docx">
                    <div className="WordSection1">
                        <DataGrid
                            dataSource={this.state.dateCapitol && this.state.dateCapitol ? this.state.dateCapitol.randuri : null}
                            height={document.getElementById('chenar').offsetHeight - 75} //offsetHeight ia INALTIMEA elementului cu id-ul 'chenar'
                            style={{ marginTop: "5px", paddingLeft: "10px" }}
                            columnAutoWidth={true}
                            allowColumnReordering={true}
                            showBorders={true}
                            showRowLines={true}
                            rowAlternationEnabled={false}
                            wordWrapEnabled={true}
                            keyExpr={"codRand"}
                            onInitialized={(e) => { this.datagrid = e.component; }}
                            onEditingStart={(e) => { this.editingStart(e) }}
                            onSaved={(e) => {
                                var randModificat = e.changes[0].data;
                                this.totalizarePeLinii(randModificat.refRand);
                            }}
                            onToolbarPreparing={(e) => { this.handleToolbarPreparing(e) }}
                            onContextMenuPreparing={(e) => {
                                if (e && e.row && e.row.data)
                                    if ((this.verificaAccesIstoric() === true && localStorage.getItem("role") !== "UtilizatorExtern") || localStorage.getItem("role") === "Administrator")
                                        this.setState({ linieSelectata: e ? e.row.data : null }, () => {

                                            if (e.row.rowType === "data") {
                                                //if (localStorage.getItem("checkClickDreapta").replaceAll(" ", "").split(",").includes(localStorage.getItem("siruta").toString()))
                                                e.items = [{
                                                    text: "Export Word",
                                                    icon: "exportselected",
                                                    onItemClick: this.exportWord
                                                },
                                                {
                                                    text: "Export Excel",
                                                    icon: "exportxlsx",
                                                    onItemClick: function () {
                                                        e.component.exportToExcel();
                                                    }
                                                }];
                                                //else
                                                //    e.items = [];
                                            }
                                        })
                                    else
                                        this.setState({ linieSelectata: e ? e.row.data : null }, () => {

                                            if (e.row.rowType === "data") {
                                                e.items = [];
                                            }
                                        });
                            }}
                        >
                            <Scrolling mode="virtual" />
                            <FilterRow visible={false}
                                applyFilter={false} />
                            <FilterPanel visible={false} />

                            {/*se iau coloanele din date.js cu service.getColoane() si ce afiseaza doar cele ce indeplinesc conditiile*/}
                            {this.state.dateCapitol && this.state.dateCapitol ? this.handleBenzi(this.state.dateCapitol.benzi) : null}
                            <Editing
                                allowUpdating={true}
                                mode="cell" />
                            <Export enabled={true} fileName={this.props.cap + '_' + this.props.anSelectat} />
                        </DataGrid>
                    </div>
                </div>
            </Fragment>
        );
    }
}