import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import { Button, SelectBox, DateBox, TextBox, LoadIndicator } from 'devextreme-react';
import DataGrid, { Column, Pager, Paging, FilterRow, FilterPanel, Selection } from 'devextreme-react/data-grid';
import clase from './Transfer.module.css';
import { navigate } from "@reach/router";
import service from './DateTransfer';
import Utilitare from './../../../../../../../content/Utilitare';

export default class Transfer extends Component {
    state = {
        loadIndicatorVisible: false,
        tipTransfer: 1,
        dataTransfer: new Date(Date.now()),
        idGospViitoare: null,
        membruSelectat: null //obiect: membrul care cva fi trecut ca proprietar al 
    }

    componentDidMount() {
        let siruta = localStorage.getItem("siruta") ? localStorage.getItem("siruta").replaceAll("\"", "") : null;
        let tip = 7;//cand tip = 7 se iau din coloaneGridView coloanele pentru grid-ul de gospodarii din Transfer parcele
        let an = this.props.anSelectat;

        service.getListaGospodarii(siruta, tip, an).then(r => {
            this.setState({
                dateGospodarii: r.gosp,
                listaColoane: r.cols,
                tipuriGospodarii: r.tipuriGosps,
                amGospodarii: true,
                tipuriTransfer: r.tipuriTransfer,
                tipuriCatFol: r.tipuriCatFol
            })
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "Transfer");
        })
    }

    handleOnRowClick = async (dateGospViitoare) => {
        this.setState({ idGospViitoare: dateGospViitoare.idRegistrulAgricol, membruSelectat: null });

        await axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Capitole/' + dateGospViitoare.idRegistrulAgricol + '/' + 1 + '/' + localStorage.getItem("siruta") + "/",
        })
            .then((response) => {
                //daca e ok, aici primim raspuns
                this.setState({ membri: response.data.randuri });
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "Transfer");
            });
    }

    handleTransfer = async () => {
        var dateDeTrimis = {
            idGospVeche: this.props.gospodariaSelectata.idRegistrulAgricol,
            idGospNoua: this.state.idGospViitoare,
            idUnic: this.props.parcelaSelectata.idUnic,
            dataAct: this.state.dataTransfer,
            tipTransfer: this.state.tipTransfer,
            numeProprietar: this.state.membruSelectat ? this.state.membruSelectat.numeleSiPrenumele : null,
            siruta: parseInt(localStorage.getItem("siruta")),
            capitol: this.props.capitol
        }

        if (['1', '2', '3', /*'4', '5', '6',*/ '7'].includes(dateDeTrimis.tipTransfer.toString())) {
            alert("Transferul nu a fost implementat inca pentru tipul ales.");
            return;
        }

        if (!dateDeTrimis.tipTransfer) {
            alert("Nu aţi selectat tipul transferului.");
            return;
        }
        if (!dateDeTrimis.dataAct) {
            alert("Nu aţi selectat data.");
            return;
        }
        if (!dateDeTrimis.siruta || !dateDeTrimis.idUnic) {
            alert("Utilizatorul curent a fost delogat.");
            navigate('./');
        }

        await axios({
            data: dateDeTrimis,
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/Capitole/TransferParcela/' + localStorage.getItem("check2a"),
        })
            .then((response) => {
                //daca e ok, aici primim raspuns
                this.props.setLinieDeSters(dateDeTrimis.idUnic);
                this.setState({ loadIndicatorVisible: false });
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "Transfer");
            });

        alert("Transfer efectuat.");
        this.props.stergeLinieSelectata();
        this.props.ascunde();
    }

    handleSelectieTipTransfer = (tip) => {
        this.setState({ tipTransfer: tip })
    }

    handleClickMembru = (membru) => {
        this.setState({ membruSelectat: membru });
    }

    handleData = (data) => {
        this.setState({ dataTransfer: data });
    }

    onCellPrepared = (e) => {
        e.cellElement.style.backgroundColor = "#d4d4d4";
        e.cellElement.style.textAlign = "center";
    }

    render() {
        //console.log("transfer.jsx props", this.props);
        //console.log("transfer.jsx state", this.state);

        let gospodarii = this.state.dateGospodarii ? this.state.dateGospodarii.filter(x => x.idRegistrulAgricol != this.props.gospodariaSelectata.idRegistrulAgricol) : null;

        const headerTemplate = (columnHeader) => {
            return <p style={{ width: "100%", textAlign: "center" }}>{columnHeader.column.caption}</p>;
        }

        const dataGrid = this.state.amGospodarii ? <DataGrid
            style={{ margin: "0 5px 0 5px" }}
            height={window.innerHeight / 2}
            id="gospodarii"
            dataSource={gospodarii}
            columnAutoWidth={true}
            allowColumnReordering={true}
            showBorders={true}
            keyExpr={"idRegistrulAgricol"}
            rowAlternationEnabled={false}
            remoteOperations={false}
            focusedRowEnabled={true}
            onRowClick={(e) => { this.handleOnRowClick(e.data); }}
            onRowPrepared={(e) => {
                if (e.rowType === 'data') {
                    if (e.data.ani) {
                        e.rowElement.style.color = 'red';
                    }
                }
                if (e.isSelected) {
                    e.rowElement.style.backgroundColor = 'aquamarine';
                    //e.rowElement.style.color = '#fff';
                }
            }}
        >
            <Selection mode="single" deferred={false} />

            {/*<Scrolling mode="virtual" />*/}

            <FilterRow
                visible={true}
                applyFilter={true} />
            <FilterPanel visible={true} />

            {this.state.listaColoane.map((col) =>
                <Column
                    key={col.idColoaneGridView}
                    dataField={col.denumireCamp}
                    caption={col.denumire}
                    headerCellRender={headerTemplate}
                    width={col.lungime + '%'}
                    visible={col.vizibil}
                />
            )}
            <Paging defaultPageSize={25} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 15, 25, 50]}
                showInfo={true}
            />
        </DataGrid> : null;

        return (
            <Fragment>
                <div style={{ width: "30%", display: "inline-block" }}>
                    {this.state.loadIndicatorVisible ?
                        <div style={{ position: "absolute", top: "0px", bottom: "0px", left: "0px", right: "0px", zIndex: "1502", backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                            <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", border: "1px solid black", backgroundColor: "white" }}>
                                <p>Se încarcă...</p>
                                <LoadIndicator
                                    className="button-indicator"
                                    visible={this.state.loadIndicatorVisible}
                                />
                            </div>
                        </div>
                        : null}

                    <p>Tip transfer</p>
                    <SelectBox
                        className={clase.Margini}
                        dataSource={this.state.tipuriTransfer}
                        valueExpr="valoare"
                        displayExpr="denumire"
                        placeholder="Tip transfer..."
                        defaultValue={1}
                        onValueChanged={(e) => this.handleSelectieTipTransfer(e.value)}
                    />
                    <p>Data contractului</p>
                    <DateBox
                        className={clase.Margini}
                        defaultValue={new Date(Date.now())}
                        type="date"
                        displayFormat="dd.MM.yyyy"
                        acceptCustomValue={false}
                        onValueChanged={(e) => this.handleData(e.value)}
                    />
                    <p>Către:</p>
                    <div>
                        <TextBox
                            width={"100%"}
                            className={clase.Margini}
                            style={{ display: "inline-block" }}
                            disabled
                            value={this.state.membruSelectat ? this.state.membruSelectat.numeleSiPrenumele : ""}//aici afiseaza numele noului proprietar
                        />
                        <p style={{ textAlign: "center", fontWeight: "bold" }}>Se va efectua transferul pentru:</p>
                    </div>

                    <table width={"100%"}>
                        <tbody>
                            <tr>
                                <td>
                                    <p style={{ display: "inline-block" }}>Reprezentant legal/Firma:</p>
                                    <TextBox style={{ display: "inline-block", float: "right" }} id="reprezentant" className={clase.Margini} disabled value={this.props.gospodariaSelectata.numeReprezentantLegal + ' ' + this.props.gospodariaSelectata.initialaReprezentantLegal + ' ' + this.props.gospodariaSelectata.prenumeReprezentantLegal} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p style={{ display: "inline-block" }}>Volum:</p>
                                    <TextBox style={{ display: "inline-block", float: "right" }} id="volum" className={clase.Margini} disabled value={this.props.gospodariaSelectata.volum.toString()} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p style={{ display: "inline-block" }}>Pozitie:</p>
                                    <TextBox style={{ display: "inline-block", float: "right" }} id="pozitie" className={clase.Margini} disabled value={this.props.gospodariaSelectata.pozitie.toString()} />
                                </td>
                            </tr>

                            { /* pentru capitolul IIb  */}
                            {this.props.capitol === "CAP. II b" ?
                                <React.Fragment>
                                    <tr>
                                        <td>
                                            <p style={{ display: "inline-block" }}>Denumirea parcelei:</p>
                                            <TextBox style={{ display: "inline-block", float: "right" }} id="denumire" className={clase.Margini} disabled value={this.props.parcelaSelectata && this.props.parcelaSelectata.denumireParcelaTarlaSola ? this.props.parcelaSelectata.denumireParcelaTarlaSola : null} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style={{ display: "inline-block" }}>Categoria de folosinta:</p>
                                            <TextBox style={{ display: "inline-block", float: "right" }} id="catFol" className={clase.Margini} disabled value={this.props.parcelaSelectata && this.state.tipuriCatFol ? this.state.tipuriCatFol.find(x => x.valoare /*lasa cu 2 egaluri*/ == this.props.parcelaSelectata.categoriaDeFolosinta).denumire : null} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style={{ display: "inline-block" }}>Intravilan:</p>
                                            <TextBox style={{ display: "inline-block", float: "right" }} id="intravilan" className={clase.Margini} disabled value={this.props.parcelaSelectata && (this.props.parcelaSelectata.suprafataIntravilanAri || this.props.parcelaSelectata.suprafataIntravilanAri) ? "da" : "nu"} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style={{ display: "inline-block" }}>Suprafata (ha):</p>
                                            <TextBox style={{ display: "inline-block", float: "right" }} id="suprafata" className={clase.Margini} disabled value={this.props.parcelaSelectata ? (this.props.parcelaSelectata.suprafataIntravilanHa ?? 0 + this.props.parcelaSelectata.suprafataIntravilanHa ?? 0).toString() : null} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style={{ display: "inline-block" }}>Suprafata (ari):</p>
                                            <TextBox style={{ display: "inline-block", float: "right" }} id="suprafata" className={clase.Margini} disabled value={this.props.parcelaSelectata ? (this.props.parcelaSelectata.suprafataIntravilanAri ?? 0 + this.props.parcelaSelectata.suprafataIntravilanAri ?? 0).toString() : null} />
                                        </td>
                                    </tr>
                                </React.Fragment>
                                :/* pentru capitolul XI  */
                                <React.Fragment>
                                    <tr>
                                        <td>
                                            <p style={{ display: "inline-block" }}>Adresa:</p>
                                            <TextBox style={{ display: "inline-block", float: "right" }} id="adresa" className={clase.Margini} disabled value={this.props.parcelaSelectata && this.props.parcelaSelectata.Adresa ? this.props.parcelaSelectata.Adresa : null} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style={{ display: "inline-block" }}>Zona:</p>
                                            <TextBox style={{ display: "inline-block", float: "right" }} id="adresa" className={clase.Margini} disabled value={this.props.parcelaSelectata && this.props.parcelaSelectata.Zona ? this.props.parcelaSelectata.Zona : null} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style={{ display: "inline-block" }}>Suprafaţa construită:</p>
                                            <TextBox style={{ display: "inline-block", float: "right" }} id="suprafata" className={clase.Margini} disabled value={this.props.parcelaSelectata && this.props.parcelaSelectata.suprafataConstruita ? this.props.parcelaSelectata.suprafataConstruita : null} />
                                        </td>
                                    </tr>
                                </React.Fragment>
                            }

                            <tr>
                                <td>
                                    <div style={{ textAlign: "center" }}>
                                        <Button
                                            width={120}
                                            text="Transferă"
                                            type="success"
                                            stylingMode="outlined"
                                            onClick={() => { this.setState({ loadIndicatorVisible: true }); this.handleTransfer() }}
                                        />
                                        &nbsp;
                                        <Button
                                            width={120}
                                            text="Anulează"
                                            type="danger"
                                            stylingMode="outlined"
                                            onClick={this.props.ascunde}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ width: "68%", display: "inline-block", float: "right" }}>
                    {dataGrid}

                    <DataGrid
                        style={{ width: '30%', margin: "auto" }}
                        dataSource={this.state.membri ? this.state.membri : null}
                        focusedRowKey={"id"}
                        rowAlternationEnabled={false}
                        onRowClick={(e) => { this.handleClickMembru(e.data) }}
                        onCellPrepared={this.onCellPrepared}
                    >
                        <Selection mode="single" deferred={false} />
                        <Column dataField={"numeleSiPrenumele"} caption={"Nume şi prenume"} />
                    </DataGrid>

                </div>



            </Fragment>
        )
    }
}