const date = [
    {
        idGosp: 1,
        reprezentantLegal: "Ion I. Ion",
        codFermier: 1234,
        email: "ion@ion.ion",
        telefon: "0700.000000",
        an: 2010
    }, {
        idGosp: 2,
        reprezentantLegal: "Vasile V. Vasile",
        codFermier: 1234,
        email: "vasile@vasile.vas",
        telefon: "0711.111111",
        an: 2010
    }, {
        idGosp: 3,
        reprezentantLegal: "Gheroghe G. Gheorghe",
        codFermier: 1234,
        email: "gheorghe@ion.ion",
        telefon: "0722.222222",
        an: 2014
    }, {
        idGosp: 4,
        reprezentantLegal: "Popescu P. Popescu",
        codFermier: 1234,
        email: "popescu@ion.ion",
        telefon: "0733.333333",
        an: 2011
    }, {
        idGosp: 5,
        reprezentantLegal: "Ion I. Ion",
        codFermier: 1234,
        email: "ion@ion.ion",
        telefon: "0700.000000",
        an: 2010
    }, {
        idGosp: 6,
        reprezentantLegal: "Vasile V. Vasile",
        codFermier: 1234,
        email: "vasile@vasile.vas",
        telefon: "0711.111111",
        an: 2010
    }, {
        idGosp: 7,
        reprezentantLegal: "Gheroghe G. Gheorghe",
        codFermier: 1234,
        email: "gheorghe@ion.ion",
        telefon: "0722.222222",
        an: 2014
    }, {
        idGosp: 8,
        reprezentantLegal: "Popescu P. Popescu",
        codFermier: 1234,
        email: "popescu@ion.ion",
        telefon: "0733.333333",
        an: 2011
    }
];

export default {
    getDate() {
        return date;
    }
}