import './globals';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const token = localStorage.getItem("autok");
if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token; //adauga implicit tokenul la toate requesturile de dupa autentificare
}

const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

