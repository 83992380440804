import React, { Component, Fragment } from 'react';

export default class AlteGospodarii extends Component {
    constructor(props) {
        super(props);
        //this.dataSource = getTasks(props.data.key);
    }

    render() {
        //console.log(this.props);
        var rezultat = this.props && this.props.data && this.props.data.data && this.props.data.data.listagosp && this.props.data.data.listagosp.length == 0
                ? null
            : <div> Alte gospodării unde apare: {this.props.data.data.listagosp.toString()} </div>;

        return rezultat;
    }
}