import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import { Button, TextBox } from 'devextreme-react';
import clase from './Utilizatori.module.css';
import { navigate } from "@reach/router";
import service from './date';

export default class ModificatiUtilizator extends Component {
    state = {
        utilizatorDeModificat: null,
        nume: null,
        numeU: null,
        parola: null,
        confirmare: null
    }

    stil = {
        minWidth: '300px',
        width: '30%',
        height: 'calc(100vh - 57px)',
        margin: 'auto',
        border: '1px solid #ddd',
        textAlign: "center",
        overflow: "hidden"
    };

    handleModificare = () => {
        if (this.state.utilizatorDeModificat.parola !== this.state.confirmare || (!this.state.utilizatorDeModificat.parola || !this.state.confirmare)) {
            alert("Parola nu se confirmă.");
            return;
        }

        axios({
            data: this.state.utilizatorDeModificat,
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: "api/Utilizatori/SalveazaModificareUtilizator",
        })
            .then((response) => {
                if (response.data === true) {
                    alert("Utilizatorul a fost modificat în baza de date.");
                    navigate('./');
                }
            })
            .catch((error) => {
                console.log("Eroare la adaugare (Adaugare utilizator)", error);
                alert("Eroare la conexiunea cu baza de date. (Modificati.jsx)");
            });
    }

    handleNume = (nume) => {
        let num = { ...this.state.utilizatorDeModificat };
        num.nume = nume;
        this.setState({ utilizatorDeModificat: num });
    }

    handleNumeUtilizator = (nume) => {
        let numU = { ...this.state.utilizatorDeModificat };
        numU.numeUtilizator = nume;
        this.setState({ utilizatorDeModificat: numU });
    }

    handleParola = (parola) => {
        let par = { ...this.state.utilizatorDeModificat };
        par.parola = parola;
        this.setState({ utilizatorDeModificat: par });
    }

    handleConfirmare = (parola) => {
        this.setState({ confirmare: parola });
    }

    handleCNP = (cnp) => {
        let codNP = { ...this.state.utilizatorDeModificat };
        codNP.cnp = cnp;
        this.setState({ utilizatorDeModificat: codNP });
    }

    componentDidMount() {
        service.getUtilizator(localStorage.getItem("userName")).then(r => {
            this.setState({ utilizatorDeModificat: r.utilizator })
        }).catch(e => {//in caz de eroare
            alert("S-a produs o eroare la conexiunea cu baza de date! (Modificare utilizator)");
            navigate(`./`);
        })
    }

    render() {
        return (
            <Fragment>
                <div style={this.stil} id='chenar'>
                    <h3> Modificare utilizator</h3>
                    <div style={{ padding: "0 5px 0 5px" }}>
                        <TextBox
                            id="nume"
                            width={"100%"}
                            className={clase.Margini}
                            placeholder="Nume si prenume..."
                            onValueChanged={(e) => { this.handleNume(e.value) }}
                            value={this.state.utilizatorDeModificat ? this.state.utilizatorDeModificat.nume + " " + this.state.utilizatorDeModificat.prenume : null}
                            readOnly={true}
                        />
                        <TextBox
                            id="numeU"
                            width={"100%"}
                            className={clase.Margini}
                            placeholder="Nume utilizator..."
                            onValueChanged={(e) => { this.handleNumeUtilizator(e.value) }}
                            value={this.state.utilizatorDeModificat ? this.state.utilizatorDeModificat.numeUtilizator : null}
                            readOnly={true}
                        />
                        <TextBox
                            mode="password"
                            id="parola"
                            width={"100%"}
                            className={clase.Margini}
                            placeholder="Parola..."
                            onValueChanged={(e) => { this.handleParola(e.value) }}
                            value={this.state.utilizatorDeModificat ? this.state.utilizatorDeModificat.parola : null}
                        />
                        <TextBox
                            mode="password"
                            id="confirm"
                            width={"100%"}
                            className={clase.Margini}
                            placeholder="Confirmare parola..."
                            onValueChanged={(e) => { this.handleConfirmare(e.value) }}
                        />
                        <TextBox
                            id="cnp"
                            width={"100%"}
                            className={clase.Margini}
                            placeholder="CNP..."
                            onValueChanged={(e) => { this.handleCNP(e.value) }}
                            value={this.state.utilizatorDeModificat ? this.state.utilizatorDeModificat.cnp : null}
                            readOnly={true}
                        />
                    </div>

                    <Button
                        text="Modifica utilizator"
                        type="success"
                        onClick={this.handleModificare}
                    />
                </div>
            </Fragment>
        );
    }
}