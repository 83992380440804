import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import clase from './AdeverinteSablon.module.css';
import DataGrid, { Pager, Paging, FilterRow, FilterPanel, Editing, Selection, Column } from 'devextreme-react/data-grid';
import { Button, TextBox, FileUploader, ScrollView } from 'devextreme-react';
import service from './DateSablon';
import { base64StringToBlob } from "blob-util";
import Utilitare from './../../../../../../../content/Utilitare';

export default class AdeverinteSablon extends Component {
    state = {
        adeverinte: null,
        fisier: null,
        denumireSablon: null,
        adeverintaSelectata: null,
        scop: "_",
        procedura: null,
        nrRegistratura: "........",
        caleFisier: "_",
        numeFisier: "_",
        numarDisabled: false
    }

    componentDidMount() {
        service.getAdeverinteSablon().then(r => {
            this.setState({
                adeverinte: r.listaAdeverinte
            })
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "AdeverinteSablon");
        })
    }

    handleGetNrRegistratura = () => {
        /*pentru prezentare video*/
        //this.setState({
        //    nrRegistratura: (Math.floor(Math.random() * 1000) + 1).toString(),
        //    caleFisier: "_",
        //    numeFisier: "_",
        //    numarDisabled: true
        //});
        //return;
        /*end of pentru prezentare video*/

        service.getNumarRegistratura(this.props.idPrimarie, this.props.idGospSelectata).then(r => {
            if (!r.nrAdeverinta.nrCur || r.nrAdeverinta.nrCur === '........')
                alert("Nu există legătură cu aplicaţia Document Management şi Arhivare.<br />Pentru a instala/restaura interconectivitatea, contactaţi administratorul aplicaţiei.");

            this.setState({
                nrRegistratura: r.nrAdeverinta.nrCur,
                caleFisier: r.nrAdeverinta.cale ? r.nrAdeverinta.cale : "_",
                numeFisier: r.nrAdeverinta.numeFisier ? r.nrAdeverinta.numeFisier : "_",
                numarDisabled: true
            });
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "AdeverinteSablon");
            this.setState({
                nrRegistratura: "........",
                numarDisabled: false
            });
        })
    }

    handleNrRegistratura = (valoare) => {
        this.setState({ nrRegistratura: valoare });
    }

    handleScop = (scop) => {
        this.setState({ scop: scop });
    }

    handleAfisare = () => {
        if (!this.state.adeverintaSelectata) {
            alert("Nu aţi selectat un model de adeverinţă.");
            return;
        }

        var idReg = this.props.idGospSelectata;
        var idAdev = this.state.adeverintaSelectata.idAdeverinteSablon;
        var an = localStorage.getItem("anSelectat");
        var idPrim = this.props.idPrimarie;
        var ptr = this.state.scop;
        var id_unic = this.props.membruSelectat.idUnic;
        var userLog = localStorage.getItem("userName");
        var nrr = this.state.nrRegistratura;
        var cale = this.state.caleFisier;
        var numeFisier = this.state.numeFisier;

        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/AdeverinteSablon/ProcessTemplate/' + idReg +
                '/' + idAdev +
                '/' + an +
                '/' + idPrim +
                '/' + ptr +
                '/' + id_unic +
                '/' + userLog +
                '/' + nrr +
                '/' + encodeURI(cale) +
                '/' + numeFisier
        })
            .then((response) => {
                if (response.data) {
                    const blob = base64StringToBlob(
                        response.data.content,
                        response.data.mimeInfo
                    );

                    const a = document.createElement("a");

                    a.href = URL.createObjectURL(blob);
                    a.download = response.data.originalFileName;
                    a.click();

                    this.handleScrieJurnalOperare();
                    //this.setState({ nrRegistratura: "........" }); //nu stiu daca e cazul ca, dupa eliberarea adeverintei, sa resetez numarul
                } else {
                    alert("S-a produs o eroare la comunicarea cu baza de date! (AdeverinteSablon afisare)");
                }
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "AdeverinteSablon");
            });
    }

    handleScrieJurnalOperare = () => {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/AdeverinteSablon/PostAdeverinteJurnalOperare/' + localStorage.getItem("siruta") + '/' + this.props.idGospSelectata + '/' + " şablon " + "/" + this.state.adeverintaSelectata.denumireSablon,
        })
            .then((response) => {
                if (response.data == false)
                    console.log("S-a produs o eroare la scrierea adeverintei in JurnalOperare");
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "AdeverinteSablon");
            });
    }

    handleProcedura = (proc) => {
        this.setState({ procedura: proc });
    }

    handleFisier = (fisier) => {
        if (this.state.denumireSablon === null || this.state.denumireSablon === "") {
            alert("Nu aţi completat numele şablonului.");
            return;
        }

        if (this.state.procedura === null || this.state.procedura === "") {
            alert("Nu aţi completat numele procedurii.");
            return;
        }

        if (fisier !== "" && fisier !== undefined && fisier !== null) {
            var reader = new FileReader();
            reader.readAsDataURL(fisier);
            reader.onload = () => {
                var res = reader.result;
                var continut = res.split(",")[1];

                axios({
                    data: {
                        siruta: parseInt(localStorage.getItem("siruta")),
                        denumireSablon: this.state.denumireSablon,
                        procedura: this.state.procedura,
                        fisier: continut
                    },
                    headers: { "Content-Type": "application/json" },
                    method: "post",
                    url: "/api/AdeverinteSablon/InsertAdeverintaSablon"
                }).then((response) => {
                    if (response.data) {
                        this.setState({
                            adeverinte: response.data//,
                            //fisier: null,
                            //denumireSablon: null,
                            //procedura: null
                        });
                    } else {
                        alert("S-a produs o eroare la comunicarea cu baza de date! (AdeverinteSablon)");
                    }

                }).catch((error) => {
                    Utilitare.handleEroare(error, "AdeverinteSablon");
                });
            };
            reader.onerror = (error) => {
                alert("S-a produs o eroare la citirea fisierului! (AdeverinteSablon)");
            };
        }
        else {
            alert('Nu există fişierul!');
        }

        //this.setState({ fisier: fisier });
    }

    handleSelectieAdeverinta = (adeverinta) => {
        this.setState({ adeverintaSelectata: adeverinta, nrRegistratura: "........" });
    }

    handleDelete = (e) => {
        if (!e.data.idAdeverinteSablon) {
            alert("Selectaţi o linie din tabel.");
            return;
        }
        axios({
            headers: { "Content-Type": "application/json" },
            method: "delete",
            url: './api/AdeverinteSablon/StergereSablon/' + e.data.idAdeverinteSablon + "/" + localStorage.getItem("siruta"),
        })
            .then((response) => {
                if (response.data) {
                    this.setState({
                        adeverinte: response.data//,
                        //fisier: null,
                        //denumireSablon: null,
                        //procedura: null
                    });
                } else {
                    alert("S-a produs o eroare la comunicarea cu baza de date! (AdeverinteSablon)");
                }
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "AdeverinteSablon");
            });
    }

    handleDescarcareSablon = (adeverintaSelectata) => {
        if (!adeverintaSelectata) {
            alert("Selectaţi o adeverinţă!");
            return;
        }

        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/AdeverinteSablon/GetSablon/' + localStorage.getItem("siruta") + "/" + adeverintaSelectata.idAdeverinteSablon,
        })
            .then((response) => {
                if (response.data) {
                    const blob = base64StringToBlob(
                        response.data.content,
                        response.data.mimeInfo
                    );

                    const a = document.createElement("a");

                    a.href = URL.createObjectURL(blob);
                    a.download = response.data.originalFileName;
                    a.click();
                } else {
                    alert("S-a produs o eroare la comunicarea cu baza de date! (AdeverinteSablon afisare)");
                }
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "AdeverinteSablon");
            });
    }

    render() {
        //console.log("AdeverinteSablon props", this.props);
        //console.log("AdeverinteSablon state", this.state);

        return (
            <Fragment>
                <ScrollView
                    style={{ height: "100%" }}
                    direction={"both"}
                >
                    <div style={{ width: "95%" }}>
                        <DataGrid
                            dataSource={this.state.adeverinte ? this.state.adeverinte : null}
                            columnAutoWidth={true}
                            allowColumnReordering={true}
                            showBorders={true}
                            focusedRowEnabled={true}
                            keyExpr={"idAdeverinteSablon"}
                            rowAlternationEnabled={false}
                            wordWrapEnabled={true}
                            onInitialized={(e) => { this.datagrid = e.component; }}
                            onSelectionChanged={(e) => this.handleSelectieAdeverinta(e.selectedRowsData[0])}
                            onRowRemoving={(e) => this.handleDelete(e)}
                        >
                            <Selection mode="single" deferred={false} />
                            <Editing
                                mode="popup"
                                allowDeleting={localStorage.getItem("role").toLowerCase().includes("administrator") ? true : false}
                                useIcons={true}
                            >
                            </Editing>
                            <FilterRow visible={false}
                                applyFilter={false} />
                            <FilterPanel visible={false} />
                            <Paging defaultPageSize={10} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[3, 5, 10]}
                                showInfo={true} />
                            {/*folosim coloanele implicite*/}
                            <Column dataField="idAdeverinteSablon" caption={"Id"} />
                            <Column dataField="denumireSablon" caption={"Nume sablon"} />
                            {localStorage.getItem("role").toLowerCase().includes("administrator") ? <Column dataField="sqlOrder" caption={"Procedura"} /> : null}
                        </DataGrid>

                        <table style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td colSpan="3" style={{ textAlign: "left" }}>
                                        <Button
                                            style={{ display: "inline-block", position: "relative", top: "-2px" }}
                                            text="Generează număr registratură"
                                            type="success"
                                            stylingMode="outlined"
                                            onClick={this.handleGetNrRegistratura}
                                        />
                                        <TextBox
                                            width={120}
                                            style={{ margin: "10px 0 0 5px", display: "inline-block" }}
                                            placeholder="Nr. Registratura"
                                            value={this.state.nrRegistratura}
                                            onValueChanged={(e) => this.handleNrRegistratura(e.value)}
                                            disabled={this.state.numarDisabled === true && this.state.nrRegistratura != "........"}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "50%" }}>
                                        <TextBox
                                            width={300}
                                            style={{ marginTop: "10px" }}
                                            placeholder="Pentru a-i servi la..."
                                            onValueChanged={(e) => this.handleScop(e.value)}
                                        />
                                    </td>
                                    <td style={{ width: "25%", verticalAlign: "top" }}>
                                        {localStorage.getItem("role").toLowerCase().includes("administrator") ?
                                            <TextBox
                                                width={300}
                                                style={{ marginTop: "10px" }}
                                                placeholder="Procedura"
                                                onValueChanged={(e) => this.handleProcedura(e.value)}
                                            />
                                            : null}
                                    </td>
                                    <td style={{ width: "25%" }}>
                                        {localStorage.getItem("role").toLowerCase().includes("administrator") ?
                                            <FileUploader
                                                style={{ marginTop: "10px", position: "relative", top: "4px" }}
                                                selectButtonText="Încarcă şablon de pe disc"
                                                labelText=""
                                                accept=".doc,.docx,application/msword"
                                                uploadMode="useForm"
                                                onValueChanged={(e) => { this.setState({ fisier: e.value[0] }) }}
                                            />
                                            : null}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "50%" }}>
                                        <div>
                                            <Button
                                                width={120}
                                                text="Afişează"
                                                type="success"
                                                stylingMode="outlined"
                                                onClick={this.handleAfisare}
                                            />
                                            &nbsp;
                                            <Button
                                                width={120}
                                                text="Anulează"
                                                type="danger"
                                                stylingMode="outlined"
                                                onClick={this.props.ascunde}
                                            />
                                        </div>
                                    </td>
                                    <td style={{ width: "25%", verticalAlign: "top" }}>
                                        <div>
                                            {localStorage.getItem("role").toLowerCase().includes("administrator") ?
                                                <TextBox
                                                    style={{ marginTop: "10px", display: "inline-box" }}
                                                    width={300}
                                                    placeholder="Nume şablon..."
                                                    onValueChanged={(e) => this.setState({ denumireSablon: e.value })}
                                                />
                                                : null}
                                        </div>
                                    </td>
                                    <td style={{ width: "25%", position: "relative", top: "5px", left: "5px" }}>
                                        {localStorage.getItem("role").toLowerCase().includes("administrator") ?
                                            <Button
                                                text="Salvează şablon"
                                                type="normal"
                                                stylingMode="outlined"
                                                onClick={() => { this.handleFisier(this.state.fisier) }}
                                            />
                                            : null}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "50%" }}></td>
                                    <td style={{ width: "25%" }}></td>
                                    <td style={{ width: "25%", position: "relative", top: "5px", left: "5px" }}>
                                        {localStorage.getItem("role").toLowerCase().includes("administrator") ?
                                            <Button
                                                text="Descarcă şablon"
                                                type="normal"
                                                stylingMode="outlined"
                                                onClick={() => { this.handleDescarcareSablon(this.state.adeverintaSelectata) }}
                                            />
                                            : null}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ScrollView>


            </Fragment>
        );
    }
}