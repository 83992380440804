import axios from 'axios';
/********** aici sunt capitolele ****************/

const butonExport = ['pdf', 'xls', 'rtf', 'csv', 'xml'];

const getChildren = (dataSource,refId) => {
    var lista = [];  
    var childs = dataSource.filter((r) => { return r["idParinte"] === refId });// ia copiii cu acel refId primit ca parametru
    //console.log(refId, childs);
    if (childs.length > 0) { //daca exista copii
        childs.map((f) => {  // pentru fiecare copil
            var elem = {
                id: f["idListaTabele"], text: f["denumire"], prescurtat: f["prescurtat"] } //creeaza obiectul 
            var copii = getChildren(dataSource, f["idListaTabele"]); // se autoapeleaza pentru lista de copii
            if (copii.length > 0) { //daca exista copii in acest parinte, adauga cele 2 proprietati noi (expanded si copii sai)
                elem["expanded"] = true;
                elem["items"] = copii;
            }
            lista.push(elem);// pune in lista copiii
            return true;
        })
    } 

    return lista;
}

export default {
    getCapitole(an, idGospSelectata) {
        return new Promise((resolve, reject) => {
            const capitole = axios.get("./api/Capitole/GetListaCapitole/" + an);

            const listaDeclaratii = axios.get("./api/Gospodarii/GetDeclaratii/" + localStorage.getItem("siruta") + "/" + idGospSelectata);

            axios.all([capitole, listaDeclaratii]).then(
                axios.spread((responseC, responseLD) => {
                    var co = getChildren(responseC.data, null)//null inseamna ca ia parintii (care au idParinte=null)

                    let raspuns = {
                        capitole: co,
                        listaDeclaratii: responseLD.data
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    },
    getExport() {
        return butonExport;
    }, 
    getDateCapitol(refRegistru) {
        return new Promise((resolve, reject) => {
            const dateCapitol = axios.get("./api/Capitole/" + refRegistru + "/1/" + localStorage.getItem("siruta"));

            axios.all([dateCapitol]).then(
                axios.spread((responseDC) => {
                    
                    let raspuns = {
                        capitole: responseDC.data
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    }
}