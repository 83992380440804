import React, { Component, Fragment } from 'react';
//import { alert } from "devextreme/ui/dialog";
import { Button, TextBox, CheckBox } from 'devextreme-react';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import clase from './Miscari.module.css';
import service from './DateMiscari';
//import { navigate } from "@reach/router";
import Utilitare from './../../../../../../../content/Utilitare';

export default class Miscari extends Component {
    state = {
        miscari: null,
        categoriiFolosinta: null,
        tipuriCladiri: null,
        destinatiiCladiri: null
    }

    componentDidMount() {
        service.getMiscari(localStorage.getItem("siruta"), this.props.parcelaSelectata.idUnic).then(r => {
            this.setState({
                miscari: r.miscari,
                categoriiFolosinta: r.categoriiFolosinta,
                tipuriCladiri: r.tipuriCladiri,
                destinatiiCladiri: r.destinatiiCladiri
            })
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "Miscari");
        })
    }

    render() {
        //console.log("MiscariParcela props", this.props);
        //console.log("MiscariParcele state", this.state);

        return (
            <Fragment>
                {this.props.capitol === "CAP. II b" ?
                    <div style={{ width: "50%", margin: "auto" }}>
                        <table width={"100%"}>
                            <tbody>
                                <tr>
                                    <td>
                                        <p> Nr. topo parcela/tarla/sola: </p>
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            className={clase.Margini}
                                            disabled
                                            value={this.props.parcelaSelectata ? this.props.parcelaSelectata.nrTopograficParcelaTarlaSola : null}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p> Denumire parcela/tarla/sola: </p>
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            className={clase.Margini}
                                            disabled
                                            value={this.props.parcelaSelectata ? this.props.parcelaSelectata.denumireParcelaTarlaSola : null}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p> Categoria de folosinta: </p>
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            className={clase.Margini}
                                            disabled
                                            value={this.props.parcelaSelectata.categoriaDeFolosinta.toString()}
                                        />
                                    </td>
                                </tr>
                                {this.props ?
                                    <tr>
                                        <td>
                                            <p> Intravilan: </p>
                                        </td>
                                        <td>
                                            <CheckBox
                                                className={clase.Margini}
                                                disabled
                                                value={(this.props.parcelaSelectata.suprafataIntravilanAri ?? 0 +
                                                    this.props.parcelaSelectata.suprafataIntravilanHa ?? 0) > 0
                                                    ? true
                                                    : false}
                                            />
                                        </td>
                                    </tr>
                                    : null}
                                <tr>
                                    <td>
                                        <p> Suprafata (ha): </p>
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            className={clase.Margini}
                                            disabled
                                            value={(this.props.parcelaSelectata.suprafataIntravilanHa ?? 0
                                                + this.props.parcelaSelectata.suprafataExtravilanHa ?? 0).toString()}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p> Suprafata (ha): </p>
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            className={clase.Margini}
                                            disabled
                                            value={(this.props.parcelaSelectata.suprafataIntravilanAri ?? 0
                                                + this.props.parcelaSelectata.suprafataExtravilanAri ?? 0).toString()}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p> Proprietar: </p>
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            className={clase.Margini}
                                            disabled
                                            value={this.props.parcelaSelectata ? this.props.parcelaSelectata.proprietar : null}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> ://capitolul 11
                    <div style={{ width: "50%", margin: "auto" }}>
                        <table width={"100%"}>
                            <tbody>
                                <tr>
                                    <td>
                                        <p> Adresa: </p>
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            className={clase.Margini}
                                            disabled
                                            value={this.props.parcelaSelectata ? this.props.parcelaSelectata.adresa : null}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p> Anul dării în folosinţă: </p>
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            className={clase.Margini}
                                            disabled
                                            value={this.props.parcelaSelectata && this.props.parcelaSelectata.anulDariiInFol ? this.props.parcelaSelectata.anulDariiInFol.toString() : null}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p> Zona: </p>
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            className={clase.Margini}
                                            disabled
                                            value={this.props.parcelaSelectata.Zona}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p> Destinaţie: </p>
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            className={clase.Margini}
                                            disabled
                                            value={this.state.destinatiiCladiri ? this.state.destinatiiCladiri.find(x => x.valoare === this.props.parcelaSelectata.destinatieCladire).denumire : null}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p> Tip: </p>
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            className={clase.Margini}
                                            disabled
                                            value={this.state.tipuriCladiri ? this.state.tipuriCladiri.find(x => x.valoare === this.props.parcelaSelectata.tipConstructie).denumire : null}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p> Suprafata (mp): </p>
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            className={clase.Margini}
                                            disabled
                                            value={(this.props.parcelaSelectata.suprafataConstruita).toString()}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p> Proprietar: </p>
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            className={clase.Margini}
                                            disabled
                                            value={this.props.parcelaSelectata ? this.props.parcelaSelectata.proprietar : null}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }

                <DataGrid
                    dataSource={this.state.miscari}
                >
                    <Column dataField="id" caption="idMiscariParcele" visible={false} />
                    <Column dataField="idDat" caption="De la ID" />
                    <Column dataField="descriereTransfer" caption="Acţiune" />
                    <Column dataField="idPrimit" caption="către ID" />
                    <Column dataField="numeProprietar" caption="Proprietar" />
                    <Column dataField="dataOperare" dataType="date" format="dd.MM.yyyy" caption="Data operării" />
                </DataGrid>

                <div className={clase.Butoane}>
                    <Button
                        width={120}
                        text="Anuleaza"
                        type="normal"
                        stylingMode="outlined"
                        onClick={this.props.ascunde}
                    />
                </div>
            </Fragment>
        )
    }
}