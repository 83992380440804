import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import DataGrid, { Column, Pager, Paging, FilterRow, FilterPanel, Scrolling, Editing, RequiredRule, Selection } from 'devextreme-react/data-grid';
import { Button, } from 'devextreme-react';
import Utilitare from './../../../../../content/Utilitare';
import service from './date';

class Declaratii extends Component {
    state = {
        listaDeclaratii: null
    }

    componentDidMount() {
        this.getDeclaratii();
    }

    getDeclaratii = () => {
        service.getListaDeclaratii(this.props.idGosp).then(r => {
            this.setState({
                listaDeclaratii: r.declaratii
            })

        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "declaratii");
        });
    }

    verificaAccesIstoric = () => {
        var ani = JSON.parse(localStorage.getItem("listaAni"));

        var anSelectat = parseInt(localStorage.getItem("anSelectat"));

        var accesIstoric = localStorage.getItem("accesIstoric");
        var siruta = localStorage.getItem("siruta");

        if (anSelectat < Math.max(...ani) && !accesIstoric.includes(siruta))
            return false;

        return true;
    }

    handleOnRowInserting = (date) => {
        var gasit = this.state.listaDeclaratii.filter(x =>
            x.nrDeclaratie.toString() === date.nrDeclaratie.toString()
            && Date.parse(x.dataDeclaratie) === Date.parse(date.dataDeclaratie)
        );
        if (gasit.length > 0) {
            alert("Declaraţia a fost înregistrată deja.");
            this.getDeclaratii();
            return;
        }

        var dataDeTrimis = (new Date(date.dataDeclaratie)).getFullYear() + '-' + ((new Date(date.dataDeclaratie)).getMonth() + 1) + "-" + (new Date(date.dataDeclaratie)).getDate() + "T00:00:00";

        axios.post("./api/Gospodarii/UpsertDeclaratii/" + localStorage.getItem("siruta") + "/" + this.props.idGosp + "/" + (date.idDeclaratii ?? 0) + "/" + date.nrDeclaratie + "/" + dataDeTrimis + "/" + date.comentariu)
            .then((response) => {
                //console.log("raspuns", response.data);
                this.setState({ listaDeclaratii: response.data });
            })
            .catch((e) => {
                console.log("Eroare", e);
                this.getDeclaratii();
            });
    }

    handleOnRowUpdating = (dateVechi, dateNoi) => {
        for (const property in dateVechi) {
            if (dateNoi[property])
                dateVechi[property] = dateNoi[property];
        }

        var gasit = this.state.listaDeclaratii.filter(x =>
            x.nrDeclaratie.toString() === dateVechi.nrDeclaratie.toString()
            && Date.parse(x.dataDeclaratie) === Date.parse(dateVechi.dataDeclaratie)
        );
        if (gasit.length > 1) {
            alert("Declaraţia a fost înregistrată deja.");
            this.getDeclaratii();
            return;
        }

        var dataDeTrimis = (new Date(dateVechi.dataDeclaratie)).getFullYear() + '-' + ((new Date(dateVechi.dataDeclaratie)).getMonth() + 1) + "-" + (new Date(dateVechi.dataDeclaratie)).getDate() + "T00:00:00";

        axios.post("./api/Gospodarii/UpsertDeclaratii/" + localStorage.getItem("siruta") + "/" + this.props.idGosp + "/" + (dateVechi.idDeclaratii ?? 0) + "/" + dateVechi.nrDeclaratie + "/" + dataDeTrimis + "/" + dateVechi.comentariu)
            .then((response) => {
                //console.log("raspuns", response.data);
                this.setState({ listaDeclaratii: response.data });
            })
            .catch((e) => {
                console.log("Eroare", e);
            });
    }

    handleOnRowRemoving = (id) => {
        axios.post("./api/Gospodarii/DeleteDeclaratii/" + localStorage.getItem("siruta") + "/" + this.props.idGosp + "/" + id)
            .then((response) => {
                //console.log("raspuns", response.data);
                this.setState({ listaDeclaratii: response.data });
            })
            .catch((e) => {
                console.log("Eroare", e);
            });
    }

    render() {
        //console.log("declaratii props", this.props);
        //console.log("declaratii state", this.state);

        return (
            <Fragment>
                <DataGrid
                    width={"500px"}
                    style={{ marginTop: "5px", paddingLeft: "10px" }}
                    dataSource={this.state.listaDeclaratii}
                    columnAutoWidth={true}
                    allowColumnReordering={true}
                    showBorders={true}
                    keyExpr={"idDeclaratii"}
                    rowAlternationEnabled={false}
                    wordWrapEnabled={true}
                    onInitialized={(e) => { this.datagrid = e.component; }}
                    //onRowClick={(e) => this.handleOnRowClick(e.data)}
                    showRowLines={true}
                    //selectedRowKeys={[this.state.linieSelectata ? this.state.linieSelectata.idUnic : null]}
                    //onRowDblClick={(e) => this.datagrid.editRow(e.dataIndex)}
                    defaultSelectedRowKeys={[this.state.listaDeclaratii && this.state.listaDeclaratii > 0 ? this.state.listaDeclaratii[0].idDeclaratii : null]}
                    onRowUpdating={(e) => { this.handleOnRowUpdating(e.oldData, e.newData) }}
                    onRowRemoving={(e) => this.handleOnRowRemoving(e.data.idDeclaratii)}
                    onRowInserting={(e) => this.handleOnRowInserting(e.data)}
                    //onInitNewRow={(e) => this.handleOnInitNewRow(e)}
                    //onToolbarPreparing={(e) => { this.handleToolbarPreparing(e) }}
                    //onRowPrepared={(e) => { this.handleOnRowPrepared(e); }}
                    onContextMenuPreparing={(e) => {
                        if (e && e.row && e.row.data)
                            if ((this.verificaAccesIstoric() === true && localStorage.getItem("role") !== "UtilizatorExtern") || localStorage.getItem("role") === "Administrator")
                                this.setState({ linieSelectata: e ? e.row.data : null }, () => {
                                    //this.props.setLinieSelectata(e ? e.row.data : null);

                                    if (e.row.rowType === "data") {
                                        //if (localStorage.getItem("checkClickDreapta").replaceAll(" ", "").split(",").includes(localStorage.getItem("siruta").toString()))
                                        e.items = [{
                                            text: "Adaugă",
                                            icon: "add",
                                            onItemClick: function () {
                                                e.component.addRow();
                                            }
                                        },
                                        {
                                            text: "Modifică",
                                            icon: "edit",
                                            onItemClick: function () {
                                                e.component.editRow(e.row.rowIndex);
                                            }
                                        },
                                        {
                                            text: "Şterge",
                                            icon: "trash",
                                            onItemClick: function () {
                                                e.component.deleteRow(e.row.rowIndex);
                                            }
                                        }];
                                    }
                                })
                            else
                                this.setState({ linieSelectata: e ? e.row.data : null }, () => {
                                    //this.props.setLinieSelectata(e ? e.row.data : null);

                                    if (e.row.rowType === "data") {
                                        e.items = [];
                                    }
                                })
                        else
                            if (this.verificaAccesIstoric() === true && localStorage.getItem("role") !== "UtilizatorExtern")
                                e.items = [{
                                    text: "Adaugă",
                                    icon: "add",
                                    onItemClick: function () {
                                        e.component.addRow();
                                    }
                                }];
                    }}
                >
                    <Selection mode="single" />
                    <FilterRow visible={false}
                        applyFilter={false} />
                    <FilterPanel visible={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <Paging defaultPageSize={50} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 25, 50]}
                        showInfo={true} />

                    <Column dataField="nrDeclaratie">
                        <RequiredRule message="Necesar a fi completat." />
                    </Column>
                    <Column dataField="dataDeclaratie" dataType="date" format="dd.MM.yyyy">
                        <RequiredRule message="Necesar a fi completat." />
                    </Column>
                    <Column dataField="comentariu" />

                    {(!localStorage.getItem("checkClickDreapta").replaceAll(" ", "").split(",").includes(localStorage.getItem("siruta").toString()))
                        || localStorage.getItem("role") === 'UtilizatorExtern'
                        || this.verificaAccesIstoric() === false
                        ? localStorage.getItem("role") === "Administrator"
                            ? <Editing
                                allowUpdating={true}
                                allowAdding={true}
                                allowDeleting={true}
                                useIcons={true}
                                mode="form" />
                            : <Editing mode="form" />
                        : <Editing
                            allowUpdating={true}
                            allowAdding={true}
                            allowDeleting={true}
                            useIcons={true}
                            mode="form" />
                    }
                </DataGrid>

                <Button
                    style={{ position: "fixed", bottom: "10px", right: "10px" }}
                    width={120}
                    text="Închide"
                    type="danger"
                    stylingMode="outlined"
                    onClick={this.props.ascunde}
                />

            </Fragment>
        );
    }
}

export default Declaratii;