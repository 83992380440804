/*pentru a adauga un nou criteriu de cautare, mai intai adaugam un buton (posibil sa renuntam la butoane, sa punem o lista)*/
/*la eventul onclick al butonului se ataseaza un handleEvent care va trimite catre component sus criteriul si va stabili coloanele*/
/*selectia datelor se va face in ComponentSus.jsx, in handleCautareAvansata (care va primi criteriul si va face request-ul catre baza de date)*/

import React, { Component, Fragment } from 'react';
import { alert } from "devextreme/ui/dialog";
import DataGrid, { Column, Pager, Paging, FilterRow, FilterPanel } from 'devextreme-react/data-grid';
import { Button, TextBox, LoadIndicator } from 'devextreme-react';
import clase from './CautareAvansata.module.css';
import 'bootstrap';
import Validator, { /*PatternRule,*/ NumericRule } from 'devextreme-react/validator';
import service from './Date';
//import { navigate } from "@reach/router";
import { PatternRule } from 'devextreme-react/form';
import Utilitare from './../../../../../content/Utilitare';

export default class CautareAvansata extends Component {
    state = {
        loadIndicatorVisible: false,
        tipCautare: "membri",
        membruDeTrimis: null,
        membruSelectat: null,
        parcelaDeTrimis: null,
        dateCautare: null,
        columns: []
    }

    componentDidMount() {
        service.getListaColoane(5).then(r => {
            this.setState({ columns: r.cols })
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "CautareAvansata");
        })
    }

    handleTrimiteId = () => {
        //trimite idGosp catre datagrid-ul din ComponentSus
        if (!this.state.membruSelectat) {
            alert("Nu aţi efectuat selecţia!");
            return;
        }

        this.props.cautare(this.state.membruSelectat.refRegistrulAgricol, this.state.membruSelectat.tip);
    }

    handleRowClickMembru = (membru) => {
        this.setState({ membruSelectat: membru });//membru contine toate datele membrului
    }

    headerTemplate = (columnHeader) => {
        return <p style={{ width: "100%", textAlign: "center" }}>{columnHeader.column.caption}</p>;
    }

    handleCautareMembri = () => {
        this.props.cautareAvansata("membri");

        service.getListaColoane(5).then(r => {
            this.setState({
                columns: r.cols
            })
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "CautareAvansata");
        })
    }

    handleCautareParcele = () => {
        this.props.cautareAvansata("parcele");

        service.getListaColoane(6).then(r => {
            this.setState({
                columns: r.cols
            })
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "CautareAvansata");
        })
    }

    handleTrimiteMembru = () => {
        this.setState({ loadIndicatorVisible: true });
        let membruDeTrimis = { ...this.state.membruDeTrimis };
        membruDeTrimis.siruta = parseInt(localStorage.getItem("siruta"));
        membruDeTrimis.anSelectat = parseInt(this.props.anSelectat);

        service.getListaMembri(membruDeTrimis).then(r => {
            this.setState({ dateCautare: r.listaMembri }, () => this.setState({ loadIndicatorVisible: false }));
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "CautareAvansata");
        })
    }

    handleTrimiteParcela = () => {
        let parcelaDeTrimis = { ...this.state.parcelaDeTrimis };
        parcelaDeTrimis.siruta = parseInt(localStorage.getItem("siruta"));
        parcelaDeTrimis.anSelectat = parseInt(this.props.anSelectat);

        service.getListaParcele(parcelaDeTrimis).then(r => {
            this.setState({ dateCautare: r.listaParcele }, () => this.setState({ loadIndicatorVisible: false }));
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "CautareAvansata");
        })
    }

    render() {
        //console.log("CautareAvansata state", this.state);
        //console.log("CautareAvansata props", this.props);

        const formularMembri =
            <div className="container" style={this.stil}>
                <div className="row" style={{ margin: "10px 0 5px 0" }}>
                    <div className="col col-sm-3">
                        <TextBox
                            placeholder={"Nume..."}
                            width={"100%"}
                            style={{ display: 'inline-block' }}
                            onValueChanged={(e) => {
                                let mbr = { ...this.state.membruDeTrimis };
                                mbr.nume = e.value;
                                this.setState({ membruDeTrimis: mbr })
                            }}
                            onEnterKey={this.handleTrimiteMembru}
                        >
                            <Validator>
                                <PatternRule message={"Nu folosiţi cifre."} pattern={/^[^0-9]+$/} />
                            </Validator>
                        </TextBox>
                    </div>
                    <div className="col col-sm-1">
                        <TextBox
                            placeholder={"I..."}
                            width={"100%"}
                            style={{ display: 'inline-block' }}
                            onValueChanged={(e) => {
                                let mbr = { ...this.state.membruDeTrimis };
                                mbr.initiala = e.value;
                                this.setState({ membruDeTrimis: mbr })
                            }}
                            maxLength={4}
                            onEnterKey={this.handleTrimiteMembru}
                        >
                            <Validator>
                                <PatternRule message={"Nu folosiţi cifre."} pattern={/^[^0-9]+$/} />
                            </Validator>
                        </TextBox>
                    </div>
                    <div className="col col-sm-3">
                        <TextBox
                            placeholder={"Prenume..."}
                            width={"100%"}
                            style={{ display: 'inline-block' }}
                            onValueChanged={(e) => {
                                let mbr = { ...this.state.membruDeTrimis };
                                mbr.prenume = e.value;
                                this.setState({ membruDeTrimis: mbr })
                            }}
                            onEnterKey={this.handleTrimiteMembru}
                        >
                            <Validator>
                                <PatternRule message={"Nu folosiţi cifre."} pattern={/^[^0-9]+$/} />
                            </Validator>
                        </TextBox>
                    </div>
                    <div className="col col-sm-3">
                        <TextBox
                            placeholder={"CNP..."}
                            width={"100%"}
                            style={{ display: 'inline-block' }}
                            onValueChanged={(e) => {
                                let mbr = { ...this.state.membruDeTrimis };
                                mbr.cnp = e.value.replace(" ", "");
                                this.setState({ membruDeTrimis: mbr })
                            }}
                            maxLength={13}
                            onEnterKey={this.handleTrimiteMembru}
                        >
                            <Validator>
                                <NumericRule message="Numai cifre." />
                            </Validator>
                        </TextBox>
                    </div>
                    <div className="col col-sm-2">
                        <Button
                            style={{ display: "block", marginBottom: "5px" }}
                            width={500}
                            text="Caută gospodării"
                            type="default"
                            stylingMode="outlined"
                            onClick={this.handleTrimiteMembru}
                        />
                    </div>
                </div>
            </div>

        const formularParcele =
            <div className="container" style={this.stil}>
                <div className="row" style={{ margin: "10px 0 5px 0"}}>
                    <div className="col col-sm-3">
                        <TextBox
                            placeholder={"Nume titular..."}
                            width={"100%"}
                            style={{ display: 'inline-block' }}
                            onValueChanged={(e) => {
                                let parc = { ...this.state.parcelaDeTrimis };
                                parc.numeTitular = e.value;
                                this.setState({ parcelaDeTrimis: parc })
                            }}
                            onEnterKey={this.handleTrimiteParcela}
                        >
                            <Validator>
                                <PatternRule message={"Nu folosiţi cifre."} pattern={/^[^0-9]+$/} />
                            </Validator>
                        </TextBox>
                    </div>
                    <div className="col col-sm-2">
                        <TextBox
                            placeholder={"Iniţiala titular..."}
                            width={"100%"}
                            style={{ display: 'inline-block' }}
                            onValueChanged={(e) => {
                                let parc = { ...this.state.parcelaDeTrimis };
                                parc.initialaTitular = e.value;
                                this.setState({ parcelaDeTrimis: parc })
                            }}
                            onEnterKey={this.handleTrimiteParcela}
                        >
                            <Validator>
                                <PatternRule message={"Nu folosiţi cifre."} pattern={/^[^0-9]+$/} />
                            </Validator>
                        </TextBox>
                    </div>

                    <div className="col col-sm-3">
                        <TextBox
                            placeholder={"Prenume titular..."}
                            width={"100%"}
                            style={{ display: 'inline-block' }}
                            onValueChanged={(e) => {
                                let parc = { ...this.state.parcelaDeTrimis };
                                parc.prenumeTitular = e.value;
                                this.setState({ parcelaDeTrimis: parc })
                            }}
                            onEnterKey={this.handleTrimiteParcela}
                        >
                            <Validator>
                                <PatternRule message={"Nu folosiţi cifre."} pattern={/^[^0-9]+$/} />
                            </Validator>
                        </TextBox>
                    </div>
                </div>

                <div className="row" style={{ margin: "10px 0 5px 0" }}>
                    <div className="col col-sm-3">
                        <TextBox
                            placeholder={"Denumire parcelă..."}
                            width={"100%"}
                            style={{ display: 'inline-block' }}
                            onValueChanged={(e) => {
                                let parc = { ...this.state.parcelaDeTrimis };
                                parc.denumireParcela = e.value;
                                this.setState({ parcelaDeTrimis: parc })
                            }}
                            onEnterKey={this.handleTrimiteParcela}
                        >
                        </TextBox>
                    </div>

                    <div className="col col-sm-1">
                        <TextBox
                            placeholder={"Nr. topo..."}
                            width={"100%"}
                            style={{ display: 'inline-block' }}
                            onValueChanged={(e) => {
                                let parc = { ...this.state.parcelaDeTrimis };
                                parc.nrTopo = e.value;
                                this.setState({ parcelaDeTrimis: parc })
                            }}
                            onEnterKey={this.handleTrimiteParcela}
                        >
                        </TextBox>
                    </div>

                    <div className="col col-sm-1">
                        <TextBox
                            placeholder={"CF, nr. cadastral sau topografic..."}
                            width={"100%"}
                            style={{ display: 'inline-block' }}
                            onValueChanged={(e) => {
                                let parc = { ...this.state.parcelaDeTrimis };
                                parc.cf = e.value;
                                this.setState({ parcelaDeTrimis: parc })
                            }}
                            onEnterKey={this.handleTrimiteParcela}
                        >
                        </TextBox>
                    </div>

                    <div className="col col-sm-3">
                        <TextBox
                            placeholder={"Menţiuni..."}
                            width={"100%"}
                            style={{ display: 'inline-block' }}
                            onValueChanged={(e) => {
                                let parc = { ...this.state.parcelaDeTrimis };
                                parc.mentiuni = e.value;
                                this.setState({ parcelaDeTrimis: parc })
                            }}
                            onEnterKey={this.handleTrimiteParcela}
                        >
                        </TextBox>
                    </div>

                    <div className="col col-sm-2">
                        <Button
                            style={{ display: "block", marginBottom: "5px" }}
                            width={500}
                            text="Caută gospodării"
                            type="default"
                            stylingMode="outlined"
                            onClick={this.handleTrimiteParcela}
                        />
                    </div>
                </div>
            </div>

        const headerTemplate = (columnHeader) => {
            return <p style={{ width: "100%", textAlign: "center" }}>{columnHeader.column.caption}</p>;
        }

        return (
            <Fragment>
                <Button
                    style={{ display: "block", marginBottom: "5px" }}
                    width={500}
                    text="Căutare membri"
                    type="normal"
                    stylingMode="outlined"
                    onClick={() => { this.handleCautareMembri(); this.setState({ tipCautare: "membri" }); }}
                />
                <Button
                    style={{ display: "block", marginBottom: "5px" }}
                    width={500}
                    text="Căutare parcele"
                    type="normal"
                    stylingMode="outlined"
                    onClick={() => { this.handleCautareParcele(); this.setState({ tipCautare: "parcele" }) }}
                />

                {this.state.tipCautare === "membri" ? formularMembri : null}
                {this.state.tipCautare === "parcele" ? formularParcele : null}

                {this.state.loadIndicatorVisible ?
                    <div style={{ position: "absolute", top: "0px", bottom: "0px", left: "0px", right: "0px", zIndex: "1502", backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", border: "1px solid black", backgroundColor: "white" }}>
                            <p>Se încarcă...</p>
                            <LoadIndicator
                                className="button-indicator"
                                visible={this.state.loadIndicatorVisible}
                            />
                        </div>
                    </div> :

                    <DataGrid
                        dataSource={this.state.dateCautare}
                        showBorders={true}
                        focusedRowEnabled={true}
                        rowAlternationEnabled={false}
                        keyExpr={"id"}
                        onRowClick={(e) => this.handleRowClickMembru(e.data)}
                        onRowDblClick={this.handleTrimiteId}
                    >
                        <FilterRow visible={true}
                            applyFilter={true} />
                        <FilterPanel visible={true} />

                        {this.state.columns.map((col) => <Column key={col.idColoaneGridView} dataField={col.denumireCamp} caption={col.denumire} headerCellRender={headerTemplate} width={col.lungime + '%'} visible={col.vizibil} />)}

                        <Paging defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} />
                    </DataGrid>
                }

                <div className={clase.Butoane} style={{ backgroundColor: 'white' }}>
                    <Button
                        width={120}
                        text="Afişează"
                        type="success"
                        stylingMode="outlined"
                        onClick={this.handleTrimiteId}
                    />
                    &nbsp;
                    <Button
                        width={120}
                        text="Anulează"
                        type="danger"
                        stylingMode="outlined"
                        onClick={this.props.ascunde}
                    />
                </div>
            </Fragment >
        );
    }
}