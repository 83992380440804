import React, { Component, Fragment } from 'react';
import { TextBox, SelectBox } from 'devextreme-react';
import Validator, { /*RequiredRule,*/ PatternRule } from 'devextreme-react/validator';

export default class Adresa extends Component {
    state = {
        //tip: this.props.tipSelectat ? this.props.tipSelectat : this.props.gospodariaSelectata.tip,
        //judetSelectat: this.props.adresa && this.props.adresa.refLocalitateComponenta
        //    ? this.props.judete.filter(x => x.denumireJudet === (this.props.localitati.filter(y => y.refLocalitateComponenta === this.props.adresa.refLocalitateComponenta).denumireJudet))[0].idNomenclatorUAT
        //    : 1,
        sirutaSelectata: null
    }

    stil = {
        width: "100%",
        /*backgroundColor: "#DFDFDF",*/
        textAlign: "left",
        verticalAlign: "bottom",
        padding: "6px 0 2px 0",
        marginTop: "2px"
    };

    componentDidMount = () => {
        if (this.props.onInitialized !== undefined) { //daca a primit prin props onInitialized
            this.props.onInitialized(this); //apeleaza functia onInitialized primita prin props cu valoarea this (pentru a trimite this al acestei componente parintelui)
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        let stat = { ...state };
        stat.adresa = props.adresa;
        stat.denumireLocalitateST = props.denumireLocalitateST ? props.denumireLocalitateST : null;
        stat.denumireLocalitatePJ = props.denumireLocalitatePJ ? props.denumireLocalitatePJ : null;

        return stat;
    }

    schimbaJudet = (valoare) => {
        if (valoare) {
            var denJud = this.props.judete ? this.props.judete.find(x => x.idNomenclatorUAT === valoare).denumireJudet : null;
            var loc = this.props.localitati && this.props.localitati.length > 0 ? this.props.localitati.filter(x => x.denumireJudet === denJud)[0].idNomenclatorUAT : null;

            this.props.handleAdrese(this.props.adresaCui, "refLocalitateComponenta", loc);
            this.props.handleAdrese(this.props.adresaCui, "refTara", 180);
            this.setState({ sirutaSelectata: this.props.localitati && this.props.localitati.length > 0 ? this.props.localitati.filter(x => x.idNomenclatorUAT === loc)[0].siruta.toString() : null, judetSelectat: valoare });
        }
    }

    render() {
        //console.log("state Adresa", this.state);
        //console.log("props Adresa", this.props);

        //let localitati = this.props.localitati && this.state.judetSelectat
        //    ? this.props.localitati.filter(l => l.denumireJudet === this.props.judete.find(x => x.idNomenclatorUAT === this.state.judetSelectat).denumireJudet)
        //    : null;
        let localitati = this.props.localitati && this.props.localitati.length > 0 && this.props.adresa && this.props.adresa.refLocalitateComponenta && this.props.localitati.find(x => x.idNomenclatorUAT === this.props.adresa.refLocalitateComponenta)
            ? this.props.localitati.filter(x => x.denumireJudet === this.props.localitati.find(x => x.idNomenclatorUAT === this.props.adresa.refLocalitateComponenta).denumireJudet)
            : null;

        if (this.props.adresaCui === "gospodarie") {//localitatile componente ale UAT-ului curent
            localitati = this.props.localitati;
        }

        //let tipStradaDefault = this.props.tipuriStrazi ? this.props.tipuriStrazi.filter(x => x.denumire.toLowerCase() === "strada")[0].valoare : null;

        return (
            <Fragment>
                <div className="container" style={this.stil}>
                    <div className="row" style={{ margin: "6px 0 2px 0" }}>
                        {this.props.adresaCui === "gospodarie" ? null :
                            <div className="col col-sm-3">
                                <SelectBox
                                    name="tari"
                                    style={{ display: "inline-block", marginTop: '2px' }}
                                    width={"100%"}
                                    dataSource={this.props.tari}
                                    displayExpr={"denumire"}
                                    valueExpr={"idNomenclatorTari"}
                                    onOpened={(e) => { e.component._popup.option('width', 300); }}
                                    onValueChanged={(e) => { this.props.handleAdrese(this.props.adresaCui, "refTara", e.value) }}
                                    searchEnabled={true}
                                    searchMode={"startswith"}
                                    onFocusIn={(e) => { e.element.querySelector("input.dx-texteditor-input").select(); }}
                                    value={this.props.adresa && this.props.adresa.refTara ? this.props.adresa.refTara : 180}
                                />
                            </div>
                        }

                        {this.props.adresaCui === "gospodarie" ? null :
                            <div className="col col-sm-3">
                                {(this.props.adresa && this.props.adresa.refTara === 180) || this.props.adresa === null || (this.props.adresa && this.props.adresa.refTara === null) ?
                                    <SelectBox
                                        placeholder="Judeţ..."
                                        name="judete"
                                        style={{ display: "inline-block", marginTop: '2px' }}
                                        width={"100%"}
                                        dataSource={this.props.judete}
                                        displayExpr={"denumireJudet"}
                                        valueExpr={"idNomenclatorUAT"}
                                        onOpened={(e) => { e.component._popup.option('width', 300); }}
                                        onValueChanged={(e) => { this.schimbaJudet(e.value)/*this.setState({ judetSelectat: (this.props.judete ? this.props.judete.find(j => j.idNomenclatorUAT === e.value).idNomenclatorUAT : 1), refLocalitateComponenta: null });*/ }}
                                        //visible={this.props.adresa && this.props.adresa.refTara ? this.props.adresa.refTara === 180 : true}
                                        searchEnabled={true}
                                        searchMode={"startswith"}
                                        onFocusIn={(e) => { e.element.querySelector("input.dx-texteditor-input").select(); }}
                                        value={
                                            this.props.adresa && this.props.adresa.refTara === 180 && this.props.adresa.refLocalitateComponenta ?
                                                this.props.localitati && this.props.judete && this.props.adresa
                                                    ? this.props.judete.find(x => x.denumireJudet === this.props.localitati.find(x => x.idNomenclatorUAT === this.props.adresa.refLocalitateComponenta).denumireJudet).idNomenclatorUAT
                                                    : 1
                                                : 1
                                        }
                                    /> : null}
                            </div>
                        }

                        {this.props.adresaCui !== "gospodarie" ? null :
                            <div className="col col-sm-3">
                                <TextBox
                                    placeholder={"e-mail..."}
                                    width={"100%"}
                                    style={{ display: 'inline-block', marginTop: '2px' }}
                                    defaultValue={this.props.actiune === "adaugare" ? "" : "EMail"}
                                    onValueChanged={(e) => { this.props.handleAdrese(this.props.adresaCui, "email", e.value) }}
                                    value={this.props.adresa && this.props.adresa.email ? this.props.adresa.email : null}
                                >
                                    <Validator>
                                        <PatternRule
                                            pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            message="Nu corespunde formatului unui email."
                                        />
                                    </Validator>
                                </TextBox>
                            </div>
                        }
                    </div>

                    <div className="row" style={{ margin: "6px 0 2px 0" }}>
                        <div className="col col-sm-3">
                            {(this.props.adresa && this.props.adresa.refTara === 180) || this.props.adresa === null || (this.props.adresa && this.props.adresa.refTara === null) ?
                                <SelectBox
                                    placeholder={"Localitate..."}
                                    style={this.props.adresaCui === "gospodarie" ? { display: "inline-block", marginTop: '2px', borderColor: "rgba(255,0,0,0.3)" } : { display: "inline-block", marginTop: '2px' }}
                                    width={"100%"}
                                    dataSource={localitati}
                                    displayExpr={"denumireLocalitate"}
                                    valueExpr={"idNomenclatorUAT"}
                                    onOpened={(e) => { e.component._popup.option('width', 300); }}
                                    onFocusIn={(e) => { e.element.querySelector("input.dx-texteditor-input").select(); }}
                                    onValueChanged={(e) => {
                                        this.props.handleAdrese(this.props.adresaCui, "refLocalitateComponenta", e.value);
                                        this.setState({ sirutaSelectata: localitati && localitati.length > 0 ? this.props.localitati.filter(x => x.idNomenclatorUAT === e.value)[0].siruta.toString() : null });
                                    }}
                                    //visible={this.props.adresa && this.props.adresa.refTara ? this.props.adresa.refTara === 180 : true}
                                    searchEnabled={true}
                                    searchMode={"startswith"}
                                    value={this.props.adresa && this.props.adresa.refLocalitateComponenta ? this.props.adresa.refLocalitateComponenta : null}
                                /> :
                                null}
                            <TextBox
                                placeholder={"Localitate..."}
                                width={"100%"}
                                style={{ display: 'inline-block', marginTop: '2px' }}
                                onValueChanged={(e) => { this.props.handleAdrese(this.props.adresaCui, this.props.adresaCui === "reprezentant" ? "denumireLocalitateST" : "denumireLocalitatePJ", e.value) }}
                                visible={this.props.adresa && this.props.adresa.refTara ? this.props.adresa.refTara !== 180 : false}
                                value={this.props.adresaCui === "reprezentant" ? this.props.denumireLocalitateST : this.props.denumireLocalitatePJ}
                            />
                        </div>

                        <div className="col col-sm-3">
                            <TextBox
                                placeholder={"SIRUTA..."}
                                width={"100%"}
                                style={{ display: 'inline-block', marginTop: '2px' }}
                                value={this.state.sirutaSelectata ? this.state.sirutaSelectata :
                                    //daca e adaugare, asta, daca e modificare, ia siruta cu ajutorul props
                                    this.props.actiune === "adaugare" ? null :
                                        localitati && localitati.length > 0 ? (this.props.adresa && this.props.adresa.siruta ? this.props.adresa.siruta.toString() : localitati[0].siruta.toString()) : null
                                }
                                //nu are on value changed pentru ca se completeaza automat cu siruta localitatii
                                disabled
                            />
                        </div>

                        <div className="col col-sm-3">
                            <TextBox
                                placeholder={"Cod poştal.."}
                                width={"100%"}
                                style={{ display: 'inline-block', marginTop: '2px' }}
                                defaultValue={this.props.actiune === "adaugare" ? "" : "CodPostal"}
                                onValueChanged={(e) => { this.props.handleAdrese(this.props.adresaCui, "codPostal", e.value) }}
                                value={this.props.adresa && this.props.adresa.codPostal ? this.props.adresa.codPostal.toString() : null}
                            >
                                <Validator>
                                    {/*<RequiredRule />*/}
                                    <PatternRule
                                        pattern="^[0-9]+$"
                                        message="Doar cifre."
                                    />
                                </Validator>
                            </TextBox>
                        </div>

                        {this.props.adresaCui !== "gospodarie" ? null :
                            <div className="col col-sm-3">
                                <TextBox
                                    placeholder={"Telefon..."}
                                    width={"100%"}
                                    style={{ display: 'inline-block', marginTop: '2px' }}
                                    defaultValue={this.props.actiune === "adaugare" ? "" : "Telefon"}
                                    onValueChanged={(e) => { this.props.handleAdrese(this.props.adresaCui, "telefon", e.value) }}
                                    value={this.props.adresa && this.props.adresa.telefon ? this.props.adresa.telefon : null}
                                >
                                    <Validator>
                                        {/*<RequiredRule />*/}
                                        <PatternRule
                                            pattern="^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|\-)?([0-9]{3}(\s|\.|\-|)){2}$"
                                            message="Nu respecta formatul numarului de telefon."
                                        />
                                    </Validator>
                                </TextBox>
                            </div>
                        }
                    </div>

                    <div className="row" style={{ margin: "6px 0 2px 0" }}>
                        <div className="col col-sm-3">
                            <SelectBox
                                placeholder="Tip artera..."
                                style={{ display: "inline-block", marginTop: '2px' }}
                                width={"100%"}
                                dataSource={this.props.tipuriStrazi}
                                displayExpr={"denumire"}
                                valueExpr={"valoare"}
                                onOpened={(e) => { e.component._popup.option('width', 300); }}
                                onValueChanged={(e) => { this.props.handleAdrese(this.props.adresaCui, "refTipArtera", e.value) }}
                                value={this.props.adresa && this.props.adresa.refTipArtera ? this.props.adresa.refTipArtera : null}
                            //daca pun strada default va salva obiectul doar cu aceasta valoare, ceea ce e inutil pentru adresaReprezentantLegal si adresaPJ pentru
                            //ca incarca baza de date cu 2 adrese goale
                            />
                        </div>

                        <div className="col col-sm-3">
                            {this.props.listaStrazi && this.props.adresaCui && this.props.adresaCui === 'gospodarie' && this.props.listaStrazi.length > 0 ?
                                <SelectBox
                                    placeholder={"Artera..."}
                                    style={{ display: "inline-block" }}
                                    width={"100%"}
                                    dataSource={this.props.listaStrazi && this.props.adresa
                                        ? this.props.listaStrazi.filter(x => x.refNomenclatorUAT === this.props.adresa.refLocalitateComponenta)
                                        : null}
                                    acceptCustomValue={true}
                                    searchEnabled={true}
                                    displayExpr={"denumire"}
                                    valueExpr={"denumire"}
                                    onOpened={(e) => { e.component._popup.option('width', 300); }}
                                    onValueChanged={(e) => { this.props.handleAdrese(this.props.adresaCui, "artera", e.value) }}
                                    value={this.props.listaStrazi && this.props.adresa && this.props.adresa.artera && this.props.listaStrazi.find(x => x.denumire.trim() === this.props.adresa.artera.trim())
                                        ? this.props.listaStrazi.find(x => x.denumire.trim() === this.props.adresa.artera.trim()).denumire
                                        : null}
                                />
                                : <TextBox
                                    placeholder={"Artera..."}
                                    width={"100%"}
                                    style={{ display: 'inline-block', marginTop: '2px' }}
                                    defaultValue={this.props.actiune === "adaugare" ? "" : "numarul"}
                                    onValueChanged={(e) => { this.props.handleAdrese(this.props.adresaCui, "artera", e.value) }}
                                    value={this.props.adresa ? this.props.adresa.artera : null}
                                />}
                        </div>

                        <div className="col col-sm-3">
                            <TextBox
                                placeholder={"Numarul..."}
                                width={"100%"}
                                style={{ display: 'inline-block', marginTop: '2px' }}
                                defaultValue={this.props.actiune === "adaugare" ? "" : "numarul"}
                                onValueChanged={(e) => { this.props.handleAdrese(this.props.adresaCui, "numar", e.value) }}
                                value={this.props.adresa ? this.props.adresa.numar : null}
                            />
                        </div>
                    </div>

                    <div className="row" style={{ margin: "6px 0 2px 0" }}>
                        <div className="col col-sm-3">
                            <TextBox
                                placeholder={"Bl. ..."}
                                width={"100%"}
                                style={{ display: 'inline-block', marginTop: '2px' }}
                                defaultValue={this.props.actiune === "adaugare" ? "" : "Bloc"}
                                onValueChanged={(e) => { this.props.handleAdrese(this.props.adresaCui, "bloc", e.value) }}
                                value={this.props.adresa ? this.props.adresa.bloc : null}
                            />
                        </div>

                        <div className="col col-sm-3">
                            <TextBox
                                placeholder={"Sc. ..."}
                                width={"100%"}
                                style={{ display: 'inline-block', marginTop: '2px' }}
                                defaultValue={this.props.actiune === "adaugare" ? "" : "Scara"}
                                onValueChanged={(e) => { this.props.handleAdrese(this.props.adresaCui, "scara", e.value) }}
                                value={this.props.adresa ? this.props.adresa.scara : null}
                            />
                        </div>

                        <div className="col col-sm-3">
                            <TextBox
                                placeholder={"Et. ..."}
                                width={"100%"}
                                style={{ display: 'inline-block', marginTop: '2px' }}
                                defaultValue={this.props.actiune === "adaugare" ? "" : "etaj"}
                                onValueChanged={(e) => { this.props.handleAdrese(this.props.adresaCui, "etaj", e.value) }}
                                value={this.props.adresa ? this.props.adresa.etaj : null}
                            />
                        </div>

                        <div className="col col-sm-3">
                            <TextBox
                                placeholder={"Ap. ..."}
                                width={"100%"}
                                style={{ display: 'inline-block', marginTop: '2px' }}
                                defaultValue={this.props.actiune === "adaugare" ? "" : "Apartament"}
                                onValueChanged={(e) => { this.props.handleAdrese(this.props.adresaCui, "apartament", e.value) }}
                                value={this.props.adresa ? this.props.adresa.apartament : null}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}