import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import { Button, LoadIndicator, TextBox, CheckBox } from 'devextreme-react';
import { navigate } from '@reach/router';
import Utilitare from './../../../../content/Utilitare';

export default class Transfer extends Component {
    state = {
        loadIndicatorVisible: false,
        anFinal: 0,
        buttonText: "Transferă"
    }

    stil = {
        minWidth: '300px',
        width: '30%',
        height: (window.innerHeight - 50).toString() + "px",
        margin: 'auto',
        border: '1px solid #ddd',
        textAlign: "center",
        overflow: "hidden"
    };

    handleTransfer = () => {
        if (this.state.loadIndicatorVisible) {//daca e deja clickuit nu permite alt click pana nu se efectueaza transferul
            return;
        }

        let rezultat = window.confirm("Se vor transfera date în anul " + (parseInt(localStorage.getItem("anSelectat")) + 1));

        if (rezultat) {
            this.setState(
                {
                    loadIndicatorVisible: true,
                    buttonText: 'Se transferă'
                },
                () => {
                    if (parseInt((new Date()).getFullYear()) <= parseInt(localStorage.getItem("anSelectat"))) {
                        alert("Nu puteţi transfera în anul " + (parseInt(localStorage.getItem("anSelectat")) + 1));
                        this.setState({ loadIndicatorVisible: false, buttonText: 'Transfera' });
                        return;
                    }

                    axios({
                        headers: { "Content-Type": "application/json" },
                        method: "post",
                        url: './api/Gospodarii/TransferAnUrmator/' + localStorage.getItem("anSelectat") + "/" + localStorage.getItem("siruta") + "/" + this.state.anFinal
                    }).then((response) => {
                        if (response.data === true) {
                            var anVechi = parseInt(localStorage.getItem("anSelectat"));
                            alert("Transferul a fost efectuat.");
                            this.setState({
                                loadIndicatorVisible: false,
                                buttonText: 'Transfera'
                            });

                            var listaAni = JSON.parse(localStorage.getItem("listaAni"));
                            listaAni.push(anVechi + 1);
                            localStorage.setItem("listaAni", JSON.stringify(listaAni));

                            this.props.anSelectat(anVechi + 1);
                            navigate("./date-gospodarii")
                        }
                        else {
                            alert("Transferul nu a fost efectuat.");
                        }
                    }).catch((error) => {
                        Utilitare.handleEroare(error, "Transfer");
                    });
                }
            );
        }
    }

    handleCheckBox = (val) => {
        if (val === true) {
            this.setState({ anFinal: 1 });
        } else {
            this.setState({ anFinal: 0 });
        }
    }

    render() {
        return (
            <Fragment>
                <div style={this.stil} id='chenar'>
                    <h3>Transfer date</h3>
                    <TextBox
                        style={{ margin: "0 5px 5px 5px" }}
                        placeholder="în anul:"
                        value={(parseInt(localStorage.getItem("anSelectat")) + 1).toString()}
                    >
                    </TextBox>
                    <CheckBox
                        defaultValue={false}
                        onValueChanged={(e) => this.handleCheckBox(e.value)}
                    /> <span className="dx-button-text" style={{
                        fontFamily: "Helvetica Neue,helvetica,verdana,sans-serif", fontSize: "13px"
                    }}>Sfârşit perioadă</span>
                    <p />
                    <Button
                        id="buton"
                        onClick={this.handleTransfer}
                    >
                        <LoadIndicator className="button-indicator" visible={this.state.loadIndicatorVisible} />
                        <span className="dx-button-text">{this.state.buttonText}</span>
                    </Button>

                </div>
            </Fragment>
        );
    }
}