import React, { Component } from 'react';
import { alert } from "devextreme/ui/dialog";
import DataGrid, { Column, Paging, FilterRow, FilterPanel } from 'devextreme-react/data-grid';
import { Button, TextBox } from 'devextreme-react';
import Utilitare from '../../../content/Utilitare';
import service from './DateInterfatari';
import axios from 'axios';

export default class RAN extends Component {
    state = {
        dateRAN: [],
        parola: ""
    }

    stil = {
        position: "relative",
        width: '98%',
        height: 'calc(100vh - 57px)',
        margin: 'auto',
        border: '1px solid #ddd',
        textAlign: "center",
        overflow: "hidden"
    };

    componentDidMount() {
        service.getDateRAN().then(r => {
            this.setState({
                dateRAN: r.listaRAN
            })

        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "RAN");
        })
    }

    handleOnClick = () => {
        if (this.state.parola === "") {
            alert("Parola nu a fost completată.");
            return;
        }

        //console.log("Noua parola este " + this.state.parola);

        axios({
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/RAN/SchimbaParola/' + localStorage.getItem("siruta") + "/" + this.state.parola,
        })
            .then((response) => {
                if (response.data == false) {
                    alert("S-a produs o eroare la conexiunea cu baza de date. (RAN.jsx)");
                }
                else {
                    alert("Noua parolă este: " + this.state.parola);
                }
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "RAN");
            });
    }

    render() {
        //console.log("RAN state", this.state);

        const headerTemplate = (columnHeader) => {
            return <p style={{ width: "100%", textAlign: "center" }}>{columnHeader.column.caption}</p>;
        }

        return (
            <React.Fragment>
                <div style={this.stil} id='chenar'>
                    <h3>Interfaţare RAN</h3>
                    <DataGrid
                        style={{ margin: '0px 5px 0 5px' }}
                        dataSource={this.state.dateRAN ? this.state.dateRAN : null}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        showBorders={true}
                        keyExpr={"idSyncCapitol"}
                        rowAlternationEnabled={false}
                    >
                        <FilterRow
                            visible={true}
                            applyFilter={true} />
                        <FilterPanel visible={true} />
                        <Paging defaultPageSize={10} />

                        <Column dataField="idSyncCapitol" caption="IDSync" alignment='center' headerCellRender={headerTemplate} visible={false} />
                        <Column dataField="id" caption="ID Gosp" headerCellRender={headerTemplate} />
                        <Column dataField="capitol" caption="Capitol" headerCellRender={headerTemplate} />
                        <Column dataField="stare" caption="Stare" headerCellRender={headerTemplate} />
                        <Column dataField="ranID" caption="ID RAN" headerCellRender={headerTemplate} />
                        <Column dataField="ranInfo" caption="Info" headerCellRender={headerTemplate} />
                        <Column dataField="ultimaActualizare" caption="Data" headerCellRender={headerTemplate} dataType={"date"} format={"dd.MM.yyyy"} />
                        <Column dataField="anRaportare" caption="An" headerCellRender={headerTemplate} />
                    </DataGrid>
                    <div>
                        <TextBox
                            style={{ margin: "10px 0 0 0", display: "inline-block" }}
                            width={"150px"}
                            showClearButton={true}
                            placeholder="Siruta"
                            value={localStorage.getItem("siruta")}
                            disabled={true}
                        />
                        <TextBox
                            style={{ margin: "10px 0 0 5px", display: "inline-block" }}
                            width={"300px"}
                            showClearButton={true}
                            placeholder="Parola"
                            onValueChanged={(e) => { this.setState({ parola: e.value }) }}
                            value={this.state.parola}
                        />
                        <Button
                            style={{ marginLeft: "5px", position: "relative", top: -1 }}
                            text={"Schimbă parola"}
                            type="normal"
                            stylingMode="outlined"
                            onClick={this.handleOnClick}
                        />
                    </div>
                </div>
            </React.Fragment >
        );
    }
}