import React, { Component } from 'react';
import DataGrid, { FilterRow, FilterPanel, Paging, Pager, Column } from 'devextreme-react/data-grid';
import axios from 'axios';
import { ScrollView } from 'devextreme-react';
import Utilitare from './../../../content/Utilitare';

export default class StergetiUtilizator extends Component {
    state = {
        utilizatori: null,
        linieSelectata: null
    }

    componentDidMount() {
        this.getUtilizatori();
    }

    getUtilizatori = () => {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Utilizatori/GetTotiUtilizatorii/' + localStorage.getItem("siruta"),
        })
            .then((response) => {
                if (response.data == null) {
                    alert("S-a produs o eroare la conexiunea cu baza de date. (Utilizatori.jsx)");
                }
                else {
                    var utilizatori = localStorage.getItem("role") === "Administrator"
                        ? response.data
                        : localStorage.getItem("role") === "AdministratorPrimarie"
                            ? response.data.filter(x => x.grup !== "Administratori")
                            : null;
                    this.setState({ utilizatori: utilizatori });
                }
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "ONRC");
            });
    }

    handleOnRowClick = (dateUtilizator) => {
        this.setState({ linieSelectata: dateUtilizator })
    }

    valoareCalculata(rowData) {
        var stare = ""
        if (rowData.stare == 0)
            stare = 'inactiv';
        else
            stare = 'activ'
        return stare;
    }

    handleStergere = () => {
        //console.log('linie selectata', this.state.linieSelectata);
        axios({
            headers: { "Content-Type": "application/json" },
            method: "delete",
            url: './api/Utilizatori/StergeUtilizator/' + localStorage.getItem("siruta") + "/" + this.state.linieSelectata.idAutentificareUtilizator,
        })
            .then((response) => {
                if (response.data == null) {
                    alert("S-a produs o eroare la conexiunea cu baza de date. (StergetiUtilizatori.jsx)");
                }
                else {
                    var utilizatori = localStorage.getItem("role") === "Administrator"
                        ? response.data
                        : localStorage.getItem("role") === "AdministratorPrimarie"
                            ? response.data.filter(x => x.grup !== "Administratori")
                            : null;
                    this.setState({ utilizatori: utilizatori });
                }
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "StergetiUtilizator");
            });
    }

    render() {
        //console.log("stergeti State", this.state);

        const headerTemplate = (columnHeader) => {
            return <p style={{ width: "100%", textAlign: "center" }}>{columnHeader.column.caption}</p>;
        }

        return (
            <div style={this.stil} id='chenar'>
                <ScrollView height="80%">
                    <h3>Activare/dezactivare Utilizatori</h3>

                    <DataGrid
                        style={{ margin: '0 5px 0 5px' }}
                        dataSource={this.state.utilizatori}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        showBorders={true}
                        keyExpr={"idAutentificareUtilizator"}
                        rowAlternationEnabled={false}
                        focusedRowEnabled={false}
                        defaultFocusedRowIndex={-1}
                        onRowClick={(e) => { this.handleOnRowClick(e.data); }}
                        selectedRowKeys={this.state.linieSelectata && this.state.linieSelectata.idAutentificareUtilizator ? [this.state.linieSelectata.idAutentificareUtilizator] : null}
                        onContextMenuPreparing={(e) => {
                            if (e && e.row && e.row.data)
                                this.setState({ linieSelectata: e.row.data }, () => {
                                    e.items = [
                                        {
                                            text: "Dezactivează/activează",
                                            onItemClick: this.handleStergere
                                        }];
                                })
                            else {
                                e.items = [];
                            }
                        }}
                    >
                        <FilterRow
                            visible={true}
                            applyFilter={true} />
                        <FilterPanel visible={true} />
                        <Paging defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[10, 25, 50]}
                        />
                        <Column dataField="idAutentificareUtilizator" caption="ID" headerCellRender={headerTemplate} alignment="center" visible={false} />
                        <Column dataField="nume" caption="Nume" headerCellRender={headerTemplate} />
                        <Column dataField="prenume" caption="Prenume" headerCellRender={headerTemplate} />
                        <Column dataField="numeUtilizator" caption="Nume utilizator" headerCellRender={headerTemplate} />
                        <Column dataField="grup" caption="Grup" headerCellRender={headerTemplate} />
                        <Column dataField="stare" dataType="string" caption="Stare" headerCellRender={headerTemplate} calculateDisplayValue={this.valoareCalculata} alignment="center" />
                        {(!localStorage.getItem("checkClickDreapta").replaceAll(" ", "").split(",").includes(localStorage.getItem("siruta").toString()))
                            ? null
                            : <Column
                                //coloana de butoane customizabile
                                type="buttons"
                                width={110}
                                buttons={[
                                    {
                                        hint: 'Activează/dezactivează',
                                        icon: 'trash',
                                        visible: true,
                                        onClick: this.handleStergere
                                    }
                                ]} />
                        }
                    </DataGrid>
                </ScrollView>
            </div >
        )
    }
}