/************************************************/
/*  MENIUL CAPITOLULelor cu nr. randuri fixe    */
/************************************************/
import React, { Component, Fragment } from 'react';
import { DropDownButton, Button, SelectBox } from 'devextreme-react';
import date from '../DateCapitole';


export default class MeniuCapFix extends Component {
    state = {
        listaDeclaratii: []
    }

    verificaAccesIstoric = () => {
        var ani = JSON.parse(localStorage.getItem("listaAni"));

        var anSelectat = parseInt(localStorage.getItem("anSelectat"));

        var accesIstoric = localStorage.getItem("accesIstoric");
        var siruta = localStorage.getItem("siruta");

        if (anSelectat < Math.max(...ani) && !accesIstoric.includes(siruta))
            return false;

        return true;
    }

    componentDidMount() {
        let listaDecl = this.props.listaDeclaratii.map(x => {
            let obj = { idDeclaratie: x.idDeclaratii, nrData: (x.nrDeclaratie + "/" + (new Date(x.dataDeclaratie)).toLocaleDateString("ro")) };
            return obj;
        });

        localStorage.removeItem("declaratieSelectata");

        this.setState({ listaDeclaratii: listaDecl });
    }

    render() {
        //console.log("meniu capFix props", this.props);
        //console.log("meniu capFix state", this.state);

        return (
            <Fragment>
                <p style={{ display: "inline-block", position: "relative", top: "1px" }}>Se modifică conform declaraţiei</p>
                <SelectBox
                    style={{ display: "inline-block", marginLeft: "5px", position: "relative", top: "1px" }}
                    height={26}
                    dataSource={this.state.listaDeclaratii}
                    displayExpr="nrData"
                    valueExpr="idDeclaratie"
                    showClearButton={true}
                    onValueChanged={(e) => { localStorage.setItem("declaratieSelectata", e.value) }}
                />

                <DropDownButton
                    text="Export"
                    icon="save"
                    dropDownOptions={{ width: 230 }}
                    items={date.getExport()}//aici e date din ../date (adica din Capitole)
                    onItemClick={(e) => { this.handleExport(e.itemData) }}
                    visible={false}
                />

                <Button
                    style={{ display: "inline-block", marginLeft: "5px" }}
                    width={120}
                    text="Şterge capitol"
                    type="danger"
                    stylingMode="outlined"
                    onClick={this.props.stergeCapitol}
                    visible={localStorage.getItem("role") === 'UtilizatorExtern' || this.verificaAccesIstoric() === false
                        ? localStorage.getItem("role") === "Administrator" ? true : false
                        : true}
                />

                <Button
                    style={{ display: "inline-block", marginLeft: "5px" }}
                    width={120}
                    text="Salvează capitol"
                    type="success"
                    stylingMode="outlined"
                    onClick={this.props.trimiteCapFix}
                    visible={localStorage.getItem("role") === 'UtilizatorExtern'
                        || this.verificaAccesIstoric() === false
                        || this.props.cap === 'CAP. XII'
                        || this.props.cap === 'CAP. XIII'
                        || this.props.cap === 'CAP. XIV'
                        || this.props.cap === 'CAP. XVI'
                        ? localStorage.getItem("role") === "Administrator" ? true : false
                        : true}
                />

                <Button
                    style={{ marginLeft: "5px" }}
                    stylingMode="outlined"
                    text="Inapoi"
                    type="danger"
                    onClick={this.props.inapoi}
                />
            </Fragment>
        );
    }
}