import React, { Component } from 'react';
import { alert } from "devextreme/ui/dialog";
import DataGrid, { FilterRow, FilterPanel, Paging, Pager, Column } from 'devextreme-react/data-grid';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import TextArea from 'devextreme-react/text-area';
import clase from '../../../custom.css';
import service from './DateInterfatari';
import axios from 'axios';
import { ScrollView } from 'devextreme-react';
import Utilitare from './../../../content/Utilitare';

export default class Onrc extends Component {
    state = {
        filtreCautare: null,
        metodeCautare: null,
        filtruCautareSelectat: 1,
        metodaCautareSelectata: 1,
        textCautat: '',
        judete: null,
        judetSelectat: 21,//21 - Alba
        dateFirme: null,
        textFirma: '',
        firmaSelectata: null
    };

    stil = {
        width: '98%',
        height: 'calc(100vh - 57px)',
        margin: 'auto',
        border: '1px solid #ddd',
        textAlign: "center",
        overflow: "hidden"
    };

    stilButon = {
        display: "inline-block",
        margin: "0 5px 0 0"
    };

    componentDidMount() {
        service.getJudete().then(r => {
            this.setState({
                judete: r.judete,
                filtreCautare: r.filtreCautare,
                metodeCautare: r.metodeCautare
            })

        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "ONRC");
        })
    }

    handleFiltreCautare = (filtru) => {
        this.setState({ filtruCautareSelectat: filtru.valoare });
    }

    handleMetodeCautare = (metoda) => {
        this.setState({ metodaCautareSelectata: metoda.valoare });
    }

    handleText = (text) => {
        this.setState({ textCautat: text });
    }

    handleOnClickCautare = () => {
        const { filtruCautareSelectat, metodaCautareSelectata, textCautat, judetSelectat } = this.state;
        if (!filtruCautareSelectat) {
            alert("Nu aţi selectat un filtru!");
            return;
        }
        if (!metodaCautareSelectata) {
            alert("Nu aţi selectat o metodă de căutare!");
            return;
        }
        if (!judetSelectat) {
            alert("Nu aţi selectat un judeţ!");
            return;
        }
        if (textCautat.length === 0) {
            alert("Nu aţi introdus textul ce trebuie căutat!");
            return;
        }

        //request cautare
        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Interfatari/GetFirme/' + filtruCautareSelectat + "/" + metodaCautareSelectata + "/" + judetSelectat + "/" + textCautat,
        })
            .then((response) => {
                if (response.data == null) {
                    alert("S-a produs o eroare la conexiunea cu baza de date. (ONRC.jsx)");
                }
                else
                    this.setState({ dateFirme: response.data })
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "ONRC");
            });
    }

    handleOnClickAfisare = () => {
        if (!this.state.firmaSelectata) {
            alert("Selectaţi o firmă din tabelul din subsolul paginii");
            return;
        }

        //request cautare
        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Interfatari/GetDateFirma/' + this.state.firmaSelectata.recomId,
        })
            .then((response) => {
                this.setState({ textFirma: response.data });
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "ONRC");
            });
    }

    handleOnClickStergere = () => {
        this.setState({
            filtruCautareSelectat: 1,
            metodaCautareSelectata: 1,
            textCautat: '',
            judetSelectat: 21,
            dateFirme: null,
            textFirma: '',
            firmaSelectata: null
        });
    }

    handleJudete = (judet) => {
        this.setState({ judetSelectat: judet.idNomenclatorJudeteONRC });
    }

    handleOnRowClick = (date) => {
        this.setState({ firmaSelectata: date });
    }

    render() {
        //console.log("ONRC state", this.state);

        const headerTemplate = (columnHeader) => {
            return <p style={{ width: "100%", textAlign: "center" }}>{columnHeader.column.caption}</p>;
        }

        return (
            <div style={this.stil} id='chenar'>
                <ScrollView height="100%">
                    <h3>Interfaţare ONRC</h3>
                    <table style={{ borderCollapse: "separate", borderSpacing: "5px", width: "100%" }}>
                        <tbody>
                            <tr>
                                <td style={{ backgroundColor: "##e3e2df", textAlign: "left", border: "1px solid #ddd", width: "40%" }}>
                                    { /*stanga*/}
                                    <p>Filtru căutare:</p>
                                    <SelectBox
                                        className={clase.Drop}
                                        style={{ marginLeft: "10px", width: "98%" }}
                                        dataSource={this.state.filtreCautare}
                                        displayExpr="denumire"
                                        valueExpr="valoare"
                                        placeholder="Alege filtrul..."
                                        defaultValue={this.state.filtruCautareSelectat}
                                        value={this.state.filtruCautareSelectat}
                                        onSelectionChanged={(e) => { this.handleFiltreCautare(e.selectedItem) }}
                                    />
                                    <br />
                                    <p>Metodă căutare:</p>
                                    <SelectBox
                                        className={clase.Drop}
                                        style={{ marginLeft: "10px", width: "98%" }}
                                        dataSource={this.state.metodeCautare}
                                        displayExpr="denumire"
                                        placeholder="Alege metoda..."
                                        valueExpr="valoare"
                                        defaultValue={this.state.metodaCautareSelectata}
                                        value={this.state.metodaCautareSelectata}
                                        onSelectionChanged={(e) => { this.handleMetodeCautare(e.selectedItem) }}
                                    />
                                    <br />
                                    <p></p>
                                    <TextBox
                                        style={{ marginLeft: "10px", width: "98%" }}
                                        placeholder="Introdu textul de căutat..."
                                        onFocusOut={(e) => { this.handleText(e.component.option("value")) }}
                                    />
                                    <p>Judeţ:</p>
                                    <SelectBox
                                        className={clase.Drop}
                                        style={{ marginLeft: "10px", width: "98%" }}
                                        dataSource={this.state.judete}
                                        displayExpr="denumire"
                                        placeholder="Alege judeţul..."
                                        valueExpr="idNomenclatorJudeteONRC"
                                        defaultValue={this.state.judetSelectat}
                                        value={this.state.judetSelectat}
                                        onSelectionChanged={(e) => { this.handleJudete(e.selectedItem) }}
                                    />
                                    <div style={{ width: "100%", textAlign: "center", margin: "10px 0 10px 0" }}>
                                        <Button
                                            style={this.stilButon}
                                            text="Caută"
                                            type="normal"
                                            stylingMode="contained"
                                            onClick={this.handleOnClickCautare}
                                        />
                                        <Button
                                            style={this.stilButon}
                                            text="Afişează detalii firmă"
                                            type="normal"
                                            stylingMode="outlined"
                                            onClick={this.handleOnClickAfisare}
                                        />
                                        <Button
                                            text="Şterge"
                                            type="normal"
                                            stylingMode="outlined"
                                            onClick={this.handleOnClickStergere}
                                        />
                                    </div>
                                </td>
                                <td style={{ backgroundColor: "lightgray", textAlign: "left", border: "1px solid #ddd" }}>
                                    { /*dreapta*/}
                                    <TextArea
                                        style={{ verticalAlign: "top", height: "300px" }}
                                        value={this.state.textFirma}
                                        placeholder="Detalii firmă..."
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <DataGrid
                        style={{ margin: '0 5px 0 5px' }}
                        dataSource={this.state.dateFirme}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        showBorders={true}
                        keyExpr={"codFiscal"}
                        rowAlternationEnabled={false}
                        focusedRowEnabled={true}
                        defaultFocusedRowIndex={-1}
                        onRowClick={(e) => { this.handleOnRowClick(e.data); }}
                    >
                        <FilterRow
                            visible={true}
                            applyFilter={true} />
                        <FilterPanel visible={true} />
                        <Paging defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[10, 25, 50]}
                        />
                        <Column dataField="nume" caption="Denumire firmă" headerCellRender={headerTemplate} />
                        <Column dataField="codFiscal" caption="Cod Fiscal" headerCellRender={headerTemplate} />
                        <Column dataField="nrRegistrulComertului" caption="Număr Registrul Comerţului" headerCellRender={headerTemplate} />
                        <Column dataField="judet" caption="Judeţ" headerCellRender={headerTemplate} />
                        <Column dataField="tara" caption="Ţara" headerCellRender={headerTemplate} />
                        <Column dataField="recomId" headerCellRender={headerTemplate} visible={false} />
                    </DataGrid>
                </ScrollView>
            </div>
        );
    }
}