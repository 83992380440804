import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import { ScrollView } from "devextreme-react";
import DataGrid, { Popup, FilterRow, FilterPanel, Paging, Column, Editing/*, Form, SimpleItem*/ } from 'devextreme-react/data-grid';
import service from './date';
import { navigate } from "@reach/router";
import Utilitare from './../../../../content/Utilitare';

export default class Nomenclatoare extends Component {
    state = {
        listaNomenclatoare: null,
        listaValori: null,
        nomenclatorSelectat: 1,
        valoareDeTrimis: null,
        inactive: false
    }

    stil = {
        width: '98%',
        height: 'calc(100vh - 57px)',
        margin: 'auto',
        border: '1px solid #ddd',
        textAlign: 'center',
        padding: '0 5px 5px 5px',
        overflow: "hidden"
    };

    componentDidMount() {
        service.getNomenclatoare().then(r => {
            this.setState({
                listaNomenclatoare: r.listaNomenclatoare
            });
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "Nomenclatoare");
        })
    }

    handleOnRowClick = (nomenclator) => {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Administrare/GetListaValori/' + nomenclator
        })
            .then((response) => {
                if (response.data === null) {
                    alert("Datele nu au putut fi încărcate. (Nomenclatoare.jsx)");
                    navigate('./date-gospodarii');
                }
                else
                    this.setState({ listaValori: response.data });
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "Nomenclatoare");
            });
    }

    handleOnRowClickValoare = (valoare) => {
        this.setState({ valoareDeTrimis: valoare });
    }

    handleOnSaving = (date) => {
        var obiect = { ...this.state.valoareDeTrimis };

        for (const property in date.data) {
            obiect[property] = date.data[property];
        }

        this.setState({ valoareDeTrimis: obiect });

        if (date.type === "insert" || date.type === "update") {
            this.upsert();

        } else { //unde date.type="remove"
            this.remove();
        }
    }

    upsert = () => {
        axios({
            data: this.state.valoareDeTrimis,
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/Administrare/InsertValoare'
        })
            .then((response) => {
                if (response.data === null) {
                    alert("Operaţiunea nu a putut fi efectuată. (Nomenclatoare.jsx)");
                    navigate('./date-gospodarii');
                }
                else
                    this.setState({ listaValori: response.data });
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "Nomenclatoare");
            });
    }

    remove = () => {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/Administrare/StergeValoare/' + this.state.valoareDeTrimis.idValoriImageCombobox
        })
            .then((response) => {
                if (response.data === null) {
                    alert("Datele nu au putut fi şterse. (Nomenclatoare.jsx)");
                    navigate('./date-gospodarii');
                }
                else
                    this.setState({ listaValori: response.data });
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "Nomenclatoare");
            });
    }

    render() {
        //console.log("administrare nomenclatoare props", this.props);
        //console.log("administrare nomenclatoare state", this.state);

        return (
            <Fragment>
                <div style={this.stil} id='chenar'>
                    <h3 style={{ margin: "auto" }}>Nomenclatoare</h3>
                    <h6>Atenţie! Cod nou şi Nume nomenclator nou rezultă nomenclator nou. </h6>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ width: "20%", verticalAlign: "top" }}>
                                    <ScrollView style={{ height: (window.innerHeight - 107).toString() + "px" }}>
                                        { /*stanga*/}
                                        <DataGrid
                                            dataSource={this.state.listaNomenclatoare ? this.state.listaNomenclatoare : null}
                                            columnAutoWidth={true}
                                            showBorders={true}
                                            wordWrapEnabled={true}
                                            rowAlternationEnabled={false}
                                            showColumnHeaders={false}
                                            focusedRowEnabled={true}
                                            onRowClick={(e) => { this.handleOnRowClick(e.data.nume) }}
                                        >
                                            <Paging enabled={false} />
                                            <FilterRow visible={false}
                                                applyFilter={false} />
                                            <FilterPanel visible={false} />
                                            <Column dataField="nume" alignment="left" />
                                        </DataGrid>
                                    </ScrollView>
                                </td>
                                <td style={{ verticalAlign: "top" }}>
                                    { /*dreapta*/}
                                    <DataGrid
                                        dataSource={this.state.listaValori ? this.state.listaValori : null}
                                        columnAutoWidth={true}
                                        showBorders={true}
                                        wordWrapEnabled={true}
                                        rowAlternationEnabled={false}
                                        focusedRowEnabled={true}
                                        keyExpr="idValoriImageCombobox"
                                        onRowClick={e => { this.handleOnRowClickValoare(e.data) }}
                                        onSaving={(e) => { this.handleOnSaving(e.changes[0]) }}
                                        selectedRowKeys={this.state.valoareDeTrimis && this.state.valoareDeTrimis.idValoriImageCombobox ? [this.state.valoareDeTrimis.idValoriImageCombobox] : null}
                                        onContextMenuPreparing={(e) => {
                                            if (e && e.row && e.row.data)
                                                this.setState({ valoareDeTrimis: e.row.data }, () => {
                                                    e.items = [{
                                                        text: "Adaugă",
                                                        icon: "add",
                                                        onItemClick: function () {
                                                            e.component.addRow();
                                                        }
                                                    },
                                                    {
                                                        text: "Modifică",
                                                        icon: "edit",
                                                        onItemClick: function () {
                                                            e.component.editRow(e.row.rowIndex);
                                                        }
                                                    },
                                                    {
                                                        text: "Şterge",
                                                        icon: "trash",
                                                        onItemClick: function () {
                                                            e.component.deleteRow(e.row.rowIndex);
                                                        }
                                                    }];
                                                })
                                            else {
                                                e.items = [{
                                                    text: "Adaugă",
                                                    icon: "add",
                                                    onItemClick: function () {
                                                        e.component.addRow();
                                                    }
                                                }];
                                            }
                                        }}
                                    >
                                        {(!localStorage.getItem("checkClickDreapta").replaceAll(" ", "").split(",").includes(localStorage.getItem("siruta").toString()))
                                            ? <Editing
                                                mode="popup"
                                            >
                                                <Popup
                                                    showTitle={true}
                                                    title="Inserare rând"
                                                />
                                            </Editing>
                                            : <Editing
                                                allowUpdating={true}
                                                allowAdding={true}
                                                allowDeleting={true}
                                                useIcons={true}
                                                mode="popup"
                                            >
                                                <Popup
                                                    showTitle={true}
                                                    title="Atenţie! Cod nou şi Nume nomenclator nou => nomenclator nou."
                                                />
                                            </Editing>
                                        }
                                        <Paging enabled={false} />
                                        <FilterRow visible={false}
                                            applyFilter={false} />
                                        <FilterPanel visible={false} />
                                        <Column dataField="cod" caption={"Cod"} alignment={"center"} dataType={"number"} />
                                        <Column dataField="valoare" caption={"Valoare"} alignment={"center"} dataType={"number"} />
                                        <Column dataField="valPr" caption={"Prescurtare"} alignment={"center"} dataType={"string"} />
                                        <Column dataField="denumire" caption={"Denumire"} alignment={"left"} dataType={"string"} />
                                        <Column dataField="stare" caption={"Stare"} alignment={"center"} dataType={"number"} />
                                        <Column dataField="numeNomenclator" caption={"Nume nomenclator"} alignment={"center"} dataType={"string"} />
                                    </DataGrid>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Fragment>
        );
    }
}