import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import DataGrid, { /*Validator, Format,*/MasterDetail, Export, Pager, Paging, FilterRow, FilterPanel, Column, Scrolling, Lookup, Editing, RangeRule, ValidationRule, RequiredRule, FormItem } from 'devextreme-react/data-grid';
import { LoadIndicator } from 'devextreme-react';
import service from './DateCapVar';
//import { navigate } from "@reach/router";
import Utilitare from "../../../../../../content/Utilitare";
import AlteGospodarii from './AlteGospodarii';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

export default class CapVar extends Component {
    state = {
        events: [],
        dateCapitol: null,
        dateFormular: null,
        popupMembruVizibil: false,
        actiune: "adaugare",
        //capitolSelectat: this.props.capitol,
        linieDeSters: null,
        linieSelectata: null,
        loadIndicatorVisible: false
    }

    componentDidMount() {
        this.incarcaCapitol();//pusa separat deoarece face refresh si la transfer parcela
    }

    componentDidUpdate(prevProps, prevState, snapshot) {//verifica daca se schimba gospodaria ca sa defocuseze linia
        if (prevProps.idGospSelectata !== this.props.idGospSelectata)
            this.handleOnRowClick(null);

        if (prevProps.capitol !== this.props.capitol) {
            this.setState({ loadIndicatorVisible: true });
            this.incarcaCapitol();
        }
    }

    incarcaCapitol = () => {
        this.setState({ loadIndicatorVisible: true });

        service.getDateCapitol(this.props.idGospSelectata, this.props.capitol).then(r => {
            this.setState({
                dateCapitol: r.capitole,
                dateFormular: r.formular,
                loadIndicatorVisible: false
            })
            this.verificaCapi();
        }).catch(error => {//in caz de eroare
            alert("S-a produs o eroare. Contactaţi administratorul.");
            Utilitare.handleEroare(error, "CapVar");
        })
    }

    handleOnRowClick = (e) => {
        this.setState({ linieSelectata: e ? e : null })
        this.props.setLinieSelectata(e ? e : null);
    }

    //stergerea unei linii se face pe baza refRegistrulAgricol, idUnic, siruta
    handleDelete = async (e) => {
        if (!e.data.idUnic) {
            alert("Selectaţi o linie din tabel.");
            return;
        }
        axios({
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/Capitole/StergereLinie/' + e.data.idUnic + "/" + e.data.refRegistru + "/" + localStorage.getItem("siruta") + "/" + localStorage.getItem("check2a") + "/" + (localStorage.getItem("declaratieSelectata") && localStorage.getItem("declaratieSelectata") !== 'null' ? localStorage.getItem("declaratieSelectata") : 0),
        })
            .then((response) => {
                var membri = { ...this.state.dateCapitol };
                var membriNoi = membri.randuri.filter(x => x.idUnic !== e.data.idUnic);
                membri.randuri = membriNoi;
                this.setState({ dateCapitol: membri }, () => { this.handleOnRowClick(null) });
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "CapVar");
            });
    }

    //ascundeUpsertLinie = () => {
    //    this.setState({ popupMembruVizibil: false });
    //}

    calculeazaValoareCelula = (valori, camp) => {
        var listaCombo = this.state.dateFormular ? this.state.dateFormular.filter(x => x.listaCombo !== null && x.listaCombo !== undefined) : null;
        var raspuns = listaCombo.find(l => l.denumire === camp)?.listaCombo
            ? listaCombo.find(l => l.denumire === camp)?.listaCombo?.find(x => x.valoare === valori[camp])?.denumire
            : valori[camp];

        return raspuns;
    }

    validareCustom = (date, campCurent) => {
        //randurile cu destinatie completata sunt campurile de ari si nu tre sa depaseasca 99.99
        var randuriCuDestinatie = this.state.dateFormular
            ? this.state.dateFormular.filter(x => x.destinatie).map(x => { return x.denumire })
            : null;

        /*validare suprafete: daca nu este introdusa nicio suprafata (suma lor = 0)*/
        var idRanduriCuDestinatie = this.state.dateFormular
            ? this.state.dateFormular.filter(x => x.destinatie).map(x => { return x.destinatie })
            : null;
        var randuriDestinatie = this.state.dateFormular
            ? this.state.dateFormular.filter(x => idRanduriCuDestinatie.includes(x.idListaCampuriTabele)).map(x => { return x.denumire })
            : null;

        var denumireRanduriDeTotalizat = [...new Set([...randuriCuDestinatie, ...randuriDestinatie])];

        var total = 0;
        denumireRanduriDeTotalizat.forEach(elem => {
            if (date.data[elem])
                total += date.data[elem];
        })

        if (total === 0 && denumireRanduriDeTotalizat.includes(campCurent)) {
            return false;
        }
        /*end of validare suprafete*/

        if (randuriCuDestinatie.indexOf(date.column.dataField) >= 0) {//validare ari
            if (parseFloat(date.value) > 99.99)
                return false;
        }

        //IMPORTANT! Daca se renunta la Firma ca membru al gospodariei, modificam validare CNP de aici:
        if (date.column.dataField === "codNumericPersonal") {//numai pentru campul CNP
            if (date.value && (date.value).toString().length <= 13)//modifici in daca lungime cnp DIFERITA de 13
                //|| !Utilitare.validCNP((date.value).toString()))//daca cnp-ul este invalid
                //if (date.value.toString() === '1111111111118')//cnp-ul acceptat
                return true;
            else
                return false;

            return true;
        }


        if (date.column.dataField === "codRand") {//numai pentru campul CNP
            return Utilitare.isNumeric(date.value);
        }

        return true;
    }

    ceLulaMeaTemplate = (text, cod) => {
        return "<div>" + text + (cod ? "<br/> (" + cod.trim() + ")" : "") + "</div>";
    }

    handleBenzi = (benzi) => {
        var benziOrdonate = benzi.sort((a, b) => (a.colOrder > b.colOrder) ? 1 : ((b.colOrder > a.colOrder) ? -1 : 0));

        return benziOrdonate.map((r) => {
            var oblig = this.state.dateFormular && this.state.dateFormular.find(x => x.denumire === r.fieldName)
                ? this.state.dateFormular.find(x => x.denumire === r.fieldName).obligatoriu
                : null;

            var lookup = r.fieldName && this.state.dateFormular.find(c => c.denumire /*lasa cu 2 egaluri*/ == r.fieldName)?.listaCombo?.map(x => { return { valoare: parseInt(x.valoare), denumire: x.denumire.toString() } });
            var tip = null;

            switch (r.tipCamp) { //'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime'
                case "varchar": {
                    tip = "string"
                    break;
                }
                case "datetime":
                    tip = "date"
                    break;
                case "numeric":
                case "money":
                case "int": {
                    tip = "number";
                    break;
                }
                default: {
                    tip = null;
                    break;
                }
            }

            return <Column
                key={r.id}
                dataField={r.fieldName}
                allowSorting={false}
                caption={r.caption + (r.codColoana ? (" (" + r.codColoana.trim() + ")") : "")}
                headerCellTemplate={this.ceLulaMeaTemplate(r.caption, r.codColoana)}
                visible={true}
                allowEditing={this.state.dateFormular && this.state.dateFormular.find(x => x.denumire === r.fieldName) && this.state.dateFormular.find(x => x.denumire === r.fieldName).total !== null ? false : true}
                dataType={tip}
                format={tip === "date" ? "dd.MM.yyyy" : tip === "number" && r.precizie && r.precizie === 2 ? "#.00" : null}
            //displayFormat={tip === "date" ? "'dd.MM.yyyy'" : null}
            //calculateCellValue={(dataField) => this.calculeazaValoareCelula(dataField, r.fieldName)} //vezi metoda mai sus
            >
                {//pentru editorul larg din cap 16
                    this.props.cap && this.props.cap === "CAP. XVI"
                        ? (r.fieldName === "mentiuniSpeciale" ? <FormItem colSpan={2} editorType="dxTextArea" editorOptions={{ height: "250px" }} /> : <FormItem editorType="dxTextBox" />)
                        : null}

                {r.fieldName === "dataDecedare"
                    ? <RangeRule message="Data invalidă." max={new Date()} />
                    : null}

                {r.fieldName === "numeleSiPrenumele"
                    ? <RequiredRule message="Necesar a fi completat." />
                    : null}

                {oblig && oblig === true ? <RequiredRule message="Necesar a fi completat." /> : null}

                <ValidationRule type={"custom"} validationCallback={e => this.validareCustom(e, r.fieldName)}></ValidationRule>

                {/*r.tipCamp === 'money' ? <Format type="fixedPoint" precision={r.precizie} /> : null*/}
                {r.tipCamp === 'money' ? <RangeRule message="Valoare invalidă." min={0} /> : null}

                {lookup ?
                    <Lookup
                        dataSource={lookup}
                        valueExpr="valoare"
                        displayExpr="denumire"
                    />
                    : null}

                {r.benzi !== undefined ?
                    this.handleBenzi(r.benzi)
                    : null}

            </Column>
        });
    }

    handleOnRowUpdating = (e) => {
        var dateDeTrimis = { ...e.oldData };//destructurare valorile vechi

        for (const [key, value] of Object.entries(e.newData)) {//inlocuire valori vechi cu cele noi
            dateDeTrimis[key] = value;
        }

        var liniiCuTotal = this.state.dateFormular.filter(x => x.total !== null);//linii care depind de un alt camp sau combobox

        if (liniiCuTotal !== null) {
            //pentru fiecare element a carui valoarea depinde de altul, cautam elementul dupa denumire si 
            //valoarea elementului corespondent (denumirea elementului corespondent gasita dupa id-ul din dateFormular)
            liniiCuTotal.forEach(element => {
                if (dateDeTrimis[element.denumire] !== dateDeTrimis[this.state.dateFormular.find(x => x.idListaCampuriTabele.toString() === element.total).denumire])
                    dateDeTrimis[element.denumire] = dateDeTrimis[this.state.dateFormular.find(x => x.idListaCampuriTabele.toString() === element.total).denumire];
            });
        }

        /*UPDATE propriu-zis */
        axios({
            data: dateDeTrimis,
            headers: { "Content-Type": "application/json" },
            method: "post",
            //url: './api/Capitole/PostCapRanduriVariabile/' + localStorage.getItem("siruta") + "/" + this.props.capitol + "/" + localStorage.getItem("check2a"),
            url: './api/Capitole/PostCapRanduriVariabile/' + localStorage.getItem("siruta") + "/" + this.props.cap + "/" + localStorage.getItem("check2a") + "/" + (localStorage.getItem("declaratieSelectata") && localStorage.getItem("declaratieSelectata") !== 'null' ? localStorage.getItem("declaratieSelectata") : 0),
        })
            .then((response) => {
                //daca e ok, aici primim raspuns idUnic != 0 
                this.adaugareInTabel(dateDeTrimis, "modificare");
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "CapVar");
            });
        /* */
    }

    handleOnInitNewRow = (e) => {//initializeaza comboBox-urile la inserarea unei linii noi
        var listaCombo = this.state.dateFormular ? this.state.dateFormular.filter(x => x.listaCombo !== null && x.listaCombo !== undefined) : null

        var boxuri = this.state.dateFormular.filter(x => x.listaCombo !== null);

        listaCombo.forEach(lista => {//initial listaCombo contine listaCombo in interiorul ei (e o lista de liste)
            var listaDoarCombo = [...lista.listaCombo];//contine fiecare listaCombo propriu-zisa
            listaDoarCombo.sort(function (a, b) { return a.valoare - b.valoare });//filtram listaCombo propriu-zisa
            boxuri.forEach(box => e.data[box.denumire] = listaDoarCombo[0]?.valoare);//ii luam valoarea minima pentru a initializa SelectBox-ul
        })
    }

    handleOnRowInserting = (e) => {
        //console.log("capVar declaSelect", this.props.declaratieSelectata);

        var dateDeTrimis = e.data;
        dateDeTrimis.refRegistru = this.props.idGospSelectata;

        var liniiCuTotal = this.state.dateFormular.filter(x => x.total !== null);//linii care depind de un alt camp sau combobox

        if (liniiCuTotal !== null) {
            //pentru fiecare element a carui valoarea depinde de altul, cautam elementul dupa denumire si 
            //valoarea elementului corespondent (denumirea elementului corespondent gasita dupa id-ul din dateFormular)
            liniiCuTotal.forEach(element => {
                if (dateDeTrimis[element.denumire] !== dateDeTrimis[this.state.dateFormular.find(x => x.idListaCampuriTabele.toString() === element.total).denumire])
                    dateDeTrimis[element.denumire] = dateDeTrimis[this.state.dateFormular.find(x => x.idListaCampuriTabele.toString() === element.total).denumire];
            });
        }

        axios({
            data: dateDeTrimis,
            headers: { "Content-Type": "application/json" },
            method: "post",
            //url: './api/Capitole/PostCapRanduriVariabile/' + localStorage.getItem("siruta") + "/" + this.props.capitol + "/" + localStorage.getItem("check2a"),
            url: './api/Capitole/PostCapRanduriVariabile/' + localStorage.getItem("siruta") + "/" + this.props.cap + "/" + localStorage.getItem("check2a") + "/" + (localStorage.getItem("declaratieSelectata") && localStorage.getItem("declaratieSelectata") !== 'null' ? localStorage.getItem("declaratieSelectata") : 0),
        })
            .then((response) => {
                //daca e ok, aici primim raspuns idUnic != 0 
                dateDeTrimis.idUnic = response.data;
                this.adaugareInTabel(dateDeTrimis, "adaugare");
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "CapVar");
            });
    }

    adaugareInTabel = (dateDeTrimis, actiune) => {//adauga modificarile, eventual inserturile in tabel
        //facem update-ul si in grid-ul afisat (in state)
        if (actiune === "modificare") {
            var valoriDinState = this.state.dateCapitol.randuri.map(x => ({ ...x }));
            valoriDinState.forEach(element => {
                if (element.idUnic === dateDeTrimis.idUnic)
                    for (const [key, value] of Object.entries(dateDeTrimis)) {//inlocuire valori vechi cu cele noi
                        element[key] = value;
                    }
            });
            var stateDeInlocuit = { ...this.state.dateCapitol };
            stateDeInlocuit.randuri = valoriDinState;
            this.setState({ dateCapitol: stateDeInlocuit });
        } else {
            stateDeInlocuit = { ...this.state.dateCapitol };
            stateDeInlocuit.randuri.push(dateDeTrimis);
            this.setState({ dateCapitol: stateDeInlocuit });
        }
    }

    verificaCapi = () => {
        if (this.props.capitol && this.props.capitol === 1 && this.state.dateCapitol && this.state.dateCapitol.randuri) {
            var nr = 0;
            this.state.dateCapitol.randuri.map(x => { if (x.codRudenie === 1) { return nr++; } })
            if (nr > 1)
                alert("Această gospodărie are " + nr + " capi de gospodărie");
        }
    }

    handleToolbarPreparing = (date) => {
        var pozitieButon = (!localStorage.getItem("checkClickDreapta").replaceAll(" ", "").split(",").includes(localStorage.getItem("siruta").toString())) || localStorage.getItem("role") === "UtilizatorExtern" || this.verificaAccesIstoric() === false ? 0 : 1;
        date.toolbarOptions.items[pozitieButon].location = "before";

        //ascunde butonul de export excel (care este implicit)
        var vizibilitate = pozitieButon === 0 ? 1 : 0;
        if (date.toolbarOptions.items[vizibilitate])
            date.toolbarOptions.items[vizibilitate].visible = false;
    }

    verificaAccesIstoric = () => {
        var ani = JSON.parse(localStorage.getItem("listaAni"));

        var anSelectat = parseInt(localStorage.getItem("anSelectat"));

        var accesIstoric = localStorage.getItem("accesIstoric");
        var siruta = localStorage.getItem("siruta");

        if (anSelectat < Math.max(...ani) && !accesIstoric.includes(siruta))
            return false;

        return true;
    }

    handleOnRowPrepared(e) {
        if (this.props.capitol === 1)
            //if (this.state.dateCapitol
            //    && this.state.dateCapitol.benzi
            //    && this.state.dateCapitol.benzi.find(x => x.fieldName === 'numeleSiPrenumele'))
            if ((e && e.data && !e.data.listagosp) || (e.data && e.data.listagosp && e.data.listagosp.length === 0)) {
                if (e.cells.length > 0 && e.cells[0].cellElement && e.cells[0].cellElement.classList)
                    e.cells[0].cellElement.classList.remove('dx-datagrid-expand');
                if (e.cells.length > 0 && e.cells[0].cellElement && e.cells[0].cellElement.childNodes[0].classList)
                    e.cells[0].cellElement.childNodes[0].classList.remove('dx-datagrid-group-closed');
            }
    }

    handleOnExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Export cap. ');

        exportDataGrid({
            component: e.component,
            worksheet,
            topLeftCell: { row: 4, column: 1 },
        }).then((cellRange) => {
            // header
            const headerRow = worksheet.getRow(2);
            headerRow.height = 30;
            worksheet.mergeCells(2, 1, 2, 12);

            headerRow.getCell(1).value = localStorage.getItem("dateCapitol");
            headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 };
            headerRow.getCell(1).alignment = { horizontal: 'center' };
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Capitol.xlsx');
            });
        });
        e.cancel = true;
    }

    exportWord = () => {
        if (!window.Blob) {
            alert('Your legacy browser does not support this action.');
            return;
        }

        var html, link, blob, url, css;

        // EU A4 use: size: 841.95pt 595.35pt;
        // US Letter use: size:11.0in 8.5in;

        css = (
            '<style>' +
            '@page WordSection1{size: 595.35pt 841.95pt;mso-page-orientation: portrait;}' +
            'div.WordSection1 {page: WordSection1;}' +
            'table{border-collapse:collapse;width:100%;}td{border:1px gray solid;padding:2px;font-size:10px}' +
            '</style>'
        );

        var html1 = "<p>" + localStorage.getItem("dateCapitol") + "</p>" + window.docx.innerHTML;

        var pozPornire = html1.indexOf("</tbody>");
        var pozOprire = html1.indexOf("<tbody role=\"presentation\">");
        var textDeInlocuit = html1.substring(pozPornire, pozOprire + 27);
        html = html1.replace(textDeInlocuit, "").replace("No data", "").replace("Loading...", "").replace("Add a row", "");

        //html = "<p>" + localStorage.getItem("dateCapitol") + "</p>" + window.docx.innerHTML;
        //console.log(html);

        blob = new Blob(['\ufeff', css + html], {
            type: 'application/msword'
        });
        url = URL.createObjectURL(blob);
        link = document.createElement('A');
        link.href = url;
        // Set default file name. 
        // Word will append file extension - do not add an extension here.
        link.download = 'Document';
        document.body.appendChild(link);
        if (navigator.msSaveOrOpenBlob) navigator.msSaveOrOpenBlob(blob, 'Document.doc'); // IE10-11
        else link.click();  // other browsers
        document.body.removeChild(link);
    }

    render() {
        //console.log("CapVar state", this.state);
        //console.log("CapVar props", this.props);
        var loadInd = <div style={{ position: "absolute", top: "0px", bottom: "0px", left: "0px", right: "0px", zIndex: "1502", backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
            <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", border: "1px solid black", backgroundColor: "white" }}>
                <p>Se încarcă...</p>
                <LoadIndicator
                    className="button-indicator"
                    visible={this.state.loadIndicatorVisible}
                />
            </div>
        </div>

        var sursaGrid = this.state.dateCapitol && this.state.dateCapitol
            ? this.state.dateCapitol.randuri.map(x => ({ ...x })).filter(y => y.idUnic !== this.state.linieDeSters).sort((a, b) => (parseInt(a.codRand) > parseInt(b.codRand)) ? 1 : ((parseInt(b.codRand) > parseInt(a.codRand)) ? -1 : 0))
            : null

        return (
            <Fragment>
                {this.state.loadIndicatorVisible ?
                    loadInd
                    : null}

                <div id="docx">
                    <div className="WordSection1">
                        <DataGrid
                            height={document.getElementById('chenar').offsetHeight - 75}
                            style={{ marginTop: "5px", paddingLeft: "10px" }}
                            //dataSource={this.state.dateCapitol && this.state.dateCapitol ? this.state.dateCapitol.randuri.map(x => ({ ...x })).filter(y => y.idUnic !== this.state.linieDeSters).sort((a, b) => (parseInt(a.codRand) > parseInt(b.codRand)) ? 1 : ((parseInt(b.codRand) > parseInt(a.codRand)) ? -1 : 0)) : null}
                            dataSource={sursaGrid}
                            columnAutoWidth={true}
                            allowColumnReordering={true}
                            showBorders={true}
                            keyExpr={"idUnic"}
                            rowAlternationEnabled={false}
                            wordWrapEnabled={true}
                            onInitialized={(e) => { this.datagrid = e.component; }}
                            onRowClick={(e) => this.handleOnRowClick(e.data)}
                            showRowLines={true}
                            selectedRowKeys={[this.state.linieSelectata ? this.state.linieSelectata.idUnic : null]}
                            onRowDblClick={(e) => this.datagrid.editRow(e.dataIndex)}
                            defaultSelectedRowKeys={[sursaGrid && sursaGrid.length > 0 ? sursaGrid[0].idUnic : null]}
                            onRowUpdating={(e) => this.handleOnRowUpdating(e)}
                            onRowRemoving={(e) => this.handleDelete(e)}
                            onRowInserting={(e) => this.handleOnRowInserting(e)}
                            onInitNewRow={(e) => this.handleOnInitNewRow(e)}
                            onToolbarPreparing={(e) => { this.handleToolbarPreparing(e) }}
                            onRowPrepared={(e) => { this.handleOnRowPrepared(e); }}
                            onExporting={this.handleOnExporting}
                            onContextMenuPreparing={(e) => {
                                if (e && e.row && e.row.data)
                                    if ((this.verificaAccesIstoric() === true && localStorage.getItem("role") !== "UtilizatorExtern") || localStorage.getItem("role") === "Administrator")
                                        this.setState({ linieSelectata: e ? e.row.data : null }, () => {
                                            this.props.setLinieSelectata(e ? e.row.data : null);

                                            if (e.row.rowType === "data") {
                                                //if (localStorage.getItem("checkClickDreapta").replaceAll(" ", "").split(",").includes(localStorage.getItem("siruta").toString()))
                                                e.items = [{
                                                    text: "Adaugă",
                                                    icon: "add",
                                                    onItemClick: function () {
                                                        e.component.addRow();
                                                    }
                                                },
                                                {
                                                    text: "Modifică",
                                                    icon: "edit",
                                                    onItemClick: function () {
                                                        e.component.editRow(e.row.rowIndex);
                                                    }
                                                },
                                                {
                                                    text: "Şterge",
                                                    icon: "trash",
                                                    onItemClick: function () {
                                                        e.component.deleteRow(e.row.rowIndex);
                                                    }
                                                },
                                                {
                                                    text: "Export Word",
                                                    icon: "exportselected",
                                                    onItemClick: this.exportWord
                                                },
                                                {
                                                    text: "Export Excel",
                                                    icon: "exportxlsx",
                                                    onItemClick: function () {
                                                        e.component.exportToExcel();
                                                    }
                                                }];
                                                //else
                                                //    e.items = [];
                                            }
                                        })
                                    else
                                        this.setState({ linieSelectata: e ? e.row.data : null }, () => {
                                            this.props.setLinieSelectata(e ? e.row.data : null);

                                            if (e.row.rowType === "data") {
                                                e.items = [];
                                            }
                                        })
                                else
                                    if (this.verificaAccesIstoric() === true && localStorage.getItem("role") !== "UtilizatorExtern")
                                        e.items = [{
                                            text: "Adaugă",
                                            icon: "add",
                                            onItemClick: function () {
                                                e.component.addRow();
                                            }
                                        }];
                            }}
                        >
                            <FilterRow visible={false}
                                applyFilter={false} />
                            <FilterPanel visible={false} />
                            <Scrolling columnRenderingMode="virtual" />
                            <Paging defaultPageSize={50} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[10, 25, 50]}
                                showInfo={true} />

                            {this.state.dateCapitol && this.state.dateCapitol ? this.handleBenzi(this.state.dateCapitol.benzi) : null}

                            {(!localStorage.getItem("checkClickDreapta").replaceAll(" ", "").split(",").includes(localStorage.getItem("siruta").toString()))
                                || localStorage.getItem("role") === 'UtilizatorExtern'
                                || this.verificaAccesIstoric() === false
                                ? localStorage.getItem("role") === "Administrator"
                                    ? <Editing
                                        allowUpdating={true}
                                        allowAdding={true}
                                        allowDeleting={true}
                                        useIcons={true}
                                        mode="form" />
                                    : <Editing mode="form" />
                                : <Editing
                                    allowUpdating={true}
                                    allowAdding={true}
                                    allowDeleting={true}
                                    useIcons={true}
                                    mode="form" />
                            }
                            <Export enabled={true} fileName={this.props.cap + '_' + this.props.anSelectat} />
                            {localStorage.getItem("checkAlteGospodarii").includes(localStorage.getItem("siruta")) && this.props.capitol === 1 && this.state.dateCapitol && this.state.dateCapitol.benzi && this.state.dateCapitol.benzi.find(x => x.fieldName === 'numeleSiPrenumele') ?
                                <MasterDetail
                                    enabled={true}
                                    component={AlteGospodarii}
                                />
                                : null
                            }
                        </DataGrid>
                    </div>
                </div>
            </Fragment>
        );
    }
}