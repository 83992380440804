/* eslint-disable */
import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import DataGrid, { Selection, Column } from 'devextreme-react/data-grid';
import { SelectBox, Button, Popup, ScrollView } from 'devextreme-react';
//import service from './date';
import clase from './RapoarteCentralizatoare.module.css';
//import './DateRC';

export default class RapoarteCentralizatoare extends Component {
    state = {
        listaAni: null,
        anSelectat: null,
        raport: null,
        listaRapoarte: [],
        popupVizibil: false
    }

    stil = {
        width: '98%',
        height: 'calc(100vh - 57px)',
        margin: 'auto',
        border: '1px solid #ddd',
        textAlign: "center",
        overflow: "hidden"
    };

    componentDidMount() {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Rapoarte/GetRapoarteCentralizatoare/' + localStorage.getItem("siruta"),
        })
            .then((response) => {
                if (response.data) {
                    this.setState({
                        listaRapoarte: response.data.listaRapoarte,
                        reportServer: response.data.reportServer,
                        idPrim: response.data.idPrim,
                        idUser: response.data.iduser,
                        listaAni: JSON.parse(localStorage.getItem("listaAni")),
                        anSelectat: JSON.parse(localStorage.getItem("anSelectat"))
                    });
                    //alert("Datele au fost salvate.");
                }
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "RapoarteCentralizatoare");
            });
    }

    handleAn = (an) => {
        this.setState({ anSelectat: an });
    }

    handleClickRaport = (raport) => {
        this.setState({ raport: raport });
    }

    handleAfisare = (e) => {
        var URLtot = "";
        if (this.state.raport.valPr === null)
            alert("Raportul nu poate fi afişat pentru perioada selectată.");
        else {
            if (this.state.raport.valPr === 'RoluriGospodarii' && localStorage.getItem("role") === 'Administrator') {
                URLtot = this.state.reportServer + this.state.raport.valPr + '&rs:Command=Render';
                console.log("urlTot = ", URLtot);

                window.open(URLtot, '_blank', 'noopener');
                //this.setState({
                //    urlDeTrimis: this.state.reportServer + this.state.raport.valPr + '&rs:Command=Render',
                //    popupVizibil: true
                //})
                return;
            }
            if (this.state.raport.valPr === 'RoluriGospodarii' && localStorage.getItem("role") !== 'Administrator') {
                alert("Nu aveţi drepturile necesare pentru vizualizarea acestui raport.");
                return;
            }

            URLtot = this.state.reportServer + this.state.raport.valPr + '&rs:Command=Render&idprim=' + this.state.idPrim + '&An=' + this.state.anSelectat;

            console.log("urlTot = ", URLtot);

            window.open(URLtot, '_blank', 'noopener');
            //this.setState({
            //    urlDeTrimis: this.state.reportServer + this.state.raport.valPr + '&rs:Command=Render&idprim=' + this.state.idPrim + '&An=' + this.state.anSelectat,
            //    popupVizibil: true
            //    })
            this.handleScrieJurnalOperare();
        }
    }

    handleScrieJurnalOperare = () => {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/Rapoarte/PostAdeverinteJurnalOperare/' + localStorage.getItem("siruta") + '/' + 0 + '/' + ' RaportCentralizator ' + '/' + this.state.raport.idValoriImageCombobox,
        })
            .then((response) => {
                if (response.data == false)
                    console.log("S-a produs o eroare la scrierea adeverintei in JurnalOperare");
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "RapoarteCentralizatoare");
            });
    }

    render() {
        console.log("this.state rapoarteCentralizatoare", this.state);

        const headerTemplate = (columnHeader) => {
            return <p style={{ width: "100%", textAlign: "center" }}>{columnHeader.column.caption}</p>;
        }

        return (
            <Fragment>
                <div style={this.stil} id='chenar'>
                    <ScrollView height="100%">
                        <div>
                            <h3 style={{ width: "100%", textAlign: "center", display: "inline-block" }}> Rapoarte centralizatoare </h3>

                            <SelectBox
                                id='select3'
                                className={clase.Drop}
                                style={{ marginRight: "3px" }}
                                width={70}
                                dataSource={this.state.listaAni ? this.state.listaAni : null}
                                value={this.state.anSelectat ? this.state.anSelectat : null}
                                onSelectionChanged={(e) => { this.handleAn(e.selectedItem) }}
                            />
                        </div>

                        <DataGrid
                            keyExpr="valoare"
                            dataSource={this.state.listaRapoarte ? this.state.listaRapoarte : null}
                            focusedRowKey={"valoare"}
                            focusedRowEnabled={false}
                            rowAlternationEnabled={false}
                            showBorders={true}
                            onRowClick={(e) => { this.handleClickRaport(e.data) }}
                            onRowDblClick={(e) => { this.handleAfisare(e) }}
                            selectedRowKeys={this.state.raport && this.state.raport.valoare ? [this.state.raport.valoare] : null}
                            onContextMenuPreparing={(e) => {
                                //console.log(e)
                                if (e && e.row && e.row.data)
                                    if (e.row.rowType === "data")
                                        this.setState({ raport: e.row.data }, () => {

                                            e.items = [{
                                                text: "Afişează",
                                                icon: "file",
                                                onItemClick: (e) => { this.handleAfisare() }
                                            }];
                                        })
                                    else
                                        this.setState({ raport: e.row.data }, () => {
                                            this.handleClickRaport(e.row.data);

                                            if (e.row.rowType === "data") {
                                                e.items = [];
                                            }
                                        })
                            }}
                        >
                            <Selection mode="single" deferred={false} />
                            <Column dataField="valoare" headerCellRender={headerTemplate} width={"50px"} caption={"Nr."} />
                            <Column dataField="denumire" caption="Nume raport" headerCellRender={headerTemplate} />
                            {(!localStorage.getItem("checkClickDreapta").replaceAll(" ", "").split(",").includes(localStorage.getItem("siruta").toString()))
                                ? null
                                : < Column
                                    //coloana de butoane customizabile
                                    type="buttons"
                                    width={110}
                                    buttons={[
                                        {
                                            hint: 'Afişare',
                                            icon: 'file',
                                            visible: true,
                                            onClick: (e) => { this.handleAfisare(e) }
                                        }
                                    ]}
                                />}
                        </DataGrid>
                    </ScrollView>
                </div>

                <Popup
                    visible={this.state.popupVizibil}
                    //onHiding={this.hideInfo}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showCloseButton={false}
                    showTitle={true}
                    //title="Information"
                    container=".dx-viewport"
                    fullScreen={true}
                    showCloseButton={false}
                >
                    <iframe width="100%" height="100%" src={this.state.urlDeTrimis} />
                    {/*pune aici raportul*/}
                    {/* this.state.raport?this.state.raport:null */}
                </Popup>
            </Fragment>
        );
    }
}