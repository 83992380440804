import axios from 'axios';

export default {
    getAdeverinteSablon() {
        return new Promise((resolve, reject) => {

            const listaAdeverinte = axios.get("./api/AdeverinteSablon/GetAdeverinteSablon/" + localStorage.getItem("siruta"));

            axios.all([listaAdeverinte]).then(
                axios.spread((responseLS) => {
                    let raspuns = {
                        listaAdeverinte: responseLS.data
                    }
                    //console.log("request raspuns", responseLS.data);
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    },
    getNumarRegistratura(idprim, idreg) {

        const numarRegistratura = axios.get("./api/AdeverinteSablon/GetNumarRegistratura/" + idprim + "/" + idreg);

        return new Promise((resolve, reject) => {
            axios.all([numarRegistratura]).then(
                axios.spread((responseNR) => {
                    let raspuns = {
                        nrAdeverinta: responseNR.data
                    }
                    //console.log("request raspuns", responseNR.data);
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    }
}