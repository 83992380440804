import axios from 'axios';

export default {
    getListaUAT() {
        //request ce returneaza liste de primarii si useri pentru popularea drop-ului din pagina de login in fctie de utilizator
        return new Promise((resolve, reject) => {
            axios.get("./api/DatePrimarie/getListaPrimariiUseri").then(response => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    getUtilizator(numeUtilizator) {
        return new Promise((resolve, reject) => {
            const utilizatorDeModificat = axios.get("./api/Utilizatori/GetUtilizator/",
                {
                    params: {
                        numeUtilizator: numeUtilizator.toString()
                    }
                });

            axios.all([utilizatorDeModificat]).then(
                axios
                    .spread((responseU) => {
                        //console.log("request", responseC.data)
                        let raspuns = {
                            utilizator: responseU.data
                        }
                        resolve(raspuns);
                    })
            ).catch((error) => {
                reject(error);
            });
        });
    },
    getUtilizatori() {
        var numeUtilizator = localStorage.getItem("userName");
        var siruta = localStorage.getItem("siruta");

        return new Promise((resolve, reject) => {
            const raportUtilizatori = axios.get("./api/Utilizatori/GetUtilizatori/",
                {
                    params: {
                        numeUtilizator: numeUtilizator,
                        siruta: siruta
                    }
                });

            axios.all([raportUtilizatori]).then(
                axios
                    .spread((responseU) => {
                        let raspuns = {
                            dateUtilizatori: responseU
                        }
                        resolve(raspuns);
                    })
            ).catch((error) => {
                reject(error);
            });
        }
        )
    }
};