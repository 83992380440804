import React, { Component, Fragment } from 'react';
import { alert } from "devextreme/ui/dialog";
import { Button, TextBox, TextArea } from 'devextreme-react';
import DataGrid, { Column, Pager, Paging, Selection } from 'devextreme-react/data-grid';
import List from 'devextreme-react/list';
import clase from './Notificari.module.css';
import date from './date';

class Notificari extends Component {
    state = {
        perioadeVizibile: false,
        listaEmail: [],
        mailExpeditor: null,
        parolaExpeditor: null,
        subiect: null
    }

    /*PRIMARIA se ia din state. probabil din bvaza de date*/
    primaria = "Primaria";
    textNotificare = "Notificare: Vă rugăm să vă prezentanţi la Primăria " + this.primaria + " pentru a declara completarea sau modificarea datelor pentru Registrul Agricol. Dacă deja aţi declarat completarea sau modificarea Registrului Agricol vă rugam să ignoraţi acest mesaj. VA MULŢUMIM!";

    handleAfisarePerioade = () => {
        let vizibil = this.state.perioadeVizibile;
        this.setState({ perioadeVizibile: !vizibil });
    }

    handleSelectate = (date) => {
        let listaEmailuri = [];
        for (let i = 0; i < date.length; i++) {
            listaEmailuri.push(date[i].email);
        }
        this.setState({ listaEmail: listaEmailuri });
    }

    handleTrimiteNotificare = () => {
        if (this.state.listaEmail.length === 0) {
            alert("Nu aţi selectat nicio adresă de email ca destinatar.");
            return;
        }
        if (this.state.mailExpeditor === null) {
            alert("Nu aţi introdus un email al expeditorului.");
            return;
        };
        if (this.state.parolaExpeditor === null) {
            alert("Nu aţi introdus parola expeditorului");
            return;
        };
        if (this.state.subiect === null) {
            this.setState({ subiect: "Notificare Registrul Agricol" });
        }
        alert("Trimite notificare la: " + this.state.listaEmail.toString() + ", de la: " + this.state.mailExpeditor + ", cu parola: " + this.state.parolaExpeditor + ", subiect: " + this.state.subiect);
    }

    handleEmail = (date) => {
        this.setState({mailExpeditor: date});
    }

    handleParola = (date) => {
        this.setState({ parolaExpeditor: date });
    }

    handleSubiect = (date) => {
        this.setState({ subiect: date });
    }

    render() {
        return (
            <Fragment>
                <div className={clase.FlexContainer}>
                    <Button
                        className={clase.Buton}
                        text={this.state.perioadeVizibile ? "Minimizează" : "Perioadele la care persoanele fizice şi juridice au obligaţia să declare datele pentru înscrierea în Registrul Agricol"}
                        type="normal"
                        stylingMode="outlined"
                        onClick={this.handleAfisarePerioade}
                    />
                    <div className={this.state.perioadeVizibile ? clase.Vizibil : clase.Invizibil}>
                        <p>a) 5 ianuarie – 1 martie pt.datele anuale privind membrii gospodariei, terenul aflat in proprietate si cel pe care il utilizeaza, cladirile cu destinatia de locuinta,constructii-anexe si mijloace de transport cu tractiune animala si mecanica, masinile,utilajele si instalatiile pt.agricultura si silvicultura;</p>
                        <p>b) 5-20 ianuarie- 5-20 iulie- efectivele de animale existente in gospodarie la inceputul si sfarsitul fiecarui semestru, precum si modificarile intervenite in cursul semestrului precedent ca urmare a vanzarii-cumpararii, a produsilor obtinuti, a mortii sau sacrificarii animalelor sau a altor intrari – iesiri.</p>
                        <p>c) 1 – 31 mai pentru datele privind modul de folosinta al terenurilor,suprafete cultivate, numarul pomilor in anul agricol respective,aplicarea ingrasamintelor,amendamentelor si pesticidelor</p>
                        <p>d) persoanele fizice si juridice au obligatia sa declare date pt. a fi inscrise in Registrul agricol si in afara acestor intervale de timp,in cazul in care au intervenit modificari in proprietate in termen de 30 zile de la aparitia modificarii (art.11,alin.1 /Ordonanta 28 /2008)</p>
                        <p>Persoanele fizice si juridice sunt obligate sa declare, in termenele prevazute de lege, date corecte si complete in vederea inscrierii acestora in Registrul agricol ( art.14 /Ordonanta 28/2008).</p>
                        <p>Constituie contraventie si se sanctioneaza cu amenda de la 100 lei la 500 lei, in cazul persoanelor fizice, respectiv cu amenda de la 300 lei la 1500 lei in cazul persoanelor juridice:</p>
                        <p>a) refuzul de a prezenta primarului ,prefectului sau persoanelor imputernicite de acestia, documentele si evidentele necesare in vederea verificarii realitatii datelor;</p>
                        <p>b) nedeclararea, la termenele stabilite si in forma solicitata, a datelor care fac obiectul Registrului agricol ( art.20 /Ordonanta 28 / 2008).</p>
                    </div>

                    <DataGrid
                        style={{ display: "block" }}
                        dataSource={date.getDate()}
                        showBorders={true}
                        focusedRowEnabled={true}
                        rowAlternationEnabled={false}
                        keyExpr={"idGosp"}
                        onSelectionChanged={(e) => this.handleSelectate(e.selectedRowsData)}
                    >
                        <Selection
                            mode="multiple"
                            selectAllMode="allPages"
                            showCheckBoxesMode="onClick"
                        />
                        <Paging defaultPageSize={5} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} />
                        <Column dataField="idGosp" width={50} />
                        <Column dataField="reprezentantLegal" />
                        <Column dataField="codFermier" />
                        <Column dataField="email" />
                        <Column dataField="telefon" />
                        <Column dataField="an" />
                    </DataGrid>

                    <List
                        className={clase.Lista}
                        items={this.state.listaEmail}
                    />
                    <div style={{ display: "inline-block" }}>
                        <TextBox className={clase.InputText} id={"tb1"} width={200} placeholder={"Adresa mail expeditor..."} onValueChanged={(e) => { this.handleEmail(e.value) }} />
                        <TextBox className={clase.InputText} id={"tb2"} width={200} placeholder={"Parola expeditor..."} onValueChanged={(e) => { this.handleParola(e.value) }}/>
                        <TextBox className={clase.InputText} id={"tb3"} placeholder={"Subiect..."} onValueChanged={(e) => { this.handleSubiect(e.value) }}/>
                        <p>Mesaj:</p>
                        <TextArea className={clase.TextArea} id={"tb4"} value={this.textNotificare} />
                        <Button
                            className={clase.Buton}
                            text="Trimite notificare"
                            type="success"
                            stylingMode="outlined"
                            onClick={this.handleTrimiteNotificare}
                        />
                    </div>

                </div>
                <div className={clase.Butoane}>
                    <Button
                        width={120}
                        className={clase.Buton}
                        text="Anulează"
                        type="danger"
                        stylingMode="outlined"
                        onClick={this.props.ascunde}
                    />
                </div>
            </Fragment>
        );
    }
}

export default Notificari;