import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import { Button, TextBox, CheckBox, SelectBox } from 'devextreme-react';
import clase from './Utilizatori.module.css';
import { navigate } from "@reach/router";
import Validator, { RequiredRule, PatternRule } from 'devextreme-react/validator';
import service from './date';
import Utilitare from './../../../content/Utilitare';

export default class AdaugatiUtilizator extends Component {
    state = {
        tipuriUtilizatori: localStorage.getItem("role") === "Administrator"
            ? [{ id: 1, denumire: "Administrator" }, { id: 2, denumire: "AdministratorPrimarie" }, { id: 3, denumire: "Operator" }, { id: 4, denumire: "UtilizatorExtern" }]
            : localStorage.getItem("role") === "AdministratorPrimarie"
                ? [{ id: 2, denumire: "AdministratorPrimarie" }, { id: 3, denumire: "Operator" }, { id: 4, denumire: "UtilizatorExtern" }]
                : [/*{ id: 3, denumire: "Operator" },*/ { id: 4, denumire: "UtilizatorExtern" }],
        listaUAT: null,
        listaUtilizatori: null,
        amPrimarii: false,
        utilizatorDeTrimis: { nume: "", numeUtilizator: "", parola: "", tipSelectat: "Operator", cnp: null },
        confirmare: null,
        uat: null
    }

    stil = {
        minWidth: '300px',
        width: '30%',
        height: 'calc(100vh - 57px)',
        margin: 'auto',
        border: '1px solid #ddd',
        textAlign: "center",
        overflow: "hidden"
    };

    handleAdaugare = () => {
        if (!this.state.utilizatorDeTrimis) {
            alert("Nu aţi introdus date.");
            return;
        }

        if (!this.state.utilizatorDeTrimis.nume || this.state.utilizatorDeTrimis.nume === "") {
            alert("Nu aţi introdus numele şi prenumele utilizatorului.");
            return;
        }

        if (!this.state.utilizatorDeTrimis.numeUtilizator || this.state.utilizatorDeTrimis.numeUtilizator === "") {
            alert("Nu aţi introdus numele de utilizator.");
            return;
        }

        if (!this.state.utilizatorDeTrimis.parola || this.state.utilizatorDeTrimis.parola !== this.state.confirmare || (!this.state.utilizatorDeTrimis.parola || !this.state.confirmare)) {
            alert("Parola nu se confirmă.");
            return;
        }

        if (this.state.listaUtilizatori.filter(x => x.numeUtilizator.toUpperCase() === this.state.utilizatorDeTrimis.numeUtilizator.toUpperCase()).length > 0) {
            alert("Utilizatorul există deja.");
            return;
        }

        if (!this.state.utilizatorDeTrimis.uat || this.state.utilizatorDeTrimis.uat.length === 0) {
            alert("Nu aţi selectat nicio primărie");
            return;
        }

        if (this.state.utilizatorDeTrimis.tipSelectat === "UtilizatorExtern" && (this.state.utilizatorDeTrimis.cnp === null || this.state.utilizatorDeTrimis.cnp === "" || this.state.utilizatorDeTrimis.cnp.length !== 13)) {
            alert("Nu aţi introdus un CNP valid.");
            return;
        }

        axios({
            data: this.state.utilizatorDeTrimis,
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: "api/Utilizatori/PostUtilizator",
        })
            .then((response) => {
                if (response.data === true) {
                    alert("Utilizatorul a fost adăugat în baza de date.");
                    this.setState({ utilizatorDeTrimis: { nume: "", numeUtilizator: "", parola: "" } }, () => { navigate('./date-gospodarii')})
                }
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "Adaugati");
            });
    }

    handlePrimarie = (valoare, primarie) => {
        let utilizator = { ...this.state.utilizatorDeTrimis };
        let valori = utilizator.uat ? utilizator.uat.split(",") : [];
        if (valoare === true) {
            valori.push(primarie.siruta);
        } else {
            let indexOfSiruta = valori.indexOf(primarie.siruta);
            valori.splice(indexOfSiruta, 1);
        }
        utilizator.uat = valori.sort((a, b) => { return a - b }).join();
        this.setState({ utilizatorDeTrimis: utilizator });
    }

    handleNume = (nume) => {
        let util = { ...this.state.utilizatorDeTrimis };
        util.nume = nume;
        this.setState({ utilizatorDeTrimis: util });
    }

    handleNumeUtilizator = (nume) => {
        let util = { ...this.state.utilizatorDeTrimis };
        util.numeUtilizator = nume;
        this.setState({ utilizatorDeTrimis: util });
    }

    handleParola = (parola) => {
        let util = { ...this.state.utilizatorDeTrimis };
        util.parola = parola;
        this.setState({ utilizatorDeTrimis: util });
    }

    handleConfirmare = (parola) => {
        this.setState({ confirmare: parola });
    }

    handleCNP = (cnp) => {
        let codNP = { ...this.state.utilizatorDeTrimis };
        codNP.cnp = cnp;
        this.setState({ utilizatorDeTrimis: codNP });
    }

    componentDidMount() {
        service.getListaUAT().then(r => {
            let listaUAT = r.data.primarii;
            let listaUseri = r.data.utilizatori;

            this.setState({
                listaUAT: listaUAT,
                listaUtilizatori: listaUseri,
                amPrimarii: true

            })
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "Adaugati");
        })
    }

    render() {
        //console.log("Adaugati.jsx state", this.state);

        let checks = this.state.listaUAT ? this.state.listaUAT.map(x =>
            <div key={x.siruta} style={{ display: "block", textAlign: "left", padding: "5px" }}>
                <CheckBox
                    defaultValue={false}
                    text={x.numePrimarie}
                    key={x.numePrimarie}
                    onValueChanged={(e) => { this.handlePrimarie(e.value, x) }}
                    visible={localStorage.getItem("role") === "Administrator" || (x.numePrimarie === localStorage.getItem("numePrimarie"))}
                />
            </div>) : null;

        return (
            <Fragment>
                <div style={this.stil} id='chenar'>
                    <h3> Adaugare utilizatori</h3>
                    <div style={{ padding: "0 5px 0 5px" }}>
                        <TextBox
                            id="nume"
                            style={{ borderColor: "aquamarine" }}
                            width={"100%"}
                            className={clase.Margini}
                            placeholder="Nume si prenume..."
                            onValueChanged={(e) => { this.handleNume(e.value) }}
                            value={this.state.utilizatorDeTrimis.nume}
                        >
                            <Validator>
                                <RequiredRule />
                                <PatternRule
                                    pattern="^[a-zA-ZàèìòùÀÈÌÒÙĂÎÂŞŢăîâlşţáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ -]+$"
                                    message="Doar litere."
                                />
                            </Validator>
                        </TextBox>
                        <TextBox
                            id="numeU"
                            style={{ borderColor: "aquamarine" }}
                            width={"100%"}
                            className={clase.Margini}
                            placeholder="Nume utilizator..."
                            onValueChanged={(e) => { this.handleNumeUtilizator(e.value) }}
                            value={this.state.utilizatorDeTrimis.numeUtilizator}
                        >
                            <Validator>
                                <RequiredRule />
                            </Validator>
                        </TextBox>
                        <TextBox
                            mode="password"
                            id="parola"
                            style={{ borderColor: "aquamarine" }}
                            width={"100%"}
                            className={clase.Margini}
                            placeholder="Parola..."
                            onValueChanged={(e) => { this.handleParola(e.value) }}
                            value={this.state.utilizatorDeTrimis.parola}
                        >
                            <Validator>
                                <RequiredRule />
                            </Validator>
                        </TextBox>
                        <TextBox
                            mode="password"
                            id="confirm"
                            style={{ borderColor: "aquamarine" }}
                            width={"100%"}
                            className={clase.Margini}
                            placeholder="Confirmare parola..."
                            onValueChanged={(e) => { this.handleConfirmare(e.value) }}
                            value={this.state.confirmare}
                        >
                            <Validator>
                                <RequiredRule />
                            </Validator>
                        </TextBox>
                        <TextBox
                            id="cnp"
                            style={this.state.utilizatorDeTrimis.tipSelectat === "UtilizatorExtern" ? { borderColor: "aquamarine" } : null}
                            width={"100%"}
                            maxLength={13}
                            className={clase.Margini}
                            placeholder="CNP..."
                            onValueChanged={(e) => { this.handleCNP(e.value) }}
                            value={this.state.utilizatorDeTrimis.cnp ? this.state.utilizatorDeTrimis.cnp : null}
                        //visible={this.state.utilizatorDeTrimis.tipSelectat ==="UtilizatorExtern"}
                        >
                            <Validator>
                                <PatternRule
                                    pattern="^[0-9]+$"
                                    message="Doar cifre."
                                />
                            </Validator>
                        </TextBox>
                    </div>

                    <div style={{ padding: "0 5px 0 5px" }}>
                        <SelectBox
                            className={clase.Drop}
                            width={"auto"}
                            dataSource={this.state.tipuriUtilizatori}
                            displayExpr={"denumire"}
                            valueExpr={"id"}
                            defaultValue={3}
                            onSelectionChanged={(e) => {
                                let util = { ...this.state.utilizatorDeTrimis }
                                util.tipSelectat = e.selectedItem.denumire;
                                this.setState({ utilizatorDeTrimis: util });
                            }}
                        //visible={localStorage.getItem("role") === "Administrator"}
                        />
                    </div>

                    {checks}

                    <Button
                        text="Adauga utilizator"
                        type="success"
                        onClick={this.handleAdaugare}
                    //disabled={localStorage.getItem("role") !== "Administrator"}
                    />
                </div>
            </Fragment>
        );
    }
}