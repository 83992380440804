import axios from 'axios';

export default {
    getNomenclatoare() {
        return new Promise((resolve, reject) => {
            const listaNomenclatoare = axios.get("./api/Administrare/GetNomenclatoare");

            axios.all([listaNomenclatoare]).then(
                axios.spread((responseN) => {
                    let raspuns = {
                        listaNomenclatoare: responseN.data
                    }

                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    }
}