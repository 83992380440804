import React, { PureComponent, Fragment } from 'react';
//import { alert } from "devextreme/ui/dialog";
import { Button } from 'devextreme-react';
import Capitole from './Capitole/Capitole';

export default class ComponentJos extends PureComponent {
    render() {
        //console.log("componentJos props", this.props)

        var componentaJos =
            this.props.gospodariaSelectata ?
                <Capitole
                    style={{ height: '100%' }}
                    gospodariaSelectata={this.props.gospodariaSelectata}
                    anSelectat={this.props.anSelectat}
                    selectat={this.props.selectat}
                    inapoi={this.props.inapoi}
                    capitolSelectat={this.props.capitolSelectat}
                />
                : <div>
                    <p style={{ color: "red", fontWeight: "bold" }}>Selectaţi o gospodărie!</p>
                    <Button
                        width={120}
                        text="Înapoi"
                        type="success"
                        stylingMode="outlined"
                        onClick={this.props.inapoi}
                    ></Button>
                </div>;

        return (
            <Fragment>
                { componentaJos}
            </Fragment>
        )
    }
}