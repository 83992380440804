/********** aici sunt capitolele ****************/
import axios from 'axios';

//ATENTIE! toate trebuie sa aiba cod rand UNIC, e cheie pentru dataGrid


export default {
    getDateCapitol(refRegistru, capitol) {
        return new Promise((resolve, reject) => {

            const dateCapitol = axios.get("./api/Capitole/" + refRegistru + "/" + capitol + "/" + localStorage.getItem("siruta"));

            const dateFormular = axios.get("./api/Capitole/GetListaCampuriFormular/" + capitol + "/" + refRegistru + "/" + localStorage.getItem("siruta"));

            axios.all([dateCapitol, dateFormular]).then(
                axios.spread((responseDC, responseDF) => {
                    let raspuns = {
                        capitole: responseDC.data,
                        formular: responseDF.data
                    }
                    //console.log("request raspuns", responseDC.data);
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    }
}