import axios from 'axios';

export default {
    getLocalitateJudet(siruta) {//tip este tipul de grid din tabela ColoaneGridView
        return new Promise((resolve, reject) => {
            const localitateJudet = axios.get("./api/Interfatari/GetLocalitateJudet/" + siruta);

            axios.all([localitateJudet]).then(
                axios.spread((responseLJ) => {
                    //console.log("responseLJ", responseLJ.data);
                    let raspuns = {
                        localitate: responseLJ.data.localitate,
                        judet: responseLJ.data.judet,
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    },
    getJudete() {
        return new Promise((resolve, reject) => {
            const judete = axios.get("./api/Interfatari/GetJudete/");

            const filtreCautare = axios.get("./api/Interfatari/GetFiltreCautare/");

            const metodeCautare = axios.get("./api/Interfatari/GetMetodeCautare/");

            axios.all([judete, filtreCautare, metodeCautare]).then(
                axios.spread((responseLJ, responseF, responseM) => {
                    let raspuns = {
                        judete: responseLJ.data,
                        filtreCautare: responseF.data,
                        metodeCautare: responseM.data
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    },
    getDateRNEP() {
        return new Promise((resolve, reject) => {
            const persoane = axios.get("./api/Interfatari/IncarcaGridRNEP/" + localStorage.getItem("siruta") + "/" + localStorage.getItem("anSelectat"));

            const fisiere = axios.get("./api/Interfatari/IncarcaGridFisiere/" + localStorage.getItem("siruta"));

            axios.all([persoane, fisiere]).then(
                axios.spread((responseP, responseF) => {
                    let raspuns = {
                        persoane: responseP.data,
                        fisiere: responseF.data
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    },
    getDateRAN() {
        return new Promise((resolve, reject) => {
            const listaRAN = axios.get("./api/RAN/GetSituatieRAN/" + localStorage.getItem("siruta"));

            axios.all([listaRAN]).then(
                axios.spread((responseR) => {
                    let raspuns = {
                        listaRAN: responseR.data
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    }
}