import axios from 'axios';

export default {
    getListaDeclaratii(idRegistrulAgricol) {//tip este tipul de grid din tabela ColoaneGridView
        return new Promise((resolve, reject) => {
            const listaDeclaratii = axios.get("./api/Gospodarii/GetDeclaratii/" + localStorage.getItem("siruta") + "/" + idRegistrulAgricol);

            axios.all([listaDeclaratii]).then(
                axios.spread((responseLD) => {
                    let raspuns = {
                        declaratii: responseLD.data
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    }
}