import axios from 'axios';

export default {
    getListaCategorii() {//tip este tipul de grid din tabela ColoaneGridView
        return new Promise((resolve, reject) => {

            const categorii = axios.get("./api/Rapoarte/GetCategorii/");

            axios.all([categorii]).then(
                axios.spread((responseC) => {
                    let raspuns = {
                        categorii: responseC.data
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    },
    getListaCulturi(idCapitol, idCultura) {//tip este tipul de grid din tabela ColoaneGridView
        return new Promise((resolve, reject) => {

            const culturi = axios.get("./api/Rapoarte/GetListaCulturi/" + idCapitol);
            const productii = axios.get("./api/Rapoarte/GetListaProductiaVegetala/" + localStorage.getItem("siruta") + "/" + localStorage.getItem("anSelectat") + "/" + idCapitol + "/" + idCultura);
            const gospodarii = axios.get("./api/Rapoarte/GetListaGospodariiProductiaVegetala/" + localStorage.getItem("siruta") + "/" + localStorage.getItem("anSelectat") + "/" + idCapitol + "/" + idCultura);
            const procent = axios.get("./api/Rapoarte/GetProcent/" + localStorage.getItem("siruta") + "/" + localStorage.getItem("anSelectat") + "/" + idCapitol + "/" + idCultura);
            const server = axios.get("./api/Rapoarte/GetServerString/" + localStorage.getItem("siruta"));

            axios.all([culturi, productii, gospodarii, procent, server ]).then(
                axios.spread((responseC, responseP, responseG, responsePr, responseS) => {
                    let raspuns = {
                        listaCulturi: responseC.data,
                        listaProductii: responseP.data,
                        listaGospodarii: responseG.data,
                        procent: responsePr.data,
                        serverString: responseS.data
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    }
}