import axios from 'axios';

export default {
    getDateAdrese() {
        return new Promise((resolve, reject) => {
            const tari = axios.get("./api/Gospodarii/GetTari/");
            const judete = axios.get("./api/Gospodarii/GetJudete/");
            const localitati = axios.get("./api/Gospodarii/GetLocalitati/");
            const listaStrazi = axios.get("./api/Gospodarii/GetListaStrazi/" + localStorage.getItem("siruta"));
            const tipuriStrazi = axios.get("./api/Gospodarii/GetTipuriStrazi/");
            const categoriiGospodarii = axios.get("./api/Gospodarii/GetCategoriiGospodarii/");
            const tipuriGospodarii = axios.get("./api/Gospodarii/GetTipuriGospodarii/");

            axios.all([tari, judete, localitati, listaStrazi, tipuriStrazi, categoriiGospodarii, tipuriGospodarii]).then(
                axios.spread((responseT, responseJ, responseL, responseLS, responseTS, responseCG, responseTG) => {
                    let raspuns = {
                        tari: responseT.data,
                        judete: responseJ.data,
                        localitati: responseL.data,
                        listaStrazi: responseLS.data,
                        tipuriStrazi: responseTS.data,
                        categoriiGospodarii: responseCG.data,
                        tipuriGospodarii: responseTG.data
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    },
    getDateGospodarie(idGospodarie) {
        return new Promise((resolve, reject) => {
            const dateGospodarie = axios.get("./api/Gospodarii/GetGospodarie/", { params: { id: idGospodarie } });

            axios.all([dateGospodarie]).then(
                axios.spread((responseDG) => {
                    let raspuns = {
                        dateGospodarie: responseDG.data
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    }
}