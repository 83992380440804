import React, { Component } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import classes from './Acasa.module.css';
import { navigate } from "@reach/router";
import service from './Date';
import { SelectBox, TextBox, Button } from 'devextreme-react';
import 'bootstrap';
import Utilitare from './../../../content/Utilitare';
import logo from './logoATS.jpg';

class Acasa extends Component {
    state = {
        amPrimarii: false,
        listaUAT: [],
        listaUtilizatori: [],
        siruteSelectate: [],
        UatVizibil: false,
        numeUtilizator: null,
        parola: null,
        siruta: null,
        numePrimarie: null,
        butonActiv: false
    }

    handleOnNumeChanged = (nume) => {
        var sirute;

        if (nume === "") {
            this.setState({ numeUtilizator: null });
        }

        if (nume && nume !== "")
            axios({
                headers: { "Content-Type": "application/json" },
                method: "get",
                url: "api/DatePrimarie/GetPrimariiUser/" + nume,
            })
                .then((response) => {
                    if (response)
                        sirute = response.data.toString();
                    else
                        return;

                    if (sirute === "primarieInexistanta") {
                        alert("Utilizatorul nu aparţine niciunei primării.");
                        document.getElementsByName("NumeUtilizator").value = null;
                        document.getElementsByName("Parola").value = null;
                        document.getElementsByName("SelectPrimarie").value = null;
                        this.setState({ UatVizibil: false, numeUtilizator: nume, parola: null, uat: null, siruteSelectate: [] });
                        this.setState({ butonActiv: false });
                        return;
                    }

                    //console.log("sirute", sirute)

                    let siruteSelectate = [];
                    if (sirute.length > 0) {
                        siruteSelectate = sirute.split(",");
                        if (siruteSelectate.length === 1) {
                            document.getElementsByName("SelectPrimaria").value = parseInt(siruteSelectate[0]);
                            this.setState({ numeUtilizator: nume, siruta: parseInt(siruteSelectate[0])/*, parola: document.getElementsByName("Parola").value */ });
                        } else if (siruteSelectate.length > 1) {
                            document.getElementsByName("SelectPrimaria").value = null;
                            this.setState({ UatVizibil: true, siruteSelectate: siruteSelectate, siruta: parseInt(siruteSelectate[0]), numeUtilizator: nume });
                        } else if (siruteSelectate.length < 0) {
                            this.setState({ siruta: null, UatVizibil: false, siruteSelectate: [], numeUtilizator: null });
                            return;
                        } else {
                            this.setState({ butonActiv: false });
                            return;
                        }
                    }
                    else
                        return;

                    this.setState({ numeUtilizator: nume, butonActiv: true });
                })
                .catch((error) => {
                    this.setState({ butonActiv: false });
                    Utilitare.handleEroare(error, "Acasa");
                });
    }

    handleOnParolaChanged = (parola) => {
        this.setState({ parola: parola });
    }

    handleOnUatChanged = (siruta) => {
        this.setState({ siruta: siruta });
    }

    handleAutentificare = () => {
        if (this.state.numeUtilizator == null || this.state.numeUtilizator === "") {
            alert("Utilizatorul nu este inregistrat.");
            return;
        }
        //let utilizatoriFiltrati = this.state.listaUtilizatori.filter(x => x.numeUtilizator.toLowerCase() === this.state.numeUtilizator.toLowerCase());
        //if (utilizatoriFiltrati.length === 0) {
        //    alert("Utilizatorul nu este inregistrat.");
        //    document.getElementsByName("NumeUtilizator").value = null;
        //    document.getElementsByName("Parola").value = null;
        //    document.getElementsByName("SelectPrimarie").value = null;
        //    this.setState({ UatVizibil: false });
        //    this.setState({ siruteSelectate: [] });
        //    return;
        //}
        if (this.state.parola == null) {
            alert("Parola este obligatorie.");
            return;
        }
        if (this.state.siruta == null) {
            alert("Alegerea primariei la care sunteti inregistrat este obligatorie.");
            return;
        }

        Utilitare.loginToken('/api/Account/Login', {
            UserName: this.state.numeUtilizator,
            Password: this.state.parola,
            siruta: this.state.siruta.toString()
        });
        this.props.context.setState({ loggedIn: true });
    }

    componentDidMount() {
        //localStorage.clear();

        service.getListaUAT().then(r => {
            let listaUAT = r.data.primarii;
            //let listaUseri = r.data.utilizatori;

            this.setState({
                listaUAT: listaUAT,
                //  listaUtilizatori: listaUseri,
                amPrimarii: true
            })
            //console.log(this.state.listaUtilizatori);
        }).catch(e => {//in caz de eroare
            alert("S-a produs o eroare la conexiunea cu baza de date.");
            navigate(`./`);
        })
    }

    render() {
        //console.log("Acasa.state", this.state);
        console.log("Acasa.state", this.props);

        return this.state.amPrimarii
            ? <div className={classes.Pagina}>
                <h6 className={classes.Formular} style={{ marginTop: "10px", marginBottom: "10px" }}>Autentificare</h6>
                <img src={logo} alt="Acasă" width={300} />
                <hr />
                {this.props.externalToken ? <h3 style={{margin:"auto"}}> Se autentifică... </h3> :
                    <div>
                        <div className="container" style={{ width: "40%" }}>
                            <div className="row" style={{ marginBottom: "9px", textAlign: "right" }}>
                                <div className="col col-sm-3">
                                </div>
                                <div className="col col-sm-6">
                                    <TextBox
                                        name="NumeUtilizator"
                                        className="Randuri"
                                        width={"100%"}
                                        style={{ display: 'inline-block' }}
                                        onValueChange={(e) => { if (e !== this.state.numeUtilizator) this.handleOnNumeChanged(e); }}
                                        placeholder={"Nume utilizator"}
                                        value={this.state.numeUtilizator}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: "9px", textAlign: "right" }}>
                                <div className="col col-sm-3">
                                </div>
                                <div className="col col-sm-6">
                                    <TextBox
                                        name="Parola"
                                        mode="password"
                                        className="Randuri"
                                        width={"100%"}
                                        style={{ display: 'inline-block' }}
                                        placeholder={"Parola"}
                                        onValueChanged={(e) => { this.handleOnParolaChanged(e.value) }}
                                        value={this.state.parola}
                                        onEnterKey={this.handleAutentificare}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: "5px", textAlign: "right" }} >
                                <div className="col col-sm-3">
                                </div>
                                <div className="col col-sm-6">
                                    <SelectBox
                                        name="SelectPrimaria"
                                        className="Randuri"
                                        style={{ display: "inline-block" }}
                                        width={"100%"}
                                        dataSource={this.state.siruteSelectate ? this.state.listaUAT.filter(x => this.state.siruteSelectate.includes(x.siruta.toString())) : this.state.listaUAT}
                                        displayExpr={"numePrimarie"}
                                        valueExpr={"siruta"}
                                        onOpened={(e) => { e.component._popup.option('width', 300); }}
                                        onValueChanged={(e) => { this.handleOnUatChanged(e.value) }}
                                        visible={this.state.UatVizibil}
                                        //defaultValue={this.state.listaUAT ? this.state.listaUAT.filter(x => this.state.siruteSelectate.includes(x.siruta.toString()))[0] : null}
                                        value={this.state.siruta}
                                        placeholder={"Primaria"}
                                        onEnterKey={this.handleAutentificare}
                                    />
                                </div>
                            </div>
                        </div>
                        <Button
                            style={{ margin: "auto" }}
                            width={120}
                            text="Login"
                            type="success"
                            stylingMode="outlined"
                            onClick={this.handleAutentificare}
                        />
                    </div>
                }
            </div>
            : <div></div>
    }
}

export default Acasa;