import React, { /*Component,*/ Fragment } from 'react';
//import $ from 'jquery';
import axios from 'axios';
import { confirm, alert } from 'devextreme/ui/dialog';
import DataGrid, { Column, Pager, Paging, FilterRow, FilterPanel, Selection, ColumnChooser } from 'devextreme-react/data-grid';
import { Button, LoadIndicator } from 'devextreme-react';
import { Popup as Pop } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import Dosar from './Dosar/Dosar';
import Declaratii from './Declaratii/Declaratii';
import PopAdeverinteTip from './PopAdeverinteTip/PopAdeverinteTip';
import MeniuSus from './MeniuSus/MeniuSus';
import CautareAvansata from './CautareAvansata/CautareAvansata';
import service from "./Date";
import UpsertGospodarie from './Upsert/UpsertGospodarie';
//import { navigate } from "@reach/router";
import Utilitare from './../../../../content/Utilitare';
//import ContextMenu from 'devextreme/ui/context_menu';


export default class ComponentSus extends React.PureComponent {
    constructor(props) {
        super(props);
        this.gridRef = React.createRef();
    }

    get dataGrid() {
        return this.gridRef.current.instance;
    }

    state = {
        //dateCautare: null,
        utilizatorLogat: localStorage.getItem("userName"),
        filtreaza: false,
        popupVizibil: false,
        seAfiseaza: null,
        idGospCautat: null,
        numarGospodarii: null,
        numarGospodariiInchise: null,
        tipCautareAvansata: "membri",
        actiune: "adaugare",
        vizibil: false,
        gospodariaSelectata: null,
        //din request:
        amGospodarii: false,
        dateGospodarii: [],
        listaColoane: [],
        tipuriGospodarii: [],
        idGospodarieSelectataDefault: null,
        loadIndicatorVisible: false,
        popupDeclaratiiVisible: false,
        idGospDeclaratii: null
    };

    datagrid = '';

    componentDidMount() {
        this.setState({ loadIndicatorVisible: true });
        let siruta = localStorage.getItem("siruta") ? localStorage.getItem("siruta").replaceAll("\"", "") : null;
        let tip = this.props.tipSelectat;
        let an = this.props.anSelectat;
        service.getListaGospodarii(siruta, tip, an).then(r => {
            this.setState({
                dateGospodarii: r.gosp,
                listaColoane: r.cols,
                numarGospodarii: r.gosp.length,
                numarGospodariiInchise: r.gosp.filter(x => x.ani != null).length,
                tipuriGospodarii: r.tipuriGosps,
                idGospodarieSelectataDefault: r.gosp[0].idRegistrulAgricol,
                loadIndicatorVisible:false
            })

        }).catch(error => {//in caz de eroare
            //this.setState({ loadIndicatorVisible: true });
            alert("S-a produs o eroare. Vă rugăm să contactaţşi administratorul.");
            Utilitare.handleEroare(error, "ComponentSus");
        })
    }

    handleSelectieCautare = async (idGospGasita, tip) => {
        //aici se primeste idGosp-ul persoanei care a fost cautata in Cautare Membru
        //se inchide popupul si se seteaza selectia pentru tip din combobox
        this.setState({ loadIndicatorVisible: true });
        let tipCautat = 0;
        this.setState({ tipSelectat: tip });

        //aici incarca din nou grid-ul cu gospodariile necesare pentru tipul membrului selectat. 
        let siruta = localStorage.getItem("siruta").replaceAll("\"", "");
        let an = this.props.anSelectat;

        await service.getListaGospodarii(siruta, tip, an).then(r => {
            this.setState({
                dateGospodarii: r.gosp,
                listaColoane: r.cols,
                numarGospodarii: r.gosp.length,
                numarGospodariiInchise: r.gosp.filter(x => x.ani != null).length,
                tipuriGospodarii: r.tipuriGosps,
                idGospodarieSelectataDefault: r.gosp[0].idRegistrulAgricol,
                amGospodarii: true
            }//, () => { this.setState({ amGospodarii: true }) }
            )
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "ComponentSus");
        })

        if (this.state.dateGospodarii.filter(x => x.idRegistrulAgricol === idGospGasita).length > 0) {
            //tipCautat = this.state.dateGospodarii.filter(x => x.idRegistrulAgricol === idGospGasita)[0].tip;
            this.state.grid.navigateToRow(idGospGasita);
        } else {
            alert("Nu există nicio gospodărie care să corespundă criteriilor de căutare.");
            this.setState({
                popupVizibil: false,
                tipSelectat: tipCautat
            });
            return;
        }

        this.handleSelecteazaTip(tipCautat);

        //modifica optiunile coloanei (aici filtrul pe coloana respectiva)
        //pentru a selecta dataGridul astfel, am creat referinta catre el (vezi la definirea DataGrid-ului, constructorul si get dataGrid())
        this.dataGrid.columnOption("idRegistrulAgricol", "filterValue", idGospGasita); //(parametrii: coloana, optiunea, valoarea)

        this.setState({
            popupVizibil: false,
            loadIndicatorVisible: false
        });
    }

    handleFiltreazaUtilizator = (utilizator) => {
        this.setState({ filtreaza: utilizator });
    }

    handleFiltreazaTip = (tip) => {
        this.setState({ tipSelectat: (tip === 0) ? null : tip })
        this.setState({ idGospCautat: null });

        //filtrarea pe utilizator se face in 3 pasi. nu exista coalesce(fum, fuo, ''), ca in sql
        let filtruUtilizatorOperare = this.state.amGospodarii ?
            (this.state.filtreaza ?
                this.state.dateGospodarii.filter(x => (x.utilizator ? x.utilizator : "") === this.state.utilizatorLogat.toLowerCase() && !x.utilizatorModificare)
                : this.state.dateGospodarii)
            : null;
        let filtruUtilizatorModificare = this.state.amGospodarii ?
            (this.state.filtreaza ?
                this.state.dateGospodarii.filter(x => (x.utilizatorModificare ? x.utilizatorModificare : "") === this.state.utilizatorLogat.toLowerCase())
                : this.state.dateGospodarii)
            : null;
        let filtruUtilizator = this.state.filtreaza ? (filtruUtilizatorOperare ? filtruUtilizatorOperare.concat(filtruUtilizatorModificare) : (filtruUtilizatorModificare ? filtruUtilizatorModificare : null)) : this.state.dateGospodarii;

        this.props.getGosp(filtruUtilizator[0]);
        this.props.selecteazaIndex(0);
    }

    handleAfiseaza = (ceSeAfiseaza) => {
        if (ceSeAfiseaza === "declaratii") {
            this.setState({ seAfiseaza: ceSeAfiseaza, popupDeclaratiiVizibil: true });
            return;
        }

        this.setState({ seAfiseaza: ceSeAfiseaza, popupVizibil: true/*, dateCautare: (ceSeAfiseaza === "Cautare" ? service.getMembri() : null)*/ })
    }

    handleAscundePopup = () => {
        this.setState({ popupVizibil: false })
    }

    handleOnRowClick = (e) => {
        this.props.getGosp(e.data);
        this.setState({ gospodariaSelectata: e.data });
    }

    handleCautareAvansata = (obiectCautare) => {
        /*in functie de ce primeste de la popup, ii intorci un set de date pt grid*/
        switch (obiectCautare) {
            case "membri":
                break;
            case "parcele":
                break;
            default:
                break;
        }

        this.setState({ tipCautareAvansata: obiectCautare });
    }

    handleAdaugare = () => {
        alert("Adaugare gospodarie");
    }

    handleUpsert = (actiune) => {
        let vizVechi = this.state.vizibil;
        if (this.props.tipSelectat !== 0)
            this.setState({ vizibil: !vizVechi, actiune: actiune });
        else
            alert("Nu se pot efectua operaţii pe tipul selectat!");
        if (this.state.vizibil === false) {
            this.reincarcaGrid();
        }
    }

    handleDelete = async (date) => {
        let result = confirm("<i>Sigur doriţi să " + (date.ani ? "deschideţi" : "închideţi") + " această gospodărie?</i>", (date.ani ? "Deschidere" : "Închidere") + " gospodărie");
        await result.then((dialogResult) => {
            if (dialogResult) {
                let idGospodarieSelectata = parseInt(date.idRegistrulAgricol);
                axios({
                    data: idGospodarieSelectata,
                    headers: { "Content-Type": "application/json" },
                    method: "put",
                    url: './api/Gospodarii/InchidereGospodarie',
                })
                    .then((response) => {
                        let gosps = [...this.state.dateGospodarii];
                        gosps.find(x => x.idRegistrulAgricol === date.idRegistrulAgricol).ani = (date.ani ? null : new Date().getFullYear());
                        this.setState({ dateGospodarii: gosps });
                        alert("A fost " + (date.ani ? "închisă" : "deschisă") + " gospodaria: " + idGospodarieSelectata.toString());
                    })
                    .catch((error) => {
                        Utilitare.handleEroare(error, "MeniuAplicatie");
                    });
            }
        });
    }

    reincarcaGrid = () => {
        let siruta = localStorage.getItem("siruta").replaceAll("\"", "");
        let tip = this.props.tipSelectat;
        let an = this.props.anSelectat;

        service.getListaGospodarii(siruta, tip, an).then(r => {
            this.setState({
                dateGospodarii: r.gosp,
                listaColoane: r.cols,
                numarGospodarii: r.gosp.length,
                numarGospodariiInchise: r.gosp.filter(x => x.ani != null).length,
                tipuriGospodarii: r.tipuriGosps,
                idGospodarieSelectataDefault: r.gosp && r.gosp.length > 0 ? r.gosp[0].idRegistrulAgricol : null
            }, () => {
                this.setState({ amGospodarii: true })
            })
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "ComponentSus");
        })
    }

    getPozMax = (vol) => {
        let poz = this.state.dateGospodarii.filter(x => x.volum === vol).map(y => y.pozitie);
        return poz.length > 0 ? Math.max(...poz) : 1;
    }

    handleSelecteazaTip = (e) => {
        if (e !== this.props.tipSelectat) {
            this.props.selecteazaTip(e);
            this.reincarcaGrid();
        }
    }

    verificaAccesIstoric = () => {
        var ani = JSON.parse(localStorage.getItem("listaAni"));

        var anSelectat = parseInt(localStorage.getItem("anSelectat"));

        var accesIstoric = localStorage.getItem("accesIstoric");
        var siruta = localStorage.getItem("siruta");

        if (anSelectat < Math.max(...ani) && !accesIstoric.includes(siruta))
            return false;

        return true;
    }

    handleAscundePopupDeclaratii = () => {
        this.setState({ idGospDeclaratii: null, popupDeclaratiiVizibil: false });
    }

    handleAfiseazaDeclaratii = (idPrim) => {
        this.setState({ idGospDeclaratii: idPrim }, () => { this.handleAfiseaza("declaratii") })
    }

    render() {
        //console.log("comp sus state", this.state);
        //console.log("comp sus props", this.props);

        let filtruUtilizatorOperare = this.state.amGospodarii ?
            (this.state.filtreaza ?
                this.state.dateGospodarii.filter(x => (x.utilizator ? x.utilizator : "") === this.state.utilizatorLogat.toLowerCase() && !x.utilizatorModificare)
                : this.state.dateGospodarii)
            : null;
        let filtruUtilizatorModificare = this.state.amGospodarii ?
            (this.state.filtreaza ?
                this.state.dateGospodarii.filter(x => (x.utilizatorModificare ? x.utilizatorModificare : "") === this.state.utilizatorLogat.toLowerCase())
                : this.state.dateGospodarii)
            : null;
        let filtruUtilizator = this.state.filtreaza ? (filtruUtilizatorOperare ? filtruUtilizatorOperare.concat(filtruUtilizatorModificare) : (filtruUtilizatorModificare ? filtruUtilizatorModificare : null)) : this.state.dateGospodarii;

        let deAfisat = null;
        switch (this.state.seAfiseaza) {
            case "Dosar":
                deAfisat = <Dosar
                    gospSelectata={this.state.gospodariaSelectata}
                    ascunde={this.handleAscundePopup}
                />;
                break;
            case "Adeverinte":
                deAfisat = <PopAdeverinteTip
                    gospSelectata={this.state.gospodariaSelectata}
                    ascunde={this.handleAscundePopup} />;
                break;
            case "Cautare":
                deAfisat = <CautareAvansata
                    cautareAvansata={this.handleCautareAvansata}
                    ascunde={this.handleAscundePopup}
                    cautare={this.handleSelectieCautare}
                    anSelectat={this.props.anSelectat} />;
                break;
            case "declaratii":
                deAfisat = <Declaratii
                    idGosp={this.state.idGospDeclaratii}
                    ascunde={this.handleAscundePopupDeclaratii}
                />;
                break;
            default:
                deAfisat = null;
        }

        let popupDosar = this.state.popupVizibil ?
            <Pop
                visible={this.state.popupVizibil}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showTitle={true}
                title={this.state.seAfiseaza !== "Cautare"
                    ? this.state.seAfiseaza
                    : "Cautare " + this.state.tipCautareAvansata}
                onHidden={this.handleAscundePopup}
            >
                <ScrollView id="scrollview">
                    <div className="text-content">
                        {deAfisat}
                    </div>
                </ScrollView>
            </Pop>
            : null;

        let popupDeclaratii = this.state.popupDeclaratiiVizibil ?
            <Pop
                width={550}
                visible={this.state.popupDeclaratiiVizibil}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showTitle={true}
                title={"Declaraţii"}
                onHidden={this.handleAscundePopupDeclaratii}
            >
                <ScrollView id="scrollview">
                    <div className="text-content">
                        {deAfisat}
                    </div>
                </ScrollView>
            </Pop>
            : null;

        const headerTemplate = (columnHeader) => {
            return <p style={{ width: "100%", textAlign: "center" }}>{columnHeader.column.caption}</p>;
        };

        const upsertTemplate = () => {
            return <div id="btn-custom">
                <Button
                    //width={60}
                    style={{ marginRight: "3px" }}
                    hint="Adaugare"
                    icon="add"
                    type="normal"
                    stylingMode="outlined"
                    onClick={() => this.handleUpsert("adaugare")}
                />
            </div>
        };

        const dataGrid = this.state.amGospodarii ? <DataGrid
            id="gospodarii"
            style={{ margin: "0 5px 0 5px" }}
            height={window.innerHeight - 110}
            dataSource={filtruUtilizator}
            columnAutoWidth={true}
            allowColumnReordering={true}
            showBorders={true}
            keyExpr={"idRegistrulAgricol"}
            rowAlternationEnabled={false}
            remoteOperations={false}
            defaultSelectedRowKeys={[this.state.dateGospodarii.length > 0 ? this.state.dateGospodarii[0].idRegistrulAgricol : null]}
            focusedRowKey={this.state.dateGospodarii.length > 0 ? this.state.dateGospodarii[0].idRegistrulAgricol : null}
            autoNavigateToFocusedRow={true}
            selectedRowKeys={[this.state.gospodariaSelectata ? this.state.gospodariaSelectata.idRegistrulAgricol : 0]}
            onRowClick={(e) => { this.handleOnRowClick(e); }}
            onEditingStart={(e) => { this.handleOnEditingStart(e.data); this.setState({ idGospCautat: null }); }}
            onRowPrepared={(e) => {
                if (e.rowType === 'data') {
                    if (e.data.ani) {
                        e.rowElement.style.color = 'red';
                    }
                }
            }}
            onInitialized={(e => { this.setState({ grid: e.component }) })}
            onRowDblClick={this.props.onDblClick}
            onContextMenuPreparing={(e) => {
                if (e && e.row && e.row.data)
                    if ((this.verificaAccesIstoric() === true && (localStorage.getItem("role") !== "UtilizatorExtern") || localStorage.getItem("role") === "Administrator"))
                        this.setState({ gospodariaSelectata: e.row.data }, () => {
                            this.props.getGosp(e.row.data);

                            if (e.row.rowType === "data") {
                                e.items = [{
                                    text: "Adaugă",
                                    icon: 'add',
                                    onItemClick: () => this.handleUpsert("adaugare")
                                },
                                {
                                    text: "Modifică",
                                    icon: "edit",
                                    onItemClick: () => this.handleUpsert("modificare")
                                    //e.component.addRow();
                                },
                                {
                                    text: "Închide/Deschide",
                                    icon: "trash",
                                    onItemClick: () => this.handleDelete(e.row.data)
                                },
                                {
                                    text: "Capitole",
                                    icon: "chart",
                                    onItemClick: this.props.onDblClick
                                },
                                {
                                    text: "Declaraţii",
                                    icon: "copy",
                                    onItemClick: () => this.handleAfiseazaDeclaratii(e.row.data.idRegistrulAgricol)
                                }];
                            }
                        })
                    else
                        this.setState({ gospodariaSelectata: e.row.data }, () => {
                            this.props.getGosp(e.row.data);

                            if (e.row.rowType === "data") {
                                e.items = [{
                                    text: "Capitole",
                                    icon: "chart",
                                    onItemClick: this.props.onDblClick
                                }];
                            }
                        })
                else
                    if (this.verificaAccesIstoric() === true && localStorage.getItem("role") !== "UtilizatorExtern")
                        e.items = [{
                            text: "Adaugă",
                            icon: 'add',
                            onItemClick: () => this.handleUpsert("adaugare")
                        }];
            }}
            ref={this.gridRef}
        >
            <Selection mode="single" deferred={false} />
            <ColumnChooser enabled={true} mode={"select"} title={null} />

            {/*<Scrolling mode="virtual" />*/}

            <FilterRow
                showOperationChooser={true}
                visible={true}
                applyFilter={true}
            />
            <FilterPanel visible={true} />

            {this.state.listaColoane.map((col) =>
                <Column
                    key={col.idColoaneGridView}
                    dataField={col.denumireCamp}
                    caption={col.denumire}
                    headerCellRender={headerTemplate}
                    width={col.lungime + '%'}
                    visible={col.vizibil}
                />
            )}

            { /*pentru a reveni la varianta clasica, cu butoane, scrie false in appsettings.json la MyConfig, proprietatea checkClickDreapta */}
            {(!localStorage.getItem("checkClickDreapta").replaceAll(" ", "").split(",").includes(localStorage.getItem("siruta").toString()))
                || localStorage.getItem("role") === "UtilizatorExtern"
                || (this.verificaAccesIstoric() === false)
                ? localStorage.getItem("role") === "UtilizatorExtern"
                    ? <Column
                        //coloana de butoane customizabile
                        type="buttons"
                        width={110}
                        buttons={[
                            {
                                hint: 'Capitole',
                                icon: 'chart',
                                visible: true,
                                onClick: this.props.onDblClick
                            }
                        ]}
                        headerCellRender={upsertTemplate}
                    />
                    : null
                : <Column
                    //coloana de butoane customizabile
                    type="buttons"
                    width={110}
                    buttons={[
                        {
                            hint: 'Capitole',
                            icon: 'chart',
                            visible: true,
                            onClick: this.props.onDblClick
                        },
                        {
                            hint: 'Modificare',
                            icon: 'edit',
                            visible: true,
                            onClick: () => this.handleUpsert("modificare")
                        },
                        {
                            hint: 'Ştergere',
                            icon: 'trash',
                            visible: true,
                            onClick: (e) => this.handleDelete(e.row.data)
                        },
                        {
                            hint: 'Declaraţii',
                            icon: 'copy',
                            visible: true,
                            onClick: (e) => this.handleAfiseazaDeclaratii(e.row.data.idRegistrulAgricol)
                        }
                    ]}
                    headerCellRender={upsertTemplate}
                />}

            <Paging defaultPageSize={25} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 15, 25, 50]}
                showInfo={true}
            />
        </DataGrid>
            : null;

        return (
            <Fragment>
                {this.state.loadIndicatorVisible ?
                    <div style={{ position: "absolute", top: "0px", bottom: "0px", left: "0px", right: "0px", zIndex: "1502", backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", border: "1px solid black", backgroundColor: "white" }}>
                            <p>Se încarcă...</p>
                            <LoadIndicator
                                className="button-indicator"
                                visible={this.state.loadIndicatorVisible}
                            />
                        </div>
                    </div>
                    : null}

                {!this.state.vizibil ? <MeniuSus
                    selecteazaAn={(e) => { this.props.selecteazaAn(e); this.reincarcaGrid(); }}
                    selecteazaTip={(e) => { this.handleSelecteazaTip(e) }}
                    filtrareUtilizator={this.handleFiltreazaUtilizator}
                    filtrareTip={this.handleFiltreazaTip}
                    afisare={this.handleAfiseaza}
                    gospodariaSelectata={this.state.gospodariaSelectata}
                    numarGospodarii={this.state.numarGospodarii}
                    numarGospodariiInchise={this.state.numarGospodariiInchise}
                    tipuriGospodarii={this.state.tipuriGospodarii}
                    handleDelogare={this.props.handleDelogare}
                    tipSelectat={this.props.tipSelectat}
                    anSelectat={this.props.anSelectat}
                /> : null}

                {this.state.vizibil ? <UpsertGospodarie
                    actiune={this.state.actiune}
                    tipSelectat={this.props.tipSelectat}
                    idGospodariaSelectata={this.state.gospodariaSelectata ? this.state.gospodariaSelectata.idRegistrulAgricol : (this.state.idGospodarieSelectataDefault ? this.state.idGospodarieSelectataDefault : null)}
                    categorieGospodariaSelectata={this.state.gospodariaSelectata ? this.state.gospodariaSelectata.categorieGospodarie : (this.props.tipSelectat === 1 || this.props.tipSelectat === 2 ? 'G' : 'SC')}
                    ascundeUpsert={() => this.handleUpsert("adaugare")}
                    anSelectat={this.props.anSelectat}
                    getPozMax={this.getPozMax}
                    handleSelectieCautare={this.handleSelectieCautare}
                /> : dataGrid}

                {popupDosar}
                {popupDeclaratii}
            </Fragment>
        )
    }
}