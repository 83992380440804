/**************************/
/*  MENIUL CAPITOLULUI    */
/**************************/
import React, { Component, Fragment } from 'react';
import { alert } from "devextreme/ui/dialog";
import { Button, DropDownButton, CheckBox, SelectBox } from 'devextreme-react';
import date from '../DateCapitole'; //pentru butonul Export

export default class MeniuCap11 extends Component {
    state = {
        listaDeclaratii: [],
        check2a: localStorage.getItem("check2a") && localStorage.getItem("check2a") /* lasa cu 2 = */ == "true" ? true : false
    }

    componentDidMount() {
        let listaDecl = this.props.listaDeclaratii.map(x => {
            let obj = { idDeclaratie: x.idDeclaratii, nrData: (x.nrDeclaratie + "/" + (new Date(x.dataDeclaratie)).toLocaleDateString("ro")) };
            return obj;
        });

        localStorage.removeItem("declaratieSelectata");

        this.setState({ listaDeclaratii: listaDecl });
    }

    handleExport = (tip) => {
        alert("Se exporta fisier de tip: " + tip);
    }

    handleAdauga = (cap) => {
        alert("Adauga in cap. " + cap);
    }

    handleModifica = (cap) => {
        alert("Modifica in cap. " + cap);
    }

    verificaAccesIstoric = () => {
        var ani = JSON.parse(localStorage.getItem("listaAni"));

        var anSelectat = parseInt(localStorage.getItem("anSelectat"));

        var accesIstoric = localStorage.getItem("accesIstoric");
        var siruta = localStorage.getItem("siruta");

        if (anSelectat < Math.max(...ani) && !accesIstoric.includes(siruta))
            return false;

        return true;
    }

    render() {
        const { cap } = this.props;
        let butoaneVizibile = (["11", "12", "13", "14", "15a", "15b", "16"].indexOf(cap) >= 0) ? true : false; //capitole cu nr. de randuri variabil

        return (
            <Fragment>
                <p style={{ display: "inline-block", position: "relative", top: "1px" }}>Se modifică conform declaraţiei</p>
                <SelectBox
                    style={{ display: "inline-block", marginLeft: "5px", position: "relative", top: "1px" }}
                    height={26}
                    dataSource={this.state.listaDeclaratii}
                    displayExpr="nrData"
                    valueExpr="idDeclaratie"
                    showClearButton={true}
                    onValueChanged={(e) => { localStorage.setItem("declaratieSelectata", e.value) }}
                />

                <div style={{ padding: "0 15px 0 0", display: "inline-block", marginLeft: "5px" }}>
                    <CheckBox
                        style={{ display: "inline-block" }}
                        defaultValue={this.state.check2a}
                        onValueChanged={(e) => {
                            this.setState({ check2a: e.value });
                            localStorage.setItem("check2a", e.value.toString());
                        }}
                        visible={localStorage.getItem("role") === 'UtilizatorExtern' || this.verificaAccesIstoric() === false
                            ? localStorage.getItem("role") === "Administrator" ? true : false
                            : true}
                    />
                    <p style={{ display: "inline-block" }}>Totalizează în 3</p>
                </div>

                <DropDownButton
                    text="Export"
                    icon="save"
                    dropDownOptions={{ width: 230 }}
                    items={date.getExport()}//aici e date din ../date (adica din Capitole)
                    onItemClick={(e) => { this.handleExport(e.itemData) }}
                    visible={false}
                />
                <Button
                    style={{ display: "inline-block", marginLeft: "5px" }}
                    width={120}
                    text="Şterge capitol"
                    type="danger"
                    stylingMode="outlined"
                    onClick={this.props.stergeCapitol}
                    visible={localStorage.getItem("role") === 'UtilizatorExtern' || this.verificaAccesIstoric() === false
                        ? localStorage.getItem("role") === "Administrator" ? true : false
                        : true}
                />
                <Button
                    visible={butoaneVizibile}
                    style={{ marginLeft: "5px" }}
                    text="Adauga"
                    onClick={() => this.handleAdauga(cap)}
                />
                <Button
                    visible={butoaneVizibile}
                    style={{ marginLeft: "5px" }}
                    text="Modifica"
                    onClick={() => this.handleModifica(cap)}
                />
                <Button
                    style={{ marginLeft: "5px" }}
                    text="Inapoi"
                    onClick={this.props.inapoi}
                />
            </Fragment>
        );
    }
}