import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import { Button, SelectBox, TextBox, ScrollView } from 'devextreme-react';
import DataGrid, { Paging, FilterRow, FilterPanel, Selection, Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import clase from './DefalcareGospodarie.module.css';
import service from './DateDefalcare';
import Utilitare from '../../../../content/Utilitare';
import { navigate } from '@reach/router';

export default class DefalcareGospodarie extends Component {
    state = {
        tipTransfer: 1,
        dataTransfer: new Date(Date.now()),
        tipSelectat: 0,
        capGosp: null,
        membruSelectat: null, //obiect: membrul care va fi trecut ca proprietar
        tipReprezentant: "Reprezentant din familie",
        gospodarieDeTrimis: {
            gospodarie: {
                idRegistrulAgricol: 0,
                idAnterior: null,
                refDatePrimarie: null,
                tip: 1,
                volum: null,
                pozitie: null,
                pagina: null,
                denumireVolum: null,
                numarDosar: 0,
                refCategoriiPF_PJ: 1,
                refProprietate: null,
                refAdresa: null,
                refAdresaReprezentantLegal: null,
                refAdresaPJ: null,
                numeReprezentantLegal: null,
                initialaReprezentantLegal: null,
                prenumeReprezentantLegal: null,
                cnp: null,
                denumireUnitate: null,
                denumireSubunitate: null,
                codAPIA: null,
                codUnicIdentificare: null,
                codUnicGospodarieExploatatie: null,
                refUtilizator: null,
                refUtilizatorModificare: null,
                dataOperare: null,
                dataModificare: null,
                stare: 1,
                an: parseInt(localStorage.getItem("anSelectat")),
                ani: null,
                transferat: false
            },
            adresa: {
                refTara: 180,
                refTipArtera: 38
            },
            adresaReprezentantLegal: null,
            adresaPJ: null,
            denumireLocalitateST: null,
            denumireLocalitatePJ: null
        }
    }

    stil = {
        width: '98%',
        height: 'calc(100vh - 57px)',
        margin: 'auto',
        border: '1px solid #ddd',
        padding: '5px',
        overflow: "hidden"
    };

    componentDidMount() {
        let siruta = localStorage.getItem("siruta") ? localStorage.getItem("siruta").replaceAll("\"", "") : null;
        let tip = 0; //this.props.tipSelectat;
        let an = this.state.gospodarieDeTrimis.gospodarie.an;

        service.getListaGospodarii(siruta, tip, an).then(r => {
            var gdt = { ...this.state.gospodarieDeTrimis };
            gdt.gospodarie.volum = Math.max(...r.gosp.map(x => { return x.volum }));
            gdt.gospodarie.pozitie = Math.max(...r.gosp.filter(y => y.volum === Math.max(...r.gosp.map(x => { return x.volum }))).map(x => { return x.pozitie })) + 1;
            gdt.adresa.refLocalitateComponenta = r.localitatiComponente[0].idNomenclatorUAT;

            this.setState({
                dateGospodarii: r.gosp,
                numarGospodarii: r.gosp.length,
                listaColoane: r.coloane,
                tipuriGospodarii: r.tipuriGosps.filter(x => [0, 1, 2].includes(x.tip)),
                localitatiComponente: r.localitatiComponente,
                categoriiGospodarii: r.categoriiGospodarii ? r.categoriiGospodarii.filter(x => x.tip === "PF") : null,
                sirutaSelectata: r.localitatiComponente[0].siruta,

            })

        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "DefalcareGospodarie");
        })
    }

    handleAfisare = () => {
        if (!this.state.tipTransfer) {
            alert("Nu ati selectat tipul transferului.");
            return;
        }
        if (!this.state.dataTransfer) {
            alert("Nu ati selectat data.");
            return;
        }
        if (!this.state.membruSelectat) {
            alert("Nu ati selectat noul proprietar.");
            return;
        }

        alert("Defalcare");
    }

    handleOnRowClick = (rand) => {
        this.setState({ linieSelectata: rand });

        service.getDateCapitol(rand.idRegistrulAgricol).then(d => {
            this.setState({
                membri: d.capitole.randuri
            })
        }).catch(error => {
            Utilitare.handleEroare(error, "DefalcareGospodarie");
        })
    }

    handleClickMembru = (membru) => {
        if (membru.codRudenie === 1) {
            alert("Nu puteţi alege reprezentantul gospodăriei.");
            return;
        }

        //nume
        var nume = Utilitare.getNume(membru.numeleSiPrenumele);
        var gdt = { ...this.state.gospodarieDeTrimis };
        gdt.gospodarie.numeReprezentantLegal = nume;

        //initiala
        var initiala = Utilitare.getInitiala(membru.numeleSiPrenumele);
        gdt.gospodarie.initialaReprezentantLegal = initiala;

        //prenume
        var prenume = Utilitare.getPrenume(membru.numeleSiPrenumele);
        gdt.gospodarie.prenumeReprezentantLegal = prenume;

        gdt.gospodarie.cnp = membru ? membru.codNumericPersonal.toString() : null;

        this.setState({ membruSelectat: membru, gospodarieDeTrimis: gdt });
    }

    handleVolum = (vol) => {
        var gdt = { ...this.state.gospodarieDeTrimis };
        var poz = this.state.dateGospodarii ? Math.max(...this.state.dateGospodarii.filter(y => y.volum.toString() === vol.toString()).map(x => { return x.pozitie })) + 1 : 0;

        if (poz < 0 || poz > 500000)
            poz = 0;
        gdt.gospodarie.volum = vol;
        gdt.gospodarie.pozitie = poz;
        this.setState({ gospodarieDeTrimis: gdt });
    }

    handleSelectieTip = (item) => {
        this.setState({ tipSelectat: item.tip });
    }

    handleValoriNoiGosp = (valoare, camp) => {
        var gdt = { ...this.state.gospodarieDeTrimis };
        if (!gdt.gospodarie || gdt.gospodarie === undefined || gdt.gospodarie === null) {
            alert("Nu aţi selectat nicio gospodărie.");
            return;
        }
        gdt.gospodarie[camp] = valoare;
        this.setState({ gospodarieDeTrimis: gdt });
    }

    handleValoriNoiAdresa = (valoare, camp) => {
        var gdt = { ...this.state.gospodarieDeTrimis };
        if (!gdt.gospodarie || gdt.gospodarie === undefined || gdt.gospodarie === null) {
            alert("Nu ati selectat nicio gospodarie");
            return;
        }
        if (camp === "refLocalitateComponenta") {
            var siruta = this.state.localitatiComponente ? this.state.localitatiComponente.find(x => x.idNomenclatorUAT === valoare).siruta : null;
            gdt.adresa[camp] = valoare;
            this.setState({ localitateSelectata: valoare, sirutaSelectata: siruta, gospodarieDeTrimis: gdt });
            return;
        }
            
        gdt.adresa[camp] = valoare;
        this.setState({ gospodarieDeTrimis: gdt });
    }

    handleSalveaza = () => {

        //validare:
        if (this.state.gospodarieDeTrimis.gospodarie.numeReprezentantLegal === null ||
            this.state.gospodarieDeTrimis.gospodarie.numeReprezentantLegal === undefined ||
            this.state.gospodarieDeTrimis.gospodarie.numeReprezentantLegal === "") {
            alert("Nu aţi completat numele Reprezentantului Legal.");
            return;
        }
        if (this.state.gospodarieDeTrimis.gospodarie.prenumeReprezentantLegal === null ||
            this.state.gospodarieDeTrimis.gospodarie.prenumeReprezentantLegal === undefined ||
            this.state.gospodarieDeTrimis.gospodarie.prenumeReprezentantLegal === "") {
            alert("Nu aţi completat prenumele Reprezentantului Legal.");
            return;
        }
        if (this.state.gospodarieDeTrimis.gospodarie.cnp === null ||
            this.state.gospodarieDeTrimis.gospodarie.cnp === undefined ||
            !Utilitare.validCNP(this.state.gospodarieDeTrimis.gospodarie.cnp)) {
            alert("CNP-ul Reprezentantului Legal este nul sau invalid.");
            return;
        }
        if (this.state.gospodarieDeTrimis.gospodarie.volum === null ||
            this.state.gospodarieDeTrimis.gospodarie.volum === undefined) {
            alert("Nu aţi completat volumul.");
            return;
        }
        if (this.state.gospodarieDeTrimis.gospodarie.pozitie === null ||
            this.state.gospodarieDeTrimis.gospodarie.pozitie === undefined) {
            alert("Nu aţi completat pozitia.");
            return;
        }
        if (this.state.gospodarieDeTrimis.adresa.refLocalitateComponenta === null ||
            this.state.gospodarieDeTrimis.adresa.refLocalitateComponenta === undefined) {
            alert("Nu aţi selectat localitatea în care se află gospodaria/firma.");
            return;
        }

        if (this.state.gospodarieDeTrimis.gospodarie.tip === 1) { //aici verifica tipul de gospodarie si nulleaza tot ce nu trebuie trimis la tip=1
            let ob = { ...this.state.gospodarieDeTrimis };
            ob.adresaPJ = null;
            ob.adresaReprezentantLegal = null;
            ob.denumireLocalitatePJ = null;
            ob.denumireLocalitateST = null;
            ob.denumireSubunitate = null;
            ob.denumireUnitate = null;
            ob.codUnicIdentificare = null;
            this.setState({ gospodarieDeTrimis: ob });
        }
        if (this.state.gospodarieDeTrimis.gospodarie.tip === 2) { //aici verifica tipul de gospodarie si nulleaza tot ce nu trebuie trimis la tip=2
            let ob = { ...this.state.gospodarieDeTrimis };
            ob.adresaPJ = null;
            ob.denumireLocalitatePJ = null;
            ob.denumireSubunitate = null;
            ob.denumireUnitate = null;
            ob.codUnicIdentificare = null;
            this.setState({ gospodarieDeTrimis: ob });
        }

        axios({
            data: this.state.gospodarieDeTrimis,
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/Gospodarii/PostUpsert',
        }).then((response) => {
            // request mutare membru
            if (!response.data.idRegistrulAgricol) {
                alert("Gospodăria nu a fost creată.");
                return;
            }

            axios({
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: './api/Gospodarii/MutaMembru/' + this.state.membruSelectat.idUnic + "/" + localStorage.getItem("siruta") + "/" + this.state.linieSelectata.idRegistrulAgricol + "/" + response.data.idRegistrulAgricol + "/" + true + "/" + true,
            }).then((response) => {
                alert("A fost adăugată gospodăria " + this.state.membruSelectat.numeleSiPrenumele);
                navigate("./date-gospodarii")
            }).catch((error) => {
                Utilitare.handleEroare(error, "DefalcareGospodarie");
            });
            // end of request mutare membru
        }).catch((error) => {
            Utilitare.handleEroare(error, "DefalcareGospodarie");
        });
    }

    render() {
        //console.log("defalcare props", this.props);
        //console.log("defalcare state", this.state);

        let gospodarii = this.state.dateGospodarii ? this.state.dateGospodarii.filter(x => x.tip === 1 || x.tip === 2) : null;
        if (this.state.tipSelectat > 0) {
            gospodarii = this.state.dateGospodarii ? this.state.dateGospodarii.filter(x => x.tip === this.state.tipSelectat) : null;
        }

        const headerTemplate = (columnHeader) => {
            return <p style={{ width: "100%", textAlign: "center" }}>{columnHeader.column.caption}</p>;
        }

        return (
            <Fragment>
                <ScrollView scrollByContent={true} style={this.stil}>

                    <div style={{ width: "30%", display: "inline-block" }}>
                        <h3 style={{ textAlign: "center" }}>Defalcare gospodărie</h3>
                        <table width={"100%"}>
                            <tbody>
                                <tr>
                                    <td>
                                        <TextBox
                                            id="rol"
                                            width={"100%"}
                                            className={clase.Margini}
                                            placeholder="ROL..."
                                            value={this.state.linieSelectata ? this.state.linieSelectata.refProprietate : null}
                                        />
                                    </td>
                                    <td>
                                        <TextBox
                                            id="reprezentant"
                                            width={"100%"}
                                            className={clase.Margini}
                                            placeholder="Reprezentant legal..."
                                            value={this.state.linieSelectata
                                                ? this.state.linieSelectata.numeReprezentantLegal + " " + (this.state.linieSelectata.initialaReprezentantLegal ? (this.state.linieSelectata.initialaReprezentantLegal + " ") : "") + this.state.linieSelectata.prenumeReprezentantLegal
                                                : null}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 style={{ textAlign: "center" }}>Gospodărie nouă</h3>
                        <table width={"100%"}>
                            <tbody>
                                <tr>
                                    <td>
                                        <TextBox
                                            id="reprezentantNou"
                                            width={"100%"}
                                            className={clase.Margini}
                                            placeholder="Noul reprezentant legal..."
                                            value={this.state.membruSelectat ? this.state.membruSelectat.numeleSiPrenumele : null}
                                        />
                                        <TextBox
                                            id="cnp"
                                            width={"100%"}
                                            className={clase.Margini}
                                            placeholder="cnp..."
                                            value={this.state.membruSelectat ? this.state.membruSelectat.codNumericPersonal.toString() : null}
                                            maxLength={13}
                                        />
                                        <TextBox
                                            width={"100%"}
                                            id="nrDosar"
                                            className={clase.Margini}
                                            placeholder="Nr. dosar..."
                                            onValueChange={(e) => { this.handleValoriNoiGosp(e, "numarDosar") }}
                                        />
                                    </td>
                                    <td>
                                        <TextBox
                                            id="cui"
                                            width={"100%"}
                                            className={clase.Margini}
                                            placeholder="Cod unic de identificare..."
                                            onValueChange={(e) => { this.handleValoriNoiGosp(e, "codUnicIdentificare") }}
                                        />
                                        <TextBox
                                            id="ce"
                                            width={"100%"}
                                            className={clase.Margini}
                                            placeholder="Cod exploataţie..."
                                            onValueChange={(e) => { this.handleValoriNoiGosp(e, "codUnicGospodarieExploatatie") }}
                                        />
                                        <TextBox
                                            id="nrUI"
                                            width={"100%"}
                                            className={clase.Margini}
                                            placeholder="Nr. unic de identificare..."
                                            onValueChange={(e) => { this.handleValoriNoiGosp(e, "codUnicIdentificare") }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table width={"100%"}>
                            <tbody>
                                <tr>
                                    <td>
                                        <SelectBox
                                            style={{ position: "relative", top: "-4px" }}
                                            width={"100%"}
                                            //items={["G", "EAI", "PFA", "II", "IF"]}
                                            dataSource={this.state.categoriiGospodarii ? this.state.categoriiGospodarii : null}
                                            valueExpr={"idCategoriiPF_PJ"}
                                            displayExpr={"denumire"}
                                            defaultValue={1}
                                            placeholder="Tip UE..."
                                            onOpened={(e) => { e.component._popup.option('width', 300); }}
                                            onValueChange={(e) => { this.handleValoriNoiGosp(e, "refCategoriiPF_PJ") }}
                                        />
                                    </td>
                                    <td>
                                        <SelectBox
                                            style={{ position: "relative", top: "-4px" }}
                                            width={"100%"}
                                            //items={[1, 2]}
                                            dataSource={this.state.tipuriGospodarii ? this.state.tipuriGospodarii : null}
                                            valueExpr={"tip"}
                                            displayExpr={"nume"}
                                            defaultValue={1}
                                            placeholder="Tip..."
                                            onOpened={(e) => { e.component._popup.option('width', 300); }}
                                            onValueChange={(e) => { this.handleValoriNoiGosp(e, "tip") }}
                                        />
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="volum"
                                            className={clase.Margini}
                                            placeholder="Volum..."
                                            value={this.state.gospodarieDeTrimis.gospodarie.volum ? this.state.gospodarieDeTrimis.gospodarie.volum.toString() : null}
                                            onValueChange={(e) => this.handleVolum(e)}
                                        />
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="pozitie"
                                            className={clase.Margini}
                                            placeholder="Poziţie..."
                                            value={this.state.gospodarieDeTrimis.gospodarie.pozitie ? this.state.gospodarieDeTrimis.gospodarie.pozitie.toString() : null}
                                            onValueChange={(e) => this.handleValoriNoiGosp(e, "pozitie")}
                                        />
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="pagina"
                                            className={clase.Margini}
                                            placeholder="Pagina..."
                                            onValueChange={(e) => this.handleValoriNoiGosp(e, "pagina")}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 style={{ width: "100%", textAlign: "center" }}> Adresa</h3>
                        <table width={"100%"} >
                            <tbody>
                                <tr>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="judet"
                                            className={clase.Margini}
                                            value={this.state.localitatiComponente ? this.state.localitatiComponente[0].denumireJudet : null}
                                            placeholder="Judetul..."
                                            disabled={true}
                                        />
                                    </td>
                                    <td>
                                        <SelectBox
                                            id="localitatea"
                                            items={this.state.localitatiComponente ? this.state.localitatiComponente : null}
                                            style={{ position: "relative", top: "-3px" }}
                                            width={"100%"}
                                            displayExpr={"denumireLocalitate"}
                                            valueExpr={"idNomenclatorUAT"}
                                            value={this.state.gospodarieDeTrimis.adresa.refLocalitateComponenta}
                                            placeholder="Localitatea..."
                                            onValueChange={(e) => this.handleValoriNoiAdresa(e, "refLocalitateComponenta")}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="siruta"
                                            className={clase.Margini}
                                            value={this.state.sirutaSelectata ? this.state.sirutaSelectata.toString() : null}
                                            placeholder="Siruta..."
                                            disabled={true}
                                        />
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="codPostal"
                                            className={clase.Margini}
                                            placeholder="Cod postal..."
                                            onValueChange={(e) => this.handleValoriNoiAdresa(e, "codPostal")}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <SelectBox
                                            id="Artera"
                                            style={{ position: "relative", top: "-3px" }}
                                            width={"100%"}
                                            items={["Princpala", "Ionel Fernic", "Independentei", "Evolutiei", "..."]}
                                            placeholder="Strada..."
                                            visible={false}
                                        />
                                        <TextBox
                                            width={"100%"}
                                            id="artera"
                                            className={clase.Margini}
                                            placeholder={"Strada..."}
                                            onValueChange={(e) => this.handleValoriNoiAdresa(e, "artera")}
                                        />
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="nr"
                                            className={clase.Margini}
                                            placeholder="Numarul..."
                                            onValueChange={(e) => this.handleValoriNoiAdresa(e, "numar")}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table width={"100%"} >
                            <tbody>
                                <tr>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="bloc"
                                            className={clase.Margini}
                                            placeholder="Bloc..."
                                            onValueChange={(e) => this.handleValoriNoiAdresa(e, "bloc")}
                                        />
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="scara"
                                            className={clase.Margini}
                                            placeholder="Scara..."
                                            onValueChange={(e) => this.handleValoriNoiAdresa(e, "scara")}
                                        />
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="etaj"
                                            className={clase.Margini}
                                            placeholder="Etaj..."
                                            onValueChange={(e) => this.handleValoriNoiAdresa(e, "etaj")}
                                        />
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="apartament"
                                            className={clase.Margini}
                                            placeholder="Apartament..."
                                            onValueChange={(e) => this.handleValoriNoiAdresa(e, "apartament")}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table width={"100%"} >
                            <tbody>
                                <tr>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="telefon"
                                            className={clase.Margini}
                                            placeholder="Telefon..."
                                            onValueChange={(e) => this.handleValoriNoiAdresa(e, "telefon")}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className={clase.Butoane}>
                            <Button
                                text="Defalcă"
                                type="success"
                                stylingMode="outlined"
                                onClick={this.handleSalveaza}
                            />
                        </div>
                    </div>

                    <div style={{ width: "68%", display: "inline-block", float: "right" }}>
                        <SelectBox
                            className={clase.Margini}
                            dataSource={this.state.tipuriGospodarii ? this.state.tipuriGospodarii.filter(x => [0, 1, 2].indexOf(x.tip) >= 0) : null}
                            valueExpr="tip"
                            displayExpr="nume"
                            defaultValue={0}
                            onSelectionChanged={(e) => this.handleSelectieTip(e.selectedItem)}
                        //visible={false}
                        />
                        <DataGrid
                            className={clase.Margini}
                            height={"auto"}
                            dataSource={gospodarii}
                            focusedRowKey={this.state.idGospSelectat}
                            columnAutoWidth={true}
                            allowColumnReordering={true}
                            showBorders={true}
                            focusedRowEnabled={true}
                            keyExpr={"idRegistrulAgricol"}
                            rowAlternationEnabled={false}
                            onInitialized={(e) => { this.datagrid = e.component; }}
                            onRowClick={(e) => { this.handleOnRowClick(e.data) }}
                        >
                            <Summary>
                                <TotalItem column="idGosp" summaryType="count" customizeText={(e) => { return "Nr. gospodarii selectate: " + e.value }} />
                            </Summary>
                            <Selection mode="single" deferred={false} />
                            <FilterRow visible={true}
                                applyFilter={true} />
                            <FilterPanel visible={true} />
                            <Paging defaultPageSize={5} />
                            {this.state.listaColoane ? this.state.listaColoane.map((col) =>
                                <Column
                                    key={col.idColoaneGridView}
                                    dataField={col.denumireCamp}
                                    caption={col.denumire}
                                    headerCellRender={headerTemplate}
                                    width={col.lungime + '%'}
                                    visible={col.vizibil}
                                />
                            ) : null}
                        </DataGrid>

                        <DataGrid
                            style={{ width: '30%', margin: "auto" }}
                            dataSource={this.state.membri}
                            focusedRowKey={"id"}
                            rowAlternationEnabled={false}
                            onRowClick={(e) => { this.handleClickMembru(e.data) }}
                        >
                            <Selection mode="single" deferred={false} />
                            <Column dataField="idUnic" headerCellRender={headerTemplate} width={"50px"} title={"idUnic"} visible={false} />
                            <Column dataField="numeleSiPrenumele" caption="Nume" headerCellRender={headerTemplate} />
                            <Column dataField="codRudenie" caption="Cod rud" headerCellRender={headerTemplate} width={'30%'} />
                        </DataGrid>
                    </div>

                </ScrollView>
            </Fragment>
        )
    }
}