const date = [
    {
        idDosar: 3,
        IDUNIC: 1,
        ID: 1,
        denumireDocument: "Declaratie",
        titular: "Vasile Blaga",
        tip: "declaratie",
        nr: 14,
        data: "10.10.2019",
        obiect: "declaratie",
        emitent: "Primaria",
        cotaIndivid: 30,
        pret: 400,
        dataInregistrarii: "05.05.2018"
    }, {
        idDosar: 3,
        IDUNIC: 1,
        ID: 2,
        denumireDocument: "Declaratie",
        titular: "Vasile Blaga",
        tip: "declaratie",
        nr: 14,
        data: "10.10.2019",
        obiect: "declaratie",
        emitent: "Primaria",
        cotaIndivid: 30,
        pret: 400,
        dataInregistrarii: "05.05.2018"
    }, {
        idDosar: 5,
        IDUNIC: 1,
        ID: 3,
        denumireDocument: "Declaratie",
        titular: "Vasile Blaga",
        tip: "declaratie",
        nr: 14,
        data: "10.10.2019",
        obiect: "declaratie",
        emitent: "Primaria",
        cotaIndivid: 30,
        pret: 400,
        dataInregistrarii: "05.05.2018"
    }, {
        idDosar: 3,
        IDUNIC: 1,
        ID: 4,
        denumireDocument: "Declaratie",
        titular: "Vasile Blaga",
        tip: "declaratie",
        nr: 14,
        data: "10.10.2019",
        obiect: "declaratie",
        emitent: "Primaria",
        cotaIndivid: 30,
        pret: 400,
        dataInregistrarii: "05.05.2018"
    }
];

export default {
    getDate() {
        return date;
    }
}