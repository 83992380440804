import React, { Component, Fragment } from 'react';
import { alert } from "devextreme/ui/dialog";
import DataGrid, { Column, Pager, Paging, FilterRow, FilterPanel, Editing, Form, Position, Popup } from 'devextreme-react/data-grid';
import { Button, TextBox, FileUploader } from 'devextreme-react';
import clase from './Dosar.module.css';
import date from './date';

class Dosar extends Component {
    state = {
        selectie: null
    }

    handleSelectie = (selectat) => {
        this.setState({ selectie: selectat });
    }

    handleAfisare = () => {
        alert("Se afiseaza: " + this.state.selectie.titular + ", id: " + this.state.selectie.ID);
    }

    handleFisierSelectat = () => {
        alert("a fost selectat un fisier");
    }

    render() {
        const dateTabel = date.getDate();

        //console.log(this.props.gospSelectata);

        return (
            <Fragment>
                <p>Dosar număr: </p>
                <TextBox className={clase.Box} defaultValue={this.props.gospSelectata ? this.props.gospSelectata.idGosp : ""} width={60} />

                <p>Denumire: </p>
                <TextBox className={clase.Box} defaultValue={this.props.gospSelectata ? this.props.gospSelectata.nume + " " + this.props.gospSelectata.initiala + " " + this.props.gospSelectata.prenume : ""} width={300} />

                <p>Înainte de a adăuga o înregistrare, încărcaţi un fişier:</p>
                <FileUploader className={clase.Uploader} uploadMode={"useButtons"} multiple={false} accept={"image/*"} uploadUrl="#" onValueChanged={this.handleFisierSelectat} />

                <DataGrid
                    dataSource={dateTabel.filter(x => x.idDosar === this.props.gospSelectata.idGosp)}
                    columnAutoWidth={true}
                    allowColumnReordering={true}
                    showBorders={true}
                    focusedRowEnabled={true}
                    keyExpr={"ID"}
                    rowAlternationEnabled={false}
                    onRowClick={(e) => this.handleSelectie(e.data)}
                >
                    <Editing
                        mode="popup"
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        useIcons={true}

                    >
                        <Popup title={"Gospodăria " + (this.props.gospSelectata ? this.props.gospSelectata.idGosp : "")} showTitle={true}>
                            <Position my="top" at="top" of={window} />
                        </Popup>
                        <Form
                            items={[
                                { dataField: "idDosar" },
                                { dataField: "IDUNIC" },
                                { dataField: "ID" },
                                { dataField: "denumireDocument" },
                                { dataField: "titular" },
                                { dataField: "tip" },
                                { dataField: "nr" },
                                { dataField: "data" },
                                { dataField: "obiect" },
                                { dataField: "emitent" },
                                { dataField: "cotaIndivid" },
                                { dataField: "pret" },
                                { dataField: "dataInregistrarii" }
                            ]}
                        >
                        </Form>
                    </Editing>
                    <FilterRow visible={true}
                        applyFilter={true} />
                    <FilterPanel visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                    <Column dataField="idDosar" />
                    <Column dataField="IDUNIC" />
                    <Column dataField="ID" />
                    <Column dataField="denumireDocument" />
                    <Column dataField="titular" />
                    <Column dataField="tip" />
                    <Column dataField="nr" />
                    <Column dataField="data" />
                    <Column dataField="obiect" />
                    <Column dataField="emitent" />
                    <Column dataField="cotaIndivid" />
                    <Column dataField="pret" />
                    <Column dataField="dataInregistrarii" />
                </DataGrid>

                <div className={clase.Butoane}>
                    <Button
                        width={120}
                        text="Afişează"
                        type="normal"
                        stylingMode="outlined"
                        onClick={this.handleAfisare}
                    />
                    &nbsp;
                    <Button
                        width={120}
                        text="Anulează"
                        type="normal"
                        stylingMode="outlined"
                        onClick={this.props.ascunde}
                    />
                </div>
            </Fragment >
        );
    }
}

export default Dosar;