import React, { Component, Fragment } from 'react';
import clase from './AdeverinteTip.module.css';
import { Button, SelectBox, TextBox } from 'devextreme-react';
import axios from 'axios';

class SetariAdeverinte extends Component {
    state = {
        destinatieSelectata: 2
    }

    destinatie = [
        { id: 1, text: "Adeverinţă APIA" },
        { id: 2, text: "Alte adeverinţe" }
    ]

    componentDidMount() {
        //this.getSemnaturi(1);
    }

    getSemnaturi = (destinatie) => {//destinatia poate fi 1 sau 2 momentan
        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Rapoarte/GetSemnaturi/' + localStorage.getItem("siruta") + '/' + destinatie.value
        })
            .then((response) => {
                if (response.data) {
                    this.setState({
                        semnaturi: response.data,
                        destinatieSelectata: destinatie.value
                    }
                    )
                }
                else
                    alert("Eroare la conexiunea cu baza de date. (SetariAdeverinte.jsx)");
            })
            .catch((error) => {
                alert("Eroare la adaugare. (SetariAdeverinte)");
                console.log("Eroare SetariAdeverinte", error);
            });
    }

    handleSalveazaSetari = () => {

        axios({
            data: this.state.semnaturi,
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/Rapoarte/SalveazaSemnaturi/' + localStorage.getItem("siruta") + '/' + this.state.destinatieSelectata
        })
            .then((response) => {
                if (response.data) {
                    this.setState({
                        semnaturi: response.data
                    }, () => { this.props.ascundeSetari() });
                }
                else
                    alert("Eroare la conexiunea cu baza de date. (SetariAdeverinte.jsx)");
            })
            .catch((error) => {
                alert("Eroare la adaugare. (SetariAdeverinte)");
                console.log("Eroare SetariAdeverinte.jsx", error);
            });
    }

    handleValoare = (val, ce, unde) => {
        var semnaturi = [...this.state.semnaturi];
        
        if (semnaturi.find(x => x.pozitieSemnatura === unde)) {
            semnaturi.find(x => x.pozitieSemnatura === unde)[ce] = val;
        }
        else {
            semnaturi.push({
                idSemnaturi: 0,
                ordineSemnatura: 0,
                refDatePrimarie: 0,
                functia: ce === "functia" ? val : null,
                numePrenume: ce === "numePrenume" ? val : null,
                pozitieSemnatura: unde,
                vizibil: 1,
                destinatieSemnatura: this.state.destinatieSelectata

            })
        }
        this.setState({ semnaturi: semnaturi });
    }

    render() {
        //console.log("SetariAdeverinte state", this.state);
        //console.log("SetariAdeverinte props", this.props);

        return (
            <Fragment>
                <SelectBox
                    dataSource={this.destinatie}
                    displayExpr="text"
                    valueExpr="id"
                    placeholder="Alege categorie..."
                    //defaultValue={2}
                    onValueChanged={(e) => { this.getSemnaturi(e) }}
                />

                <table style={{ width: "80%", textAlign: "center", margin: "auto" }}>
                    <tbody>
                        <tr>
                            <td style={{ width: "33%" }}>
                                <TextBox
                                    style={{ marginTop: "10px", width: "100%" }}
                                    placeholder={"Funcţia"}
                                    className={clase.InputText}
                                    value={this.state.semnaturi && this.state.semnaturi.find(x => x.pozitieSemnatura === "Stanga sus")
                                        ? this.state.semnaturi.find(x => x.pozitieSemnatura === "Stanga sus").functia
                                        : null}
                                    onValueChanged={(e) => this.handleValoare(e.value, "functia", "Stanga sus")}
                                />
                                <TextBox
                                    style={{ marginTop: "10px", width: "100%" }}
                                    placeholder={"Numele"}
                                    className={clase.InputText}
                                    value={this.state.semnaturi && this.state.semnaturi.find(x => x.pozitieSemnatura === "Stanga sus")
                                        ? this.state.semnaturi.find(x => x.pozitieSemnatura === "Stanga sus").numePrenume
                                        : null}
                                    onValueChanged={(e) => this.handleValoare(e.value, "numePrenume", "Stanga sus")}
                                />
                            </td>

                            <td style={{ width: "33%" }}></td>

                            <td style={{ width: "33%" }}>
                                <TextBox
                                    style={{ marginTop: "10px", width: "100%" }}
                                    placeholder={"Funcţia"}
                                    className={clase.InputText}
                                    value={this.state.semnaturi && this.state.semnaturi.find(x => x.pozitieSemnatura === "Dreapta sus")
                                        ? this.state.semnaturi.find(x => x.pozitieSemnatura === "Dreapta sus").functia
                                        : null}
                                    onValueChanged={(e) => this.handleValoare(e.value, "functia", "Dreapta sus")}
                                />
                                <TextBox
                                    style={{ marginTop: "10px", width: "100%" }}
                                    placeholder={"Numele"}
                                    className={clase.InputText}
                                    value={this.state.semnaturi && this.state.semnaturi.find(x => x.pozitieSemnatura === "Dreapta sus")
                                        ? this.state.semnaturi.find(x => x.pozitieSemnatura === "Dreapta sus").numePrenume
                                        : null}
                                    onValueChanged={(e) => this.handleValoare(e.value, "numePrenume", "Dreapta sus")}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ width: "33%" }}></td>
                            <td style={{ width: "33%" }}>
                                <TextBox
                                    style={{ marginTop: "10px", width: "100%" }}
                                    placeholder={"Funcţia"}
                                    className={clase.InputText}
                                    value={this.state.semnaturi && this.state.semnaturi.find(x => x.pozitieSemnatura === "Centru")
                                        ? this.state.semnaturi.find(x => x.pozitieSemnatura === "Centru").functia
                                        : null}
                                    onValueChanged={(e) => this.handleValoare(e.value, "functia", "Centru")}
                                />
                                <TextBox
                                    style={{ marginTop: "10px", width: "100%" }}
                                    placeholder={"Numele"}
                                    className={clase.InputText}
                                    value={this.state.semnaturi && this.state.semnaturi.find(x => x.pozitieSemnatura === "Centru")
                                        ? this.state.semnaturi.find(x => x.pozitieSemnatura === "Centru").numePrenume
                                        : null}
                                    onValueChanged={(e) => this.handleValoare(e.value, "numePrenume", "Centru")}
                                />
                            </td>
                            <td style={{ width: "33%" }}></td>
                        </tr>
                        <tr>
                            <td style={{ width: "33%" }}>
                                <TextBox
                                    style={{ marginTop: "10px", width: "100%" }}
                                    placeholder={"Funcţia"}
                                    className={clase.InputText}
                                    value={this.state.semnaturi && this.state.semnaturi.find(x => x.pozitieSemnatura === "Stanga jos")
                                        ? this.state.semnaturi.find(x => x.pozitieSemnatura === "Stanga jos").functia
                                        : null}
                                    onValueChanged={(e) => this.handleValoare(e.value, "functia", "Stanga jos")}
                                />
                                <TextBox
                                    style={{ marginTop: "10px", width: "100%" }}
                                    placeholder={"Numele"}
                                    className={clase.InputText}
                                    value={this.state.semnaturi && this.state.semnaturi.find(x => x.pozitieSemnatura === "Stanga jos")
                                        ? this.state.semnaturi.find(x => x.pozitieSemnatura === "Stanga jos").numePrenume
                                        : null}
                                    onValueChanged={(e) => this.handleValoare(e.value, "numePrenume", "Stanga jos")}
                                />
                            </td>
                            <td style={{ width: "33%" }}></td>
                            <td style={{ width: "33%" }}>
                                <TextBox
                                    style={{ marginTop: "10px", width: "100%" }}
                                    placeholder={"Funcţia"}
                                    className={clase.InputText}
                                    value={this.state.semnaturi && this.state.semnaturi.find(x => x.pozitieSemnatura === "Dreapta jos")
                                        ? this.state.semnaturi.find(x => x.pozitieSemnatura === "Dreapta jos").functia
                                        : null}
                                    onValueChanged={(e) => this.handleValoare(e.value, "functia", "Dreapta jos")}
                                />
                                <TextBox
                                    style={{ marginTop: "10px", width: "100%" }}
                                    placeholder={"Numele"}
                                    className={clase.InputText}
                                    value={this.state.semnaturi && this.state.semnaturi.find(x => x.pozitieSemnatura === "Dreapta jos")
                                        ? this.state.semnaturi.find(x => x.pozitieSemnatura === "Dreapta jos").numePrenume
                                        : null}
                                    onValueChanged={(e) => this.handleValoare(e.value, "numePrenume", "Dreapta jos")}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className={clase.Butoane}>
                    <Button
                        className={clase.ButonGenerare}
                        width={120}
                        text="Salvează"
                        type="success"
                        stylingMode="outlined"
                        onClick={this.handleSalveazaSetari}
                    />

                    <Button
                        className={clase.Buton}
                        width={120}
                        text="Anulează"
                        type="danger"
                        stylingMode="outlined"
                        onClick={this.props.ascundeSetari}
                    />
                </div>
            </Fragment>
        );
    };
}

export default SetariAdeverinte;