import React, { Component } from 'react';
import ComponentSus from './Sus/ComponentSus'
import ComponentJos from './Jos/ComponentJos'
import service from '../DateGospodarii/Sus/Date';

class DateGospodarii extends Component {
    sursa = [{ id: 1 }, { id: 2 }];

    state = {
        selectedItems: this.sursa,
        multiple: true,
        collapsible: false,
        animationDuration: 300,
        anSelectat: service.getAni() ? service.getAni()[service.getAni().length - 1] : null,//.sort(function (a, b) { return a - b })[service.getAni().length - 1],
        numarGospodarii: 0,
        tipSelectat: 1,
        vizibil: true,
        indexSelectat: 0,
        capitolSelectat: "CAP. I"
    };

    componentDidMount() {
        localStorage.setItem("anSelectat", this.state.anSelectat);
    }

    stil = {
        width: '98%',
        //height: (window.innerHeight - 57).toString() + "px",
        height: "calc(100vh - 57px)",
        margin: 'auto',
        border: '1px solid #ddd',
        overflow: "hidden"
    };

    handleAn = (an) => {
        if (this.state.anSelectat !== an)
            this.setState({ anSelectat: an });
        localStorage.setItem("anSelectat", an);
    }

    handleGospSelectata = (date) => {
        this.setState({ gospodariaSelectata: date });
        this.props.setIdGospodariaSelectata(date.idRegistrulAgricol);//se trimite catre App id-ul gospodariei selectate pt a fi trimis apoi catre celelalte pagini
    }

    customTitle = (data) => {
        const { gospodariaSelectata } = this.state;

        var stil = {
            height: '30px',
            //backgroundColor: '#eee',
            textAlign: 'center'
        };

        var baraTitlu = (
            <div style={stil}>
                <p style={{ fontSize: '16px' }}> Nume UAT (idUAT) - tip: {this.state.tipSelectat ? this.state.tipSelectat : " toate tipurile"} - {this.state.numarGospodarii} gospodarii</p>
            </div>
        );

        localStorage.setItem("dateCapitol", "(vol. " + gospodariaSelectata.volum + "/poz. " + gospodariaSelectata.pozitie + ") " + gospodariaSelectata.numeReprezentantLegal + " " + gospodariaSelectata.initialaReprezentantLegal + " " + gospodariaSelectata.prenumeReprezentantLegal + ", " + gospodariaSelectata.localitate + ", " + gospodariaSelectata.artera + ", nr. " + gospodariaSelectata.numar + ", (" + this.state.anSelectat + ", " + this.state.capitolSelectat + ")");

        if (data.id !== 1) {
            baraTitlu = (
                <div style={stil}>
                    {gospodariaSelectata ?
                        <p style={{ fontSize: '16px' }}> (vol. {gospodariaSelectata.volum}/poz. {gospodariaSelectata.pozitie}) {gospodariaSelectata.numeReprezentantLegal} {gospodariaSelectata.initialaReprezentantLegal} {gospodariaSelectata.prenumeReprezentantLegal}, {gospodariaSelectata.localitate}, {gospodariaSelectata.artera}, nr. {gospodariaSelectata.numar}, ({this.state.anSelectat}, {this.state.capitolSelectat})</p>
                        : <p>Selectati o gospodarie.</p>}
                </div>
            );
        }

        return baraTitlu;
    }

    handleOnDblClick = () => {
        let oldVizibil = this.state.vizibil;
        this.setState({ vizibil: !oldVizibil });
    }

    handleTipSelectat = (tip) => {
        if (this.state.tipSelectat !== tip) {
            this.setState({ tipSelectat: tip })
        }
    }

    handleCapitolSelectat = (capitol) => {
        this.setState({ capitolSelectat: capitol })
    }

    render() {
        //console.log("gosp select",this.state.gospodariaSelectata)

        let randeazaComponenta = this.state.vizibil ?
            <ComponentSus
                selecteazaAn={this.handleAn}
                selecteazaTip={this.handleTipSelectat}
                getGosp={this.handleGospSelectata}
                onDblClick={this.handleOnDblClick}
                indexSelectat={this.state.indexSelectat}
                gridVizibil={this.state.vizibil}
                tipSelectat={this.state.tipSelectat}
                anSelectat={this.state.anSelectat}
                handleDelogare={this.props.handleDelogare}
            />
            :
            <ComponentJos
                gospodariaSelectata={this.state.gospodariaSelectata}
                anSelectat={this.state.anSelectat}
                inapoi={this.handleOnDblClick}
                capitolSelectat={this.handleCapitolSelectat}
            />;

        return (
            <div style={this.stil} id='chenar'>
                {this.state.gospodariaSelectata && !this.state.vizibil ? this.customTitle(this.state.gospodariaSelectata) : null}

                {randeazaComponenta}
            </div>
        )
    }
}

export default DateGospodarii;