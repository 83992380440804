/**************************/
/*  MENIUL CAPITOLULUI 2b */
/**************************/
import React, { Component, Fragment } from 'react';
import { alert } from "devextreme/ui/dialog";
import { Button, DropDownButton, Popup, CheckBox, SelectBox } from 'devextreme-react';
import date from '../DateCapitole'; //pentru butonul Export
import Transfer from './Transfer/Transfer';
import Miscari from './Miscari/Miscari';


export default class MeniuCap2b extends Component {
    state = {
        listaDeclaratii: [],
        popupVizibil: false,
        ceSeAfiseaza: "Transfer",
        check2a: localStorage.getItem("check2a") && localStorage.getItem("check2a") /*lasa cu 2 =*/ == "true" ? true : false
    }

    componentDidMount() {
        let listaDecl = this.props.listaDeclaratii.map(x => {
            let obj = { idDeclaratie: x.idDeclaratii, nrData: (x.nrDeclaratie + "/" + (new Date(x.dataDeclaratie)).toLocaleDateString("ro")) };
            return obj;
        });

        localStorage.removeItem("declaratieSelectata");

        this.setState({ listaDeclaratii: listaDecl });
    }

    handleExport = (tip) => {
        alert("Se exporta fisier de tip: " + tip);
    }

    handleMiscariParcela = () => {
        var ls = this.props.getLinieSelectata();
        if (!ls) {
            alert("Nu aţi selectat nicio parcelă!");
            return;
        }
        if (this.props.gospodariaSelectata) {
            this.setState({
                popupVizibil: true,
                ceSeAfiseaza: "Miscari",
                liniaSelectata: ls
            });
        }
    }

    handleTransferati = () => {
        var ls = this.props.getLinieSelectata();
        if (!ls) {
            alert("Nu aţi selectat nicio parcelă!");
            return;
        }
        if (this.props.gospodariaSelectata) {
            this.setState({
                popupVizibil: true,
                ceSeAfiseaza: "Transfer",
                liniaSelectata: ls
            });
        }
    }

    handleAscundePopup = () => {
        this.setState({ popupVizibil: false });
    }

    handleValidareBlocFizic = () => {
        alert("Validare bloc fizic (face parte din interfatari)");
    }

    verificaAccesIstoric = () => {
        var ani = JSON.parse(localStorage.getItem("listaAni"));

        var anSelectat = parseInt(localStorage.getItem("anSelectat"));

        var accesIstoric = localStorage.getItem("accesIstoric");
        var siruta = localStorage.getItem("siruta");

        if (anSelectat < Math.max(...ani) && !accesIstoric.includes(siruta))
            return false;

        return true;
    }

    render() {
        //console.log("MeniuCap2b state", this.state);
        //console.log("MeniuCap2b props", this.props);

        const popupTransferati =
            <Popup
                visible={this.state.popupVizibil}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showTitle={true}
                title="Transferati"
                onHidden={this.handleAscundePopup}
                fullScreen={true}
                showCloseButton={false}
            >
                {
                    this.state.ceSeAfiseaza === "Transfer" ?
                        <Transfer
                            ascunde={this.handleAscundePopup}
                            gospodariaSelectata={this.props.gospodariaSelectata}
                            parcelaSelectata={this.state.liniaSelectata}
                            anSelectat={this.props.anSelectat}
                            setLinieDeSters={this.props.setLinieDeSters}
                            capitol={this.props.capitol}
                            stergeLinieSelectata={this.props.stergeLinieSelectata}
                        /> :
                        <Miscari
                            ascunde={this.handleAscundePopup}
                            gospodariaSelectata={this.props.gospodariaSelectata}
                            parcelaSelectata={this.state.liniaSelectata}
                            anSelectat={this.props.anSelectat}
                            capitol={this.props.capitol}
                        />
                }
            </Popup>;


        return (
            <Fragment>
                {this.state.popupVizibil && this.props.gospodariaSelectata ? popupTransferati : null}

                <p style={{ display: "inline-block", position: "relative", top: "1px" }}>Se modifică conform declaraţiei</p>
                <SelectBox
                    style={{ display: "inline-block", marginLeft: "5px", position: "relative", top: "1px" }}
                    height={26}
                    dataSource={this.state.listaDeclaratii}
                    displayExpr="nrData"
                    valueExpr="idDeclaratie"
                    showClearButton={true}
                    onValueChanged={(e) => { localStorage.setItem("declaratieSelectata", e.value) }}
                />

                <div style={{ padding: "0 15px 0 0", display: "inline-block", marginLeft:"5px" }} hidden={this.props.capitol && this.props.capitol === "CAP. II b" ? false : true}>
                    <CheckBox
                        style={{ display: "inline-block" }}
                        defaultValue={this.state.check2a}
                        onValueChanged={(e) => {
                            this.setState({ check2a: e.value });
                            localStorage.setItem("check2a", e.value.toString());
                        }}
                        visible={localStorage.getItem("role") === 'UtilizatorExtern' || this.verificaAccesIstoric() === false
                            ? localStorage.getItem("role") === "Administrator" ? true : false
                            : true}
                    />
                    <p style={{ display: "inline-block" }}>Totalizează în 2a şi 3</p>
                </div>

                <DropDownButton
                    text="Export"
                    icon="save"
                    dropDownOptions={{ width: 230 }}
                    items={date.getExport()}
                    onItemClick={(e) => { this.handleExport(e.itemData) }}
                    visible={false}
                />
                {/*<Button
                    style={{ marginLeft: "5px" }}
                    text="Validare bloc fizic"
                    onClick={this.handleValidareBlocFizic}
                />*/}
                <Button
                    style={{ display: "inline-block", marginLeft: "5px" }}
                    width={120}
                    text="Şterge capitol"
                    type="danger"
                    stylingMode="outlined"
                    onClick={this.props.stergeCapitol}
                    visible={localStorage.getItem("role") === 'UtilizatorExtern' || this.verificaAccesIstoric() === false
                        ? localStorage.getItem("role") === "Administrator" ? true : false
                        : true}
                />
                <Button
                    style={{ marginLeft: "5px" }}
                    type="normal"
                    stylingMode="outlined"
                    text="Transfer"
                    onClick={this.handleTransferati}
                    visible={localStorage.getItem("role") === 'UtilizatorExtern' || this.verificaAccesIstoric() === false
                        ? localStorage.getItem("role") === "Administrator" ? true : false
                        : true}
                />
                <Button
                    style={{ marginLeft: "5px" }}
                    type="normal"
                    stylingMode="outlined"
                    text="Mişcări"
                    onClick={this.handleMiscariParcela}
                    visible={localStorage.getItem("role") === 'UtilizatorExtern' || this.verificaAccesIstoric() === false
                        ? localStorage.getItem("role") === "Administrator" ? true : false
                        : true}
                />
                <Button
                    style={{ marginLeft: "5px" }}
                    stylingMode="outlined"
                    type="danger"
                    text="Inapoi"
                    onClick={this.props.inapoi}
                />
            </Fragment>
        );
    }
}