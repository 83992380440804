import axios from 'axios';

export default {
    getDateLocalitate() {
        return new Promise((resolve, reject) => {

            const datePrimarie = axios.get("./api/DatePrimarie/GetDatePrimarie/" + parseInt(localStorage.getItem("siruta")));

            const listaUAT = axios.get("./api/DatePrimarie/GetListaUAT/" + parseInt(localStorage.getItem("siruta")));

            const localitatiComponente = axios.get("./api/DatePrimarie/GetLocalitatiComponente/" + parseInt(localStorage.getItem("siruta")));

            const nomenclatorStradal = axios.get("./api/DatePrimarie/GetNomenclatorStradal/" + parseInt(localStorage.getItem("siruta")));

            axios.all([datePrimarie, listaUAT, localitatiComponente, nomenclatorStradal]).then(
                axios.spread((responseDP, responseLUAT, responseLC, responseNS) => {
                    let raspuns = {
                        datePrimarie: responseDP.data,
                        listaUAT: responseLUAT.data,
                        localitatiComponente: responseLC.data,
                        nomenclatorStradal: responseNS.data
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    }
}