import axios from 'axios';

const membri = [
    {
        id: 1,
        gradRudenie: 1,
        nume: "Vasile",
        initiala: "V",
        prenume: "Vasilica",
        cnp: 1111111111112
    }, {
        id: 2,
        gradRudenie: 2,
        nume: "Vasile",
        initiala: "V",
        prenume: "Gheorghe",
        cnp: 1111111111113
    }, {
        id: 3,
        gradRudenie: 3,
        nume: "Vasile",
        initiala: "V",
        prenume: "Ion",
        cnp: 1111111111114
    }, {
        id: 4,
        gradRudenie: 3,
        nume: "Vasile",
        initiala: "V",
        prenume: "Petru",
        cnp: 1111111111115
    }, {
        id: 5,
        gradRudenie: 4,
        nume: "Vasile",
        initiala: "V",
        prenume: "Annabelle",
        cnp: 1111111111116
    }
];

const gradeRudenie = [
    {
        id: 1,
        denumire: "Cap gospodarie"
    }, {
        id: 2,
        denumire: "sot/sotie"
    },
    {
        id: 3,
        denumire: "fiu/fiica"
    }, {
        id: 4,
        denumire: "altele"
    }
];

const adeverinte = [
    {
        id: 1,
        denumire: "Cerere"
    }, {
        id: 2,
        denumire: "Declaratie parcele"
    }, {
        id: 3,
        denumire: "Adeverinta alocatie"
    }, {
        id: 4,
        denumire: "Adeverinta stare materiala"
    }, {
        id: 5,
        denumire: "Adeverinta terenuri"
    }, {
        id: 6,
        denumire: "Adeverinta culturi"
    }, {
        id: 7,
        denumire: "Adeverinta incalzire"
    }, {
        id: 8,
        denumire: "Adeverinta APIA"
    }, {
        id: 9,
        denumire: "Adeverinta BI/CI"
    }, {
        id: 999,
        denumire: "Adeverinta BICI"
    }
]

export default {
    getMembri() {
        return membri;
    },
    getGradeRudenie() {
        return gradeRudenie;
    },
    getAdeverinte() {
        return adeverinte;
    },
    getDateAdeverinte(siruta, idRegistrulAgricol) {//tip este tipul de grid din tabela ColoaneGridView
        return new Promise((resolve, reject) => {
            const adeverinte = axios.get("./api/Rapoarte/GetAdeverinteTip/" + siruta);

            const membri = axios.get("./api/Capitole/" + idRegistrulAgricol + '/' + 1 + '/' + siruta);

            const gradeRudenie = axios.get("./api/Rapoarte/GetGradeRudenie");

            axios.all([adeverinte, membri, gradeRudenie]).then(
                axios.spread((responseA, responseM, responseG) => {
                    let raspuns = {
                        adeverinte: responseA.data,
                        membri: responseM.data,
                        gradeRudenie: responseG.data
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    }
};