import React, { Component, Fragment } from 'react';
import service from "./DateProductii";
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import { SelectBox, TextBox, Button, ScrollView } from 'devextreme-react';
import DataGrid, { Column, Paging } from 'devextreme-react/data-grid';
import Utilitare from './../../../../content/Utilitare';

export default class ProductiaVegetala extends Component {
    state = {
        capitolSelectat: 8,
        procent: 0
    }

    stil = {
        width: '98%',
        height: 'calc(100vh - 57px)',
        margin: 'auto',
        border: '1px solid #ddd',
        textAlign: "center",
        overflow: "hidden"
    };

    capitole = [
        { id: 8, text: "4a" },
        { id: 24, text: "4a1" },
        { id: 9, text: "4b1" },
        { id: 25, text: "4b2" },
        { id: 10, text: "4c" },
        { id: 12, text: "5a" },
        { id: 13, text: "5b" },
        { id: 14, text: "5c" },
        { id: 15, text: "5d" }
    ];

    componentDidMount() {
        service.getListaCulturi(8, 2)
            .then(r => {
                this.setState({
                    listaCulturi: r.listaCulturi,
                    culturaSelectata: r.listaCulturi[0].codRand,
                    listaProductii: r.listaProductii,
                    listaGospodarii: r.listaGospodarii,
                    procent: r.procent,
                    serverString: r.serverString
                })
            })
            .catch(error => {
                Utilitare.handleEroare(error, "ProductiaVegetala");
            })
    }

    handleSchimbaCapitol = (val) => {
        this.setState({ capitolSelectat: val }, () => {
            service.getListaCulturi(val, this.state.culturaSelectata)
                .then(r => {
                    this.setState({
                        listaCulturi: r.listaCulturi,
                        culturaSelectata: r.listaCulturi[0].codRand,
                        listaProductii: r.listaProductii,
                        listaGospodarii: r.listaGospodarii,
                        procent: r.procent,
                        serverString: r.serverString
                    })
                })
                .catch(error => {
                    Utilitare.handleEroare(error, "ProductiaVegetala");
                })
        });
    }

    handleSchimbaCultura = (val) => {
        this.setState({ culturaSelectata: val }, () => {
            service.getListaCulturi(this.state.capitolSelectat, val)
                .then(r => {
                    this.setState({
                        listaCulturi: r.listaCulturi,
                        culturaSelectata: r.listaCulturi.find(x => x.codRand == val).codRand,
                        listaProductii: r.listaProductii,
                        listaGospodarii: r.listaGospodarii,
                        procent: r.procent,
                        serverString: r.serverString
                    })
                })
                .catch(error => {
                    Utilitare.handleEroare(error, "ProductiaVegetala");
                })
        });
    }

    handleClickGospodarie = (e) => {
        this.setState({ gospodarieSelectata: e.data });
    }

    handleClickProductie = (e) => {
        this.setState({ productiaSelectata: e.data });
    }

    handleSalveaza = () => {
        if (!this.state.valoare) {
            alert("Nu aţi completat cantitatea.");
            return;
        }
        if (!this.state.gospodarieSelectata) {
            alert("Nu aţi completat suprafaţa.");
            return;
        }

        var valoareDeTrimis = {
            refDatePrimarie: this.state.gospodarieSelectata.refDatePrimarie,
            refRegistrulAgricol: this.state.gospodarieSelectata.refRegistrulAgricol,
            refListaCapitole: this.state.capitolSelectat,
            cultura: parseInt(this.state.culturaSelectata),
            suprafata: parseInt(this.state.gospodarieSelectata.unitateCalcul),
            cantitate: parseInt(this.state.valoare)
        };


        axios({
            data: valoareDeTrimis,
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/Rapoarte/Salveaza/' + localStorage.getItem("siruta") + "/" + localStorage.getItem("anSelectat")
        })
            .then((response) => {
                if (response.data === true)
                    service.getListaCulturi(this.state.capitolSelectat, this.state.culturaSelectata)
                        .then(r => {
                            this.setState({
                                listaCulturi: r.listaCulturi,
                                //culturaSelectata: r.listaCulturi.find(x => x.codRand == this.state.culturaSelectata).codRand,
                                listaProductii: r.listaProductii,
                                listaGospodarii: r.listaGospodarii,
                                procent: r.procent,
                                serverString: r.serverString
                            })
                        })
                        .catch(error => {
                            Utilitare.handleEroare(error, "ProductiaVegetala");
                        })
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "ProductiaVegetala");
            });
    }

    handleSterge = () => {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/Rapoarte/Sterge/' + localStorage.getItem("siruta") + "/" + this.state.productiaSelectata.refRegistrulAgricol
        })
            .then((response) => {
                if (response.data === true)
                    service.getListaCulturi(this.state.capitolSelectat, this.state.culturaSelectata)
                        .then(r => {
                            this.setState({
                                listaCulturi: r.listaCulturi,
                                //culturaSelectata: r.listaCulturi.find(x => x.codRand == this.state.culturaSelectata).codRand,
                                listaProductii: r.listaProductii,
                                listaGospodarii: r.listaGospodarii,
                                procent: r.procent,
                                serverString: r.serverString
                            })
                        })
                        .catch(error => {
                            Utilitare.handleEroare(error, "ProductiaVegetala");
                        })
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "ProductiaVegetala");
            });
    }

    handleAfiseazaProductia = () => {
        //console.log(this.state.serverString + "&An=" + localStorage.getItem("anSelectat") + "&idtab=" + this.state.capitolSelectat);
        window.open(this.state.serverString + "&An=" + localStorage.getItem("anSelectat") + "&idtab=" + this.state.capitolSelectat, "_blank", 'noopener');
    }

    render() {
        //console.log("prodVeg state", this.state);

        return (
            <Fragment>
                <div style={this.stil} id='chenar'>
                    <h3>Productia vegetală</h3>
                    <ScrollView height="100%">
                        <div style={{ textAlign: "left", marginTop: "5px" }}>
                            <p style={{ display: "inline-block" }}> Capitolul </p>
                            <SelectBox
                                style={{ margin: "0 5px 0 5px", width: "120px", display: "inline-block" }}
                                dataSource={this.capitole}
                                displayExpr="text"
                                valueExpr="id"
                                defaultValue={8}
                                onValueChange={(e) => { this.handleSchimbaCapitol(e) }}
                            />
                        </div>
                        <div style={{ textAlign: "left", marginTop: "5px" }}>
                            <p style={{ display: "inline-block" }}> Cultura </p>
                            <SelectBox
                                style={{ margin: "0 5px 0 5px", width: "400px", display: "inline-block" }}
                                dataSource={this.state.listaCulturi ? this.state.listaCulturi : null}
                                displayExpr="denumire"
                                valueExpr="codRand"
                                value={this.state.culturaSelectata ? this.state.culturaSelectata : null}
                                onValueChange={(e) => { this.handleSchimbaCultura(e) }}
                            />
                        </div>

                        <table style={{ width: "100%", marginTop: "5px" }}>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                        <Button
                                            style={{ display: "inline-block", marginLeft: "5px", height: "30px", position: "relative", top: "-3px" }}
                                            width={180}
                                            text="Şterge"
                                            type="normal"
                                            stylingMode="outlined"
                                            onClick={this.handleSterge}
                                        />
                                        <Button
                                            style={{ display: "inline-block", marginLeft: "5px", height: "30px", position: "relative", top: "-3px" }}
                                            width={180}
                                            text="Afişează Producţia Vegetală"
                                            type="normal"
                                            stylingMode="outlined"
                                            onClick={this.handleAfiseazaProductia}
                                        />
                                    </td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                        <TextBox
                                            style={{ display: "inline-block" }}
                                            width={"180px"}
                                            placeholder={"Cantitate"}
                                            onValueChange={(e) => this.setState({ valoare: e })}
                                        />
                                        <Button
                                            style={{ display: "inline-block", marginLeft: "5px", height: "30px", position: "relative", top: "-3px" }}
                                            width={180}
                                            text="Salvează"
                                            type="normal"
                                            stylingMode="outlined"
                                            onClick={this.handleSalveaza}
                                            disabled={this.state.procent && this.state.procent >= 13 ? true : false}
                                        />
                                    </td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td style={{ width: "50%" }}>
                                        <DataGrid
                                            dataSource={this.state.listaProductii ? this.state.listaProductii : null}
                                            showBorders={true}
                                            columnAutoWidth={true}
                                            keyExpr={"refRegistrulAgricol"}
                                            focusedRowEnabled={true}
                                            selectedRowKeys={[this.state.productiaSelectata ? this.state.productiaSelectata.refRegistrulAgricol : 0]}
                                            onRowClick={(e) => { this.handleClickProductie(e) }}
                                        >
                                            <Column dataField={"idProductiaVegetala"} caption={"id"} visible={false} />
                                            <Column dataField={"refRegistrulAgricol"} caption={"ID"} width={"50px"} />
                                            <Column dataField={"tip"} caption={"Tip"} />
                                            <Column dataField={"volum"} caption={"Volum"} />
                                            <Column dataField={"pozitie"} caption={"Pozitie"} />
                                            <Column dataField={"denumire"} caption={"Reprezentant Legal/Firma"} />
                                            <Column dataField={"suprafata"} caption={"Suprafata"} />
                                            <Column dataField={"cantitate"} caption={"Cantitatea"} />

                                            <Paging defaultPageSize={15} />
                                        </DataGrid>
                                    </td>
                                    <td style={{ width: "50%" }}>
                                        <DataGrid
                                            dataSource={this.state.listaGospodarii ? this.state.listaGospodarii : null}
                                            showBorders={true}
                                            columnAutoWidth={true}
                                            keyExpr={"refRegistrulAgricol"}
                                            focusedRowEnabled={true}
                                            selectedRowKeys={[this.state.gospodarieSelectata ? this.state.gospodarieSelectata.refRegistrulAgricol : 0]}
                                            onRowClick={(e) => { this.handleClickGospodarie(e) }}
                                        >
                                            <Column dataField={"refRegistrulAgricol"} caption={"ID"} width={"50px"} />
                                            <Column dataField={"tip"} caption={"Tip"} />
                                            <Column dataField={"volum"} caption={"Volum"} />
                                            <Column dataField={"pozitie"} caption={"Pozitie"} />
                                            <Column dataField={"denumire"} caption={"Reprezentant Legal/Firma"} />
                                            <Column dataField={"unitateCalcul"} caption={"Suprafata"} />

                                            <Paging defaultPageSize={15} />
                                        </DataGrid>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Procent suprafeţe încărcate: {this.state.procent ? this.state.procent.toFixed(2) : 0}%</p>
                    </ScrollView>
                </div>
            </Fragment>
        );
    }
}