import axios from 'axios';

export default {
    getListaGospodarii(siruta, tip, an) {
        //console.log("siruta, tip, an", siruta, tip, an)
        return new Promise((resolve, reject) => {
            var tipGosp = 0;
            if (tip > 4)//daca e vorba de alt grid de gospodarii
                tipGosp = 0;
            else tipGosp = tip;

            const gospodarii = axios.get("./api/Gospodarii/GetGospodarii/",
                {
                    params: {
                        siruta: siruta,
                        tip: tipGosp,
                        an: an
                    }
                });

            const coloane = axios.get("./api/GridView/getColoane/",
                {
                    params: {
                        tip: 8 //8 = grid defalcare
                    }
                });

            const tipuriGospodarii = axios.get("./api/Gospodarii/GetTipuriGospodarii/");

            const localitatiComponente = axios.get("./api/Gospodarii/GetLocalitatiComponente/",
                {
                    params: { siruta: siruta }
                });

            const categoriiGospodarii = axios.get("./api/Gospodarii/GetCategoriiGospodarii");

            axios.all([gospodarii, coloane, tipuriGospodarii, localitatiComponente, categoriiGospodarii]).then(
                axios.spread((responseG, responseC, responseTG, responseLC, responseCG) => {
                    let raspuns = {
                        gosp: responseG.data,
                        coloane: responseC.data,
                        tipuriGosps: responseTG.data,
                        localitatiComponente: responseLC.data,
                        categoriiGospodarii: responseCG.data
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    },
    getDateCapitol(refRegistru) {
        return new Promise((resolve, reject) => {
            const dateCapitol = axios.get("./api/Capitole/" + refRegistru + "/1/" + localStorage.getItem("siruta"));

            axios.all([dateCapitol]).then(
                axios.spread((responseDC) => {

                    let raspuns = {
                        capitole: responseDC.data,
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    }
};