import React, { Component, Fragment } from 'react';
import { alert } from "devextreme/ui/dialog";
import axios from 'axios';
import DataGrid, { Popup, Editing, Column, Paging, RequiredRule, RangeRule, ValidationRule } from 'devextreme-react/data-grid';
import { ScrollView } from 'devextreme-react';
import { navigate } from "@reach/router";
import Utilitare from './../../../../../content/Utilitare';

export default class Coloane extends Component {
    state = {
        linieSelectata: null,
        coloane: this.props.coloane
    }

    handleOnSaving = (date) => {
        if (date.changes[0].type === "insert") {
            date.changes[0].data.refListaCapitole = this.props.coloane[0].refListaCapitole;

            if (date.changes[0].data.stopVal && date.changes[0].data.stopVal < date.changes[0].data.startVal) {
                alert("Data Stop nu poate fi anterioară Datei Start.");
                return;
            }

            axios({
                data: date.changes[0].data,
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: './api/Administrare/InsertCamp',
            })
                .then((response) => {
                    if (response.data === null) {
                        alert("Coloana nu a fost stearsa. (Coloane.jsx)");
                        navigate('./date-gospodarii')
                    }
                    else
                        this.setState({ coloane: response.data });
                })
                .catch((error) => {
                    Utilitare.handleEroare(error, "Coloane");
                });
        }

        if (date.changes[0].type === "remove") {
            axios({
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: './api/Administrare/DeleteCamp/' + date.changes[0].key,
            })
                .then((response) => {
                    //console.log("delete", response.data);
                    if (response.data === null) {
                        alert("Coloana nu a fost stearsa. (Coloane.jsx)");
                        navigate('./date-gospodarii');
                    }
                    else
                        this.setState({ coloane: response.data });
                })
                .catch((error) => {
                    Utilitare.handleEroare(error, "Coloane");
                });
        }
    }

    validareCustomStopVal = (date) => {
        if (parseInt(date.startVal) > parseInt(date.stopVal))
            return false;
        else
            return true;
    }

    objectsAreSame = (x, y) => {
        var objectsAreSame = true;
        for (var propertyName in x) {
            if (x[propertyName] !== y[propertyName]) {
                objectsAreSame = false;
                break;
            }
        }
        return objectsAreSame;
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (!this.objectsAreSame(nextProps.coloane, this.state.coloane) || (nextProps.coloane.length === 0 && nextProps.coloane.length !== this.state.coloane.length)) {
            this.setState({ coloane: nextProps.coloane });
        }

        return true;
    }

    render() {
        //console.log("Coloane.jsx props", this.props);
        //console.log("Coloane.jsx state", this.state);

        const headerTemplate = (columnHeader) => {
            return <div style={{ width: "100%", textAlign: "center" }}>{columnHeader.column.caption}</div>;
        }

        return (
            <Fragment>
                <ScrollView style={{ height: (window.innerHeight - 200).toString() + "px" }}>
                    <DataGrid
                        style={{ margin: "5px" }}
                        dataSource={this.state.coloane ? this.state.coloane : null}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        showBorders={true}
                        keyExpr={"idListaCampuriTabele"}
                        rowAlternationEnabled={false}
                        onRowClick={(e) => { this.setState({ linieSelectata: e.data }) }}
                        onSaving={(e) => { this.handleOnSaving(e) }}
                        selectedRowKeys={this.state.linieSelectata && this.state.linieSelectata.idListaCampuriTabele ? [this.state.linieSelectata.idListaCampuriTabele] : null}
                        onContextMenuPreparing={(e) => {
                            if (e && e.row && e.row.data)
                                this.setState({ linieSelectata: e.row.data }, () => {
                                    e.items = [{
                                        text: "Adaugă",
                                        icon: "add",
                                        onItemClick: function () {
                                            e.component.addRow();
                                        }
                                    },
                                    {
                                        text: "Şterge",
                                        icon: "trash",
                                        onItemClick: function () {
                                            e.component.deleteRow(e.row.rowIndex);
                                        }
                                    }];
                                })
                            else {
                                e.items = [{
                                    text: "Adaugă",
                                    icon: "add",
                                    onItemClick: function () {
                                        e.component.addRow();
                                    }
                                }];
                            }
                        }}
                    >
                        {(!localStorage.getItem("checkClickDreapta").replaceAll(" ", "").split(",").includes(localStorage.getItem("siruta").toString()))
                            ? <Editing
                                mode="popup"
                            >
                                <Popup
                                    showTitle={true}
                                    title="Inserare coloană"
                                />
                            </Editing>
                            : <Editing
                                //allowUpdating={true}
                                allowAdding={true}
                                allowDeleting={true}
                                useIcons={true}
                                mode="popup"
                            >
                                <Popup
                                    showTitle={true}
                                    title="Inserare coloană"
                                />
                            </Editing>
                        }
                        <Paging enabled={false} />
                        {/*exeplu coloana (nu sterge headerTemplate):*/}
                        {/*<Column dataField="idListaCampuriTabele" caption="Id" alignment='center' headerCellRender={headerTemplate} visibile={false} />*/}
                        <Column dataField="idParinte" caption="Parinte" alignment='center' headerCellRender={headerTemplate} dataType={"number"} />
                        <Column dataField="refListaCapitole" caption="RefCap" alignment='center' headerCellRender={headerTemplate} dataType={"number"} >
                            <RequiredRule />
                        </Column>
                        <Column dataField="codCamp" caption="CodCamp" alignment='center' headerCellRender={headerTemplate} dataType={"number"}>
                            <RequiredRule />
                        </Column>
                        <Column dataField="nrCol" caption="NrCol" alignment='center' headerCellRender={headerTemplate} dataType={"number"}>
                            <RequiredRule />
                        </Column>
                        <Column dataField="ordine" caption="Ordine" alignment='center' headerCellRender={headerTemplate} dataType={"number"}>
                            <RequiredRule />
                        </Column>
                        <Column dataField="tipCamp" caption="Tip Camp" alignment='center' headerCellRender={headerTemplate} dataType={"string"}>
                            <RequiredRule />
                        </Column>
                        <Column dataField="codProprietati" caption="codProprietati" alignment='center' headerCellRender={headerTemplate} dataType={"number"} >
                            <RequiredRule />
                        </Column>
                        <Column dataField="refValoriImageCombobox" caption="refVIC" alignment='center' headerCellRender={headerTemplate} dataType={"number"} />
                        <Column dataField="precizie" caption="precizie" alignment='center' headerCellRender={headerTemplate} dataType={"number"} />
                        <Column dataField="denumire" caption="Denumire" alignment='left' headerCellRender={headerTemplate} dataType={"string"}>
                            <RequiredRule />
                        </Column>
                        <Column dataField="aduna" caption="Aduna" alignment='center' headerCellRender={headerTemplate} dataType={"string"} />
                        <Column dataField="total" caption="Total" alignment='center' headerCellRender={headerTemplate} dataType={"string"} />
                        <Column dataField="destinatie" caption="Destinatie" alignment='center' headerCellRender={headerTemplate} dataType={"number"} />
                        <Column dataField="campReadOnly" caption="ReadOnly" alignment='center' headerCellRender={headerTemplate} dataType={"boolean"} >
                            <RequiredRule />
                        </Column>
                        <Column dataField="obligatoriu" caption="Obligatoriu" alignment='center' headerCellRender={headerTemplate} dataType={"boolean"} >
                            <RequiredRule />
                        </Column>
                        <Column dataField="caption" caption="Caption" alignment='center' headerCellRender={headerTemplate} dataType={"string"}>
                            <RequiredRule />
                        </Column>
                        <Column dataField="startVal" caption="Start" alignment='center' headerCellRender={headerTemplate} dataType={"number"} >
                            <RequiredRule />
                            <RangeRule min={1500} max={9999} />
                        </Column>
                        <Column dataField="stopVal" caption="Stop" alignment='center' headerCellRender={headerTemplate} dataType={"number"} >
                            <RangeRule min={1500} max={9999} />
                            <ValidationRule type={"custom"} validationCallback={(e) => { return this.validareCustomStopVal(e.data) }} message="DataStop anterioara DataStart."></ValidationRule>
                        </Column>
                    </DataGrid>
                </ScrollView>
            </Fragment>
        );
    }
}