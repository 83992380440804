/********************/
/*Meniu capitolul 1 */
/********************/
import React, { Component, Fragment } from 'react';
import { alert } from "devextreme/ui/dialog";
import { Button, DropDownButton, Popup, SelectBox } from 'devextreme-react';
import AdeverinteSablon from './AdeverinteSablon/AdeverinteSablon';
import Patrimoniu from './Patrimoniu/Patrimoniu';
import date from '../DateCapitole'; //pentru butonul Export
import datePatrimoniu from '../CapVar/DateCapVar';
import Utilitare from '../../../../../../content/Utilitare';

export default class MeniuCap1 extends Component {
    state = {
        listaDeclaratii: [],
        popupAdeverinteVizibil: false,
        popupPatrimoniuVizibil: false
    }

    componentDidMount() {
        datePatrimoniu.getApiPatrimoniu().then(r => {
            let listaDecl = this.props.listaDeclaratii.map(x => {
                let obj = { idDeclaratie: x.idDeclaratii, nrData: (x.nrDeclaratie + "/" + (new Date(x.dataDeclaratie)).toLocaleDateString("ro")) };
                return obj;
            });

            localStorage.removeItem("declaratieSelectata");

            this.setState({
                dateApi: r.date,
                listaDeclaratii: listaDecl
            })
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "MeniuCap1.jsx");
        })
    }

    trimiteLinieSelectataMeniu = (linie, gosp) => {
        this.setState({ linieSelectata: linie, gospodariaSelectata: gosp });
    }

    handleExport = (tip) => {
        alert("Se exporta fisier de tip: " + tip);
    }

    handleAdeverinteSablon = () => {
        if (this.state.gospodariaSelectata) {
            this.setState({ popupAdeverinteVizibil: true });
        }
        else {
            alert("Selectaţi un membru.");
        }
        //alert(this.state.popupAdeverinteVizibil);
    }

    handleAscundePopup = () => {
        this.setState({ popupAdeverinteVizibil: false })
    }

    handleAscundePatrimoniu = () => {
        this.setState({ popupPatrimoniuVizibil: false })
    }

    handleValidareFermier = () => {
        alert("validare fermier (face parte din interfatari)");
    }

    verificaAccesIstoric = () => {
        var ani = JSON.parse(localStorage.getItem("listaAni"));

        var anSelectat = parseInt(localStorage.getItem("anSelectat"));

        var accesIstoric = localStorage.getItem("accesIstoric");
        var siruta = localStorage.getItem("siruta");

        if (anSelectat < Math.max(...ani) && !accesIstoric.includes(siruta))
            return false;

        return true;
    }

    handleAfisarePatrimoniu = () => {
        if (!this.state.dateApi.linkApi || !this.state.dateApi.numeUtilizator
            || !this.state.dateApi.parolaUtilizator || !this.state.dateApi.tipPersoana
            || this.state.dateApi.linkApi === "" || this.state.dateApi.numeUtilizator === ""
            || this.state.dateApi.parolaUtilizator === "" || this.state.dateApi.tipPersoana !== "AplicatieExterna") {
            alert("Serviciul de interfatare cu Modulul Taxe şi Impozite Locale nu este configurat sau este configurat greşit.");
            return;
        }

        if (!this.state.linieSelectata) {
            alert("Selectati o linie din tabel")
            return;
        }
        if (!this.state.linieSelectata.codNumericPersonal) {
            alert("CNP-ul nu a fost completat");
            return;
        }
        if (this.state.linieSelectata.codNumericPersonal.toString() === ""
            || this.state.linieSelectata.codNumericPersonal.toString() === "1111111111118") {
            alert("Nu avem CNP valid.");
            this.setState({ popupPatrimoniuVizibil: false });
            return;
        }

        this.setState({ popupPatrimoniuVizibil: true });
    }

    render() {
        //console.log("meniu cap1 props", this.props);
        //console.log("meniu cap1 state", this.state);

        const popupAdeverinteSablon =
            <Popup
                visible={this.state.popupAdeverinteVizibil}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showTitle={true}
                title="Adeverinţe şablon"
                fullScreen={true}
                showCloseButton={false}
            //onHidden={this.handleAscundePopup}
            >
                <AdeverinteSablon
                    ascunde={this.handleAscundePopup}
                    idGospSelectata={this.state.gospodariaSelectata ? this.state.gospodariaSelectata.idRegistrulAgricol : null}
                    membruSelectat={this.state.linieSelectata}
                    idPrimarie={this.state.gospodariaSelectata ? this.state.gospodariaSelectata.refDatePrimarie : null}
                />
            </Popup>;

        const popupPatrimoniu =
            <Popup
                visible={this.state.popupPatrimoniuVizibil}
                height={"80%"}
                width={"80%"}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showTitle={true}
                title={"Patrimoniu terenuri " + (this.state.linieSelectata && this.state.linieSelectata.numeleSiPrenumele ? this.state.linieSelectata.numeleSiPrenumele : "")}
                //fullScreen={true}
                showCloseButton={false}
            //onHidden={this.handleAscundePopup}
            >
                <Patrimoniu
                    ascunde={this.handleAscundePatrimoniu}
                    cnp={this.state.linieSelectata && this.state.linieSelectata.codNumericPersonal ? this.state.linieSelectata.codNumericPersonal : null}
                    dateApi={this.state.dateApi ? this.state.dateApi : null}
                    numeleSiPrenumele={this.state.linieSelectata && this.state.linieSelectata.numeleSiPrenumele ? this.state.linieSelectata.numeleSiPrenumele : null}
                    refRegistru={this.state.linieSelectata && this.state.linieSelectata.refRegistru ? this.state.linieSelectata.refRegistru : null}
                />
            </Popup>;

        return (
            <Fragment>
                {this.state.popupAdeverinteVizibil ? popupAdeverinteSablon : null}
                {this.state.popupPatrimoniuVizibil ? popupPatrimoniu : null}

                <p style={{ display: "inline-block", position: "relative", top: "1px" }}>Se modifică conform declaraţiei</p>
                <SelectBox
                    style={{ display: "inline-block", marginLeft: "5px", position: "relative", top: "1px" }}
                    height={26}
                    dataSource={this.state.listaDeclaratii}
                    displayExpr="nrData"
                    valueExpr="idDeclaratie"
                    showClearButton={true}
                    onValueChanged={(e) => { localStorage.setItem("declaratieSelectata", e.value) }}
                />

                <DropDownButton
                    text="Export"
                    icon="save"
                    stylingMode="outlined"
                    type="default"
                    dropDownOptions={{ width: 230 }}
                    items={date.getExport()}
                    onItemClick={(e) => { this.handleExport(e.itemData) }}
                    visible={false}
                />
                <Button
                    style={{ marginLeft: "5px" }}
                    stylingMode="outlined"
                    type="default"
                    text="Afişează patrimoniu"
                    onClick={this.handleAfisarePatrimoniu}
                />
                <Button
                    style={{ marginLeft: "5px" }}
                    stylingMode="outlined"
                    text="Validare fermier"
                    onClick={this.handleValidareFermier}
                    visible={false}
                />
                <Button
                    style={{ display: "inline-block", marginLeft: "5px" }}
                    width={120}
                    text="Şterge capitol"
                    type="danger"
                    stylingMode="outlined"
                    onClick={this.props.stergeCapitol}
                    visible={localStorage.getItem("role") === 'UtilizatorExtern' || this.verificaAccesIstoric() === false
                        ? localStorage.getItem("role") === "Administrator" ? true : false
                        : true}
                />
                <Button
                    style={{ marginLeft: "5px" }}
                    stylingMode="outlined"
                    text="Adeverinţe şablon"
                    onClick={this.handleAdeverinteSablon}
                    visible={localStorage.getItem("role") === 'UtilizatorExtern' || this.verificaAccesIstoric() === false
                        ? localStorage.getItem("role") === "Administrator" ? true : false
                        : true}
                />
                <Button
                    style={{ marginLeft: "5px" }}
                    stylingMode="outlined"
                    type="danger"
                    text="Inapoi"
                    onClick={this.props.inapoi}
                />
            </Fragment >
        );
    }
}