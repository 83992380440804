import React, { Component } from "react";
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import { navigate } from "@reach/router"
import { SelectBox, Button, CheckBox } from 'devextreme-react';
import service from "../Date";
import clase from "./MeniuSus.module.css";
import Utilitare from './../../../../../content/Utilitare';

class MeniuSus extends Component {
    state = {
        tipSelectat: this.props.tipSelectat
    }
    handleAfisare = this.props.afisare;

    componentDidMount() {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Rapoarte/GetReportServer/' + localStorage.getItem("siruta"),
        })
            .then((response) => {
                if (response.data) {
                    if (response.data == null) {
                        alert("Eroare la conexiunea cu baza de date(MeniuSus.jsx)");
                    }
                    else
                        this.setState({
                            reportServer: response.data.reportServer,
                            iduser: response.data.iduser
                        });
                }
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "MeniuSus");
            });
    }

    handleDelogare = () => {
        axios.post('/api/Account/LogOut', {})
            .then((response) => {
                localStorage.clear();
                navigate(`./`);
            }, (error) => {
                alert("A aparut o problema la delogare. Contactati administratorul.");
            });
    }

    handleSituatieGF = () => {
        if (!this.props.gospodariaSelectata) {
            alert("Nu aţi selectat o gospodărie.");
            return;
        }

        var d = new Date();

        var zi = d.getDate().toString();
        if (zi.length < 2)
            zi = '0' + zi;

        var luna = (d.getMonth() + 1).toString();
        if (luna.length < 2)
            luna = '0' + luna;

        var dataAzi = zi + "." + luna + "." + d.getFullYear();

        if (parseInt(localStorage.getItem("anSelectat")) < d.getFullYear()) {
            dataAzi = zi + "." + luna + "." + localStorage.getItem("anSelectat");
        }

        var URLtot = this.state.reportServer+'Cap_Tot'+'&rs:Command=Render&HouseID='+this.props.gospodariaSelectata.idRegistrulAgricol+'&LaData='+dataAzi+'&UatID='+this.props.gospodariaSelectata.refDatePrimarie;
        console.log("urlTot = ", URLtot);

        window.open(URLtot, '_blank', 'noopener');

        this.handleScrieJurnalOperare();
    }

    handleScrieJurnalOperare = () => {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/Rapoarte/PostAdeverinteJurnalOperare/' + localStorage.getItem("siruta") + '/' + this.props.gospodariaSelectata.idRegistrulAgricol + '/' + ' ExtrasRegistrulAgricol ' + '/' + 0,
        })
            .then((response) => {
                if (response.data == false)
                    console.log("S-a produs o eroare la scrierea adeverintei in JurnalOperare");
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "AdeverinteTip");
            });
    }

    render() {
        //console.log("Meniu sus state", this.state);

        return (
            <div className={clase.Container} style={{ marginTop: '5px', border: 'none' }}>
                <div>
                    <SelectBox
                        className={clase.Drop}
                        style={{ marginLeft: "5px" }}
                        width={"auto"}
                        dataSource={this.props.tipuriGospodarii}
                        displayExpr={"nume"}
                        valueExpr={"idTipuriGospodarii"}
                        defaultValue={localStorage.getItem("role") === "UtilizatorExtern" ? 0 : 1}
                        onOpened={(e) => { e.component._popup.option('width', 300); }}
                        onSelectionChanged={(e) => { this.props.selecteazaTip(e.selectedItem.tip); }}
                        value={localStorage.getItem("role") === "UtilizatorExtern" ? 0 : this.props.tipSelectat}
                    />
                    {localStorage.getItem("role") === "UtilizatorExtern" ?
                        null :
                        <Button
                            className={clase.Buton}
                            text='Situaţie gospodărie/firmă selectată'
                            onClick={this.handleSituatieGF} />
                    }
                    <p style={{ display: "inline-block" }}>{this.props.numarGospodarii} gospodării <span style={{ color: "red" }}>({this.props.numarGospodariiInchise} închise)</span></p>
                </div>
                <div style={{ fontWeight: "bold", fontSize: "15px" }}>{localStorage.getItem("numePrimarie")}</div>
                <div >
                    <div style={localStorage.getItem("role") === "UtilizatorExtern" ? { display: "none" } : { display: "inline-block", position: "relative", top: "-2px", marginLeft: "5px" }}>
                        <CheckBox
                            defaultValue={false}
                            text={"Utilizator"}
                            onValueChanged={(e) => { this.props.filtrareUtilizator(e.value) }} />
                    </div>
                    <Button
                        className={clase.Buton}
                        text='Căutare avansată'
                        onClick={() => this.handleAfisare("Cautare")}
                        visible={localStorage.getItem("role") === "UtilizatorExtern" ? false : true}
                    />
                    <Button
                        className={clase.Buton}
                        text='Adeverinţe tip'
                        onClick={() => { this.props.gospodariaSelectata ? this.handleAfisare("Adeverinte") : alert("Selectează o linie din tabel") }}
                        visible={localStorage.getItem("role") === "UtilizatorExtern" ? false : true}
                    />
                    {/*<Button
                        className={clase.Buton}
                        text='Dosar'
                        onClick={() => { this.props.gospodariaSelectata ? this.handleAfisare("Dosar") : alert("Selectează o linie din tabel") }} />*/}
                    <SelectBox
                        id='select3'
                        className={clase.Drop}
                        style={{ margin: "0 5px 0 5px", position: "relative", top: "2px" }}
                        width={70}
                        dataSource={service.getAni()}
                        defaultValue={this.props.anSelectat ? this.props.anSelectat : null}
                        onSelectionChanged={(e) => { this.props.selecteazaAn(e.selectedItem) }}
                    />
                    <Button
                        style={{ marginRight: "5px" }}
                        text={"Delogare " + localStorage.getItem("userName")}
                        type="danger"
                        stylingMode="outlined"
                        onClick={() => { this.handleDelogare(); this.props.handleDelogare(); }}
                    />
                </div>
            </div >
        );
    }
}

export default MeniuSus;