import React, { Component, Fragment } from 'react';
import { alert } from "devextreme/ui/dialog";
import axios from 'axios';
import { Button, SelectBox, TextBox, ScrollView } from 'devextreme-react';
import DataGrid, { Paging, FilterRow, FilterPanel, Selection, Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import clase from './UnificareGospodarie.module.css';
import service from './DateUnificare';
import { navigate } from '@reach/router';
import Utilitare from './../../../../content/Utilitare';

export default class UnificareGospodarie extends Component {
    state = {
        tipTransfer: 1,
        dataTransfer: new Date(Date.now()),
        idTipSelectat: null,
        idGospSelectat: null,
        capGosp: null,
        membruSelectat1: null,
        membruSelectat2: null,
        tipReprezentant: "Reprezentant din familie",
        idGosp1: null,
        idGosp2: null
    }

    stil = {
        width: '98%',
        height: 'calc(100vh - 57px)',
        margin: 'auto',
        border: '1px solid #ddd',
        padding: '5px',
        overflow: "hidden"
    };

    componentDidMount() {
        let siruta = localStorage.getItem("siruta") ? localStorage.getItem("siruta").replaceAll("\"", "") : null;
        let tip = 0;
        let an = parseInt(localStorage.getItem("anSelectat"));

        service.getListaGospodarii(siruta, tip, an).then(r => {
            var volum = Math.max(...r.gosp.map(x => { return x.volum }));
            var pozitie = Math.max(...r.gosp.filter(y => y.volum === Math.max(...r.gosp.map(x => { return x.volum }))).map(x => { return x.pozitie })) + 1;

            this.setState({ volum: volum, pozitie: pozitie });

            this.setState({
                dateGospodarii: r.gosp,
                numarGospodarii: r.gosp.length,
                listaColoane: r.coloane,
                tipuriGospodarii: r.tipuriGosps.filter(x => [0, 1, 2].includes(x.tip)),
                localitatiComponente: r.localitatiComponente,
                categoriiGospodarii: r.categoriiGospodarii ? r.categoriiGospodarii.filter(x => x.tip === "PF") : null
            })

        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "UnificareGospodarie");
        })
    }

    handleOnRowClick = (rand) => {
        this.setState({ linieSelectata: rand });

        service.getDateCapitol(rand.idRegistrulAgricol).then(d => {
            this.setState({
                membri: d.capitole.randuri
            })
        }).catch(error => {
            Utilitare.handleEroare(error, "UnificareGospodarie");
        })
    }

    handleSalveaza = () => {
        if (!this.state.membruSelectat1
            || !this.state.membruSelectat2
            || !this.state.membruSelectat1.refRegistru
            || !this.state.membruSelectat2.refRegistru) {
            alert("Nu aţi selectat ambele gospodării.");
            return;
        }

        if (!this.state.volum || this.state.volum === "") {
            alert("Nu aţi completat volumul.");
            return;
        }

        if (!this.state.pozitie || this.state.pozitie === "") {
            alert("Nu aţi completat poziţia.");
            return;
        }

        if (this.state.idGosp1 === this.state.idGosp2) {
            alert("Nu puteţi unifica aceeaşi gospodărie.");
            return;
        }

        axios({
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/Gospodarii/UnificareCapitoleGospodarii/' + localStorage.getItem("siruta") + "/" + this.state.idGosp1 + "/" + this.state.idGosp2 + "/" + this.state.volum + "/" + this.state.pozitie,
        }).then((response) => {
            alert("A fost adăugată gospodăria " + this.state.membruSelectat1.numeleSiPrenumele);
            navigate("./date-gospodarii")
        }).catch((error) => {
            Utilitare.handleEroare(error, "UnificareGospodarie");
        });
    }

    handleVolum = (vol) => {
        var poz = this.state.dateGospodarii ? Math.max(...this.state.dateGospodarii.filter(y => y.volum.toString() === vol.toString()).map(x => { return x.pozitie })) + 1 : 0;

        if (poz < 0 || poz > 500000)
            poz = 0;

        this.setState({ volum: vol, pozitie: poz });
    }

    handleLocalitate = (idLoc) => {
        var siruta = this.state.localitatiComponente ? this.state.localitatiComponente.find(x => x.idNomenclatorUAT === idLoc).siruta : null;
        this.setState({ localitateSelectata: idLoc, sirutaSelectata: siruta });
    }

    handleSelectieTip = (item) => {
        this.setState({ tipSelectat: item.tip });
    }

    handleRep1 = () => {
        //pune PRIMUL membru care are codRudenie = 1
        const membru = this.state.membri ? this.state.membri.filter(x => x.codRudenie === 1)[0] : null;
        if (!membru) {
            alert("Gospodăria nu are Reprezentant legal.");
            return;
        }
        var idGosp1 = membru ? membru.refRegistru : "";

        var gosp1 = this.state.dateGospodarii ? this.state.dateGospodarii.find(x => x.idRegistrulAgricol === idGosp1) : null;

        var denumireLoc = gosp1 ? gosp1.localitate : null;

        var idLoc = denumireLoc && this.state.localitatiComponente ? this.state.localitatiComponente.find(x => x.denumireLocalitate === denumireLoc).idNomenclatorUAT : null;

        var sirutaSelectata = this.state.localitatiComponente && idLoc ? this.state.localitatiComponente.find(x => x.idNomenclatorUAT === idLoc).siruta : null;

        this.setState({ membruSelectat1: membru, idGosp1: idGosp1, localitateSelectata: idLoc, gospodarie1: gosp1, sirutaSelectata: sirutaSelectata });
    }

    handleRep2 = () => {
        if (!this.state.membruSelectat1 || !this.state.membruSelectat1.refRegistru) {
            alert("Nu puteţi unifica o singură gospodărie.");
            return;
        }

        //pune PRIMUL membru care are codRudenie = 1
        const membru = this.state.membri ? this.state.membri.filter(x => x.codRudenie === 1)[0] : null;
        if (!membru) {
            alert("Gospodăria nu are Reprezentant legal.");
            return;
        }
        if (membru.refRegistru === this.state.membruSelectat1.refRegistru) {
            alert("Nu puteţi unifica aceeasi gospodărie.");
            return;
        }

        this.setState({ membruSelectat2: membru, idGosp2: membru ? membru.refRegistru : "" });
    }

    render() {
        //console.log("unificareGospodarie props", this.props);
        //console.log("unificareGospodarie state", this.state);

        let gospodarii = this.state.dateGospodarii ? this.state.dateGospodarii.filter(x => x.tip === 1 || x.tip === 2) : null;
        if (this.state.tipSelectat > 0) {
            gospodarii = this.state.dateGospodarii ? this.state.dateGospodarii.filter(x => x.tip === this.state.tipSelectat) : null;
        }

        const headerTemplate = (columnHeader) => {
            return <p style={{ width: "100%", textAlign: "center" }}>{columnHeader.column.caption}</p>;
        }

        return (
            <Fragment>
                <ScrollView scrollByContent={true} style={this.stil}>
                    <div style={{ width: "30%", display: "inline-block" }}>
                        <h3 style={{ textAlign: "center" }}>Unificare gospodarie</h3>
                        <table width={"100%"}>
                            <tbody>
                                <tr>
                                    <td>
                                        <TextBox
                                            id="reprezentant"
                                            width={"100%"}
                                            className={clase.Margini}
                                            value={!this.state.membruSelectat1 ? "" : this.state.membruSelectat1.numeleSiPrenumele}
                                            placeholder="Reprezentant legal prima gospodărie..."
                                            disabled={true}
                                        />
                                    </td>
                                    <td>
                                        <Button
                                            style={{ position: "relative", top: "-5px" }}
                                            icon="arrowleft"
                                            type="normal"
                                            stylingMode="outlined"
                                            onClick={this.handleRep1}
                                        />
                                    </td>
                                </tr>
                                <tr width="10%">
                                    <td>
                                        <TextBox
                                            id="reprezentant"
                                            width={"100%"}
                                            className={clase.Margini}
                                            value={!this.state.membruSelectat2 ? "" : this.state.membruSelectat2.numeleSiPrenumele}
                                            placeholder="Reprezentant legal a doua gospodărie..."
                                            disabled={true}
                                        />
                                    </td>
                                    <td>
                                        <Button
                                            style={{ position: "relative", top: "-5px" }}
                                            icon="arrowleft"
                                            type="normal"
                                            stylingMode="outlined"
                                            onClick={this.handleRep2}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 style={{ textAlign: "center" }}>Gospodarie noua</h3>
                        <table width={"100%"}>
                            <tbody>
                                <tr>
                                    <td>
                                        <TextBox
                                            id="reprezentantNou"
                                            width={"100%"}
                                            className={clase.Margini}
                                            value={!this.state.membruSelectat1 ? "" : this.state.membruSelectat1.numeleSiPrenumele.toString()}
                                            placeholder="Noul reprezentant legal..."
                                            disabled={true}
                                        />
                                        <TextBox
                                            id="cnp"
                                            width={"100%"}
                                            className={clase.Margini}
                                            value={!this.state.membruSelectat1 ? "" : this.state.membruSelectat1.codNumericPersonal.toString()}
                                            placeholder="CNP..."
                                            disabled={true}
                                        />
                                        <TextBox
                                            width={"100%"}
                                            id="nrDosar"
                                            className={clase.Margini}
                                            placeholder="Nr. dosar..."
                                            value={this.state.gospodarie1 ? this.state.gospodarie1.numarDosar : null}
                                            onValueChange={(e) => { this.handleValoriNoiGosp(e, "numarDosar") }}
                                            disabled={true}
                                        />
                                    </td>
                                    <td>
                                        <TextBox
                                            id="cui"
                                            width={"100%"}
                                            className={clase.Margini}
                                            placeholder="Cod unic de identificare..."
                                            onValueChange={(e) => { this.handleValoriNoiGosp(e, "codUnicIdentificare") }}
                                            disabled={true}
                                        />
                                        <TextBox
                                            id="cnp"
                                            width={"100%"}
                                            className={clase.Margini}
                                            placeholder="Cod exploataţie..."
                                            value={this.state.gospodarie1 ? this.state.gospodarie1.codUnicGospodarieExploatatie : null}
                                            onValueChange={(e) => { this.handleValoriNoiGosp(e, "codUnicGospodarieExploatatie") }}
                                            disabled={true}
                                        />
                                        <TextBox
                                            width={"100%"}
                                            id="nrDosar"
                                            className={clase.Margini}
                                            placeholder="Nr. unic de identificare..."
                                            onValueChange={(e) => { this.handleValoriNoiGosp(e, "codUnicIdentificare") }}
                                            disabled={true}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table width={"100%"}>
                            <tbody>
                                <tr>
                                    <td>
                                        <SelectBox
                                            style={{ position: "relative", top: "-4px" }}
                                            width={"100%"}
                                            //items={["G", "EAI", "PFA", "II", "IF"]}
                                            dataSource={this.state.categoriiGospodarii ? this.state.categoriiGospodarii : null}
                                            valueExpr={"idCategoriiPF_PJ"}
                                            displayExpr={"denumire"}
                                            value={this.state.gospodarie1 ? this.state.gospodarie1.refCategoriiPF_PJ : null}
                                            placeholder="Tip UE..."
                                            onOpened={(e) => { e.component._popup.option('width', 300); }}
                                            onValueChange={(e) => { this.handleValoriNoiGosp(e, "refCategoriiPF_PJ") }}
                                            disabled={true}
                                        />
                                    </td>
                                    <td>
                                        <SelectBox
                                            style={{ position: "relative", top: "-4px" }}
                                            width={"100%"}
                                            //items={[1, 2]}
                                            dataSource={this.state.tipuriGospodarii ? this.state.tipuriGospodarii : null}
                                            valueExpr={"tip"}
                                            displayExpr={"nume"}
                                            defaultValue={1}
                                            value={this.state.gospodarie1 ? this.state.gospodarie1.tip : null}
                                            placeholder="Tip..."
                                            onOpened={(e) => { e.component._popup.option('width', 300); }}
                                            onValueChange={(e) => { this.handleValoriNoiGosp(e, "tip") }}
                                            disabled={true}
                                        />
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="volum"
                                            className={clase.Margini}
                                            placeholder="Volum..."
                                            value={this.state.volum ? this.state.volum.toString() : null}
                                            onValueChange={(e) => this.handleVolum(e)}
                                        />
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="pozitie"
                                            className={clase.Margini}
                                            placeholder="Poziţie..."
                                            value={this.state.pozitie ? this.state.pozitie.toString() : null}
                                            onValueChange={(e) => { this.setState({ pozitie: e }); }}
                                        />
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="pagina"
                                            className={clase.Margini}
                                            placeholder="Pagina..."
                                            onValueChange={(e) => this.handleValoriNoiGosp(e, "pagina")}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 style={{ width: "100%", textAlign: "center" }}> Adresa</h3>
                        <table width={"100%"} >
                            <tbody>
                                <tr>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="judet"
                                            className={clase.Margini}
                                            value={this.state.localitatiComponente ? this.state.localitatiComponente[0].denumireJudet : null}
                                            placeholder="Judetul..."
                                            disabled={true}
                                        />
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="localitatea"
                                            className={clase.Margini}
                                            value={this.state.gospodarie1 ? this.state.gospodarie1.localitate : null}
                                            placeholder="Localitate..."
                                            disabled={true}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="siruta"
                                            className={clase.Margini}
                                            value={this.state.sirutaSelectata ? this.state.sirutaSelectata.toString() : null}
                                            placeholder="Siruta..."
                                            disabled={true}
                                        />
                                    </td>
                                    <td>
                                        <TextBox
                                            width={"100%"}
                                            id="codApia"
                                            className={clase.Margini}
                                            placeholder="Cod Apia..."
                                            value={this.state.gospodarie1 ? this.state.gospodarie1.codApia : null}
                                            onValueChange={(e) => this.handleValoriNoiAdresa(e, "codPostal")}
                                            disabled={true}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className={clase.Butoane}>
                            <Button
                                text="Unifică"
                                type="success"
                                stylingMode="outlined"
                                onClick={this.handleSalveaza}
                            />
                        </div>
                    </div>

                    <div style={{ width: "68%", display: "inline-block", float: "right" }}>
                        <SelectBox
                            className={clase.Margini}
                            dataSource={this.state.tipuriGospodarii ? this.state.tipuriGospodarii.filter(x => [0, 1, 2].indexOf(x.tip) >= 0) : null}
                            valueExpr="tip"
                            displayExpr="nume"
                            defaultValue={0}
                            onSelectionChanged={(e) => this.handleSelectieTip(e.selectedItem)}
                        />
                        <DataGrid
                            className={clase.Margini}
                            height={"auto"}
                            dataSource={this.state.idGosp1 ? gospodarii.filter(x => x.idRegistrulAgricol != this.state.idGosp1) : gospodarii}
                            columnAutoWidth={true}
                            allowColumnReordering={true}
                            showBorders={true}
                            focusedRowEnabled={false}
                            keyExpr={"idRegistrulAgricol"}
                            rowAlternationEnabled={false}
                            onInitialized={(e) => { this.datagrid = e.component; }}
                            onRowClick={(e) => { this.handleOnRowClick(e.data); }}
                        >
                            <Summary>
                                <TotalItem column="idGosp" summaryType="count" customizeText={(e) => { return "Nr. gospodarii selectate: " + e.value }} />
                            </Summary>
                            <Selection mode="single" deferred={false} />
                            <FilterRow visible={true}
                                applyFilter={true} />
                            <FilterPanel visible={true} />
                            <Paging defaultPageSize={5} />
                            {this.state.listaColoane ? this.state.listaColoane.map((col) =>
                                <Column
                                    key={col.idColoaneGridView}
                                    dataField={col.denumireCamp}
                                    caption={col.denumire}
                                    headerCellRender={headerTemplate}
                                    width={col.lungime + '%'}
                                    visible={col.vizibil}
                                />
                            ) : null}
                        </DataGrid>

                        <DataGrid
                            style={{ width: '30%', margin: "auto" }}
                            dataSource={this.state.membri}
                            focusedRowKey={"id"}
                            rowAlternationEnabled={false}
                        //onRowClick={(e) => { this.handleClickMembru(e.data) }}
                        >
                            <Selection mode="single" deferred={false} />
                            <Column dataField="idUnic" headerCellRender={headerTemplate} width={"50px"} title={"idUnic"} visible={false} />
                            <Column dataField="numeleSiPrenumele" caption="Nume" headerCellRender={headerTemplate} />
                            <Column dataField="codRudenie" caption="Cod rud" headerCellRender={headerTemplate} width={'30%'} />
                        </DataGrid>
                    </div>
                </ScrollView>
            </Fragment>
        )
    }
}