import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import DataGrid, { Selection, Column } from 'devextreme-react/data-grid';
import { Button, SelectBox, ScrollView } from 'devextreme-react';
import clase from '../RapoarteCentralizatoare/RapoarteCentralizatoare.module.css';
//import service from './date';
import Utilitare from './../../../../content/Utilitare';

export default class SituatieGF extends Component {
    state = {
        raportSelectat: null,
        listaSituatiiGF: []
    }

    stil = {
        width: '98%',
        height: 'calc(100vh - 57px)',
        //height: (window.innerHeight - 57).toString() + "px",
        margin: 'auto',
        border: '1px solid #ddd',
        textAlign: "center",
        overflow: "hidden"
    };

    componentDidMount() {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Rapoarte/GetSituatiiGF/' + localStorage.getItem("siruta"),
        })
            .then((response) => {
                if (response.data) {
                    this.setState({
                        idPrim: response.data.idPrim,
                        listaSituatiiGF: response.data.listaRapoarte,
                        iduser: response.data.iduser,
                        reportServer: response.data.reportServer,
                        listaAni: JSON.parse(localStorage.getItem("listaAni")),
                        anSelectat: JSON.parse(localStorage.getItem("anSelectat"))
                    });
                    //alert("Datele au fost salvate.");
                }
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "SituatieGF");
            });
    }

    handleClickRaport = (raport) => {
        this.setState({ raportSelectat: raport });
    }

    handleAn = (an) => {
        this.setState({ anSelectat: an });
    }

    handleAfisare = () => {
        if (!this.state.raportSelectat.valPr)
            alert("Raportul nu poate fi afişat pentru perioada selectată.");
        else {
            window.open(this.state.reportServer + this.state.raportSelectat.valPr + '&rs:Command=Render&idprim=' + this.state.idPrim +
                '&Parametru_TipG=' + 1 +
                '&an=' + this.state.anSelectat //+
                //'&Parametru_IDRegistrul=' + this.props.idGospSelectata
                , '_blank', 'noopener');
        }
    }

    render() {
        //console.log("situatieGF props", this.props);
        //console.log("situatieGF state", this.state);

        const headerTemplate = (columnHeader) => {
            return <p style={{ width: "100%", textAlign: "center" }}>{columnHeader.column.caption}</p>;
        }

        return (
            <Fragment>
                <div style={this.stil} id='chenar'>
                    <ScrollView height="100%">
                        <div>
                            <h3 style={{ width: "100%", textAlign: "center" }}> Situaţie gospodării/firme cu selecţie după volum </h3>
                            <SelectBox
                                id='select3'
                                className={clase.Drop}
                                style={{ marginRight: "3px" }}
                                width={70}
                                dataSource={this.state.listaAni ? this.state.listaAni : null}
                                value={this.state.anSelectat ? this.state.anSelectat : null}
                                onSelectionChanged={(e) => { this.handleAn(e.selectedItem) }}
                            />
                        </div>

                        <DataGrid
                            //style={{ width: '30%', margin: "auto" }}
                            keyExpr="valoare"
                            dataSource={this.state.listaSituatiiGF ? this.state.listaSituatiiGF : null}
                            //focusedRowKey={"valoare"}
                            focusedRowEnabled={false}
                            rowAlternationEnabled={false}
                            showBorders={true}
                            onRowClick={(e) => { this.handleClickRaport(e.data) }}
                            onRowDblClick={(e) => { this.handleAfisare() }}
                            selectedRowKeys={this.state.raportSelectat && this.state.raportSelectat.valoare ? [this.state.raportSelectat.valoare] : null}
                            onContextMenuPreparing={(e) => {
                                if (e && e.row && e.row.data)
                                    if (e.row.rowType === "data")
                                        this.setState({ raportSelectat: e.row.data }, () => {

                                            e.items = [{
                                                text: "Afişează",
                                                icon: "file",
                                                onItemClick: (e) => { this.handleAfisare() }
                                            }];
                                        })
                                    else
                                        this.setState({ raportSelectat: e.row.data }, () => {
                                            this.handleClickRaport(e.row.data);

                                            if (e.row.rowType === "data") {
                                                e.items = [];
                                            }
                                        })
                            }}
                        >
                            <Selection mode="single" deferred={false} />
                            <Column dataField="valoare" headerCellRender={headerTemplate} width={"50px"} caption={"Nr."} />
                            <Column dataField="denumire" caption="Nume raport" headerCellRender={headerTemplate} />
                            {(!localStorage.getItem("checkClickDreapta").replaceAll(" ", "").split(",").includes(localStorage.getItem("siruta").toString()))
                                ? null
                                : <Column
                                    //coloana de butoane customizabile
                                    type="buttons"
                                    width={110}
                                    buttons={[
                                        {
                                            hint: 'Afişare',
                                            icon: 'file',
                                            visible: true,
                                            onClick: (e) => { this.handleAfisare() }
                                        }
                                    ]}
                                />
                            }
                        </DataGrid>

                        <Button
                            style={{ display: "inline-block", marginTop: "10px" }}
                            text="Afiseaza situatie"
                            type="success"
                            stylingMode="outlined"
                            onClick={this.handleAfisare}
                            visible={false}
                        />
                    </ScrollView>
                </div>
            </Fragment >
        );
    }
}