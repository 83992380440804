import axios from 'axios';

export default {
    getDateApi() {
        return new Promise((resolve, reject) => {

            const dateApi = axios.get("./api/DatePrimarie/GetApiTaxe/" + parseInt(localStorage.getItem("siruta")));

            axios.all([dateApi]).then(
                axios.spread((responseDA) => {
                    let raspuns = {
                        dateApi: responseDA.data
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    }
}