import axios from "axios";
import { navigate } from "@reach/router";

export default {

    loginToken(url, loginData) {

        axios.post(url, loginData)
            .then((response) => {
                //console.log("Acasa.jsx", response);
                if (response === undefined) {
                    alert("Nume utilizator sau parola introduse eronat.");
                    return;
                }
                //linia urmatoare adauga implicit tokenul la toate requesturile de dupa autentificare
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;

                localStorage.setItem('userName', response.data.username);
                localStorage.setItem('role', response.data.role);
                localStorage.setItem('numePrimarie', response.data.numePrimarie);
                localStorage.setItem('siruta', response.data.siruta.toString());
                localStorage.setItem('listaAni', JSON.stringify(response.data.listaAni));
                localStorage.setItem('autok', response.data.accessToken);
                localStorage.setItem('check2a', response.data.check2a);
                localStorage.setItem('accesIstoric', response.data.accesIstoric);
                localStorage.setItem('checkClickDreapta', response.data.checkClickDreapta);
                localStorage.setItem('checkAlteGospodarii', response.data.checkAlteGospodarii);
                //this.props.context.setState({ loggedIn: true }, () => { navigate('./date-gospodarii');});

                //window.setTimeout(() => { navigate('./date-gospodarii'); }, 1000);
                window.location = './date-gospodarii';
            }, (error) => {
                console.log("Eroare Acasa.jsx", error);
                alert("A aparut o problema la logare. Contactati administratorul.");
                navigate(`./`);
                //console.log(error);
            })
            .catch((e) => {
                console.log("Eroare Acasa.jsx handleAutentificare", e)
            });

    },

    handleEroare(error, pagina) {
        if (error && error.response && error.response.status !== 401 && error.response.status !== 400 && error.response.status !== 403) {
            alert("S-a produs o eroare. Contactaţi administratorul.");
            console.log(error.text, pagina);
        }
    },
    isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }
        return true;
    },
    validCNP(p_cnp) {
        var i = 0, year = 0, hashResult = 0, cnp = [], hashTable = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
        if (p_cnp.length !== 13) { return false; }
        for (i = 0; i < 13; i++) {
            cnp[i] = parseInt(p_cnp.charAt(i), 10);
            if (isNaN(cnp[i])) { return false; }
            if (i < 12) { hashResult = hashResult + (cnp[i] * hashTable[i]); }
        }
        hashResult = hashResult % 11;
        if (hashResult === 10) { hashResult = 1; }
        year = (cnp[1] * 10) + cnp[2];
        switch (cnp[0]) {
            case 1: case 2: year += 1900; break;
            case 3: case 4: year += 1800; break;
            case 5: case 6: year += 2000; break;
            case 7: case 8: case 9: { year += 2000; if (year > (parseInt(new Date().getYear(), 10) - 14)) { year -= 100; } break; }
            default: return false;
        }
        if (year < 1800 || year > 2099) { return false; }
        return (cnp[12] === hashResult);
    },
    validDataDeces(data) {
        //ATENTIE!
        //formatul este zzllaaaa, fara puncte, iar data nu trebuie sa fie din an ulterior anului curent (e vorba de data deces)
        //returneaza null daca data e aiurea sau data in format yyyy.mm.dd
        var zi = parseInt(data.substring(0, 2));
        var luna = parseInt(data.substring(2, 4));
        var an = parseInt(data.substring(4, 8));

        if (luna > 12)
            return null;
        var luni = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        if ((an % 4 === 0 && an % 100 !== 0) || an % 400 === 0)
            luni = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        var ziMax = luni[luna];

        if (zi > ziMax)
            return null;
        if (an > new Date().getFullYear())
            return null;

        return "" + an + "." + luna + "." + zi;
    },
    formatareData(data) {
        var componente = data.split(".");
        var zi = componente[2];
        var luna = componente[1];
        var an = componente[0];

        if (zi < 10)
            zi = "0" + zi;
        if (luna < 10)
            luna = "0" + luna;
        var dataRaspuns = zi + "." + luna + "." + an;
        //console.log("data raspuns", dataRaspuns);
        return dataRaspuns;
    },
    getNume(nume) {
        var numele = nume.substring(0, nume.indexOf(' '))
        if (!nume)
            return null;
        return numele.trim();
    },
    getInitiala(nume) {
        var initiala = "";
        var restFaraNume = nume.substring(nume.indexOf(' '), nume.length).trim();

        if (restFaraNume.lastIndexOf(".") === 0)
            return null;

        if (restFaraNume.lastIndexOf(".") > 0) {
            initiala = restFaraNume.substring(0, restFaraNume.lastIndexOf(".") + 1).trim();
            if (initiala.length > 4)
                return null;
        }

        if (restFaraNume.lastIndexOf(".") < 0) {
            var arr = restFaraNume.split(' ');
            if (arr.length > 1) {
                if (arr[0].lenght <= 3)
                    initiala = arr[0].trim();
                else
                    return null;
            } else {
                return null;
            }
        }

        return initiala.trim();
    },
    getPrenume(nume) {
        var prenume = "";
        if (nume.indexOf(".") > 0) {
            prenume = nume.substring(nume.lastIndexOf(".") + 1, nume.length);
        } else {
            prenume = nume.substring(nume.indexOf(" ") + 1, nume.length);
        }

        return prenume.trim();
    },
    verificareIP(text) {
        var flag = true;
        var arr = text.split(".");

        if (arr.lenght !== 4)
            flag = false;

        arr.forEach(elem => {
            if (!this.isNumeric(elem))
                flag = false;

            if (parseInt(elem) < 0 || parseInt(elem) > 255)
                flag = false;
            console.log(elem);
        });

        return flag;
    },
    isNumeric(str) {
        if (typeof str != "string") return false; // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
    },
    rotunjire(num, scale) {
        if (!("" + num).includes("e")) {
            return +(Math.round(num + "e+" + scale) + "e-" + scale);
        } else {
            var arr = ("" + num).split("e");
            var sig = ""
            if (+arr[1] + scale > 0) {
                sig = "+";
            }
            return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
        }
    }
}