import axios from 'axios';

export default {
    getListaGospodarii(siruta, tip, an) {//tip este tipul de grid din tabela ColoaneGridView
        return new Promise((resolve, reject) => {
            var tipGosp = 0;
            if (tip > 4)//daca e vorba de alt grid de gospodarii
                tipGosp = 0;
            else tipGosp = tip;

            const gospodarii = axios.get("./api/Gospodarii/GetGospodarii/",
                {
                    params: {
                        siruta: siruta,
                        tip: tipGosp,
                        an: an
                    }
                });

            const coloane = axios.get("./api/GridView/getColoane/",
                {
                    params: {
                        tip: tip
                    }
                });

            const tipuriTransfer = axios.get("./api/capitole/GetTipuriTransfer")

            const tipuriCatFol = axios.get("./api/capitole/GetTipuriCategoriiFolosinta");

            axios.all([gospodarii, coloane, tipuriTransfer, tipuriCatFol]).then(
                axios.spread((responseG, responseC, responseTT, responseCF) => {
                    let raspuns = {
                        gosp: responseG.data,
                        cols: responseC.data,
                        tipuriTransfer: responseTT.data,
                        tipuriCatFol: responseCF.data
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    }
}