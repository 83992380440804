import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import DataGrid, { Popup, Editing, Column, Paging, ValidationRule, RequiredRule, RangeRule } from 'devextreme-react/data-grid';
import { ScrollView } from 'devextreme-react';
import { navigate } from "@reach/router";
import Utilitare from './../../../../../content/Utilitare';

export default class Randuri extends Component {
    state = {
        linieSelectata: null,
        randuri: this.props.randuri
    };

    handleOnSaving = (date) => {
        if (this.props.validareRanduri(date.changes[0].data.refListaCapitole, date.changes[0].data.refListaRanduriTabele) === false) {
            return;
        }

        if (date.changes[0].type === "insert") {
            date.changes[0].data.refCap = this.props.randuri[0].refListaCapitole;

            if (date.changes[0].data.stopVal && date.changes[0].data.stopVal < date.changes[0].data.startVal) {
                alert("Data Stop nu poate fi anterioară Datei Start.");
                return;
            }

            axios({
                data: date.changes[0].data,
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: './api/Administrare/InsertRand',
            })
                .then((response) => {
                    if (response.data === null) {
                        alert("Randul nu a fost inserat. (Randuri.jsx)");
                        navigate('./date-gospodarii');
                    }
                    else
                        this.setState({ randuri: response.data });
                })
                .catch((error) => {
                    Utilitare.handleEroare(error, "Randuri");
                });
        }

        if (date.changes[0].type === "remove") {
            axios({
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: './api/Administrare/DeleteRand/' + date.changes[0].key,
            })
                .then((response) => {
                    if (response.data === null) {
                        alert("Rândul nu a fost şters. (Randuri.jsx)");
                        navigate('./date-gospodarii');
                    }
                    else
                        this.setState({ randuri: response.data });
                })
                .catch((error) => {
                    Utilitare.handleEroare(error, "Randuri");
                });
        }
    }

    validareCustomStopVal = (date) => {
        if (parseInt(date.startVal) > parseInt(date.stopVal))
            return false;
        else
            return true;
    }

    objectsAreSame = (x, y) => {
        var objectsAreSame = true;
        for (var propertyName in x) {
            if (x[propertyName] !== y[propertyName]) {
                objectsAreSame = false;
                break;
            }
        }
        return objectsAreSame;
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (!this.objectsAreSame(nextProps.randuri, this.state.randuri)) {
            this.setState({ randuri: nextProps.randuri });
        }

        return true;
    }

    render() {
        //console.log("Randuri.jsx props", this.props);
        //console.log("Randuri.jsx state", this.state);

        const headerTemplate = (columnHeader) => {
            return <div style={{ width: "100%", textAlign: "center" }}>{columnHeader.column.caption}</div>;
        }

        return (
            <Fragment>
                <ScrollView style={{ height: (window.innerHeight - 200).toString() + "px" }}>
                    <DataGrid
                        style={{ margin: "5px" }}
                        dataSource={this.props.randuri ? this.props.randuri : null}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        showBorders={true}
                        keyExpr={"idListaRanduriTabele"}
                        rowAlternationEnabled={false}
                        onRowClick={(e) => { this.setState({ linieSelectata: e.data }) }}
                        onSaving={(e) => { this.handleOnSaving(e) }}
                        selectedRowKeys={this.state.linieSelectata && this.state.linieSelectata.idListaRanduriTabele ? [this.state.linieSelectata.idListaRanduriTabele] : null}
                        onContextMenuPreparing={(e) => {
                            if (e && e.row && e.row.data)
                                this.setState({ linieSelectata: e.row.data }, () => {
                                    e.items = [{
                                        text: "Adaugă",
                                        icon: "add",
                                        onItemClick: function () {
                                            e.component.addRow();
                                        }
                                    },
                                    {
                                        text: "Şterge",
                                        icon: "trash",
                                        onItemClick: function () {
                                            e.component.deleteRow(e.row.rowIndex);
                                        }
                                    }];
                                })
                            else {
                                e.items = [{
                                    text: "Adaugă",
                                    icon: "add",
                                    onItemClick: function () {
                                        e.component.addRow();
                                    }
                                }];
                            }
                        }}
                    >
                        {(!localStorage.getItem("checkClickDreapta").replaceAll(" ", "").split(",").includes(localStorage.getItem("siruta").toString()))
                            ? <Editing
                                mode="popup"
                            >
                                <Popup
                                    showTitle={true}
                                    title="Inserare rând"
                                />
                            </Editing>
                            : <Editing
                                //allowUpdating={true}
                                allowAdding={this.props.capitolSelectat && this.props.capitolSelectat.insereaza ? false : true}
                                allowDeleting={this.props.capitolSelectat && this.props.capitolSelectat.insereaza ? false : true}
                                useIcons={true}
                                mode="popup"
                            >
                                <Popup
                                    showTitle={true}
                                    title="Inserare rând"
                                />
                            </Editing>
                        }
                        <Paging enabled={false} />
                        {/*exeplu coloana (nu sterge headerTemplate):*/}
                        {/*<Column dataField="idListaRanduriTabele" caption="Id" alignment='center' headerCellRender={headerTemplate} visibile={false} />*/}
                        <Column dataField="refListaCapitole" caption="refCap" alignment='center' headerCellRender={headerTemplate} >
                            <RequiredRule />
                        </Column>
                        <Column dataField="idParinte" caption="IdParinte" alignment='center' headerCellRender={headerTemplate} dataType={"number"} />
                        <Column dataField="codRand" caption="Cod Rand" alignment='center' headerCellRender={headerTemplate} dataType={"number"} >
                            <RequiredRule />
                        </Column>
                        <Column dataField="ordine" caption="Ordine" alignment='center' headerCellRender={headerTemplate} dataType={"number"}>
                            <RequiredRule />
                        </Column>
                        <Column dataField="parinteCodRand" caption="CodRandParinte" alignment='center' headerCellRender={headerTemplate} dataType={"number"} />
                        <Column dataField="aduna" caption="Aduna" alignment='center' headerCellRender={headerTemplate} dataType={"string"} />
                        <Column dataField="scade" caption="Scade" alignment='center' headerCellRender={headerTemplate} dataType={"string"} />
                        <Column dataField="total" caption="Total" alignment='center' headerCellRender={headerTemplate} dataType={"string"} />
                        <Column dataField="denumire" caption="Denumire" alignment='left' headerCellRender={headerTemplate} dataType={"string"} >
                            <RequiredRule />
                        </Column>
                        <Column dataField="randReadOnly" caption="ReadOnly" alignment='center' headerCellRender={headerTemplate} dataType={"boolean"} />
                        <Column dataField="startVal" caption="Start" alignment='center' headerCellRender={headerTemplate} dataType={"number"} >
                            <RequiredRule />
                            <RangeRule min={1500} max={9999} />
                        </Column>
                        <Column dataField="stopVal" caption="Stop" alignment='center' headerCellRender={headerTemplate} dataType={"number"}>
                            <RangeRule min={1500} max={9999} />
                            <ValidationRule type={"custom"} validationCallback={(e) => { return this.validareCustomStopVal(e.data) }} message="DataStop anterioara DataStart."></ValidationRule>
                        </Column>
                    </DataGrid>
                </ScrollView>
            </Fragment>
        );
    }
}