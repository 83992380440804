import axios from 'axios';

let tabs = [
    {
        id: 0,
        text: 'Benzi tabele'
    },
    {
        id: 1,
        text: 'Randuri tabele'
    },
    {
        id: 2,
        text: 'Coloane tabele'
    },
    {
        id: 3,
        text: 'Creare'
    }
];

export default {
    getCapitole() {
        return new Promise((resolve, reject) => {
            const capitole = axios.get("./api/Administrare/GetCapitole");
            const benzi = axios.get('./api/Administrare/GetBenzi/' + -1);
            const randuri = axios.get('./api/Administrare/GetRanduri/' + -1);
            const campuri = axios.get('./api/Administrare/GetCampuri/' + -1);
            const capitoleAdm = axios.get('./api/Administrare/GetCapitoleAdm');

            axios.all([capitole, benzi, randuri, campuri, capitoleAdm]).then(
                axios.spread((responseC, responseB, responseR, responseCT, responseCap) => {
                    let raspuns = {
                        capitole: responseC,
                        benzi: responseB,
                        randuri: responseR,
                        campuri: responseCT,
                        capitoleAdm: responseCap
                    }

                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    },
    getTabs() {
        return tabs;
    },
    getDateCapitole(idCapitol) {
        return new Promise((resolve, reject) => {
            const benzi = axios.get('./api/Administrare/GetBenzi/' + idCapitol);
            const randuri = axios.get('./api/Administrare/GetRanduri/' + idCapitol);
            const campuri = axios.get('./api/Administrare/GetCampuri/' + idCapitol);
            const capitoleAdm = axios.get('./api/Administrare/GetCapitoleAdm');

            axios.all([benzi, randuri, campuri, capitoleAdm]).then(
                axios.spread((responseB, responseR, responseC, responseCap) => {
                    let raspuns = {
                        benzi: responseB,
                        randuri: responseR,
                        campuri: responseC,
                        capitoleAdm: responseCap
                    }

                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    }
}