import React, { Component, Fragment } from 'react';
import { alert } from "devextreme/ui/dialog";
import { DateBox, SelectBox, Button, TextBox, ScrollView } from 'devextreme-react';
import axios from 'axios';
import Utilitare from './../../../content/Utilitare';

export default class Apia extends Component {
    state = {
        tipFisierSelectat: "persoana"
    }

    stil = {
        width: '98%',
        height: 'calc(100vh - 57px)',
        margin: 'auto',
        border: '1px solid #ddd',
        textAlign: "center",
        overflow: "hidden"
    };

    stilButon = {
        margin: "0 0 5px 0"
    };

    handleOnClickLocal = () => {
        //request cautare
        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Interfatari/SalvezPeServer/' + localStorage.getItem("siruta") + "/" + this.state.tipFisierSelectat.toString(),
        })
            .then((response) => {
                alert("S-a exportat fisierul de tip: " + this.state.tipFisierSelectat);
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "APIA");
            });
    }

    handleOnClickTransmitere = () => {
        if (!this.state.un) {
            alert("Nu aţi completat numele de autentificare pentru sFTP.");
            return;
        }

        if (!this.state.pass) {
            alert("Nu aţi completat parola de autentificare pentru sFTP.");
            return;
        }

        if (!this.state.ssh) {
            alert("Nu aţi completat ssh-ul autentificare pentru sFTP.");
            return;
        }

        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Interfatari/TransmiteApia/' + localStorage.getItem("siruta") + "/" + this.state.un.toString() + "/" + this.state.pass.toString() + "/" + this.state.ssh.toString(),
        })
            .then((response) => {
                alert("Datele au fost transmise catre APIA.");
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "APIA");
            });
    }

    handleOnClickLpis = () => {
        var win = window.open('http://lpis.apia.org.ro/', '_blank');
        win.focus();
    }

    handleOnClickParcela = () => {
        let serviciu = "https://48409:AB_FcZ4U@lpis.apia.org.ro/wms/referinta?";
        let coordonate = "513627,503095,515746,504195";
        let url = serviciu + "request=GetMap&service=WMS&version=1.3.0&layers=referinta&styles=default&crs=EPSG:31700&bbox=" + coordonate + "&width=1000&height=1000&format=image/png";
        var win = window.open(url, '_blank');
        win.focus();
    }

    handleOnValueChangedTip = (id) => {
        this.setState({ tipFisierSelectat: id })
    }

    render() {
        //console.log("apia.jsx state", this.state);

        return (
            <Fragment>
                <ScrollView height="80%">
                    <div style={this.stil} id='chenar'>
                        <h3>Interfaţare APIA</h3>

                        <p>Data</p>
                        <DateBox
                            width="200"
                            style={{ margin: "auto" }}
                            defaultValue={new Date()}
                            pickerType="rollers"
                            displayFormat="dd.MM.yyyy"
                            disabled={true}
                        />
                        <br />
                        <p>Tip fişier</p>
                        <SelectBox
                            width="200"
                            style={{ margin: "auto" }}
                            dataSource={[{ id: "persoana", denumire: "Persoane înscrise în RA" }, { id: "parcela", denumire: "Parcele înscrise în RA" }, { id: "suprafete", denumire: "Suprafeţe agricol - RA" }]}
                            defaultValue={"persoana"}
                            valueExpr="id"
                            displayExpr="denumire"
                            onValueChanged={(e) => { this.handleOnValueChangedTip(e.value); }}
                        />
                        <br />

                        <Button
                            width={200}
                            style={this.stilButon}
                            text="Exporta local"
                            type="normal"
                            stylingMode="contained"
                            onClick={this.handleOnClickLocal}
                        />
                        <br />

                        <div style={{ textAlign: "center", margin: "10px 0 5px 0" }}>
                            <TextBox
                                style={{ display: "inline-block", margin: "0 5px 0 0" }}
                                width={200}
                                showClearButton={true}
                                placeholder="Nume utilizator"
                                onValueChanged={(e) => { this.setState({ un: e.value }) }} />

                            <TextBox
                                style={{ display: "inline-block", margin: "0 5px 0 0" }}
                                width={200}
                                showClearButton={true}
                                placeholder="Parola"
                                mode="password"
                                onValueChanged={(e) => { this.setState({ pass: e.value }) }} />

                            <TextBox
                                style={{ display: "inline-block" }}
                                width={200}
                                showClearButton={true}
                                placeholder="ssh"
                                onValueChanged={(e) => { this.setState({ ssh: e.value }) }} />

                        </div>

                        <Button
                            style={this.stilButon}
                            text="Transmitere APIA"
                            type="normal"
                            stylingMode="contained"
                            onClick={this.handleOnClickTransmitere}
                        />
                        <br />
                        <br />

                        <Button
                            style={this.stilButon}
                            width={200}
                            text="lpis.apia.org.ro"
                            type="normal"
                            stylingMode="contained"
                            onClick={this.handleOnClickLpis}
                        />
                        <br />

                        <Button
                            style={this.stilButon}
                            width={200}
                            text="Lpis parcela"
                            type="normal"
                            stylingMode="contained"
                            onClick={this.handleOnClickParcela}
                        />
                        <br />
                    </div>
                </ScrollView>
            </Fragment>
        );
    }
}