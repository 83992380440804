import React, { Fragment } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import 'bootstrap';
import { Button, TextBox, SelectBox, ScrollView, LoadIndicator } from 'devextreme-react';
import service from "./Date";
import Adresa from './Adresa/Adresa';
import clase from './UpsertGospodarie.module.css';
import Validator, { RequiredRule, PatternRule, StringLengthRule } from 'devextreme-react/validator';
//import { navigate } from "@reach/router";
import Utilitare from '../../../../../content/Utilitare';

/*se declara referinta de la Adresa reprezentantLegal*/
let reprezentantLegal = null;
let unitate = null;
let gospodarie = null;

export default class UpsertGospodarie extends React.PureComponent {
    state = {
        loadIndicatorVisible: false,
        tip: this.props.tipSelectat,
        //din request
        tari: null,
        judete: null,
        localitati: null,
        tipuriStrazi: null,
        categoriiGospodarii: null,
        tipuriGospodarii: null,
        categorieSelectata: null,
        //catre Post
        gospodarieDeTrimis: {
            gospodarie: {
                idRegistrulAgricol: 0,
                idAnterior: null,
                refDatePrimarie: null,
                tip: this.props.tipSelectat,
                volum: null,
                pozitie: null,
                pagina: null,
                denumireVolum: null,
                numarDosar: null,
                refCategoriiPF_PJ: this.props.tipSelectat === 1 || this.props.tipSelectat === 2 ? 3 : 8,
                refProprietate: null,
                refAdresa: null,
                refAdresaReprezentantLegal: null,
                refAdresaPJ: null,
                numeReprezentantLegal: null,
                initialaReprezentantLegal: null,
                prenumeReprezentantLegal: null,
                cnp: null,
                denumireUnitate: null,
                denumireSubunitate: null,
                codAPIA: null,
                codUnicIdentificare: null,
                codUnicGospodarieExploatatie: null,
                refUtilizator: null,
                refUtilizatorModificare: null,
                dataOperare: null,
                dataModificare: null,
                stare: 1,
                an: this.props.anSelectat,
                ani: null,
                transferat: false
            },
            adresa: {
                refTara: 180
            },
            adresaReprezentantLegal: null,
            adresaPJ: null,
            denumireLocalitateST: null,
            denumireLocalitatePJ: null
        }
    }

    componentDidMount() {
        this.setState({ loadIndicatorVisible: true });
        service.getDateAdrese().then(r => {
            this.setState({
                tari: r.tari,
                judete: r.judete,
                localitati: r.localitati,
                listaStrazi: r.listaStrazi,
                tipuriStrazi: r.tipuriStrazi,
                categoriiGospodarii: r.categoriiGospodarii,
                tipuriGospodarii: r.tipuriGospodarii,
                categorieSelectata: this.props.categorieGospodariaSelectata ? r.categoriiGospodarii.filter(x => x.denumireScurta === this.props.categorieGospodariaSelectata)[0].idCategoriiPF_PJ : null
            })
            if (this.props.actiune === "modificare" && this.props.idGospodariaSelectata) {
                service.getDateGospodarie(this.props.idGospodariaSelectata)
                    .then(r => {
                        this.setState({ gospodarieDeTrimis: r.dateGospodarie, loadIndicatorVisible: false })
                    })
                    .catch(error => {
                        this.setState({ loadIndicatorVisible: true }, () => { Utilitare.handleEroare(error, "MeniuAplicatie"); });
                    })
            } else {
                this.setState({ loadIndicatorVisible: false });
            }
        }).catch(error => {//in caz de eroare
            this.setState({ loadIndicatorVisible: true }, () => { Utilitare.handleEroare(error, "MeniuAplicatie"); });
        })
    }

    handleLocalitateComponenta = (denumire, adresaCui) => {
        let g = { ...this.state.gospodarieDeTrimis };
        if (adresaCui === "reprezentantLegal") {
            g.denumireLocalitateST = denumire;
        }
        if (adresaCui === "unitate") {
            g.denumireLocalitatePJ = denumire;
        }

        this.setState({ gospodarieDeTrimis: g });
    }

    stil = {
        width: "70%",
        /*backgroundColor: "#DFDFDF",*/
        border: "1px solid #DFDFDF",
        borderRadius: "5px",
        textAlign: "left",
        verticalAlign: "bottom",
        padding: "6px 0 2px 0",
        marginTop: "2px"
    };

    handleSalveaza = async () => {//apeleaza datele din reprezentantLegal, unitate, gospodarie
        var idNomUAT = this.state.gospodarieDeTrimis.adresa.refLocalitateComponenta;
        var loc = this.state.localitati.find(x => x.idNomenclatorUAT === idNomUAT);
        
        if (!localStorage.getItem("accesIstoric").includes(loc.codSirutaUAT)) {
            var anS = parseInt(localStorage.getItem("anSelectat"));
            var aniS = JSON.parse(localStorage.getItem("listaAni"));
            
            if (anS !== Math.max(...aniS)) {
                alert("Nu aveţi dreptul de modificare în anul curent.");
                return;
            }
        }

        if (!this.state.gospodarieDeTrimis.refCategoriiPF_PJ) {
            let cpj = { ...this.state.gospodarieDeTrimis };
            cpj.gospodarie.refCategoriiPF_PJ = parseInt(this.state.categorieSelectata);
            this.setState({ gospodarieDeTrimis: cpj });
        }

        //daca tara reprezentantuluiLegal sau a PJ-ului nu a fost schimbata, ii punem implicit 180 (Romania)
        if (this.state.gospodarieDeTrimis.adresaReprezentantLegal &&
            (this.state.gospodarieDeTrimis.adresaReprezentantLegal.refTara === undefined || this.state.gospodarieDeTrimis.adresaReprezentantLegal === null)) {
            let gdt = { ...this.state.gospodarieDeTrimis };
            gdt.adresaReprezentantLegal.refTara = 180;
            this.setState({ gospodarieDeTrimis: gdt });
        }
        if (this.state.gospodarieDeTrimis.adresaPJ &&
            (this.state.gospodarieDeTrimis.adresaPJ.refTara === undefined || this.state.gospodarieDeTrimis.adresaPJ === null)) {
            let gdt = { ...this.state.gospodarieDeTrimis };
            gdt.adresaPJ.refTara = 180;
            this.setState({ gospodarieDeTrimis: gdt });
        }
        //daca initiala tatalui nu are punct
        if (this.state.gospodarieDeTrimis.gospodarie.initialaReprezentantLegal && (this.state.gospodarieDeTrimis.gospodarie.initialaReprezentantLegal !== null ||
            this.state.gospodarieDeTrimis.gospodarie.initialaReprezentantLegal !== undefined ||
            this.state.gospodarieDeTrimis.gospodarie.initialaReprezentantLegal !== "") &&
            this.state.gospodarieDeTrimis.gospodarie.initialaReprezentantLegal.indexOf(".") < 0) {
            let gdt = { ...this.state.gospodarieDeTrimis };
            gdt.initialaReprezentantLegal = gdt.initialaReprezentantLegal + ".";
            this.setState({ gospodarieDeTrimis: gdt });
        }

        let gospodarieDeTrimis = { ...this.state.gospodarieDeTrimis };
        gospodarieDeTrimis.adresa = gospodarie ? gospodarie.state.adresa : null;
        gospodarieDeTrimis.adresaReprezentantLegal = reprezentantLegal ? reprezentantLegal.state.adresa : null;
        gospodarieDeTrimis.adresaPJ = unitate ? unitate.state.adresa : null;
        this.setState({ gospodarieDeTrimis: gospodarieDeTrimis })

        //validare:
        if (this.state.gospodarieDeTrimis.gospodarie.numeReprezentantLegal === null ||
            this.state.gospodarieDeTrimis.gospodarie.numeReprezentantLegal === undefined ||
            this.state.gospodarieDeTrimis.gospodarie.numeReprezentantLegal === "") {
            alert("Nu aţi completat numele Reprezentantului Legal.");
            return;
        }

        if (this.state.gospodarieDeTrimis.gospodarie.prenumeReprezentantLegal === null ||
            this.state.gospodarieDeTrimis.gospodarie.prenumeReprezentantLegal === undefined ||
            this.state.gospodarieDeTrimis.gospodarie.prenumeReprezentantLegal === "") {
            alert("Nu aţi completat prenumele Reprezentantului Legal.");
            return;
        }
        if (this.state.gospodarieDeTrimis.gospodarie.cnp === null ||
            this.state.gospodarieDeTrimis.gospodarie.cnp === undefined ||
            !Utilitare.validCNP(this.state.gospodarieDeTrimis.gospodarie.cnp)) {
            alert("CNP-ul Reprezentantului Legal este nul sau invalid.");
            return;
        }
        if (this.state.gospodarieDeTrimis.gospodarie.volum === null ||
            this.state.gospodarieDeTrimis.gospodarie.volum === undefined) {
            alert("Nu aţi completat volumul.");
            return;
        }
        if (this.state.gospodarieDeTrimis.gospodarie.pozitie === null ||
            this.state.gospodarieDeTrimis.gospodarie.pozitie === undefined) {
            alert("Nu aţi completat pozitia.");
            return;
        }
        if (this.state.gospodarieDeTrimis.adresa.refLocalitateComponenta === null ||
            this.state.gospodarieDeTrimis.adresa.refLocalitateComponenta === undefined) {
            alert("Nu aţi selectat localitatea în care se află gospodaria/firma.");
            return;
        }
        if (this.state.gospodarieDeTrimis.tip === 3 &&
            (this.state.gospodarieDeTrimis.denumireUnitate === null ||
                this.state.gospodarieDeTrimis.denumireUnitate === undefined)) {
            alert("Nu aţi completat Denumirea Unităţii.");
            return;
        }
        if (this.state.gospodarieDeTrimis.tip === 4 &&
            (this.state.gospodarieDeTrimis.denumireUnitate === null ||
                this.state.gospodarieDeTrimis.denumireUnitate === undefined)) {
            alert("Nu aţi completat Denumirea Unităţii.");
            return;
        }
        if (this.state.gospodarieDeTrimis.tip === 4 &&
            (this.state.gospodarieDeTrimis.denumireSubunitate === null ||
                this.state.gospodarieDeTrimis.denumireSubunitate === undefined)) {
            alert("Nu aţi completat Denumirea Subunităţii.");
            return;
        }

        if (this.state.gospodarieDeTrimis.adresaReprezentantLegal && this.state.gospodarieDeTrimis.adresaReprezentantLegal.refTara !== 180) {
            let gos = { ...this.state.gospodarieDeTrimis };
            gos.adresaReprezentantLegal.refLocalitateComponenta = null;
            this.setState({ gospodarieDeTrimis: gos })
        }
        if (this.state.gospodarieDeTrimis.adresaPJ && this.state.gospodarieDeTrimis.adresaPJ.refTara !== 180) {
            let gos = { ...this.state.gospodarieDeTrimis };
            gos.adresaPJ.refLocalitateComponenta = null;
            this.setState({ gospodarieDeTrimis: gos })
        }

        if (this.state.gospodarieDeTrimis.gospodarie.tip === 1) { //aici verifica tipul de gospodarie si nulleaza tot ce nu trebuie trimis la tip=1
            let ob = { ...this.state.gospodarieDeTrimis };
            ob.adresaPJ = null;
            ob.adresaReprezentantLegal = null;
            ob.denumireLocalitatePJ = null;
            ob.denumireLocalitateST = null;
            ob.denumireSubunitate = null;
            ob.denumireUnitate = null;
            ob.codUnicIdentificare = null;
            this.setState({ gospodarieDeTrimis: ob });
        }
        if (this.state.gospodarieDeTrimis.gospodarie.tip === 2) { //aici verifica tipul de gospodarie si nulleaza tot ce nu trebuie trimis la tip=2
            let ob = { ...this.state.gospodarieDeTrimis };
            ob.adresaPJ = null;
            ob.denumireLocalitatePJ = null;
            ob.denumireSubunitate = null;
            ob.denumireUnitate = null;
            ob.codUnicIdentificare = null;
            this.setState({ gospodarieDeTrimis: ob });
        }
        if (this.state.gospodarieDeTrimis.gospodarie.tip === 3) { //aici verifica tipul de gospodarie si nulleaza tot ce nu trebuie trimis la tip=3
            let ob = { ...this.state.gospodarieDeTrimis };
            //ob.adresaReprezentantLegal = null;
            //ob.denumireLocalitateST = null;
            ob.adresaPJ = null;
            ob.denumireLocalitatePJ = null;
            ob.denumireSubunitate = null;
            this.setState({ gospodarieDeTrimis: ob });
        }

        await axios({
            data: this.state.gospodarieDeTrimis,
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/Gospodarii/PostUpsert',
        })
            .then((response) => {
                this.props.handleSelectieCautare(response.data.idRegistrulAgricol, 0);
                this.props.ascundeUpsert();
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "MeniuAplicatie");
            });
    }

    handleAdrese = (adresaCui, ceValoare, valoare) => {
        if (ceValoare === "denumireLocalitateST") {
            let g = { ...this.state.gospodarieDeTrimis }
            g.denumireLocalitateST = valoare;
            this.setState({ gospodarieDeTrimis: g });
            return;
        }
        if (ceValoare === "denumireLocalitatePJ") {
            let g = { ...this.state.gospodarieDeTrimis }
            g.denumireLocalitatePJ = valoare;
            this.setState({ gospodarieDeTrimis: g });
            return;
        }

        let adresa = null;
        switch (adresaCui) {
            case "gospodarie":
                adresa = { ...this.state.gospodarieDeTrimis.adresa };
                break;
            case "reprezentantLegal":
                adresa = { ...this.state.gospodarieDeTrimis.adresaReprezentantLegal };
                break;
            case "unitate":
                adresa = { ...this.state.gospodarieDeTrimis.adresaPJ };
                break;
            default:
                adresa = { ...this.state.gospodarieDeTrimis.adresa };
        }
        adresa[ceValoare] = valoare;
        let adresaDeTrimis = { ...this.state.gospodarieDeTrimis };
        switch (adresaCui) {
            case "gospodarie":
                adresaDeTrimis.adresa = adresa;
                break;
            case "reprezentantLegal":
                adresaDeTrimis.adresaReprezentantLegal = adresa;
                break;
            case "unitate":
                adresaDeTrimis.adresaPJ = adresa;
                break;
            default:
                adresaDeTrimis.adresa = adresa;
        }

        this.setState({ gospodarieDeTrimis: adresaDeTrimis });
    }

    render() {
        //console.log("UpsertGospodarie props", this.props);
        //console.log("UpsertGospodarie state", this.state);

        let tipSelectat = null;
        let denumireSubunitate = null;
        let adresaReprezentantLegal = null;
        let denumireUnitate = null;
        let adresaUnitate = null;
        let coduriFirma = null;

        tipSelectat = <p style={{ fontWeight: "bold" }}>{this.state.tipuriGospodarii ? this.state.tipuriGospodarii.find(x => x.tip === this.props.tipSelectat).nume : null}</p>;

        denumireSubunitate =
            <div className="container" style={this.stil}>
                <div className="row" style={{ margin: "6px 0 2px 0" }}>
                    <div className="col col-sm-4"></div>
                    <div className="col col-sm-5">
                        <TextBox
                            placeholder={"Denumirea subunităţii..."}
                            width={"100%"}
                            style={{ display: 'inline-block', marginTop: '2px' }}
                            defaultValue={this.props.actiune === "adaugare" ? "" : this.state.gospodarieDeTrimis.gospodarie.denumireSubunitate}
                            onValueChanged={(e) => {
                                let den = { ...this.state.gospodarieDeTrimis };
                                den.gospodarie.denumireSubunitate = e.value;
                                this.setState({ gospodarieDeTrimis: den });
                            }}
                            value={this.state.gospodarieDeTrimis.gospodarie.denumireSubunitate}
                        />
                    </div>
                </div>
            </div>

        adresaReprezentantLegal =
            <div className="container" style={this.stil}>
                <div className="row" style={{ margin: "6px 0 2px 0" }}>
                    <div className="col col-sm-12">
                        <p style={{ textAlign: "center", fontWeight: "bold" }}>Domiciliu stabil reprezentant:</p>
                    </div>
                </div>

                <div className="container" style={this.stil}>
                    <div className="col col-sm-12">
                        <Adresa
                            adresaCui={"reprezentantLegal"}
                            actiune={this.props.actiune}
                            tipSelectat={this.state.tip}
                            idGospodariaSelectata={this.props.idGospodariaSelectata}
                            onInitialized={(r) => { reprezentantLegal = r; }} //reprezentant va avea valoarea this (componenta Adresa)
                            tari={this.state.tari}
                            judete={this.state.judete}
                            //localitati={this.props.actiune === "adaugare" ? (this.state.localitati ? this.state.localitati : null) : null}
                            localitati={this.state.localitati ? this.state.localitati : null}
                            tipuriStrazi={this.state.tipuriStrazi}
                            categoriiGospodarii={this.state.categoriiGospodarii}
                            handleLocalitateComponenta={this.handleLocalitateComponenta}
                            adresa={this.state.gospodarieDeTrimis.adresaReprezentantLegal}
                            denumireLocalitateST={this.state.gospodarieDeTrimis.denumireLocalitateST}
                            denumireLocalitatePJ={this.state.gospodarieDeTrimis.denumireLocalitatePJ}
                            handleAdrese={this.handleAdrese}
                        />
                    </div>
                </div>
            </div>

        coduriFirma =
            <div className="row" style={{ margin: "6px 0 2px 0" }}>
                <div className="col col-sm-2"></div>
                <div className="col col-sm-2">
                    <TextBox
                        placeholder={"CUI..."}
                        width={"100%"}
                        style={{ display: 'inline-block', marginTop: '2px' }}
                        defaultValue={this.props.actiune === "adaugare" ? "" : "CUI"}
                        onValueChanged={(e) => {
                            let cui = { ...this.state.gospodarieDeTrimis };
                            cui.gospodarie.codUnicIdentificare = e.value;
                            this.setState({ gospodarieDeTrimis: cui });
                        }}
                        value={this.state.gospodarieDeTrimis.gospodarie.codUnicIdentificare}
                        disabled={this.state.tip === 1 || this.state.tip === 2 ? true : false}
                    />
                </div>

                <div className="col col-sm-1"></div>
                <div className="col col-sm-2">
                    <TextBox
                        placeholder={"Cod APIA..."}
                        width={"100%"}
                        style={{ display: 'inline-block', marginTop: '2px' }}
                        defaultValue={this.props.actiune === "adaugare" ? "" : "cod APIA"}
                        onValueChanged={(e) => {
                            let apia = { ...this.state.gospodarieDeTrimis };
                            apia.gospodarie.codAPIA = e.value;
                            this.setState({ gospodarieDeTrimis: apia });
                        }}
                        value={this.state.gospodarieDeTrimis.gospodarie.codAPIA}
                    />
                </div>

                <div className="col col-sm-1"></div>
                <div className="col col-sm-2">
                    <TextBox
                        placeholder={"Cod unic exploataţie..."}
                        width={"100%"}
                        style={{ display: 'inline-block', marginTop: '2px' }}
                        defaultValue={this.props.actiune === "adaugare" ? "" : "Cod Unic"}
                        onValueChanged={(e) => {
                            let cue = { ...this.state.gospodarieDeTrimis };
                            cue.gospodarie.codUnicGospodarieExploatatie = e.value;
                            this.setState({ gospodarieDeTrimis: cue });
                        }}
                        value={this.state.gospodarieDeTrimis.gospodarie.codUnicGospodarieExploatatie}
                    />
                </div>
            </div>

        denumireUnitate =
            <div className="row" style={{ margin: "6px 0 2px 0" }}>
                <div className="col col-sm-3"></div>
                <div className="col col-sm-6">
                    <TextBox
                        placeholder={"Denumirea unităţii..."}
                        width={"100%"}
                        style={{ display: 'inline-block', marginTop: '2px' }}
                        defaultValue={this.props.actiune === "adaugare" ? "" : "DenumireUnitate"}
                        onValueChanged={(e) => {
                            let den = { ...this.state.gospodarieDeTrimis };
                            den.gospodarie.denumireUnitate = e.value;
                            this.setState({ gospodarieDeTrimis: den });
                        }}
                        value={this.state.gospodarieDeTrimis.gospodarie.denumireUnitate}
                    />
                </div>
            </div>

        adresaUnitate =
            <div className="container" style={this.stil}>
                <div className="container" style={this.stil}>
                    <div className="col col-sm-12">
                        <Adresa
                            adresaCui={"unitate"}
                            actiune={this.props.actiune}
                            tipSelectat={this.state.tip}
                            idGospodariaSelectata={this.props.idGospodariaSelectata}
                            onInitialized={(r) => { unitate = r; }} //unitate va avea valoarea this (componenta Adresa)
                            tari={this.state.tari}
                            judete={this.state.judete}
                            //localitati={this.props.actiune === "adaugare" || (this.props.actiune === "modificare" && (this.state.gospodarieDeTrimis.denumireLocalitateST || this.state.gospodarieDeTrimis.denumireLocalitatePJ)) ?
                            //    (this.state.localitati ? this.state.localitati : null)
                            //    : null}
                            localitati={this.state.localitati ? this.state.localitati : null}
                            tipuriStrazi={this.state.tipuriStrazi}
                            categoriiGospodarii={this.state.categoriiGospodarii}
                            handleLocalitateComponenta={this.handleLocalitateComponenta}
                            adresa={this.state.gospodarieDeTrimis.adresaPJ}
                            denumireLocalitateST={this.state.gospodarieDeTrimis.denumireLocalitateST}
                            denumireLocalitatePJ={this.state.gospodarieDeTrimis.denumireLocalitatePJ}
                            handleAdrese={this.handleAdrese}
                        />
                    </div>
                </div>
            </div >

        return (
            <Fragment>
                {this.state.loadIndicatorVisible ?
                    <div style={{ position: "absolute", top: "0px", bottom: "0px", left: "0px", right: "0px", zIndex: "1502", backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", border: "1px solid black", backgroundColor: "white" }}>
                            <p>Se încarcă...</p>
                            <LoadIndicator
                                className="button-indicator"
                                visible={this.state.loadIndicatorVisible}
                            />
                        </div>
                    </div>
                    : null}

                <ScrollView style={{ height: 'calc(100vh - 67px)', }}>
                    <div className="text-content">
                        <div style={{ margin: '5px', textAlign: "center" }} className={clase.p}>
                            <h6 style={{ fontWeight: "bold" }}>{this.props.actiune === "adaugare" ? "Adăugare" : "Modificare"} Gospodărie</h6>

                            {tipSelectat}

                            { /*dosar, rol, apare permanent*/}
                            <div className="container" style={this.stil}>
                                <div className="row" style={{ margin: "6px 0 2px 0" }}>
                                    <div className="col col-sm-3"></div>
                                    <div className="col col-sm-3">
                                        <TextBox
                                            placeholder={"Dosar nr. ..."}
                                            width={"100%"}
                                            style={{ display: 'inline-block', marginTop: '2px' }}
                                            defaultValue={this.props.actiune === "adaugare" ? "" : "nr.Dosar"}
                                            onValueChanged={(e) => {
                                                let dos = { ...this.state.gospodarieDeTrimis };
                                                dos.gospodarie.numarDosar = parseInt(e.value);
                                                this.setState({ gospodarieDeTrimis: dos });
                                            }}
                                            value={this.state.gospodarieDeTrimis.gospodarie.numarDosar ? this.state.gospodarieDeTrimis.gospodarie.numarDosar.toString() : null}
                                        />
                                    </div>

                                    <div className="col col-sm-3">
                                        <TextBox
                                            placeholder={"Rol..."}
                                            width={"100%"}
                                            style={{ display: 'inline-block', marginTop: '2px' }}
                                            defaultValue={this.props.actiune === "adaugare" ? "" : "Rol"}
                                            onValueChanged={(e) => {
                                                let rol = { ...this.state.gospodarieDeTrimis };
                                                rol.gospodarie.refProprietate = e.value;
                                                this.setState({ gospodarieDeTrimis: rol });
                                            }}
                                            value={this.state.gospodarieDeTrimis.gospodarie.refProprietate}
                                        />
                                    </div>
                                </div>
                            </div>

                            { /*tip, volum, pozitie, pagina trebuie sa apara permanent*/}
                            { /*TipGospodarie*/}
                            <div className="container" style={this.stil}>
                                <div className="row" style={{ margin: "6px 0 2px 0" }}>
                                    <div className="col col-sm-4"></div>
                                    <div className="col col-sm-5">
                                        <SelectBox
                                            style={{ display: "inline-block", marginTop: '2px' }}
                                            width={"100%"}
                                            dataSource={this.state.tipuriGospodarii
                                                ? this.props.tipSelectat === 1 || this.props.tipSelectat === 2
                                                    ? this.state.tipuriGospodarii.filter(x => x.tip !== 0 && x.tip !== 3 && x.tip !== 4)
                                                    : this.state.tipuriGospodarii.filter(x => x.tip !== 0 && x.tip !== 1 && x.tip !== 2)
                                                : null}
                                            valueExpr={"idTipuriGospodarii"}
                                            displayExpr={"nume"}
                                            onOpened={(e) => { e.component._popup.option('width', 300); }}
                                            defaultValue={this.props.tipSelectat}
                                            onValueChanged={(e) => {
                                                let tip = { ...this.state.gospodarieDeTrimis };
                                                tip.gospodarie.tip = e.value;
                                                let cat = e.value === 1 || e.value === 2 ? 1 : 8;
                                                tip.gospodarie.refCategoriiPF_PJ = cat;
                                                this.setState({ gospodarieDeTrimis: tip, tip: e.value });
                                            }}
                                            value={this.state.gospodarieDeTrimis.gospodarie.tip}
                                        />

                                    </div>
                                    <div className="col col-sm-9" style={{ textAlign: "right", fontSize: "70%" }}></div>
                                </div>

                                {this.state.tip === 3 ? denumireUnitate : null}
                                {coduriFirma}

                                <div className="row" style={{ margin: "6px 0 2px 0" }}>
                                    <div className="col col-sm-2">
                                        <SelectBox
                                            style={{ display: "inline-block", marginTop: '2px' }}
                                            width={"100%"}
                                            dataSource={this.state.categoriiGospodarii
                                                ? this.state.categoriiGospodarii.filter(x => this.state.tip === 1 || this.state.tip === 2 ? x.tip === "PF" : x.tip === "PJ")
                                                : null}
                                            displayExpr={"denumire"}
                                            valueExpr={"idCategoriiPF_PJ"}
                                            onOpened={(e) => { e.component._popup.option('width', 300); }}
                                            value={
                                                this.props.actiune === "adaugare" ? (this.state.categorieSelectata ? this.state.categorieSelectata : null) : (this.state.gospodarieDeTrimis.gospodarie.refCategoriiPF_PJ)
                                            }
                                            onValueChanged={(e) => {
                                                let cat = { ...this.state.gospodarieDeTrimis };
                                                cat.gospodarie.refCategoriiPF_PJ = parseInt(e.value);
                                                this.setState({ gospodarieDeTrimis: cat, categorieSelectata: e.value });
                                            }}
                                        />
                                    </div>

                                    <div className="col col-sm-1"></div>
                                    <div className="col col-sm-2">
                                        <TextBox
                                            placeholder={"Volum..."}
                                            width={"100%"}
                                            style={{ display: 'inline-block', marginTop: '2px', borderColor: "rgba(255, 0, 0, 0.3)" }}
                                            defaultValue={this.props.actiune === "adaugare" ? "" : "Volum"}
                                            onValueChanged={(e) => {
                                                let vol = { ...this.state.gospodarieDeTrimis };
                                                vol.gospodarie.volum = parseInt(e.value);
                                                vol.gospodarie.pozitie = this.props.getPozMax(parseInt(e.value)) + 1;
                                                this.setState({ gospodarieDeTrimis: vol });

                                            }}
                                            value={this.state.gospodarieDeTrimis.gospodarie.volum ? this.state.gospodarieDeTrimis.gospodarie.volum.toString() : null}
                                        >
                                            <Validator>
                                                <RequiredRule />
                                                <PatternRule
                                                    pattern="^[0-9]+$"
                                                    message="Doar cifre."
                                                />
                                            </Validator>
                                        </TextBox>
                                    </div>

                                    <div className="col col-sm-2">
                                        <TextBox
                                            placeholder={"Poziţie..."}
                                            width={"100%"}
                                            style={{ display: 'inline-block', marginTop: '2px', borderColor: "rgba(255,0,0,0.3)" }}
                                            defaultValue={this.props.actiune === "adaugare" ? "" : "Pozitie"}
                                            onValueChanged={(e) => {
                                                let poz = { ...this.state.gospodarieDeTrimis };
                                                poz.gospodarie.pozitie = parseInt(e.value);
                                                this.setState({ gospodarieDeTrimis: poz });
                                            }}
                                            value={this.state.gospodarieDeTrimis.gospodarie.pozitie ? this.state.gospodarieDeTrimis.gospodarie.pozitie.toString() : null}
                                        >
                                            <Validator>
                                                <RequiredRule />
                                                <PatternRule
                                                    pattern="^[0-9]+$"
                                                    message="Doar cifre."
                                                />
                                            </Validator>
                                        </TextBox>
                                    </div>

                                    <div className="col col-sm-2">
                                        <TextBox
                                            placeholder={"Pagina..."}
                                            width={"100%"}
                                            style={{ display: 'inline-block', marginTop: '2px' }}
                                            defaultValue={this.props.actiune === "adaugare" ? "" : "Pagina"}
                                            onValueChanged={(e) => {
                                                let pag = { ...this.state.gospodarieDeTrimis };
                                                pag.gospodarie.pagina = parseInt(e.value);
                                                this.setState({ gospodarieDeTrimis: pag });
                                            }}
                                            value={this.state.gospodarieDeTrimis.gospodarie.pagina ? this.state.gospodarieDeTrimis.gospodarie.pagina.toString() : null}
                                        >
                                            <Validator>
                                                <RequiredRule />
                                                <PatternRule
                                                    pattern="^[0-9]+$"
                                                    message="Doar cifre."
                                                />
                                            </Validator>
                                        </TextBox>
                                    </div>

                                    <div className="col col-sm-1"></div>
                                    <div className="col col-sm-2">
                                        <TextBox
                                            placeholder={"Denumire volum..."}
                                            width={"100%"}
                                            style={{ display: 'inline-block', marginTop: '2px' }}
                                            defaultValue={this.props.actiune === "adaugare" ? "" : "Pagina"}
                                            onValueChanged={(e) => {
                                                let dv = { ...this.state.gospodarieDeTrimis };
                                                dv.gospodarie.denumireVolum = e.value;
                                                this.setState({ gospodarieDeTrimis: dv });
                                            }}
                                            value={this.state.gospodarieDeTrimis.gospodarie.denumireVolum}
                                        />
                                    </div>
                                </div>
                            </div>

                            {this.state.tip === 4 ? denumireSubunitate : null}

                            <div className="container" style={this.stil}>
                                <div className="col col-sm-12">
                                    <Adresa
                                        adresaCui={"gospodarie"}
                                        actiune={this.props.actiune}//adaugare sau modificare
                                        tipSelectat={this.state.tip}
                                        idGospodariaSelectata={this.props.idGospodariaSelectata}
                                        onInitialized={(r) => { gospodarie = r; }} //gospodarie va avea valoarea this (componenta Adresa)
                                        tari={this.state.tari}
                                        judete={this.state.judete}
                                        localitati={this.state.localitati
                                            ? this.state.localitati.filter(x => x.codSirutaUAT != null && x.codSirutaUAT.toString() === localStorage.getItem("siruta").toString())
                                            : null}
                                        listaStrazi={this.state.listaStrazi ? this.state.listaStrazi : null}
                                        tipuriStrazi={this.state.tipuriStrazi}
                                        categoriiGospodarii={this.state.categoriiGospodarii}
                                        handleLocalitateComponenta={this.handleLocalitateComponenta}
                                        adresa={this.state.gospodarieDeTrimis.adresa}
                                        handleAdrese={this.handleAdrese}
                                    />
                                </div>
                            </div>


                            { /*Reprezentant legal, apare permanent*/}
                            <div className="container" style={this.stil}>
                                <div className="row" style={{ margin: "6px 0 2px 0" }}>
                                    <div className="col col-sm-12">
                                        <p style={{ display: "inline-box", width: "100%", textAlign: "center", fontWeight: "bold", marginBottom: "6px" }}>Reprezentant legal (nume, iniţiala tatălui, prenume)</p>
                                    </div>

                                    <div className="col col-sm-3"></div>
                                    <div className="col col-sm-6">
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <TextBox
                                                placeholder={"Nume"}
                                                width={"40%"}
                                                style={{ display: 'inline-block', marginTop: '2px', borderColor: "rgba(255,0,0,0.3)" }}
                                                defaultValue={this.props.actiune === "adaugare" ? "" : "nume"}
                                                onValueChanged={(e) => {
                                                    let nume = { ...this.state.gospodarieDeTrimis };
                                                    nume.gospodarie.numeReprezentantLegal = e.value;
                                                    this.setState({ gospodarieDeTrimis: nume });
                                                }}
                                                value={this.state.gospodarieDeTrimis.gospodarie.numeReprezentantLegal}
                                            >
                                                <Validator>
                                                    <RequiredRule />
                                                    <PatternRule
                                                        pattern="^[a-zA-ZàèìòùÀÈÌÒÙĂÎÂŞŢăîâlşţáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ-]+$"
                                                        message="Doar litere."
                                                    />
                                                </Validator>
                                            </TextBox>
                                            <TextBox
                                                placeholder={"Init."}
                                                width={"10%"}
                                                style={{ display: 'inline-block', marginTop: '2px', marginLeft: "5px", borderColor: "rgba(255,0,0,0.3)" }}
                                                defaultValue={this.props.actiune === "adaugare" ? "" : "I"}
                                                onValueChanged={(e) => {
                                                    let ini = { ...this.state.gospodarieDeTrimis };
                                                    ini.gospodarie.initialaReprezentantLegal = e.value;
                                                    this.setState({ gospodarieDeTrimis: ini });
                                                }}
                                                value={this.state.gospodarieDeTrimis.gospodarie.initialaReprezentantLegal ? this.state.gospodarieDeTrimis.gospodarie.initialaReprezentantLegal : null}
                                            >
                                                <Validator>
                                                    <RequiredRule />
                                                    <PatternRule
                                                        pattern="^[a-zA-ZàèìòùÀÈÌÒÙĂÎÂŞŢăîâlşţáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ-]{0,3}[.]+$"
                                                        message="Doar litere şi punct."
                                                    />
                                                    <StringLengthRule message="Maxim 3 litere." max={4} />
                                                </Validator>
                                            </TextBox>
                                            <TextBox
                                                placeholder={"Prenume"}
                                                width={"40%"}
                                                style={{ display: 'inline-block', marginTop: '2px', marginLeft: "5px", borderColor: "rgba(255,0,0,0.3)" }}
                                                defaultValue={this.props.actiune === "adaugare" ? "" : "prenume"}
                                                onValueChanged={(e) => {
                                                    let pren = { ...this.state.gospodarieDeTrimis };
                                                    pren.gospodarie.prenumeReprezentantLegal = e.value;
                                                    this.setState({ gospodarieDeTrimis: pren });
                                                }}
                                                value={this.state.gospodarieDeTrimis.gospodarie.prenumeReprezentantLegal}
                                            >
                                                <Validator>
                                                    <RequiredRule />
                                                    <PatternRule
                                                        pattern="^[a-zA-ZàèìòùÀÈÌÒÙĂÎÂŞŢăîâlşţáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ-]+$"
                                                        message="Doar litere şi -."
                                                    />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{ margin: "6px 0 2px 0" }}>
                                    <div className="col col-sm-3"></div>
                                    <div className="col col-sm-6">
                                        <TextBox
                                            placeholder={"CNP..."}
                                            width={"100%"}
                                            style={{ display: 'inline-block', marginTop: '2px', borderColor: "rgba(255,0,0,0.3)" }}
                                            defaultValue={this.props.actiune === "adaugare" ? "" : "cnp"}
                                            onValueChanged={(e) => {
                                                let codNP = { ...this.state.gospodarieDeTrimis };
                                                codNP.gospodarie.cnp = e.value;
                                                this.setState({ gospodarieDeTrimis: codNP });
                                            }}
                                            value={this.state.gospodarieDeTrimis.gospodarie.cnp}
                                            maxLength={13}
                                        >
                                            <Validator>
                                                <RequiredRule />
                                                <PatternRule
                                                    pattern="^[1-9]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d|5[0-2]|99)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$"
                                                    message="CNP invalid."
                                                />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                            </div>

                            {this.state.tip === 2 || this.state.tip === 4 ? adresaReprezentantLegal : null}

                            {/*this.state.tip === 3 || this.state.tip === 4 ? coduriFirma : null*/}

                            { /*Denumirea unitatii, apare la tip 4*/}
                            {this.state.tip === 4 ? denumireUnitate : null}
                            {this.state.tip === 4 ? adresaUnitate : null}
                            {this.state.tip === 3 ? adresaReprezentantLegal : null}

                            <Button
                                style={{ margin: "5px 0 0 0" }}
                                width={120}
                                text="Salvează"
                                type="normal"
                                stylingMode="outlined"
                                onClick={this.handleSalveaza}
                            />
                            <Button
                                style={{ margin: "5px 0 0 5px" }}
                                width={120}
                                text="Anulează"
                                type="normal"
                                stylingMode="outlined"
                                onClick={this.props.ascundeUpsert}
                            />
                        </div>
                    </div>
                </ScrollView>
            </Fragment>
        );
    }
}