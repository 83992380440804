import React, { Component, Fragment } from 'react';
import { SelectBox } from 'devextreme-react';
import service from "./DateProductii";
import Utilitare from './../../../../content/Utilitare';

export default class ProductiaAnimala extends Component {
    state = {
        categorii: null,
        categorieSelectata: 1
    }

    stil = {
        width: '98%',
        height: 'calc(100vh - 57px)',
        margin: 'auto',
        border: '1px solid #ddd',
        textAlign: "center",
        overflow: "hidden"
    };

    componentDidMount() {
        service.getListaCategorii()
            .then(r => {
                this.setState({ categorii: r.categorii })
            })
            .catch(error => {
                Utilitare.handleEroare(error, "ProductiaAnimala");
            })

        //        let nrGospodarii = (this.state.tipSelectat !== 0 && this.state.tipSelectat !== null) ? this.state.dateGospodarii.filter(x => x.tip === this.state.tipSelectat).length : this.state.dateGospodarii.length;
        //        this.setState({ numarGospodarii: nrGospodarii });
    }

    handleSchimbaCategorie = (val) => {
        this.setState({ categorieSelectata: val });
    }

    render() {
        return (
            <Fragment>
                <div style={this.stil} id='chenar'>
                    <h3>Productia animala</h3>

                    <SelectBox
                        style={{ margin: "0 5px 0 5px" }}
                        dataSource={this.state.categorii}
                        displayExpr="denumire"
                        valueExpr="valoare"
                        defaultValue={1}
                        onValueChange={(e) => { this.handleSchimbaCategorie(e) }}
                    />
                </div>
            </Fragment>
        );
    }
}