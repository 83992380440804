import React, { Component } from 'react';
import { alert } from "devextreme/ui/dialog";
import DataGrid, { Paging, Column } from 'devextreme-react/data-grid';
import { ScrollView } from 'devextreme-react';
import Benzi from './Benzi/Benzi';
import Coloane from './Coloane/Coloane';
import Randuri from './Randuri/Randuri';
import CapitoleAdm from './CapitoleAdm/CapitoleAdm';
import service from './date';
import 'bootstrap';
import Utilitare from './../../../../content/Utilitare';

export default class Capitole extends Component {
    state = {
        listaCapitole: null,
        benzi: null,
        capitolSelectat: null,
        tabSelectat: 0,
        continutTab: null
    }

    stil = {
        width: '98%',
        height: 'calc(100vh - 57px)',
        margin: 'auto',
        border: '1px solid #ddd',
        textAlign: "center",
        overflow: "hidden"
    };

    componentDidMount() {
        document.getElementById("tab0").style.backgroundColor = "#e6e6e6";
        [1, 2, 3].map(x => document.getElementById("tab" + x).style.backgroundColor = "white");

        service.getCapitole().then(r => {
            this.setState({
                listaCapitole: r.capitole.data,
                benzi: r.benzi.data,
                randuri: r.randuri.data,
                coloane: r.campuri.data,
                capitoleAdm: r.capitoleAdm.data,
                capitolSelectat: r.capitole.data[0],
                continutTab: <Benzi benzi={r.benzi.data} validareBenzi={this.validareBenzi} />
            });
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "Capitole");
            //navigate(`./`);
        })
    }

    handleOnRowClick = (capitol) => {
        document.getElementById("tab0").style.backgroundColor = "#e6e6e6";
        [1, 2, 3].map(x => document.getElementById("tab" + x).style.backgroundColor = "white");

        service.getDateCapitole(capitol.codTabela).then(r => {
            this.setState({
                benzi: r.benzi.data,
                randuri: r.randuri.data,
                coloane: r.campuri.data,
                capitoleAdm: r.capitoleAdm.data,
                capitolSelectat: capitol
            }, () => {
                switch (this.state.tabSelectat) {
                    case 0:
                        this.setState({
                            continutTab: <Benzi
                                benzi={r.benzi.data}
                                validareBenzi={this.validareBenzi} />
                        });
                        break;
                    case 1:
                        this.setState({
                            continutTab: <Randuri
                                randuri={this.state.randuri}
                                validareRanduri={this.validareBenzi}
                                capitolSelectat={this.state.capitoleAdm.find(x => x.idListaTabele === this.state.capitolSelectat.idListaTabele)}
                            />
                        });
                        break;
                    case 2:
                        this.setState({ continutTab: <Coloane coloane={this.state.coloane} /> });
                        break;
                    case 3:
                        this.setState({ continutTab: <CapitoleAdm capitoleAdm={this.state.capitoleAdm} /> });
                        break;
                    default:
                        this.setState({ continutTab: <Benzi benzi={this.state.benzi} validareBenzi={this.validareBenzi} /> });
                        break;
                }
            });
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "Capitole");
            //navigate(`./`);
        })
    }

    handleTabChange = (tab) => {
        document.getElementById("tab" + tab).style.backgroundColor = "#e6e6e6";

        var idUri = [0, 1, 2, 3].filter(a => a !== tab);

        idUri.map(x => document.getElementById("tab" + x).style.backgroundColor = "white");

        switch (tab) {
            case 0:
                this.setState({
                    continutTab: <Benzi
                        benzi={this.state.benzi}
                        validareBenzi={this.validareBenzi} />
                });
                break;
            case 1:
                this.setState({
                    continutTab: <Randuri
                        randuri={this.state.randuri}
                        validareRanduri={this.validareBenzi}
                        capitolSelectat={this.state.capitoleAdm.find(x => x.idListaTabele === this.state.capitolSelectat.idListaTabele)}
                    />
                });
                break;
            case 2:
                this.setState({ continutTab: <Coloane coloane={this.state.coloane} /> });
                break;
            case 3:
                this.setState({ continutTab: <CapitoleAdm capitoleAdm={this.state.capitoleAdm} /> });
                break;
            default:
                this.setState({ continutTab: <Benzi benzi={this.state.benzi} validareBenzi={this.validareBenzi} /> });
                break;
        }
    }

    validareBenzi = (refListaCapitole, refListaCampuriTabele) => {
        var idUriLCT = this.state.coloane.map(x => { return x.idListaCampuriTabele });
        var idUriC = this.state.capitoleAdm.map(x => { return x.idListaTabele });
        var flag = false;

        if (idUriC.indexOf(refListaCapitole) >= 0) {
            if (refListaCampuriTabele) {
                if (idUriLCT.indexOf(refListaCampuriTabele) >= 0)
                    flag = true;
                else {
                    alert("Nu există nicio coloană cu id-ul introdus în capitolul selectat.");
                    flag = false;
                }
            } else {
                flag = true;
            }
        }
        else {
            alert("Nu există niciun capitol cu id-ul introdus.");
            flag = false;
        }

        return flag;
    }

    render() {
        //console.log("Administrare capitol props", this.props);
        //console.log("Administrare capitol state", this.state);

        const headerTemplate = (columnHeader) => {
            return <p style={{ width: "100%", textAlign: "center" }}>{columnHeader.column.caption}</p>;
        }

        var deAfisat =
            <div className="container-fluid" style={{ width: "100%" }}>
                <div className="row">
                    <div className="col col-sm-3">
                        <ScrollView style={{ height: (window.innerHeight - 107).toString() + "px" }}>
                            <DataGrid
                                style={{ marginLeft: '5px' }}
                                dataSource={this.state.listaCapitole ? this.state.listaCapitole : null}
                                keyExpr={"denumire"}
                                columnAutoWidth={true}
                                allowColumnReordering={true}
                                showBorders={true}
                                rowAlternationEnabled={false}
                                focusedRowEnabled={true}
                                defaultFocusedRowIndex={0}
                                onRowClick={(e) => { this.handleOnRowClick(e.data); }}
                            >
                                <Paging enabled={false} />
                                <Column dataField="denumire" caption="Capitol" alignment='left' headerCellRender={headerTemplate} />
                            </DataGrid>
                        </ScrollView>
                    </div>

                    <div className="col col-sm-9">
                        <div style={{ textAlign: "left", width: "100%", border: "1px solid #ddd" }}>
                            <p id="tab0" style={{ display: "inline-block", padding: "4px 15px 4px 15px", borderRight: "1px solid #ddd", cursor: "default" }} onClick={() => this.handleTabChange(0)}> Benzi tabele</p>
                            <p id="tab1" style={{ display: "inline-block", padding: "4px 15px 4px 15px", borderRight: "1px solid #ddd", cursor: "default" }} onClick={() => this.handleTabChange(1)}> Rânduri tabele</p>
                            <p id="tab2" style={{ display: "inline-block", padding: "4px 15px 4px 15px", borderRight: "1px solid #ddd", cursor: "default" }} onClick={() => this.handleTabChange(2)}> Coloane tabele</p>
                            <p id="tab3" style={{ display: "inline-block", padding: "4px 15px 4px 15px", borderRight: "1px solid #ddd", cursor: "default" }} onClick={() => this.handleTabChange(3)}> Creare tabele</p>
                        </div>

                        {this.state.continutTab}
                    </div>
                </div>
            </div >

        return (
            <div style={this.stil} id='chenar'>
                <h3>Administrare capitole</h3>
                {deAfisat}
            </div>
        );
    }
}