import axios from 'axios';

export default {
    getListaMembri(membruDeTrimis) {
        return new Promise((resolve, reject) => {
            axios({
                data: membruDeTrimis,
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: "./api/CautareAvansata/GetListaMembriCautareAvansata/",
            })
                .then(response => {
                    let raspuns = {
                        listaMembri: response.data
                    }
                    resolve(raspuns)
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getListaParcele(parcelaDeTrimis) {
        return new Promise((resolve, reject) => {
            axios({
                data: parcelaDeTrimis,
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: "./api/CautareAvansata/GetListaParceleCautareAvansata/",
            })
                .then(response => {
                    let raspuns = {
                        listaParcele: response.data
                    }
                    resolve(raspuns)
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getListaColoane(tip) {
        return new Promise((resolve, reject) => {
            const coloane = axios.get("./api/GridView/getColoane/",
                {
                    params: {
                        tip: tip
                    }
                });

            axios.all([coloane]).then(
                axios
                    .spread((responseC) => {
                        let raspuns = {
                        cols: responseC.data
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        }
    )}
}