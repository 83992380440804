import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Utilitare from './../../../../../../../content/Utilitare';
import { Button, ScrollView, LoadIndicator } from 'devextreme-react';
import DataGrid, { Column, Grouping, GroupPanel, Pager, Paging, SearchPanel, Selection } from 'devextreme-react/data-grid';

export default class AdeverinteSablon extends Component {
    state = {
        loadIndicatorVisible: true,
        linieSelectata: null,
        proprietati: null,
        tokenApi: ""
    }

    componentDidMount() {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "post",//get aici genereaza eroarea 405
            url: this.props.dateApi.linkApi + "/api/Auth/login",
            data: {
                tipPersoana: this.props.dateApi.tipPersoana,
                numeUtilizator: this.props.dateApi.numeUtilizator,
                parolaUtilizator: this.props.dateApi.parolaUtilizator
            }
        })
            .then((response) => {
                this.setState({ tokenApi: response.data.token },
                    () => {
                        axios({
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + this.state.tokenApi
                            },
                            method: "get",//post aici genereaza eroarea 405
                            url: this.props.dateApi.linkApi + "/api/patrimoniu-cnp/" + this.props.cnp
                        })
                            .then((responseP) => {
                                this.setState({
                                    proprietati: responseP.data.proprietati, loadIndicatorVisible: false
                                })
                            })
                            .catch((errorP) => {
                                alert("Persoana nu a fost găsită în aplicaţia de Taxe şi Impozite Locale.");
                                this.setState({ loadIndicatorVisible: false });
                                return;
                            });
                    });
            })
            .catch((error) => {
                alert("Conexiunea către modulul Taxe şi Impozite Locale nu este prezentă sau nu este corect configurată.");
                this.setState({ loadIndicatorVisible: false });
                Utilitare.handleEroare(error, "Patrimoniu");
            });
    }

    salveazaTeren = () => {
        //console.log("linie selectata din state, la click pe buton: ", this.state.linieSelectata);
        this.setState({ loadIndicatorVisible: true });
        var cap = "CAP. II b";
        var deTrimis = {};
        if (this.state.linieSelectata) {
            if (this.state.linieSelectata.suprafata) {
                if (this.state.linieSelectata.codCatFol) {
                    //valorile le gasesti in obiectul primit de la api-ul care ia datele din baza de TI
                    //in Matricola/AtributeMatricola/atributul_care_iti_trebuie/ValoriAtribut
                    switch (this.state.linieSelectata.codCatFol) {
                        case '2':
                            deTrimis.categoriaDeFolosinta = 1;
                            break;
                        case '3':
                            deTrimis.categoriaDeFolosinta = 2;
                            break;
                        case '4':
                            deTrimis.categoriaDeFolosinta = 3;
                            break;
                        case '5':
                        case '15':
                            deTrimis.categoriaDeFolosinta = 4;
                            break;
                        case '6':
                        case '13':
                            deTrimis.categoriaDeFolosinta = 7;
                            break;
                        case '7':
                            deTrimis.categoriaDeFolosinta = 11;
                            break;
                        case '14':
                            deTrimis.categoriaDeFolosinta = 12;
                            break;
                        case '9':
                            deTrimis.categoriaDeFolosinta = 13;
                            break;
                        case '10':
                            deTrimis.categoriaDeFolosinta = 15;
                            break;
                        case '1':
                            deTrimis.categoriaDeFolosinta = 14;
                            break;
                        default:
                            deTrimis.categoriaDeFolosinta = 1;
                    }
                }
                if (this.state.linieSelectata.tarla) {
                    deTrimis.denumireParcelaTarlaSola = this.state.linieSelectata.tarla;
                } else if (this.state.linieSelectata.denMatricola) {
                    deTrimis.denumireParcelaTarlaSola = this.state.linieSelectata.denMatricola;
                }
                deTrimis.modUtilizare = 1;
                deTrimis.proprietar = this.props.numeleSiPrenumele;
                deTrimis.refRegistru = this.props.refRegistru;

                var supHa = Math.floor(this.state.linieSelectata.suprafata / 10000);
                var supAri = (this.state.linieSelectata.suprafata - supHa * 10000) / 100;

                if (this.state.linieSelectata && this.state.linieSelectata.tipTeren) {//daca avem tip teren
                    if (this.state.linieSelectata.tipTeren == "intravilan") {//daca e intravilan
                        if (supHa > 0) {
                            deTrimis.suprafataIntravilanHa = supHa
                        }
                        if (supAri > 0) {
                            deTrimis.suprafataIntravilanAri = supAri;
                        }
                    }
                    else {//daca e extravilan
                        if (supHa > 0) {
                            deTrimis.suprafataExtravilanHa = supHa
                        }
                        if (supAri > 0) {
                            deTrimis.suprafataExtravilanAri = supAri;
                        }
                    }
                } else {//daca nu avem tip teren, il bagam implicit la intravilan
                    if (supHa > 0) {
                        deTrimis.suprafataIntravilanHa = supHa
                    }
                    if (supAri > 0) {
                        deTrimis.suprafataIntravilanAri = supAri;
                    }
                }
            } else {
                alert("Matricola selectată nu are suprafaţa definită şi nu poate fi importată.");
            }
        } else {
            alert("Selectaţi o matricolă!");
        }

        axios({
            data: deTrimis,
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/Capitole/PostCapRanduriVariabile/' + localStorage.getItem("siruta") + "/" + cap + "/" + "false" + "/0"//localStorage.getItem("check2a")
        })
            .then((response) => {
                //daca e ok, aici primim raspuns idUnic != 0 
                if (response.data !== 0) {
                    alert("Matricola a fost salvată cu succes.");
                }
                else {
                    alert("Matricola nu a putut fi salvată.");
                }
                this.setState({ loadIndicatorVisible: false });
                //console.log(response.data);
            })
            .catch((error) => {
                this.setState({ loadIndicatorVisible: false });
                Utilitare.handleEroare(error, "Patrimoniu");
            });
    }

    render() {
        //console.log("Patrimoniu props", this.props);
        //console.log("Patrimoniu state", this.state);

        let proprietati = this.state.proprietati
            ? this.state.proprietati.map(x => {
                let matricole = x.matricole.filter(m => !m.dataIncetare && m.refTipBun === 1).map(m => {//refTipBun=1 => terenuri
                    //console.log("mmm", m);
                    //suprafata
                    var supr = m.refTipBun
                        ? (m.atributeMatricola.filter(a => a.numeAtribut === "totalTeren").length > 0
                            ? m.atributeMatricola.filter(a => a.numeAtribut === "totalTeren")[0].valoareAtribut
                            : 0)
                        : null;

                    //categorie folosinta teren
                    var atributeCatFol = m.refTipBun === 1
                        ? m.atributeMatricola.filter(a => a.numeAtribut === 'categorieFolosinta')[0].valoriAtribut
                        : null;
                    var catFol = m.refTipBun === 1
                        ? atributeCatFol
                            ? m.atributeMatricola.filter(a => a.numeAtribut === 'categorieFolosinta').length > 0
                                ? atributeCatFol.filter(atc => atc.valoareAtribut ==/*lasa2egaluri*/ m.atributeMatricola.filter(a => a.numeAtribut === 'categorieFolosinta')[0].valoareAtribut).length > 0
                                    ? atributeCatFol.filter(atc => atc.valoareAtribut ==/*lasa2egaluri*/ m.atributeMatricola.filter(a => a.numeAtribut === 'categorieFolosinta')[0].valoareAtribut)[0].capturaAtribut
                                    : "arabil"
                                : "arabil"
                            : null
                        : null;
                    var codCatFol = atributeCatFol
                        ? m.atributeMatricola.filter(a => a.numeAtribut === 'categorieFolosinta').length > 0
                            ? m.atributeMatricola.filter(a => a.numeAtribut === 'categorieFolosinta')[0].valoareAtribut
                            : 1
                        : null;

                    //tip teren (intravilan/extravilan)
                    var tipTeren = m.refTipBun === 1
                        ? m.atributeMatricola.filter(a => a.numeAtribut === 'tipTeren')[0].valoareAtribut
                        : null;

                    //tarla/parcela
                    var tarla = m.refTipBun === 1
                        ? m.atributeMatricola.filter(a => a.numeAtribut === 'Tarla')[0].valoareAtribut
                            ? m.atributeMatricola.filter(a => a.numeAtribut === 'Tarla')[0].valoareAtribut
                            : ""
                                + "/" +
                                m.atributeMatricola.filter(a => a.numeAtribut === "nrParcela")[0].valoareAtribut
                                ? m.atributeMatricola.filter(a => a.numeAtribut === "nrParcela")[0].valoareAtribut
                                : ""
                        : null;

                    //compunere matricola din informatiile anterioare
                    var matr = {
                        refMatricola: m.refMatricola,
                        tipBun: m.refTipBun === 1 ? "teren" : "cladire",
                        denMatricola: m.denMatricola ? m.denMatricola : null,
                        suprafata: supr ? parseInt(supr) : 0,
                        catFol: catFol ? catFol : null,
                        codCatFol: codCatFol ? codCatFol : null,
                        tipTeren: tipTeren && tipTeren == 1 ? "intravilan" : "extravilan",
                        tarla: tarla ? tarla !== "/" ? tarla : null : null,
                    };
                    return matr;
                });

                let raspuns =
                    matricole.length > 0
                        ? <div key={x.refProprietate}>
                            <p style={{ fontWeight: "bold" }}>• {x.adresaProprietate.adresa}</p>

                            <DataGrid
                                dataSource={matricole}
                                allowColumnReordering={true}
                                showBorders={true}
                                keyExpr={"refMatricola"}
                                onSelectionChanged={(e) => { this.setState({ linieSelectata: e.selectedRowsData[0] }) }}
                            >
                                <Selection mode="single" deferred={false} />
                                <GroupPanel visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} />
                                <Grouping autoExpandAll={false} />

                                <Column dataField="refMatricola" caption="IDMatricola" visible={false} />
                                <Column dataField="denMatricola" caption="Denumire matricola" />
                                <Column dataField="suprafata" caption="Suprafaţa (mp)" />
                                <Column dataField="catFol" caption="Categorie folosinţă" />
                                <Column dataField="codCatFol" caption="Cod categorie folosinţă" visible={false} />
                                <Column dataField="tipTeren" caption="Tip teren" />
                                <Column dataField="tarla" caption="Tarla/parcela" />
                                <Column
                                    //coloana de butoane customizabile
                                    type="buttons"
                                    width={110}
                                    buttons={[
                                        {
                                            hint: 'Importă',
                                            icon: 'insertcolumnleft',
                                            visible: true,
                                            onClick: (e) => { this.setState({ linieSelectata: e.row.data }, () => { this.salveazaTeren() }) }
                                        }
                                    ]}
                                />

                                <Pager allowedPageSizes={[5, 10, 15]} showPageSizeSelector={true} />
                                <Paging defaultPageSize={10} />
                            </DataGrid>
                        </div>
                        : null;
                return raspuns;
            }
            )
            : null;

        return (
            <Fragment>
                <div>
                    {this.state.loadIndicatorVisible ?
                        <div style={{ position: "absolute", top: "0px", bottom: "0px", left: "0px", right: "0px", zIndex: "1502", backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                            <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", border: "1px solid black", backgroundColor: "white" }}>
                                <p>Se încarcă...</p>
                                <LoadIndicator
                                    className="button-indicator"
                                    visible={this.state.loadIndicatorVisible}
                                />
                            </div>
                        </div>
                        : null}

                    <ScrollView
                        style={{ height: "100%", textAlign: "right" }}
                        direction={"both"}
                    >
                        <div style={{ width: "100%", textAlign: "left" }}>
                            {proprietati && proprietati[0] !== null && proprietati.length > 0 && this.state.loadIndicatorVisible === false ? proprietati : <div>Persoana nu deţine proprietăţi ce pot fi importate în Registrul Agricol.</div>}
                        </div>
                        <Button
                            style={{ margin: "10px 0 0 0", display: "inline-block" }}
                            width={120}
                            text="Închide"
                            type="danger"
                            stylingMode="outlined"
                            onClick={this.props.ascunde}
                        />
                    </ScrollView>

                </div>
            </Fragment>
        );
    }
}