import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import { TextBox, Button, ScrollView } from 'devextreme-react';
import { confirm } from 'devextreme/ui/dialog';
import service from './date';
import Utilitare from '../../../../content/Utilitare';

export default class AdmInterfatareTaxe extends Component {
    state = {
        linkApi: "",
        numeUtilizator: "",
        parolaUtilizator: "",
        tipPersoana: "AplicatieExterna"
    }

    stil = {
        width: '98%',
        height: 'calc(100vh - 57px)',
        margin: 'auto',
        border: '1px solid #ddd',
        textAlign: 'center',
        overflow: "hidden"
    };

    componentDidMount() {
        this.incarcaDate();
    }

    incarcaDate = () => {
        service.getDateApi().then(r => {
            if (r && r.dateApi) {
                if (localStorage.getItem("role").toLowerCase().includes("administrator"))
                    this.setState({
                        linkApi: r.dateApi.linkApi,
                        numeUtilizator: r.dateApi.numeUtilizator,
                        parolaUtilizator: r.dateApi.parolaUtilizator,
                        tipPersoana: r.dateApi.tipPersoana
                    });
                else {
                    alert("Nu aveţi drepturile necesare pentru a administra această secţiune!");
                }
            }
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "DateLocalitate");
        })
    }

    handleSalvare = () => {
        axios({
            headers: {
                "Content-Type": "application/json"
            },
            method: "post",//post aici genereaza eroarea 405
            url: "api/DatePrimarie/SetApiTaxe",
            data: {
                siruta: localStorage.getItem("siruta"),
                linkApi: this.state.linkApi,
                numeUtilizator: this.state.numeUtilizator,
                parolaUtilizator: this.state.parolaUtilizator,
            }
        })
            .then((response) => {
                if (response.data === true) {
                    alert("Datele au fost salvate!");
                }
                else {
                    alert("Datele NU au fost salvate!")
                }
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "AdmInterfatareTaxe");
            });
    }

    render() {
        //console.log("AdmInterfatareTaxe props", this.props);
        //console.log("AdmInterfatareTaxe state", this.state);

        return (
            <Fragment>
                <div style={this.stil} id='chenar'>
                    <ScrollView height={"100%"}>
                        <h3 style={{ display: "inline-block" }}>Administrare Interfaţare Taxe</h3>
                        <p />
                        <TextBox
                            style={{ margin: "10px 0 0 0", display: "inline-block" }}
                            width={"20%"}
                            showClearButton={true}
                            placeholder="Link Api"
                            onValueChanged={(e) => { this.setState({ linkApi: e.value }) }}
                            value={this.state.linkApi}
                        />
                        <p />
                        <TextBox
                            style={{ margin: "10px 0 0 0", display: "inline-block" }}
                            width={"20%"}
                            showClearButton={true}
                            placeholder="Nume utilizator"
                            onValueChanged={(e) => { this.setState({ numeUtilizator: e.value }) }}
                            value={this.state.numeUtilizator}
                        />
                        <p />
                        <TextBox
                            style={{ margin: "10px 0 0 0", display: "inline-block" }}
                            width={"20%"}
                            mode="password"
                            showClearButton={true}
                            placeholder="Parola utilizator"
                            onValueChanged={(e) => { this.setState({ parolaUtilizator: e.value }) }}
                            value={this.state.parolaUtilizator}
                        />
                        <p />
                        <Button
                            text="Salvează"
                            type="normal"
                            stylingMode="contained"
                            onClick={this.handleSalvare}
                        />
                    </ScrollView>
                </div>
            </Fragment>
        );
    }
}