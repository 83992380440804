import React, { Component, Fragment } from 'react';
import { alert } from "devextreme/ui/dialog";
import DataGrid, { Column, Export } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react';
import service from './date';
import { navigate } from "@reach/router";

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';

const dataGridRef = React.createRef(); //necesar pt print to pdf

export default class Utilizatori extends Component {
    state = {
        raportUtilizatori: null,
        popupVisible: false
    }

    stil = {
        width: "98%",
        height: "calc(100vh - 57px)",
        margin: "auto",
        border: "1px solid #ddd",
        textAlign: "center",
        overflow: "hidden"
    };

    componentDidMount() {
        service.getUtilizatori().then(r => {
            var raportUtilizatori = r.dateUtilizatori.data;

            this.setState({
                raportUtilizatori: raportUtilizatori,
            })
        }).catch(e => {//in caz de eroare
            //console.log("eroare Utilizatori.jsx", e);
            alert("S-a produs o eroare la conexiunea cu baza de date! (RaportUtilizatori)");
            navigate(`./date-gospodarii`);
        })
    }

    handlePrintPreview = () => {
        this.setState({ popupVisible: !this.state.popupVisible });
    }


    calculeazaLogat = (rowData) => {
        var logat = false;

        var dataVeche = (new Date(Date.parse(rowData.dataLogare))).setHours(0, 0, 0, 0);
        var dataNoua = (new Date()).setHours(0, 0, 0, 0);

        if (dataVeche === dataNoua)
            logat = true;

        return logat;
    }

    getHeight = () => {
        return document.getElementById("chenar").offsetHeight - 100;
    }

    exportGrid = () => { //print to pdf
        const doc = new jsPDF({ unit: "mm" });//orientation: "landscape" daca este cazul
        doc.setProperties({ title: "Raport Utilizatori" });

        const dataGrid = dataGridRef.current.instance;

        doc.text(25, 20, "Raport Utilizatori");

        exportDataGridToPdf({
            jsPDFDocument: doc,
            component: dataGrid,
            autoTableOptions: {
                startY: 25 //distanta de unde incepe dataGrid-ul
            }
        }).then(() => {
            doc.save('RaportUtilizatori.pdf');
        });
    }

    render() {
//        console.log("Utilizatori state", this.state);
        //console.log("Utilizatori props", this.props);

        const headerTemplate = (columnHeader) => {
            return <p style={{ width: "100%", textAlign: "center" }}>{columnHeader.column.caption}</p>;
        }

        return (
            <Fragment>
                <div style={this.stil} id='chenar'>
                    <h3>Raport utilizatori</h3>
                    <DataGrid
                        ref={dataGridRef} //necesar pentru print in pdf
                        height={this.getHeight}
                        style={{ margin: "0 5px" }}
                        dataSource={this.state.raportUtilizatori ? this.state.raportUtilizatori : null}
                        showBorders={true}
                        paging={ true}
                    >
                        <Export
                            enabled={true}
                            fileName="Raport utilizatori"
                        />
                        <Column dataField="nume" caption="Nume" headerCellRender={headerTemplate} width={"20%"} title={"id"} />
                        <Column dataField="numeUtilizator" caption="Nume utilizator" headerCellRender={headerTemplate} width={"20%"} />
                        <Column dataField="uat" caption="Grup" headerCellRender={headerTemplate} width={"20%"} />
                        <Column dataField="dataLogare" dataType="datetime" format={"dd.MM.yyyy HH:mm:ss"} caption="Data autentificării" headerCellRender={headerTemplate} width={"20%"} />
                        <Column dataField="acum"
                            caption="Logat azi"
                            headerCellRender={headerTemplate}
                            width={"20%"}
                            calculateCellValue={this.calculeazaLogat} />
                    </DataGrid>

                    <div style={{ marginTop: "5px" }}>
                    <Button //necesar pt print to pdf
                        id='exportButton'
                        icon='exportpdf'
                        text='Export to PDF'
                        onClick={this.exportGrid}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}