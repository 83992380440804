import axios from 'axios';

const utilizator = {
    nume: "",
    parola: "",
    UAT: ""
}

export default {
    getListaUAT() {
        //request ce returneaza liste de primarii si useri pentru popularea drop-ului din pagina de login in fctie de utilizator
        return new Promise((resolve, reject) => {

            //axios.defaults.baseURL = "/RA_V2";

            axios.get("./api/DatePrimarie/getListaPrimariiUseri").then(response => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    getUtilizator() {
        return utilizator;
    }
};