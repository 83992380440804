var dateMeniu =
    [//meniul principal
        {
            id: '0',
            denumire: 'Închide meniu',
            icon: 'menu'
        },
        {
            id: '1',
            denumire: 'Acasă/Delogare',
            icon: 'home'
        },
        {
            id: '2',
            denumire: 'Vizualizare-introducere date',
            icon: 'edit'
        },
        {
            id: '3',
            denumire: 'Interfaţări',
            icon: 'info',
            items: [{
                id: '3.1',
                denumire: 'APIA',
                icon: 'spinup'
            },
            {
                id: '3.2',
                denumire: 'ANCPI',
                icon: 'spinup'
            },
            {
                id: '3.3',
                denumire: 'RNEP',
                icon: 'spinup'
            },
            {
                id: '3.4',
                denumire: 'ONRC',
                icon: 'spinup'
            },
            {
                id: '3.5',
                denumire: 'RAN',
                icon: 'spinup'
            }]
        },
        {
            id: '4',
            denumire: 'Mișcări',
            icon: 'warning',
            items: [{
                id: '4.1',
                denumire: 'Defalcare gospodării',
                icon: 'spinup'
            },
            {
                id: '4.2',
                denumire: 'Unificare gospodării',
                icon: 'spinup'
            }]
        },
        {
            id: '5',
            denumire: 'Rapoarte',
            icon: 'print',
            items: [{
                id: '5.1',
                denumire: 'Descarca Declaratie',
                icon: 'spinup'
            }, {
                id: '5.2',
                denumire: 'Situatie gospodarii/firme cu selectie dupa volum',
                icon: 'spinup'
            }, {
                id: '5.3',
                denumire: 'Rapoarte centralizatoare',
                icon: 'spinup'
            }//, {
                //id: '5.4',
                //denumire: "Producţia animală",
                //icon: 'spinup'
                //}
                , {
                id: '5.5',
                denumire: "Producţia vegetală",
                icon: 'spinup'
            }
            ]
        },
        {
            id: '6',
            denumire: 'Administrare',
            icon: 'toolbox',
            items: [{
                id: '6.1',
                denumire: 'Capitole',
                icon: 'spinup'
            },
            {
                id: '6.2',
                denumire: 'Nomenclatoare',
                icon: 'spinup'
            },
            {
                id: '6.3',
                denumire: 'Date localitate',
                icon: 'spinup'
            },
            {
                id: '6.4',
                denumire: 'Interfatare Taxe',
                icon: 'spinup'
            },
            {
                id: '6.5',
                denumire: 'Transferuri',
                items: [{
                    id: '6.5.1',
                    denumire: 'Transfer date in anul următor',
                    icon: 'spinup'
                }]
            }]
        },
        {
            id: '7',
            denumire: 'Utilizatori',
            icon: 'user',
            items: [
                {
                    id: '7.1',
                    denumire: 'Adăugați',
                    icon: 'spinup'
                },
                {
                    id: '7.2',
                    denumire: 'Modificați',
                    icon: 'spinup'
                },
                {
                    id: '7.3',
                    denumire: 'Ştergeţi',
                    icon: 'spinup'
                }]
        },
        {
            id: '8',
            icon: 'group',
            denumire: 'Raport utilizatori'
        },
        {
            id: '9',
            denumire: 'Manual',
            icon: 'doc'
        }
    ];

var dateMeniuUtilizatorExtern =
    [//meniul principal UtilizatorExytern
        {
            id: '0',
            denumire: 'Închide meniu',
            icon: 'menu'
        },
        {
            id: '1',
            denumire: 'Acasă/Delogare',
            icon: 'home'
        },
        {
            id: '2',
            denumire: 'Vizualizare date',
            icon: 'edit'
        },
        //{
        //    id: '5',
        //    denumire: 'Rapoarte',
        //    icon: 'print',
        //    items: [{
        //        id: '5.1',
        //        denumire: 'Descarca Declaratie',
        //        icon: 'spinup'
        //    }
        //    ]
        //},
        {
            id: '7',
            denumire: 'Utilizatori',
            icon: 'user',
            items: [
                {
                    id: '7.2',
                    denumire: 'Modificați',
                    icon: 'spinup'
                }]
        }];
export default { dateMeniu, dateMeniuUtilizatorExtern };