import React, { Component } from 'react';
import { alert } from "devextreme/ui/dialog";
import DataGrid, { Column, Paging, FilterRow, FilterPanel } from 'devextreme-react/data-grid';
import { Button, TextBox, ScrollView, LoadIndicator, Popup } from 'devextreme-react';
import axios from 'axios';
import clase from './RNEP.module.css';
import Utilitare from '../../../content/Utilitare';
import service from './DateInterfatari';

export default class Rnep extends Component {
    state = {
        fisierSelectat: null,
        loadIndicatorVisible: true,
        popupVisible: false,
        adresa: "",
        un: "",
        pass: "",
        ssh: "",
        port: ""
    }

    stil = {
        position: "relative",
        width: '98%',
        height: 'calc(100vh - 57px)',
        margin: 'auto',
        border: '1px solid #ddd',
        textAlign: "center",
        overflow: "hidden"
    };

    stilButon = {
        margin: "0 5px 0 0"
    }

    componentDidMount() {
        service.getDateRNEP().then(r => {
            var listaFisiere = r.fisiere.map((x) => {
                return ({ denumire: x })
            });

            this.setState({
                dateInterogari: r.persoane,
                listaFisiere: listaFisiere
            }, () => { this.setState({ loadIndicatorVisible: false }) })

        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "RNEP");
        })
    }

    handleOnClickAfiseazaPopup = () => {//cred ca trebuie inlocuit cu action-ul ce construieste si trimite fisierul
        this.setState({ popupVisible: true });
    }

    handleOnClickPopup = () => {
        if ((this.state.adresa && this.state.adresa !== "") || Utilitare.verificareIP(this.state.adresa)) {

            if (!this.state.un || this.state.un === "") {
                alert("Nume utilizator invalid.")
                return;
            }

            if (!this.state.pass || this.state.pass === "") {
                alert("Parola invalidă.")
                return;
            }

            if (!this.state.ssh || this.state.ssh === "") {
                alert("Ssh invalid.");
                return;
            }

            if (!this.state.port || this.state.port === "" || (Utilitare.isNumeric(this.state.port) === false)) {
                alert("Port invalid.");
                return;
            }

            this.setState({ loadIndicatorVisible: true });

            if (this.state.destinatiePopup === "Trimite")
                this.trimite();
            if (this.state.destinatiePopup === "Descarcă")
                this.descarca();
            if (this.state.destinatiePopup === "Şterge")
                this.sterge();

        }
        else {
            alert("Adresă invalidă.")
            return;
        }
    }

    trimite = () => {
        this.setState({ loadIndicatorVisible: true });

        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Interfatari/TrimiteFisier/' + localStorage.getItem("siruta") + "/" + this.state.adresa + "/" + this.state.un + "/" + this.state.pass + "/" + this.state.ssh + "/" + this.state.port,
        })
            .then((response) => {
                this.setState({ popupVisible: false, loadIndicatorVisible: false });
                if (response.data === false)
                    alert("Utilizatorul logat nu are drepturi pe sFTP sau datele introduse pentru autentificare nu sunt corecte.\nFişierul nu a fost trimis.")
                else
                    alert("Fişierul a fodst trimis");
            })
            .catch((error) => {
                this.setState({ loadIndicatorVisible: false, popupVisible: false });
                Utilitare.handleEroare(error, "RNEP");
            });
    }

    descarca = () => {
        this.setState({ loadIndicatorVisible: true });

        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Interfatari/DescarcaFisier/' + localStorage.getItem("siruta") + "/" + this.state.un + "/" + this.state.pass + "/" + this.state.adresa + "/" + this.state.ssh + "/" + parseInt(this.state.port),
        })
            .then((response) => {
                //console.log(response.data);
                if (response.data == null) {
                    alert("S-a produs o eroare la conexiunea cu baza de date.");
                }
                else {
                    var listaFisiere = response.data.map((x) => {
                        return ({ denumire: x })
                    });

                    this.setState({ listaFisiere: listaFisiere }, () => { this.setState({ loadIndicatorVisible: false, popupVisible: false }) });
                }
            })
            .catch((error) => {
                this.setState({ loadIndicatorVisible: false, popupVisible: false });
                Utilitare.handleEroare(error, "RNEP");
            });
    }

    sterge = () => {
        this.setState({ loadIndicatorVisible: true });

        if (this.state.fisierSelectat === null) {
            alert("Nu aţi selectat niciun fişier.")
            return;
        }

        this.setState({ loadIndicatorVisible: true });
        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Interfatari/StergeFisier/'
                + localStorage.getItem("siruta") + "/"
                + this.state.fisierSelectat + "/"
                + this.state.un + "/"
                + this.state.adresa + "/"
                + this.state.pass + "/"
                + this.state.ssh + "/"
                + this.state.port,
        })
            .then((response) => {
                if (response.data == null) {
                    alert("Eroare la conexiunea cu baza de date. (RNEP.jsx)");
                }
                else {
                    var listaFisiere = response.data.map((x) => {
                        return ({ denumire: x })
                    });

                    this.setState({ listaFisiere: listaFisiere }, () => { this.setState({ loadIndicatorVisible: false, popupVisible: false }) })
                }
            })
            .catch((error) => {
                this.setState({ loadIndicatorVisible: false, popupVisible: false });
                Utilitare.handleEroare(error, "RNEP");
            });
    }

    handleOnClickProceseaza = () => {
        if (this.state.fisierSelectat === null) {
            alert("Nu aţi selectat niciun fişier.")
            return;
        }

        this.setState({ loadIndicatorVisible: true });
        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Interfatari/ProceseazaRaspuns/' + localStorage.getItem("siruta") + "/" + this.state.fisierSelectat,
        })
            .then((response) => {
                if (response.data == null) {
                    alert("S-a produs o eroare la conexiunea cu baza de date. (RNEP.jsx)");
                }
                else {
                    var listaFisiere = response.data.map((x) => {
                        return ({ denumire: x })
                    });

                    this.setState({ listaFisiere: listaFisiere }, () => { this.setState({ loadIndicatorVisible: false }) })
                }
            })
            .catch((error) => {
                this.setState({ loadIndicatorVisible: false });
                Utilitare.handleEroare(error, "RNEP");
            });
    }

    handleOnRowClick = (numeFisier) => {
        this.setState({ fisierSelectat: numeFisier });
    }

    render() {
        const headerTemplate = (columnHeader) => {
            return <p style={{ width: "100%", textAlign: "center" }}>{columnHeader.column.caption}</p>;
        }

        return (
            <React.Fragment>
                <div style={this.stil} id='chenar'>
                    {this.state.loadIndicatorVisible ?
                        <div style={{ position: "absolute", top: "0px", bottom: "0px", left: "0px", right: "0px", zIndex: "1502", backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                            <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", border: "1px solid black", backgroundColor: "white" }}>
                                <p>Se încarcă...</p>
                                <LoadIndicator
                                    className="button-indicator"
                                    visible={this.state.loadIndicatorVisible}
                                />
                            </div>
                        </div>
                        : null}
                    <ScrollView height="98%">
                        <h3>Interfaţare RNEP</h3>
                        <table id="tabel">
                            <tbody>
                                <tr>
                                    <td style={{ width: '70%', verticalAlign: 'top' }}>
                                        { /*coloana din stanga*/}
                                        <DataGrid
                                            style={{ marginLeft: '5px' }}
                                            dataSource={this.state.dateInterogari ? this.state.dateInterogari : null}
                                            columnAutoWidth={true}
                                            allowColumnReordering={true}
                                            showBorders={true}
                                            keyExpr={"idInterogariRNEP"}
                                            rowAlternationEnabled={false}
                                        >
                                            <FilterRow
                                                visible={true}
                                                applyFilter={true} />
                                            <FilterPanel visible={true} />
                                            <Paging defaultPageSize={10} />

                                            <Column dataField="idInterogariRNEP" caption="ID" alignment='center' headerCellRender={headerTemplate} />
                                            <Column dataField="cnp" caption="cnp" headerCellRender={headerTemplate} />
                                            <Column dataField="serie" caption="Serie" headerCellRender={headerTemplate} />
                                            <Column dataField="numar" caption="Număr" headerCellRender={headerTemplate} />
                                            <Column dataField="nume" caption="Nume" headerCellRender={headerTemplate} />
                                            <Column dataField="prenume" caption="Prenume" headerCellRender={headerTemplate} />
                                            <Column dataField="response" caption="Răspuns" headerCellRender={headerTemplate} />
                                            <Column dataField="iS_DataInterogare" caption="Data răspuns" headerCellRender={headerTemplate} />
                                        </DataGrid>
                                        <br />
                                        <Button
                                            text="Trimite fişier date pentru procesare"
                                            type="normal"
                                            stylingMode="contained"
                                            onClick={() => {
                                                this.setState({ destinatiePopup: "Trimite" });
                                                this.handleOnClickAfiseazaPopup();
                                            }}
                                        />
                                    </td>
                                    <td style={{ verticalAlign: 'top' }}>
                                        { /*coloana din dreapta*/}
                                        <DataGrid
                                            style={{ marginRight: '5px' }}
                                            dataSource={this.state.listaFisiere ? this.state.listaFisiere : null}
                                            columnAutoWidth={true}
                                            allowColumnReordering={true}
                                            showBorders={true}
                                            keyExpr={"denumire"}
                                            rowAlternationEnabled={false}
                                            focusedRowEnabled={true}
                                            onRowClick={(e) => { this.handleOnRowClick(e.data.denumire); }}
                                        >
                                            <FilterRow
                                                visible={true}
                                                applyFilter={true} />
                                            <FilterPanel visible={true} />
                                            <Paging defaultPageSize={10} />
                                            <Column dataField="denumire" caption="Nume fisier" alignment='center' headerCellRender={headerTemplate} />
                                        </DataGrid>
                                        <br />
                                        <div>
                                            <Button
                                                style={this.stilButon}
                                                text="Descarcă răspuns"
                                                type="normal"
                                                stylingMode="contained"
                                                onClick={() => {
                                                    this.setState({ destinatiePopup: "Descarcă" });
                                                    this.handleOnClickAfiseazaPopup();
                                                }}
                                            />
                                            <Button
                                                style={this.stilButon}
                                                text="Şterge răspuns"
                                                type="normal"
                                                stylingMode="outlined"
                                                onClick={() => {
                                                    this.setState({ destinatiePopup: "Şterge" });
                                                    this.handleOnClickAfiseazaPopup();
                                                }}
                                            />
                                            <Button
                                                text="Procesează răspuns"
                                                type="normal"
                                                stylingMode="outlined"
                                                onClick={this.handleOnClickProceseaza}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Popup
                            visible={this.state.popupVisible}
                            dragEnabled={false}
                            closeOnOutsideClick={false}
                            showCloseButton={false}
                            showTitle={true}
                            title="Autentificare sFTP"
                            width={500}
                            height={300}
                        >
                            <TextBox
                                style={{ margin: "10px 0 0 0" }}
                                width={"100%"}
                                showClearButton={true}
                                placeholder="Adresă conectare"
                                onValueChanged={(e) => { this.setState({ adresa: e.value }) }}
                                value={this.state.adresa}
                            />

                            <TextBox
                                style={{ margin: "10px 0 0 0" }}
                                width={"100%"}
                                showClearButton={true}
                                placeholder="Nume utilizator"
                                onValueChanged={(e) => { this.setState({ un: e.value }) }}
                                value={this.state.un}
                            />

                            <TextBox
                                style={{ margin: "10px 0 0 0" }}
                                width={"100%"}
                                showClearButton={true}
                                placeholder="Parola"
                                mode="password"
                                onValueChanged={(e) => { this.setState({ pass: e.value }) }}
                                value={this.state.pass}
                            />

                            <TextBox
                                style={{ margin: "10px 0 0 0" }}
                                width={"100%"}
                                showClearButton={true}
                                placeholder="ssh"
                                onValueChanged={(e) => { this.setState({ ssh: e.value }) }}
                                value={this.state.ssh}
                            />

                            <TextBox
                                style={{ margin: "10px 0 0 0" }}
                                width={"100%"}
                                showClearButton={true}
                                placeholder="port"
                                onValueChanged={(e) => { this.setState({ port: e.value }) }}
                                value={this.state.port}
                            />

                            <div className={clase.Butoane}>
                                <Button
                                    className={clase.Buton}
                                    text={this.state.destinatiePopup}
                                    type="normal"
                                    stylingMode="outlined"
                                    onClick={this.handleOnClickPopup}
                                />
                                <Button
                                    className={clase.Buton}
                                    text="Înapoi"
                                    type="danger"
                                    stylingMode="outlined"
                                    onClick={() => { this.setState({ popupVisible: false }) }}
                                />
                            </div>
                        </Popup>
                    </ScrollView>
                </div>
            </React.Fragment >
        );
    }
}