import React, { Component, Fragment } from 'react';
import { alert } from "devextreme/ui/dialog";
import { Button, ScrollView } from 'devextreme-react';
import service from './DateInterfatari';
import Utilitare from './../../../content/Utilitare';

export default class Ancpi extends Component {
    state = {
        localitate: null,
        judet: null
    }

    stil = {
        width: '98%',
        height: 'calc(100vh - 57px)',
        margin: 'auto',
        border: '1px solid #ddd',
        textAlign: "center",
        overflow: "hidden"
    };

    stilButon = {
        margin: "0 0 5px 0"
    };

    componentDidMount() {
        let siruta = localStorage.getItem("siruta") ? localStorage.getItem("siruta").replaceAll("\"", "") : null;

        service.getLocalitateJudet(siruta).then(r => {
            this.setState({
                localitate: r.localitate,
                judet: r.judet
            })

        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "ANCPI");
        })
    }

    handleOnClickDdapt = () => {
        let url = 'https://appgw.ancpi.ro:4442/ddapt_up/public/externallogin.aspx';
        let win = window.open(url, '_blank');
        win.focus();
    }

    handleOnClickManual = () => {
        alert('Afiseaza manual DDAPT');
    }

    handleOnClickOrtofoto = () => {
        let url = 'http://geoportal.ancpi.ro/geoportal/imobile/Harta.html';
        let win = window.open(url, '_blank');
        win.focus();
    }

    handleOnClickHarta = () => {
        let url = "https://geoportal.ancpi.ro/portal/apps/webappviewer/index.html?find=%20" + this.state.localitate + ",%20" + this.state.judet + ",%20ROU&level=8";
        let win = window.open(url, '_blank');
        win.focus();
    }

    handleOnClickPerimetru = () => {
        let url = "https://geoportal.ancpi.ro/portal/apps/webappviewer/index.html?id=3636729de6c94787aee0899565d0a515&find=" + this.state.localitate + ",%20" + this.state.judet + ",%20ROU";
        let win = window.open(url, '_blank');
        win.focus();
    }

    handleOnClickNrCadastral = () => {
        let url = "http://geoportal.ancpi.ro/geoportal/imobile/Harta.html";
        let win = window.open(url, '_blank');
        win.focus();
    }

    handleOnClickGeoportal = () => {
        let url = "https://geoportal.ancpi.ro/portal/home/";
        let win = window.open(url, '_blank');
        win.focus();
    }

    render() {
        return (
            <Fragment>
                <ScrollView height="80%">
                    <div style={this.stil} id='chenar'>
                        <h3>Interfaţare ANCPI</h3>

                        <Button
                            width={200}
                            style={this.stilButon}
                            text="ANCPI - DDAPT"
                            type="normal"
                            stylingMode="contained"
                            onClick={this.handleOnClickDdapt}
                        />
                        <Button
                            width={200}
                            style={this.stilButon}
                            text="Manual - DDAPT"
                            type="normal"
                            stylingMode="contained"
                            onClick={this.handleOnClickManual}
                            visible={false}
                        />
                        <br />

                        <Button
                            width={200}
                            style={this.stilButon}
                            text="ANCPI - Ortofotoplanuri"
                            type="normal"
                            stylingMode="contained"
                            onClick={this.handleOnClickOrtofoto}
                        />
                        <br />

                        <Button
                            width={200}
                            style={this.stilButon}
                            text="Harta"
                            type="normal"
                            stylingMode="contained"
                            onClick={this.handleOnClickHarta}
                        />
                        <br />

                        <Button
                            width={200}
                            style={this.stilButon}
                            text="ANCPI - Perimetrul construit"
                            type="normal"
                            stylingMode="contained"
                            onClick={this.handleOnClickPerimetru}
                        />
                        <br />

                        <Button
                            width={200}
                            style={this.stilButon}
                            text="ANCPI - Cautare nr. cadastral"
                            type="normal"
                            stylingMode="contained"
                            onClick={this.handleOnClickNrCadastral}
                        />
                        <br />

                        <Button
                            width={200}
                            style={this.stilButon}
                            text="ANCPI - Geoportal"
                            type="normal"
                            stylingMode="contained"
                            onClick={this.handleOnClickGeoportal}
                        />
                    </div>
                </ScrollView>
            </Fragment>
        );
    }
}