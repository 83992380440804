import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { alert } from "devextreme/ui/dialog";
import DataGrid, { Popup, Editing, Column, Paging, RequiredRule, RangeRule, ValidationRule } from 'devextreme-react/data-grid';
import { ScrollView } from 'devextreme-react';
import { navigate } from "@reach/router";
import Utilitare from './../../../../../content/Utilitare';

export default class CapitoleAdm extends Component {
    state = {
        linieSelectata: null,
        capitole: this.props.capitoleAdm
    }

    handleOnSaving = (date) => {
        if (date.changes[0].type === "insert") {
            if (date.changes[0].data.stopVal && date.changes[0].data.stopVal < date.changes[0].data.startVal) {
                alert("Data Stop nu poate fi anterioară Datei Start.");
                return;
            }

            axios({
                data: date.changes[0].data,
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: './api/Administrare/InsertCapitolAdm'
            })
                .then((response) => {
                    if (response.data === null) {
                        alert("Capitolul nu a fost inserat.");
                        navigate('./date-gospodarii')
                    }
                    else
                        this.setState({ capitole: response.data });
                })
                .catch((error) => {
                    Utilitare.handleEroare(error, "CapitoleAdm");
                });
        }

        if (date.changes[0].type === "remove") {
            axios({
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: './api/Administrare/DeleteCapitolAdm/' + date.changes[0].key,
            })
                .then((response) => {
                    if (response.data === null) {
                        alert("Capitolul nu a fost şters.");
                        navigate('./date-gospodarii');
                    }
                    else
                        this.setState({ capitole: response.data });
                })
                .catch((error) => {
                    Utilitare.handleEroare(error, "MeniuAplicatie");
                });
        }
    }

    validareCustomStopVal = (date) => {
        if (parseInt(date.startVal) > parseInt(date.stopVal))
            return false;
        else
            return true;
    }

    render() {
        //console.log("CapitolNou.jsx props", this.props);
        //console.log("CapitolNou.jsx state", this.state);

        const headerTemplate = (columnHeader) => {
            return <div style={{ width: "100%", textAlign: "center" }}>{columnHeader.column.caption}</div>;
        }

        return (
            <Fragment>
                <ScrollView style={{ height: (window.innerHeight - 200).toString() + "px" }}>
                    <DataGrid
                        style={{ margin: "5px" }}
                        dataSource={this.state.capitole ? this.state.capitole : null}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        showBorders={true}
                        keyExpr={"idListaTabele"}
                        rowAlternationEnabled={false}
                        onRowClick={(e) => { this.setState({ linieSelectata: e.data }) }}
                        onSaving={(e) => { this.handleOnSaving(e) }}
                        selectedRowKeys={this.state.linieSelectata && this.state.linieSelectata.idListaTabele ? [this.state.linieSelectata.idListaTabele] : null}
                        onContextMenuPreparing={(e) => {
                            if (e && e.row && e.row.data)
                                this.setState({ linieSelectata: e.row.data }, () => {
                                    e.items = [{
                                        text: "Adaugă",
                                        icon: "add",
                                        onItemClick: function () {
                                            e.component.addRow();
                                        }
                                    },
                                    {
                                        text: "Şterge",
                                        icon: "trash",
                                        onItemClick: function () {
                                            e.component.deleteRow(e.row.rowIndex);
                                        }
                                    }];
                                })
                            else {
                                e.items = [{
                                    text: "Adaugă",
                                    icon: "add",
                                    onItemClick: function () {
                                        e.component.addRow();
                                    }
                                }];
                            }
                        }}
                    >
                        {(!localStorage.getItem("checkClickDreapta").replaceAll(" ", "").split(",").includes(localStorage.getItem("siruta").toString()))
                            ? <Editing
                                mode="popup"
                            >
                                <Popup
                                    showTitle={true}
                                    title="Inserare capitol"
                                />
                            </Editing>
                            : <Editing
                                //allowUpdating={true}
                                allowAdding={true}
                                allowDeleting={true}
                                useIcons={true}
                                mode="popup"
                            >
                                <Popup
                                    showTitle={true}
                                    title="Inserare capitol"
                                />
                            </Editing>
                        }
                        <Paging enabled={false} />
                        {/*exeplu coloana (nu sterge headerTemplate):*/}
                        {/*<Column dataField="idListaTabele" caption="Id" alignment='center' headerCellRender={headerTemplate} visibile={false} />*/}
                        {/*<Column dataField="refListaCapitole" caption="RefCap" alignment='center' headerCellRender={headerTemplate} />*/}
                        <Column dataField="idParinte" caption="Parinte" alignment='center' headerCellRender={headerTemplate} dataType={"number"} />
                        <Column dataField="codRand" caption="Cod Rand" alignment='center' headerCellRender={headerTemplate} dataType={"number"} >
                            <RequiredRule />
                        </Column>
                        <Column dataField="insereaza" caption="Insereaza" alignment='center' headerCellRender={headerTemplate} dataType={"boolean"}>
                            <RequiredRule />
                        </Column>
                        <Column dataField="denumire" caption="Denumire" alignment='left' headerCellRender={headerTemplate} dataType={"string"}>
                            <RequiredRule />
                        </Column>
                        <Column dataField="cap" caption="Prescurtare" alignment='center' headerCellRender={headerTemplate} dataType={"string"} />
                        <Column dataField="startVal" caption="Start" alignment='center' headerCellRender={headerTemplate} dataType={"number"} >
                            <RequiredRule />
                            <RangeRule min={1500} max={9999} />
                        </Column>
                        <Column dataField="stopVal" caption="Stop" alignment='center' headerCellRender={headerTemplate} dataType={"number"} >
                            <RangeRule min={1500} max={9999} />
                            <ValidationRule type={"custom"} validationCallback={(e) => { return this.validareCustomStopVal(e.data) }} message="DataStop anterioara DataStart."></ValidationRule>
                        </Column>
                    </DataGrid>
                </ScrollView>
            </Fragment>
        );
    }
}