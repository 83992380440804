import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';
import Utilitare from './content/Utilitare';
import queryString from 'query-string';
//import { alert } from "devextreme/ui/dialog";

import axios from 'axios';

import { Button } from 'devextreme-react';
import notify from 'devextreme/ui/notify';

import './custom.css';

import { Router, navigate } from '@reach/router';//routerul lui Ciprian

//import roMessages from "devextreme/localization/messages/ro.json";
import { locale/*, loadMessages*/ } from "devextreme/localization";

import Convertor from './components/Convertor/Convertor';
import MeniuAplicatie from './components/MeniuAplicatie/MeniuAplicatie';
import Acasa from './components/Pagina/Acasa/Acasa';
import DateGospodarii from './components/Pagina/DateGospodarii/DateGospodarii';
import DefalcareGospodarie from './components/Pagina/Miscari/DefalcareGospodarie/DefalcareGospodarie';
import UnificareGospodarie from './components/Pagina/Miscari/UnificareGospodarie/UnificareGospodarie';
import NotFound from './components/NotFound/NotFound';
import SituatieGF from './components/Pagina/Rapoarte/SituatieGF/SituatieGF';
import RapoarteCentralizatoare from './components/Pagina/Rapoarte/RapoarteCentralizatoare/RapoarteCentralizatoare';
import Utilizatori from './components/Pagina/Utilizatori/Utilizatori';
import AdaugatiUtilizator from './components/Pagina/Utilizatori/Adaugati';
import ModificatiUtilizator from './components/Pagina/Utilizatori/Modificati';
import StergetiUtilizator from './components/Pagina/Utilizatori/Stergeti';
import Capitole from './components/Pagina/Administrare/Capitole/Capitole';
import Transfer from './components/Pagina/Administrare/Transfer/Transfer';
import DateLocalitate from './components/Pagina/Administrare/DateLocalitate/DateLocalitate';
import Nomenclatoare from './components/Pagina/Administrare/Nomenclatoare/Nomenclatoare';
import AdmInterfatareTaxe from './components/Pagina/Administrare/InterfatareTaxe/AdmInterfatareTaxe';
import Apia from './components/Pagina/Interfatari/APIA';
import Ancpi from './components/Pagina/Interfatari/ANCPI';
import Rnep from './components/Pagina/Interfatari/RNEP';
import Onrc from './components/Pagina/Interfatari/ONRC';
import Ran from './components/Pagina/Interfatari/RAN';
import ProductiaAnimala from './components/Pagina/Rapoarte/Productii/ProductiaAnimala';
import ProductiaVegetala from './components/Pagina/Rapoarte/Productii/ProductiaVegetala';

//let esteLogat = true;

export default class App extends Component {
    constructor(props) {
        super(props);
        //loadMessages(roMessages);
        locale(navigator.language);

        axios.interceptors.response.use(
            response => {
                return response;
            },
            async error => {
                const originalRequest = error.config;
                if (error && error.response && !originalRequest._retry)
                    if (error.response.status === 401 || error.response.status === 400) {
                        notify({ message: "Nu sunteti autorizat să accesaţi aceste resurse.", position: "top center", shading: true }, "error", 3000);
                        //navigate(`./`);
                    } else if ((error.response.status === 403)) {
                        notify({ message: "Nu aveţi dreptul să accesaţi aceste resurse. Contactaţi administratorul aplicaţiei.", position: "top center", shading: true }, "error", 3000);
                        navigate(`./date-gospodarii`);
                    }
                return Promise.reject(error);
            }
        );
    }

    state = {
        loggedIn: localStorage.getItem("siruta") ? true : false, //pune aici doar FALSE daca vrei sa se delogheze la refresh(din F5)
        idGospSelectata: null,
        deschis: false,
        anSelectat: null
    }

    static displayName = App.name;
    /*PENTRU ROUTING folosim @reach/router in loc de react-router-dom. vezi apelarea in MeniuAplicatie.js. Nu uita sa instalezi pachetul*/
    setIdGospSelectata = (id) => {
        //this.setState({ idGospSelectata: id ?? -1 });
    }

    inchideMeniu = () => {
        this.setState({ deschis: !this.state.deschis });
    }

    handleDelogare = () => {
        this.setState({
            idGospodariaSelectata: null,
            deschis: false,
            loggedIn: false
        });
    }

    handleAnSelectat = (an) => {
        this.setState({ anSelectat: an });
    }

    render() {
        let params = queryString.parse(window.location.search)
        let externalToken = params ? params["externalToken"] : undefined;
        if (externalToken) {
            Utilitare.loginToken('/api/Account/Login-jwt', { token: externalToken });
        }
        //console.log("app.js state", this.state);

        /*trebuie sa modifici si in MeniuAplicatie functionalitatea butonului*/
        return (
            <div style={{ margin: "10px", height: (window.innerHeight - 21).toString() + "px", backgroundColor: "white", overflow: "hidden" }}>

                <Convertor style={{ display: "table", margin: "auto" }} />

                <Button
                    icon={"menu"}
                    style={{ position: "fixed", top: "10px", left: "10px", zIndex: "1000", borderRadius: "0", height: "21", width: "21" }}
                    onClick={() => { this.setState({ deschis: !this.state.deschis }) }}
                    visible={this.state.loggedIn}
                />

                <div style={{
                    overflow: "hidden",
                    width: this.state.deschis ? "300px" : "40px",
                    backgroundColor: "white",
                    position: "fixed",
                    top: "10px",
                    left: "10px",
                    zIndex: "1000",
                    transition: "all 0.3s ease-in-out"
                }}>
                    {this.state.deschis
                        ? <MeniuAplicatie inchidereMeniu={this.inchideMeniu} drawerDeschis={this.state.deschis} style={{ height: "1000px" }} idGospSelectata={this.state.idGospSelectata} handleDelogare={this.handleDelogare} />
                        : null}
                </div>

                <div id="view" style={{ backgroundColor: "white", position: "relative", left: (this.state.deschis ? "300px" : 0), overflow: "hidden", transition: "all 0.3s ease-in-out" }}>
                    <div style={{ marginLeft: "15px" }}>
                        {this.state.loggedIn ?
                            <Router primary={false} /*basepath="/RA_V2"*/ onChange={(e) => { debugger; console.log(e); }} >
                                <Acasa context={this} path='/' icon="home" ascundeMeniu={this.handleLoggedIn} externalToken={externalToken}/>
                                <DateGospodarii setIdGospodariaSelectata={this.setIdGospSelectata} path='/date-gospodarii' handleDelogare={this.handleDelogare} />
                                <DefalcareGospodarie idGospSelectata={this.state.idGospSelectata} path='/defalcare-gospodarii' />
                                <UnificareGospodarie idGospSelectata={this.state.idGospSelectata} path='/unificare-gospodarii' />
                                <SituatieGF idGospSelectata={this.state.idGospSelectata} path='/situatieGF' />
                                <RapoarteCentralizatoare path='/rapoarteCentralizatoare' />
                                <ProductiaAnimala path='/productiaAnimala' />
                                <ProductiaVegetala path='/productiaVegetala' />
                                <Utilizatori path='/utilizatori' />
                                <AdaugatiUtilizator path='/adaugatiUtilizator' />
                                <ModificatiUtilizator path='/modificatiUtilizator' />
                                <StergetiUtilizator path='/stergetiUtilizator' />
                                <Capitole path='/capitole' />
                                <Transfer path='/transfer' anSelectat={this.handleAnSelectat} />
                                <DateLocalitate path='/dateLocalitate' />
                                <Nomenclatoare path='/nomenclatoare' />
                                <AdmInterfatareTaxe path='/admInterfatareTaxe' />
                                <Apia path='/apia' />
                                <Ancpi path='/ancpi' />
                                <Rnep path='/rnep' />
                                <Onrc path='/onrc' />
                                <Ran path='/ran' />
                                <NotFound path='/not-found' default />
                            </Router>
                            :
                            <Router primary={false} /*basepath="/RA_V2"*/>
                                <Acasa context={this} path='/' icon="home" externalToken={externalToken}/>
                                <Acasa context={this} default externalToken={externalToken}/>
                            </Router>
                        }
                    </div>
                </div>
            </div>
        );
    }
}