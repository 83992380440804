import React, { Component, Fragment } from 'react';
import { alert } from "devextreme/ui/dialog";
import { TextBox } from 'devextreme-react';
import clase from './Convertor.module.css';

export default class Convertor extends Component {
    state = {
        valoareInitiala: '0 mp',
        ha: '0',
        ari: '0'
    }

    handleValueChange = (e) => {
        let valInit = null
        let ha = 0;
        let ari = 0;
        let valNum = 0;
        let multiplicator = 1;
        //const valoareAnterioara = e.previousValue;
        const valoareNoua = e.value;
        this.setState({ valoareInitiala: valoareNoua });

        valInit = valoareNoua.replace(",", ".").trim().split(" ");

        if (!isNaN(valInit[0])) {//daca e numar
            valNum = Number(valInit[0]);
            if (valInit.length === 1) {// daca nu se specifica unitatea, se ia implicit ca mp

                ha = this.calculHa(valNum).toString();
                ari = this.calculAri(valNum).toString();
            } else {
                switch (valInit[1].trim()) {
                    case "mp":
                        multiplicator = 1;
                        break
                    case "po"://pogon
                        multiplicator = 5000;
                        break;
                    case "ju"://jugar
                        multiplicator = 5755;
                        break;
                    case "la"://lantz
                        multiplicator = 5800;
                        break;
                    case "st"://stanjen
                        multiplicator = 3.6;
                        break;
                    case "pr"://prajina falceasca
                        multiplicator = 180;
                        break;
                    case "sm"://stanjen patrat moldovenesc
                        multiplicator = 4.97;
                        break;
                    case "ac"://acru
                        multiplicator = 5000;
                        break;
                    default:
                        multiplicator = 1;
                        break;
                }
                //ha = this.calculHa(valNum * multiplicator).toString();
                ha = (Math.round((this.calculHa(valNum * multiplicator) + Number.EPSILON) * 100) / 100).toString();
                //ari = this.calculAri(valNum * multiplicator).toString();
                ari = (Math.round((this.calculAri(valNum * multiplicator) + Number.EPSILON) * 100) / 100).toString();
            }
            this.setState({ ha: ha, ari: ari });
        }
        else
            alert("Valoarea introdusa nu e un numar!");
    }

    calculHa = (val) => {
        return (Math.floor(val / 10000));
    }
    calculAri = (val) => {
        return ((val - (Math.floor(val / 10000)) * 10000) / 100);
    }

    render() {
        return <Fragment>
            <div style={{ position: "relative", top: "-5px", ...this.props.style, marginTop:"5px" }} >
                <div className={clase.tooltip}>
                    <span className={clase.tooltiptext}>mp - metru patrat <br /> po - pogon <br /> ju - jugăr <br /> la - lanţ<br /> st - stânjen<br /> pr - prăjină<br /> sm - stânjen moldovenesc <br /> ac - acru</span>
                    <p style={{ display: "inline-block" }}>Convertor suprafeţe:</p>
                </div>
                <TextBox
                    style={{ display: "inline-block" }}
                    width={"100px"}
                    value={this.state.valoareInitiala}
                    onValueChanged={(e) => this.handleValueChange(e)}
                />
                <div style={{ display: "inline-block" }}>
                    <p style={{ display: "inline-block" }}>Ha: </p>
                    <TextBox
                        disabled
                        style={{ display: "inline-block" }}
                        width={"100px"}
                        value={this.state.ha}
                    />
                </div>
                <div style={{ display: "inline-block" }}>
                    <p style={{ display: "inline-block" }}>Ari: </p>
                    <TextBox
                        disabled
                        style={{ display: "inline-block" }}
                        width={"100px"}
                        value={this.state.ari}
                    />
                </div>
            </div>
        </Fragment>;
    }
}