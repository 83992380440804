import React, { Component, Fragment } from 'react';
import { alert } from "devextreme/ui/dialog";
import { Button, /*CheckBox,*/ TextBox, Popup, RadioGroup } from 'devextreme-react';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import clase from './AdeverinteTip.module.css';
import SetariAdeverinte from './SetariAdeverinte';
import service from './date';
import axios from 'axios';
import Utilitare from './../../../../../../content/Utilitare';
import serviceNrAdev from '../../../Jos/Capitole/CapVar/AdeverinteSablon/DateSablon';

class AdeverinteTip extends Component {
    state = {
        tipExport: [{ id: 0, text: "word" }, { id: 2, text: "excel" }, { id: 3, text: "pdf" }],
        tipExportSelectat: { id: 0, text: "word" },
        nrRegistratura: "........",
        caleFisier: "_",
        numeFisier: "_",
        numarDisabled: false,
        taxaTimbru: true,
        valoareTaxa: null,
        membruSelectat: null,
        dateMembri: null,
        scop: '_',
        adeverintaSelectata: null,
        popupSetariVisible: false
    }

    componentDidMount() {
        service.getDateAdeverinte(localStorage.getItem("siruta"), this.props.selectat.idRegistrulAgricol).then(r => {
            this.setState({
                idprim: r.adeverinte.idPrim,
                iduser: r.adeverinte.iduser,
                listaAdeverinte: r.adeverinte.listaRapoarte,
                reportServer: r.adeverinte.reportServer,
                gradeRudenie: r.gradeRudenie,
                membri: r.membri.randuri
            })

        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "AdeverinteTip");
        })
    }

    handleGetNrRegistratura = () => {
        if (!this.state.adeverintaSelectata) {
            alert("Nu aţi selectat niciun model de adeverinţă.");
            return;
        }

    /*pentru prezentare video*/
        //this.setState({
        //    nrRegistratura: (Math.floor(Math.random() * 1000) + 1).toString(),
        //    caleFisier: "_",
        //    numeFisier: "_",
        //    numarDisabled: true
        //});
        //return;
    /*end of pentru prezentare video*/

        serviceNrAdev.getNumarRegistratura(this.props.selectat.refDatePrimarie, this.props.selectat.idRegistrulAgricol).then(r => {
            if (!r.nrAdeverinta.nrCur
                || r.nrAdeverinta.nrCur === '........'
                || !r.nrAdeverinta.numeFisier
                || r.nrAdeverinta.numeFisier === ""
                || !r.nrAdeverinta.cale
                || r.nrAdeverinta.cale === "")
                alert("Nu există legătură cu aplicaţia Document Management şi Arhivare.<br />Pentru a instala/restaura interconectivitatea, contactaţi administratorul aplicaţiei.");

            this.setState({
                nrRegistratura: r.nrAdeverinta.nrCur,
                caleFisier: r.nrAdeverinta.cale && r.nrAdeverinta.cale !== "" ? r.nrAdeverinta.cale : "_",
                numeFisier: r.nrAdeverinta.numeFisier && r.nrAdeverinta.cale !== "" ? r.nrAdeverinta.numeFisier : "_",
                numarDisabled: true
            });
        }).catch(error => {//in caz de eroare
            Utilitare.handleEroare(error, "AdeverinteSablon");
            this.setState({
                nrRegistratura: "........",
                numarDisabled: false
            });
        })
    }

    handleNrRegistratura = (valoare) => {
        this.setState({ nrRegistratura: valoare });
    }

    showFile = (blob) => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([blob], { type: "application/" + this.state.tipExportSelectat.text })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        var extensie = "docx";
        switch (this.state.tipExportSelectat.text) {
            case "word":
                extensie = "doc";
                break;
            case "excel":
                extensie = "xls";
                break;
            case "pdf":
                extensie = "pdf";
                break;
            default:
                extensie = "word";
                break;
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download = "file." + extensie;
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    }

    handleRowClickAdeverinte = (selectat) => {
        this.setState({
            adeverintaSelectata: selectat,
            nrRegistratura: "........",
            caleFisier: "_",
            numeFisier: "_",
            numarDisabled: false
        });
    }

    handleAfiseazaAdeverinta = () => {
        if (!this.state.adeverintaSelectata) {
            alert("Nu aţi selectat nicio adeverinţă!");
            return;
        }

        let URL = (`./api/Rapoarte/PreviewReportTip?idprim=${this.state.idprim}&iduser=${this.state.iduser}&Parametru_Model_Adeverinta=${this.state.adeverintaSelectata.valoare}&Parametru_NrAdeverinta=${this.state.nrRegistratura}&Parametru_IDRegistrul=${this.props.selectat.idRegistrulAgricol}&Parametru_An=${localStorage.getItem("anSelectat")}&Parametru_Destinatie=${this.state.scop}&Parametru_TipG=${this.props.selectat.tip}&Parametru_datan=nu&Parametru_reprezentant_m=nu&Parametru_reprezentant_b=nu&Parametru_datanm=nu&Parametru_cnpm=nu&tipExportSelectat=${this.state.tipExportSelectat.text}&caleFisier=${this.state.caleFisier}&numeFisier=${this.state.numeFisier}`).replace(" ", "");        

        if (this.state.adeverintaSelectata.valoare === 3) {

            if (!this.state.membruSelectat) {
                alert("Nu aţi selectat niciun membru.");
                return;
            }

            URL = (`./api/Rapoarte/PreviewReportTip?idprim=${this.state.idprim}&iduser=${this.state.iduser}&Parametru_Model_Adeverinta=${this.state.adeverintaSelectata.valoare}&Parametru_NrAdeverinta=${this.state.nrRegistratura}&Parametru_IDRegistrul=${this.props.selectat.idRegistrulAgricol}&Parametru_An=${localStorage.getItem("anSelectat")}&Parametru_Destinatie=${this.state.scop}&Parametru_TipG=${this.props.selectat.tip}&Parametru_datan=12.12.2021&Parametru_reprezentant_m=${this.state.membruSelectat.numeleSiPrenumele}&Parametru_reprezentant_b=${this.state.membruSelectat.numeleSiPrenumele}&Parametru_datanm=10.10.2020&Parametru_cnpm=${this.state.membruSelectat.codNumericPersonal}&tipExportSelectat=${this.state.tipExportSelectat.text}&caleFisier=${this.state.caleFisier}&numeFisier=${this.state.numeFisier}`).replace(" ", "");
        }

        axios.get(URL, { responseType: 'blob' })
            .then((response) => {
                this.showFile(response.data);
                this.handleScrieJurnalOperare();

            })
            .catch((e) => {
                console.log("Eroare", e);
                this.handleScrieJurnalOperare();
            });

        this.handleScrieJurnalOperare();
    }

    handleScrieJurnalOperare = () => {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: './api/Rapoarte/PostAdeverinteJurnalOperare/' + localStorage.getItem("siruta") + '/' + this.props.selectat.idRegistrulAgricol + '/' + ' tip ' + "/" + this.state.adeverintaSelectata.valoare,
        })
            .then((response) => {
                if (response.data === false)
                    console.log("S-a produs o eroare la scrierea adeverintei in JurnalOperare");
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "AdeverinteTip");
            });
    }

    handleRowClickMembri = (date) => {
        this.setState({ membruSelectat: date })
    }

    handleScop = (valoare) => {
        this.setState({ scop: valoare });
    }

    handleAfiseazaSetari = () => { this.setState({ popupSetariVisible: true }) }
    handleAscundeSetari = () => { this.setState({ popupSetariVisible: false }) }

    render() {
        //console.log("AdeverinteTip state", this.state);
        //console.log("AdeverinteTip props", this.props);

        return (
            <Fragment>
                <div className={clase.FlexContainer}>
                    <div className={clase.Jumatati}>
                        <Button
                            style={{ display: "inline-block", position: "relative", top: "-2px" }}
                            text="Generează număr registratură"
                            type="success"
                            stylingMode="outlined"
                            onClick={this.handleGetNrRegistratura}
                        />
                        <TextBox
                            width={120}
                            style={{ margin: "10px 0 0 5px", display: "inline-block" }}
                            placeholder="Nr. Registratura"
                            value={this.state.nrRegistratura}
                            onValueChanged={(e) => this.handleNrRegistratura(e.value)}
                            disabled={this.state.numarDisabled === true && this.state.nrRegistratura !== "........"}
                        />
                        <span style={{ margin: "0 10px 0 30px" }}>Exportă </span>
                        <RadioGroup
                            style={{ display: "inline-block", position: "relative", top: "5px" }}
                            items={this.state.tipExport}
                            defaultValue={this.state.tipExport[0]}
                            layout="horizontal"
                            onValueChanged={(e) => { this.setState({ tipExportSelectat: e.value }) }}
                        />

                        <p>Se elibereaza pentru {this.state.membruSelectat ? <span className={clase.Bold}> {this.state.membruSelectat.numeleSiPrenumele} </span> : null},
                    <br />cnp: {this.state.membruSelectat ? <span className={clase.Bold}>{this.state.membruSelectat.codNumericPersonal}</span> : null},
                    <br />grad rudenie: {this.state.membruSelectat ? <span className={clase.Bold}>{this.state.gradeRudenie && this.state.gradeRudenie.find(x => x.valoare === this.state.membruSelectat.codRudenie) ? this.state.gradeRudenie.find(x => x.valoare === this.state.membruSelectat.codRudenie).denumire : null}</span> : null},
                    <br />vol: {this.state.membruSelectat ? <span className={clase.Bold}>{this.props.selectat.volum}</span> : null},
                    <br />poz: {this.state.membruSelectat ? <span className={clase.Bold}>{this.props.selectat.pozitie}</span> : null}
                        </p>
                        <p style={{ marginBottom: "6px" }}>Pentru a-i servi la:</p>
                        <TextBox
                            id={"tb2"}
                            className={clase.InputText}
                            placeholder="Destinaţie sau perioada păşunat(APIA)..."
                            onValueChanged={(e) => this.handleScop(e.value)}
                        />
                        <br />
                        <DataGrid
                            className={clase.InputText}
                            dataSource={this.state.membri}
                            showBorders={true}
                            focusedRowEnabled={true}
                            rowAlternationEnabled={false}
                            keyExpr={"idUnic"}
                            onRowClick={(e) => this.handleRowClickMembri(e.data)}
                        >
                            <Paging defaultPageSize={10} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[5, 10, 20]}
                                showInfo={true} />
                            <Column dataField="numeleSiPrenumele" caption={"Nume"} />
                            <Column dataField="codNumericPersonal" caption={"CNP"} />
                            <Column dataField="denumireRudenie" caption={"Grad Rudenie"} />
                        </DataGrid>
                    </div>
                    <div className={clase.Jumatati}>
                        <DataGrid
                            dataSource={this.state.listaAdeverinte}
                            showBorders={true}
                            focusedRowEnabled={true}
                            rowAlternationEnabled={false}
                            keyExpr={"valoare"}
                            onRowClick={(e) => this.handleRowClickAdeverinte(e.data)}
                        >
                            <Paging defaultPageSize={10} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[5, 10, 20]}
                                showInfo={true} />
                            <Column dataField="valoare" width={50} />
                            <Column dataField="denumire" />
                        </DataGrid>
                    </div>
                </div>

                <Popup
                    visible={this.state.popupSetariVisible}
                    dragEnabled={false}
                    closeOnOutsideClick={false}
                    showCloseButton={false}
                    showTitle={true}
                    title="Setări adeverinţe"
                >
                    <SetariAdeverinte
                        ascundeSetari={this.handleAscundeSetari}
                    />
                </Popup>

                <div className={clase.Butoane}>
                    <Button
                        className={clase.ButonGenerare}
                        style={{ marginRight: "-10px" }}
                        width={120}
                        text="Setări Adeverinţe"
                        type="default"
                        stylingMode="outlined"
                        onClick={this.handleAfiseazaSetari}
                    />

                    <Button
                        className={clase.ButonGenerare}
                        width={180}
                        text="Generează Adeverinţa"
                        type="success"
                        stylingMode="outlined"
                        onClick={this.handleAfiseazaAdeverinta}
                    />

                    <Button
                        className={clase.Buton}
                        width={120}
                        text="Anulează"
                        type="danger"
                        stylingMode="outlined"
                        onClick={this.props.ascunde}
                    />
                </div>
            </Fragment>
        );
    }
}

export default AdeverinteTip;