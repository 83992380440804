import axios from 'axios';

export default {
    getMiscari(siruta, idUnic) {//tip este tipul de grid din tabela ColoaneGridView
        return new Promise((resolve, reject) => {
            

            const miscari = axios.get("./api/Capitole/GetMiscari/" + siruta + "/" + idUnic);

            const categoriiFolosinta = axios.get("./api/Capitole/GetTipuriCategoriiFolosinta/");

            const tipuriCladiri = axios.get("./api/Capitole/GetTipuriCladiri/");

            const destinatiiCladiri = axios.get("./api/Capitole/GetTipuriDestinatiiCladiri/");

            axios.all([miscari, categoriiFolosinta, tipuriCladiri, destinatiiCladiri]).then(
                axios.spread((responseM, responseC, responseT, responseD) => {
                    let raspuns = {
                        miscari: responseM.data,
                        categoriiFolosinta: responseC.data,
                        tipuriCladiri: responseT.data,
                        destinatiiCladiri: responseD.data
                    }
                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    }
}