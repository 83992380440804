import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Utilitare from './../../content/Utilitare';
import { ScrollView, Button } from 'devextreme-react';
import valori from './StructuraMeniuAplicatie';
import { navigate } from '@reach/router';
import manual from './Manual Registrul Agricol.pdf';

class MeniuAplicatie extends Component {

    componentDidMount() {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "get",
            url: './api/Rapoarte/GetReportServer/' + localStorage.getItem("siruta"),
        })
            .then((response) => {
                if (response.data) {
                    if (response.data == null) {
                        alert("Eroare la conexiunea cu baza de date(MeniuSus.jsx)");
                    }
                    else
                        this.setState({
                            reportServer: response.data.reportServer,
                            iduser: response.data.iduser,
                            idPrim: response.data.idPrim
                        });
                }
            })
            .catch((error) => {
                Utilitare.handleEroare(error, "MeniuAplicatie");
            });
    }

    buttonClick(id) {
        if (id !== '0')
            this.props.inchidereMeniu();
        switch (id) {
            case '0':
                this.props.inchidereMeniu();
                break;
            case '1':
                this.props.handleDelogare();
                navigate(`./`);
                break;
            case '2':
                navigate(`./date-gospodarii`);
                break;
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
                break;
            case '3.1':
                navigate(`./apia`);
                break;
            case '3.2':
                navigate(`./ancpi`);
                break;
            case '3.3':
                navigate(`./rnep`);
                break;
            case '3.4':
                navigate(`./onrc`);
                break;
            case '3.5':
                navigate(`./ran`);
                break;
            case '6.5':
                break;
            case '4.1':
                navigate(`./defalcare-gospodarii`);
                break;
            case '4.2':
                navigate(`./unificare-gospodarii`);
                break;
            case '5.1':
                window.open(this.state.reportServer + 'Declaratie_RegAgricol' + '&rs:Command=Render&idprim=' + this.state.idPrim, '_blank', 'noopener');
                //navigate(`./date-gospodarii`);
                break;
            case '5.2':
                navigate(`./situatieGF`);
                break;
            case '5.3':
                navigate(`./rapoarteCentralizatoare`);
                break;
            case '5.4':
                navigate(`./productiaAnimala`);
                break;
            case '5.5':
                navigate(`./productiaVegetala`);
                break;
            case '6.1':
                navigate(`./capitole`);
                break;
            case '6.2':
                navigate(`./nomenclatoare`);
                break;
            case '6.3':
                navigate(`./dateLocalitate`);
                break;
            case '6.4':
                navigate(`./admInterfatareTaxe`);
                break;
            case '6.5.1':
                navigate(`./transfer`);
                break;
            case '7.1':
                navigate(`./adaugatiUtilizator`);
                break;
            case '7.2':
                navigate(`./modificatiUtilizator`);
                break;
            case '7.3':
                navigate(`./stergetiUtilizator`);
                break;
            case '8':
                navigate(`./utilizatori`);
                break;
            case '9':
                //navigate(`./utilizatori`);
                window.open(manual, '_blank', 'noopener');
                break;
            default:
                navigate(`./not-found`);
                break;
        }
    }

    butoane = (valori, list, margine) => {
        let butoane = list !== undefined ? list : [];
        let margini = margine !== undefined ? margine : 0;

        valori.forEach((btn, index) => {
            butoane.push(<Button
                key={btn.id}
                hint={btn.denumire}
                className={margini === 0 ? "" : btn.id.substring(0, btn.id.lastIndexOf("."))}
                style={{ display: "block", borderRadius: "0", textAlign: "left", marginLeft: margini.toString() + "px" }}
                width={300 - margini}
                text={btn.denumire}
                type="normal"
                stylingMode="outlined"
                icon={btn.icon}
                onClick={(e) => { this.buttonClick(btn.id) }
                }
            />);

            if (btn.items !== null && btn.items !== undefined)
                butoane = this.butoane(btn.items, butoane, margini + 10);
        }
        )
        return butoane;
    }

    render() {
        return (
            <Fragment>
                <ScrollView style={{ height: (window.innerHeight - 25).toString() + "px" }}>
                    {this.butoane(localStorage.getItem("role") === "UtilizatorExtern" ? valori.dateMeniuUtilizatorExtern : valori.dateMeniu)}
                </ScrollView>
            </Fragment>
        );
    }
}

export default MeniuAplicatie;